import { IAsset } from '@/types/Asset';
import { IFund } from '@/types/Fund';
import { IGeneralLedger } from '@/types/GeneralLedger';
import { IInvestmentEntity } from '@/types/IInvestmentEntity';
import { TInvestmentObjectClass } from '@/types/IInvestmentObject';
import { IUserRole } from '@/types/User';
import { IUserTag } from '@/types/IUserTag';
import { ILegalEntitiable } from 'types/LegalEntitiable';
import { LeClassification } from 'bundles/Shared/entities/leClasssification';
import { UserForPermitted } from 'bundles/Shared/entities/permissions';
import { TConnectionReportType } from '@/bundles/Shared/entities/connection/model';
import { ERPSystem } from '@/bundles/Shared/entities/erpsystem';

export const ExternalSourceType = {
  SYMMETRE: 'symmetre',
  QUICKBOOKS_DESKTOP: 'quickbooks_desktop',
  QUICKBOOKS_ONLINE: 'quickbooks_online',
  YARDI: 'yardi',
  MRI: 'mri',
  ENTRATA: 'entrata',
  M3: 'm3',
  REALPAGE: 'realpage',
  RESMAN: 'resman',
  CYMA: 'cyma',
  SAGE: 'sage',
  RENTEGI: 'rentegi',
  NET_SUITE: 'net_suite',
  FORTRESS: 'fortress',
  APP_FOLIO: 'app_folio',
  RASI: 'rasi',
  XERO: 'xero',
  RENT_MANAGER: 'rent_manager',
  BUILDIUM: 'buildium',
  PROPERTYWARE: 'propertyware',
  RENTVINE: 'rentvine',
  ZOHO: 'zoho',
  MICROSOFT_DYNAMICS_365: 'microsoft_dynamics_365',
  ACUMATICA: 'acumatica',
  INFOR: 'infor',
  RADIX: 'radix',
  ANYONE_HOME: 'anyone_home',
} as const satisfies Record<string, ERPSystem>;

export type ExternalSourceType =
  (typeof ExternalSourceType)[keyof typeof ExternalSourceType];

export type LegalEntity = {
  id: string;
  /**
   * @deprecated
   */
  code: string;
  name: string;
  excluded: boolean;
  legalEntitiable: Pick<
    ILegalEntitiable,
    'aasmState' | 'id' | 'name' | 'objectType' | 'pictureUrl' | 'slug'
  >;
  legalEntitiableType: TInvestmentObjectClass | null;
  legalEntitiableId: number | null;
  permitted: {
    public: boolean;
    roles: IUserRole[];
    tags: IUserTag[];
    users: UserForPermitted[];
    autoSelectedUsers: UserForPermitted[];
    investmentEntities: IInvestmentEntity[];
  };
  /**
   * @deprecated
   */
  source: ExternalSourceType;
  managementPeriods: LegalEntityManagementPeriod[];
  lastActivity?: {
    brief: {
      code: string;
      name: string;
      source: string;
    };
    id: number;
    kind: string;
    legalEntity: Pick<LegalEntity, 'id' | 'name'>;
    updatedAt: Date;
    whoDoneIt: {
      fullName: string;
      id: string;
    };
    details?: unknown;
  };
  classification?: LeClassification | null;
  generalLedgers?: IGeneralLedger[];
  capitalTransactionSources?: unknown[];
  entity?: IAsset | IFund;
  capitalInvestments?: {
    id: number;
    commitmentAmount: string;
    investmentEntity: IInvestmentEntity;
    legalEntity: Omit<LegalEntity, 'capitalInvestments'>;
    legalEntityCapitalTransactionSources: unknown[];
    totalContributions: string;
    totalContributionsCount: number;
    totalDistributions: string;
    totalDistributionsCount: number;
  }[];
};

export type LegalEntityManagementPeriod = {
  id: string;
  type: TConnectionReportType;
  legalEntity: LegalEntity;
  dateFrom: Date | null;
  dateTo: Date | null;
  erpSystem: ExternalSourceType;
  externalIdentifier: string;
  propertyManagementCompanyName: string;
  createdAt: Date;
  updatedAt: Date;
};

export interface LEConfigsMeta {
  perPage: string;
  totalFireStationPermissions: number;
  totalSize: number;
}

export interface LEKindsAmountMeta {
  notMappedSize: number;
  mappedSize: number;
  excludedSize: number;
}
