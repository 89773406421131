import { usePutApiCoreAssetsByAssetIdReportBuilderReportsAndIdMutation } from '@/entities/report/reportBuilder/api';
import { GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse } from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import {
  filterRedundantFieldsFromPayloadBody,
  isReportPayloadBodyAndReportDataEqual,
  transformFormWatchToPayloadBody,
} from '@/entities/report/reportBuilder/lib/helpers';
import { asserts } from '@/lib/typeHelpers/assertsType';
import { IAsset } from '@/types/Asset';
import { debounce } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

export const useDebouncedPutApiCoreAssetsReportBuilderReportsById = ({
  assetId,
  reportData,
  methods,
}: {
  assetId: IAsset['id'];
  reportData:
    | GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse
    | undefined;
  methods: UseFormReturn<
    {
      name: string;
      subtitle: string;
      date: string | undefined;
      legalEntityIds: string[];
      dateFormat: string;
    },
    unknown,
    undefined
  >;
}) => {
  const { watch } = methods;
  const [updateReport, updateOptions] =
    usePutApiCoreAssetsByAssetIdReportBuilderReportsAndIdMutation();
  const debouncedUpdateReport = useCallback(debounce(updateReport, 500), []);

  const onReportUpdate = () => {
    asserts(reportData, 'Fire this func where "data" exists!');

    const payloadBody = transformFormWatchToPayloadBody(watch);

    const { isFormAndDataEqual, isFormDateAndDataDateEqual } =
      isReportPayloadBodyAndReportDataEqual(reportData.report, payloadBody);

    if (isFormAndDataEqual) return;

    const filteredPayloadBody = filterRedundantFieldsFromPayloadBody({
      payloadBody,
      isFormDateAndDataDateEqual,
    });

    updateReport({
      assetId,
      id: reportData.report.id,
      body: filteredPayloadBody,
    });
  };

  useEffect(() => {
    const sub = watch((value, { name }) => {
      if (name !== 'legalEntityIds' && name !== 'date') return;
      if (reportData?.report == null) {
        return;
      }

      debouncedUpdateReport({
        id: reportData.report.id,
        assetId,
        body: {
          name: value.name!,
          date: value.date!,
          subtitle: value.subtitle!,
          date_format: value.dateFormat,
          legal_entity_ids:
            (value?.legalEntityIds as string[] | undefined) ?? [],
        },
      });
    });

    return () => {
      sub.unsubscribe();
    };
  }, [reportData]);

  return {
    onReportUpdate,
    updateOptions,
  };
};
