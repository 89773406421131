import { settingsEmailsCustomTemplateEnhancedApi } from '@/entities/emails/customTemplates/api/settingsEmailsCustomTemplateEnhancedApi';
import type { EmailCustomTemplate } from '@/entities/emails/customTemplates/model';
import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

export const useDeleteEmailCustomTemplateMutation = (params?: {
  shouldNavigateBack?: boolean;
}) => {
  const { navigateBack } = useNavigateBack({
    fallbackUrl: ROUTES_ROOT.settings.emails.templates.custom.fullPath,
  });

  const { confirm } = useModal();
  const [deleteCustomTemplate, options] =
    settingsEmailsCustomTemplateEnhancedApi.useDeleteEmailCustomTemplateMutation();

  const handleDeleteTemplate = async (
    templateId: EmailCustomTemplate['id'],
  ) => {
    const bool = await confirm({
      title: 'Delete Template',
      subtitle: 'Are you sure you want to delete this template?',
    });
    if (!bool) return;

    deleteCustomTemplate({
      templateId,
    });

    if (params?.shouldNavigateBack) {
      navigateBack();
    }
  };

  return [handleDeleteTemplate, options] as const;
};
