import React, { useState } from 'react';
import {
  AnimationLoader,
  Button,
  CurrencyFormatter,
  IconButton,
  LabelTitle,
} from 'stories';
import {
  generatePath,
  ROUTES_ROOT,
  TRouteParams,
} from '@/shared/lib/hooks/useNavigation';
import { navigate, useParams } from '@reach/router';
import {
  useGetInvoiceQuery,
  useMarkAsPaidInvoiceMutation,
} from 'bundles/Construction/api/invoices';
import { formatDate } from '@/shared/lib/formatting/dates';
import styles from 'bundles/DrawPackage/InvoicesFlow/Invoices/invoice/Invoice.module.scss';
import { cn } from '@/shared/lib/css/cn';
import LineItemsList from 'bundles/DrawPackage/InvoicesFlow/Invoices/approve/components/LineItemsList';
import TransactionCard from 'bundles/DrawPackage/InvoicesFlow/Invoices/pay/components/markAsPaid/TransactionCard';
import { SelectTransactionTable } from 'bundles/DrawPackage/InvoicesFlow/Invoices/pay/components/markAsPaid/SelectTransactionTable';
import { useModal } from '@/shared/lib/hooks/useModal';
import AddInvoicesModal from 'bundles/DrawPackage/InvoicesFlow/Invoices/pay/components/markAsPaid/AddInvoicesModal';
import { Invoice } from '@/shared/types/reconcile/Invoice';
import { SendDataButton } from 'bundles/DrawPackage/components/SendDataButton/SendDataButton';
import AllocationStatistics from 'bundles/REconcile/components/AllocationStatistics';
import InvoiceCard from 'bundles/DrawPackage/InvoicesFlow/Invoices/pay/components/markAsPaid/InvoiceCard';
import CustomPreview from 'bundles/Shared/components/FilePreviews/CustomPreview';
import KeyDetailsItem from 'bundles/DrawPackage/InvoicesFlow/Invoices/layouts/KeyDetailsItem';
import { IDevelopmentLegalEntityRequisitionTransaction } from 'bundles/REconcile/types/ILineItem';
import { generateRequisitionDates } from 'bundles/DrawPackage/utils';
import { useAllocation } from 'bundles/REconcile/components/AllocationProvider';
import { GapBlock } from 'bundles/DrawPackage/InvoicesFlow/Invoices/layouts/GapBlock';

export type IDevelopmentLegalEntityInvoiceTransaction = Pick<
  IDevelopmentLegalEntityRequisitionTransaction,
  'id' | 'value' | 'date' | 'vendorEntity'
>;

const MarkAsPaidInvoice = () => {
  const params =
    useParams<
      TRouteParams['RECONCILE_DEVELOPMENT_LEGAL_ENTITY_INVOICES_PAYMENT']
    >();

  const { data: invoice, isLoading } = useGetInvoiceQuery({
    legalEntityCode: params?.legalEntityCode,
    invoiceId: params.invoiceId,
  });

  const [markAsPaid, { isLoading: isMarkLoading, isSuccess: isMarkSuccess }] =
    useMarkAsPaidInvoiceMutation();

  const { openModal } = useModal();

  const inRequisition = invoice?.requisition;

  const [selectedTx, setSelectedTx] =
    useState<IDevelopmentLegalEntityInvoiceTransaction | null>(null);
  const [selectedInvoices, setSelectedInvoices] = useState<Invoice[]>([]);

  const selectTxAmount = Number(selectedTx?.value ?? 0);
  const invoiceAmount = Number(invoice?.amount) ?? 0;

  const {
    total,
    allocatedAmount: allocatedAmountInvoices,
    notAllocatedAmount,
    allocationFlags,
  } = useAllocation({
    total: selectTxAmount > invoiceAmount ? selectTxAmount : invoiceAmount,
    items:
      selectTxAmount < invoiceAmount
        ? [
            {
              amount: selectTxAmount,
            },
          ]
        : [
            ...selectedInvoices,
            {
              amount: invoice?.amount ?? 0,
            },
          ],
    sumMapper: ({ amount }) => Number(amount),
  });

  const { isNotFullyAllocated } = allocationFlags;

  const handleRemoveTransaction = () => {
    setSelectedTx(null);
  };

  const handleRemoveInvoice = (id: UUID) => {
    const newSelectedInvoices = selectedInvoices.filter((inv) => inv.id !== id);
    setSelectedInvoices(newSelectedInvoices);
  };

  const handleMarkAsPaid = async () => {
    if (selectedTx) {
      const joinInvoicesIds = [
        params.invoiceId,
        ...selectedInvoices.map((item) => item.id),
      ];
      await markAsPaid({
        legalEntityCode: params.legalEntityCode,
        invoiceId: params.invoiceId,
        body: {
          invoice_ids: joinInvoicesIds,
          transaction_id: selectedTx.id,
        },
      });
    }
  };

  if (isMarkSuccess) {
    navigate(
      generatePath(
        ROUTES_ROOT.reconcile.development.legalEntity.invoices.pay.fullPath,
        {
          legalEntityCode: params.legalEntityCode,
          invoiceId: params.invoiceId,
        },
      ),
    );
  }

  const handleClose = () => {
    navigate(
      generatePath(
        ROUTES_ROOT.reconcile.development.legalEntity.invoices.pay.fullPath,
        {
          legalEntityCode: params.legalEntityCode,
          invoiceId: params.invoiceId,
        },
      ),
    );
  };

  const addInvoices = () => {
    openModal(AddInvoicesModal, {
      legalEntityCode: params.legalEntityCode,
      selectedInvoices,
      setSelectedInvoices,
      allocatedAmount: notAllocatedAmount,
    });
  };
  return (
    <CustomPreview
      handleClose={handleClose}
      leftBlock={
        <SelectTransactionTable
          selectedTransaction={selectedTx}
          setSelectedTransaction={setSelectedTx}
        />
      }
      withoutHeader
    >
      {isLoading ? (
        <AnimationLoader />
      ) : (
        <div className="relative flex h-screen flex-col bg-white">
          <div className="absolute right-tw-5 top-tw-5 flex gap-tw-2">
            <IconButton
              iconName="close"
              size="l"
              onClick={handleClose}
              variant="secondary"
            />
          </div>
          <div className="flex-1">
            <div className="flex flex-col items-center gap-tw-2 pb-tw-4 pt-tw-6">
              <div className="body-semibold text-dark-60">Mark as Paid</div>
              <div className="flex w-full justify-center">
                <CurrencyFormatter
                  value={invoice?.amount}
                  classes={{
                    allParts: 'header2-regular text-dark-60',
                  }}
                />
              </div>
              <div className="body-regular flex justify-center gap-tw-1">
                <div>{formatDate(invoice?.date, 'MMM DD, YYYY')}</div>
                <div>•</div>
                <div>{invoice?.vendor.name}</div>
              </div>
            </div>
            <div
              className={cn(
                'flex flex-1 flex-col gap-tw-6',
                styles.tableBody,
                styles.markForAsPaidContainer,
              )}
            >
              <div>
                <div className="px-tw-6">
                  <div className="rounded-lg bg-light">
                    <KeyDetailsItem title="Invoice">
                      {invoice?.document.title}
                    </KeyDetailsItem>
                    <KeyDetailsItem title="Number">
                      {invoice?.number}
                    </KeyDetailsItem>
                    {inRequisition && (
                      <KeyDetailsItem title="Requisition">
                        <div className="flex items-center gap-tw-4 text-dark-60">
                          <div className="inline-regular text-light-60">
                            {generateRequisitionDates(
                              invoice?.requisition.beginningDate,
                              invoice?.requisition.endingDate,
                            )}
                          </div>
                        </div>
                      </KeyDetailsItem>
                    )}
                  </div>
                </div>
              </div>
              <GapBlock>
                <div className="body-semibold text-dark-60">
                  Development Line Items
                </div>
                <div>
                  <LineItemsList />
                </div>
              </GapBlock>
              <div className="flex flex-col gap-tw-2 px-tw-6">
                <AllocationStatistics
                  className="px-tw-4 pt-tw-4"
                  totalAmount={total}
                  allocatedAmount={allocatedAmountInvoices}
                />
              </div>
              <div className="flex flex-col gap-tw-1.5 px-tw-6 text-dark-60">
                <div className="body-semibold">Link to</div>
                <LabelTitle className="inline-regular">
                  Select Transaction
                </LabelTitle>
                <div className="flex flex-col gap-tw-1">
                  {selectedTx && (
                    <TransactionCard
                      vendorName={selectedTx.vendorEntity?.name}
                      {...selectedTx}
                      handleRemove={handleRemoveTransaction}
                    />
                  )}
                </div>
              </div>
              {Boolean(selectedInvoices.length) && (
                <div className="flex flex-col gap-tw-1.5 px-tw-6 text-dark-60">
                  <div className="body-semibold">Added invoices</div>
                  <div className="flex flex-col gap-tw-1">
                    {selectedInvoices.map((selectInv) => (
                      <InvoiceCard
                        key={selectInv.id}
                        {...selectInv}
                        handleRemove={handleRemoveInvoice}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-tw-3 bg-white px-tw-6 pb-tw-6">
              {selectTxAmount > invoiceAmount && isNotFullyAllocated && (
                <Button
                  variant="secondary"
                  className="w-full"
                  onClick={addInvoices}
                >
                  Add Invoices
                </Button>
              )}
              <SendDataButton
                variant="success"
                className="w-full"
                onClick={handleMarkAsPaid}
                disabled={isNotFullyAllocated || isMarkLoading}
                isLoading={isMarkLoading}
                disappearIconName="checkSmall"
              >
                Mark as Paid
              </SendDataButton>
            </div>
          </div>
        </div>
      )}
    </CustomPreview>
  );
};

export default MarkAsPaidInvoice;
