import { cn } from '@/shared/lib/css/cn';
import { ClassNameProps } from '@/types/Props';

type Props = React.PropsWithChildren<ClassNameProps>;

export const DashboardBoardNavigationContainer = (props: Props) => (
  <div
    className={cn(
      'flex items-center rounded-xl bg-neutral-200',
      props.className,
    )}
  >
    {props.children}
  </div>
);
