import { CornerArrow } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/CornerArrow';
import { IColumn } from '@/bundles/Shared/components/Table/types';
import { ReportManualVariableLegalEntity } from '@/entities/report/manualVariable';
import { formatDate } from '@/shared/lib/formatting/dates';
import { getNumberFormatterByDisplayOptions } from '@/shared/lib/formatting/table';
import { IconButton } from '@/stories';

const EMPTY_VALUE = <div className="flex p-tw-4">-</div>;

export function useManualVariableColumnDefs({
  onAddClick,
}: {
  onAddClick: ({
    legalEntity,
  }: {
    legalEntity: ReportManualVariableLegalEntity;
  }) => void;
}): IColumn<ReportManualVariableLegalEntity>[] {
  return [
    {
      dataField: 'name',
      text: 'Legal Entity',
      formatter: ({ row }) => <>{row.name}</>,
    },
    {
      dataField: 'dateFrom',
      text: 'Latest Date From',
      formatter: ({ row }) => (
        <>{row.dateFrom ? formatDate(row.dateFrom, 'MMM DD, YYYY') : '-'}</>
      ),
    },
    {
      dataField: 'dateTo',
      text: 'Latest Date To',
      formatter: ({ row }) => (
        <>{row.dateTo ? formatDate(row.dateTo, 'MMM DD, YYYY') : '-'}</>
      ),
    },
    {
      dataField: 'value',
      text: 'Active Value',
      classes: '!p-0',
      formatter: ({ row }) => {
        if (row.valueType == null) {
          return EMPTY_VALUE;
        }

        const Component = getNumberFormatterByDisplayOptions({
          type: row.valueType,
          precision: 0,
        });

        if (Component == null) {
          return EMPTY_VALUE;
        }

        return (
          <CornerArrow
            hidden={row.value == null}
            onClick={() => {
              if (row.value == null) return;
              onAddClick({ legalEntity: row });
            }}
          >
            <div className="flex justify-end p-tw-4">
              <Component value={row.value} />
            </div>
          </CornerArrow>
        );
      },
    },
    {
      dataField: 'actions',
      text: '',
      classes: 'w-[56px]',
      formatter: ({ row }) => (
        <div className="flex">
          <IconButton
            onClick={() => onAddClick({ legalEntity: row })}
            iconName="addSmall"
          />
        </div>
      ),
    },
  ];
}
