import { DistributionKind } from '@/bundles/REturn/actions/types';
import { DISTRIBUTION_LABEL_MAPPINGS } from '@/bundles/REturn/components/Ownership/modals/addDistributionsModal/AddDistributionsModal';
import { GetApiPortfolioActivityLogItemsApiResponse } from 'bundles/Shared/shared/api/portfolioGeneratedApi';
import { startCase } from 'lodash-es';

type ActivityLogItem =
  GetApiPortfolioActivityLogItemsApiResponse['items'][number];

type FeedMessageActivityLogItem = ActivityLogItem & {
  feedMessage: NonNullable<ActivityLogItem['feedMessage']>;
};

type TransactionContributionActivityLogItem = ActivityLogItem & {
  contribution: NonNullable<ActivityLogItem['contribution']>;
};

type TransactionDistributionActivityLogItem = ActivityLogItem & {
  distribution: NonNullable<ActivityLogItem['distribution']>;
};

type TransactionActivityLogItem =
  | TransactionContributionActivityLogItem
  | TransactionDistributionActivityLogItem;

export const isFeedMessageActivityLogItem = (
  item: ActivityLogItem,
): item is FeedMessageActivityLogItem => {
  return Boolean(item.feedMessage);
};

export const isTransactionActivityLogItem = (
  item: ActivityLogItem,
): item is TransactionActivityLogItem => {
  return Boolean(item.contribution) || Boolean(item.distribution);
};

export const isContributionActivityLogItem = (
  item: ActivityLogItem,
): item is TransactionContributionActivityLogItem => {
  return Boolean(item.contribution);
};

export const isDistributionActivityLogItem = (
  item: ActivityLogItem,
): item is TransactionDistributionActivityLogItem => {
  return Boolean(item.distribution);
};

export const getIdForActivityLogItem = (item: ActivityLogItem) => {
  if (isFeedMessageActivityLogItem(item)) return item.feedMessage.id;
  if (item.contribution) return `contribution-${item.contribution.id}`;
  if (item.distribution) return `distribution-${item.distribution.id}`;
  return item.createdAt;
};

export const getDistributionKinds = (item: ActivityLogItem) => {
  if (isDistributionActivityLogItem(item)) {
    return item.distribution.kinds
      ?.map(
        (k) =>
          DISTRIBUTION_LABEL_MAPPINGS[k as DistributionKind] || startCase(k),
      )
      .join(', ');
  }
};
