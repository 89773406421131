import { STATIC_DATE_PICKER_PROPS } from '@/bundles/REturn/components/Ownership/modals/consts';
import { IColumn } from '@/bundles/Shared/components/Table/types';
import { VariableValueType } from '@/entities/valueType/model';
import { ReportManualVariableLegalEntityValue } from '@/entities/report/manualVariable/model';
import { ValueTypeInput } from '@/entities/valueType/ui/ValueTypeInput';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { IconButton } from '@/stories';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';

export const ReactDatePickerWithState = ({
  defaultValue,
  onUpdate,
}: {
  defaultValue: string | Date | null;
  onUpdate: (date: Date | null) => void;
}) => {
  const [value, setValue] = useState<Date | null>(
    defaultValue ? dayjs(defaultValue).toDate() : null,
  );

  return (
    <ReactDatePicker
      {...STATIC_DATE_PICKER_PROPS}
      selected={value ?? null}
      onChange={(date) => {
        setValue(date);
      }}
      onBlur={() => {
        onUpdate(value);
      }}
    />
  );
};
export const useManualVariablesLEValuesColumnDefs = ({
  valueType,
  actions,
  deps,
}: {
  valueType: VariableValueType | null;
  actions: {
    onRemove: (id: ReportManualVariableLegalEntityValue['id']) => void;
    onUpdate: (v: ReportManualVariableLegalEntityValue) => void;
  };
  deps: React.DependencyList;
}) => {
  return useMemo<IColumn<ReportManualVariableLegalEntityValue>[]>(() => {
    return [
      {
        dataField: 'dateFrom',
        text: 'Date From',
        formatter: ({ row: item }) => (
          <ReactDatePickerWithState
            defaultValue={item.dateFrom}
            onUpdate={(date) => {
              actions.onUpdate({
                ...item,
                dateFrom: date ? formatToDateStringForRequest(date) : null,
              });
            }}
          />
        ),
      },
      {
        dataField: 'dateTo',
        text: 'Date To',
        formatter: ({ row: item }) => (
          <ReactDatePickerWithState
            defaultValue={item.dateTo}
            onUpdate={(date) => {
              actions.onUpdate({
                ...item,
                dateTo: date ? formatToDateStringForRequest(date) : null,
              });
            }}
          />
        ),
      },
      {
        dataField: 'value',
        text: 'Value',
        formatter: ({ row }) => {
          return (
            <ValueTypeInput
              valueType={valueType ?? 'number'}
              value={row.value}
              onChange={(value) => {
                actions.onUpdate({
                  ...row,
                  value,
                });
              }}
            />
          );
        },
      },
      {
        dataField: 'actions',
        headerClasses: 'w-[56px]',
        text: '',
        formatter: ({ row }) => (
          <>
            <IconButton
              iconName="trash"
              size="m"
              onClick={() => actions.onRemove(row.id)}
            />
          </>
        ),
      },
    ];
  }, deps);
};
