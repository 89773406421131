import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { MasterUnitTableWidget } from '@/bundles/Shared/widgets/dashboard/widgets/masterUnitTable/widget';
import { GridOptions, ICellRendererParams } from 'ag-grid-community';
import { BasicCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/BasicCellRenderer';
import { Label } from '@/stories';

import { formatUnitStatus } from '@/entities/report/unitTypes/lib';
import { RENTROLL_UNIT_STATUS_COLORS } from '@/entities/report/unitTypes/config';
import { getDefaultAgGridNumberColDef } from '@/shared/lib/formatting/table';
import { convertCentsToDollars } from '@/shared/lib/converters';
import { TRentrollUnitStatus } from '@/entities/report/unitTypes/model';
import {
  ColDef,
  ValueFormatterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';
import { startCase } from 'lodash-es';
import { TableVizConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';

export const MASTER_UNIT_TABLE_WIDGET_CONFIG = {
  layout: {
    w: 2,
    h: 13,
    maxH: 12,
    minH: 6,
    minW: 1,
    maxW: 2,
  },
  title: 'Master Unit Table',
  icon: 'table',
  Component: MasterUnitTableWidget,
} as const satisfies WidgetConfiguration;

const UNIT_STATUS_CELL_RENDERER = (params: ICellRendererParams) => (
  <BasicCellRenderer {...params}>
    <Label
      color={RENTROLL_UNIT_STATUS_COLORS[params.value as TRentrollUnitStatus]}
    >
      {params.valueFormatted}
    </Label>
  </BasicCellRenderer>
);

const unitStatusValueFormatter = (params: ValueFormatterParams) =>
  formatUnitStatus(params.value);

export const LEASE_DETAILS_MODAL_DEFAULT_AG_GRID_CONFIG = {
  columnDefs: [
    {
      colId: 'residentName',
      headerName: 'Resident Name',
      field: 'residentName',
    },
    {
      groupId: 'general',
      headerName: 'General',
      headerGroupComponentParams: {
        style: {
          backgroundColor: 'rgba(11, 23, 48, 1)',
        },
      },
      children: [
        {
          headerName: 'Trade Out',
          colId: 'tradeOut',
          field: 'tradeoutCents',
          valueGetter: ({ data }) => convertCentsToDollars(data.tradeoutCents),
          ...getDefaultAgGridNumberColDef({
            type: 'currency',
          }),
        },
        {
          headerName: 'Lease Rent',
          colId: 'actualRent',
          field: 'actualRentCents',
          valueGetter: ({ data }) =>
            convertCentsToDollars(data.actualRentCents),
          ...getDefaultAgGridNumberColDef({
            type: 'currency',
          }),
        },
        {
          headerName: 'Lease Type',
          colId: 'leaseType',
          field: 'leaseType',
          valueFormatter: (params) => startCase(params.value),
        },
        {
          headerName: 'Lease Status',
          colId: 'leaseStatus',
          field: 'status',
        },
      ],
    },
    {
      groupId: 'moveDetails',
      headerName: 'Move Date',
      headerGroupComponentParams: {
        style: {
          backgroundColor: 'rgba(8, 80, 128, 1)',
          borderColor: 'rgba(22, 139, 221, 1)',
        },
      },
      children: [
        {
          headerName: 'Move-In',
          colId: 'moveIn',
          field: 'moveInDate',
          ...getDefaultAgGridNumberColDef({
            type: 'date',
            date_format: 'MM/DD/YYYY',
          }),
        },
        {
          headerName: 'Move Out',
          colId: 'moveOut',
          field: 'moveOutDate',
          ...getDefaultAgGridNumberColDef({
            type: 'date',
            date_format: 'MM/DD/YYYY',
          }),
        },
      ],
    },
    {
      groupId: 'leaseDetails',
      headerName: 'Lease Details',
      headerGroupComponentParams: {
        style: {
          backgroundColor: 'rgba(58, 29, 106, 1)',
          borderColor: 'rgba(96, 64, 169, 1)',
        },
      },
      children: [
        {
          headerName: 'Term',
          colId: 'leaseTerm',
          field: 'termMonths',
        },
        {
          headerName: 'Signed Date',
          colId: 'signDate',
          field: 'signDate',
          ...getDefaultAgGridNumberColDef({
            type: 'date',
            date_format: 'MM/DD/YYYY',
          }),
        },
        {
          headerName: 'Start Date',
          colId: 'startDate',
          field: 'startDate',
          initialSort: 'desc',
          ...getDefaultAgGridNumberColDef({
            type: 'date',
            date_format: 'MM/DD/YYYY',
          }),
        },
        {
          headerName: 'End Date',
          colId: 'endDate',
          field: 'endDate',
          ...getDefaultAgGridNumberColDef({
            type: 'date',
            date_format: 'MM/DD/YYYY',
          }),
        },
      ],
    },
    {
      groupId: 'additional',
      headerName: 'Additional',
      headerGroupComponentParams: {
        style: {
          backgroundColor: 'rgba(11, 23, 48, 1)',
          borderColor: 'rgba(33, 42, 47, 1)',
        },
      },
      // balance, deposit
      children: [
        {
          headerName: 'Balance',
          colId: 'balance',
          field: 'balanceCents',
          valueGetter: ({ data }) => convertCentsToDollars(data.balanceCents),
          ...getDefaultAgGridNumberColDef({
            type: 'currency',
          }),
        },
        {
          headerName: 'Deposit',
          colId: 'deposit',
          field: 'totalDepositCents',
          valueGetter: ({ data }) =>
            convertCentsToDollars(data.totalDepositCents),
          ...getDefaultAgGridNumberColDef({
            type: 'currency',
          }),
        },
      ],
    },
  ],
} satisfies Partial<GridOptions>;

export const MASTER_UNIT_TABLE_VIZ_CONFIG = {
  column_groups: [
    {
      group_id: 'general',
      header_name: 'General',
      order: 0,
      background: 'rgba(11, 23, 48, 1)',
      icon: null,
    },
    {
      group_id: 'leasedStatus',
      header_name: 'Leased Status',
      order: 1,
      background: 'rgba(58, 29, 106, 1)',
      border_color: 'rgba(96, 64, 169, 1)',
      icon: null,
    },
    {
      group_id: 'physical',
      header_name: 'Physical',
      order: 2,
      background: 'rgba(11, 98, 79, 1)',
      border_color: 'rgba(19, 137, 117, 1)',
      icon: null,
    },
    {
      group_id: 'leaseDetails',
      header_name: 'Lease Details (Current Tenant)',
      order: 3,
      background: 'rgba(3, 40, 98, 1)',
      border_color: 'rgba(56, 83, 169, 1)',
      icon: null,
    },
    {
      group_id: 'rentFees',
      header_name: 'Rent & Fees (Current Tenant)',
      order: 4,
      background: 'rgba(11, 98, 79, 1)',
      border_color: 'rgba(19, 137, 117, 1)',
      icon: null,
    },
    {
      group_id: 'futureTenant',
      header_name: 'Future Tenant',
      order: 5,
      background: 'rgba(8, 80, 128, 1)',
      border_color: 'rgba(22, 139, 221, 1)',
      icon: null,
    },
  ],
  columns: [
    {
      group_id: 'general',
      col_id: '0',
      key: 'sqft',
      order: 0,
      value_display_options: {
        type: 'sqft',
        precision: 2,
      },
    },
    {
      group_id: 'general',
      col_id: '1',
      key: 'source_unit_type_name',
      order: 1,
    },
    {
      group_id: 'general',
      col_id: '2',
      key: 'unit_type_name',
      order: 2,
    },
    {
      group_id: 'leasedStatus',
      col_id: '3',
      key: 'leased_status',
      order: 1,
    },
    {
      group_id: 'physical',
      col_id: '4',
      key: 'occupied_status',
      order: 0,
    },
    {
      group_id: 'physical',
      col_id: '5',
      key: 'occupied_in_30_days_status',
      order: 1,
    },
    {
      group_id: 'physical',
      col_id: '6',
      key: 'occupied_in_60_days_status',
      order: 2,
    },
    {
      group_id: 'physical',
      col_id: '7',
      key: 'occupied_in_90_days_status',
      order: 3,
    },
    {
      group_id: 'leaseDetails',
      col_id: '8',
      key: 'current_lease_resident_name',
      order: 0,
    },
    {
      group_id: 'leaseDetails',
      col_id: '9',
      key: 'current_lease_move_in_date',
      order: 1,
      value_display_options: {
        type: 'date',
        date_format: 'MM/DD/YYYY',
      },
    },
    {
      group_id: 'leaseDetails',
      col_id: '10',
      key: 'current_lease_end_date',
      order: 2,
      value_display_options: {
        type: 'date',
        date_format: 'MM/DD/YYYY',
      },
    },
    {
      group_id: 'leaseDetails',
      col_id: '12',
      key: 'current_lease_renewal_status',
      order: 3,
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
        hide_comma_separator: false,
      },
    },
    {
      group_id: 'leaseDetails',
      col_id: '11',
      key: 'current_lease_move_out_date',
      order: 4,
      value_display_options: {
        type: 'date',
        date_format: 'MM/DD/YYYY',
      },
    },
    {
      group_id: 'rentFees',
      col_id: '13',
      key: 'market_rent',
      order: 0,
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
      },
    },
    {
      group_id: 'rentFees',
      col_id: '14',
      key: 'asking_rent',
      order: 1,
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
        hide_comma_separator: false,
      },
    },
    {
      group_id: 'rentFees',
      col_id: '15',
      key: 'current_lease_actual_rent',
      order: 2,
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
      },
    },
    {
      group_id: 'rentFees',
      col_id: '16',
      key: 'current_lease_tradeout_rent_diff',
      order: 3,
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
      },
    },
    {
      group_id: 'rentFees',
      col_id: '17',
      key: 'current_lease_rent_psf',
      order: 4,
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
      },
    },
    {
      group_id: 'rentFees',
      col_id: '18',
      key: 'current_lease_balance',
      order: 5,
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
      },
    },
    {
      group_id: 'rentFees',
      col_id: '19',
      key: 'current_lease_parking_fee',
      order: 6,
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
      },
    },
    {
      group_id: 'rentFees',
      col_id: '20',
      key: 'current_lease_pet_fee',
      order: 7,
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
      },
    },
    {
      group_id: 'rentFees',
      col_id: '21',
      key: 'current_lease_storage_fee',
      order: 8,
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
      },
    },
    {
      group_id: 'rentFees',
      col_id: '22',
      key: 'current_lease_laundry_fee',
      order: 9,
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
      },
    },
    {
      group_id: 'rentFees',
      col_id: '23',
      key: 'current_lease_utilities_fee',
      order: 10,
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
      },
    },
    {
      group_id: 'rentFees',
      col_id: '24',
      key: 'current_lease_leasing_fee',
      order: 11,
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
      },
    },
    {
      group_id: 'rentFees',
      col_id: '25',
      key: 'current_lease_penalty_fee',
      order: 12,
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
      },
    },
    {
      group_id: 'rentFees',
      col_id: '26',
      key: 'current_lease_misc_fee',
      order: 13,
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
      },
    },
    {
      group_id: 'rentFees',
      col_id: '27',
      key: 'current_lease_concessions_fee',
      order: 14,
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
      },
    },
    {
      group_id: 'futureTenant',
      col_id: '28',
      key: 'future_lease_resident_name',
      order: 0,
    },
    {
      group_id: 'futureTenant',
      col_id: '29',
      key: 'future_lease_actual_rent',
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
      },
      order: 1,
    },
    {
      group_id: 'futureTenant',
      col_id: '30',
      key: 'future_lease_move_in_date',
      order: 2,
      value_display_options: {
        type: 'date',
        date_format: 'MM/DD/YYYY',
      },
    },
  ],
  rows: [],
} as const satisfies TableVizConfig;

export const DEFAULT_MASTER_UNIT_TABLE_WIDGET_CONFIG = {
  viz_config: MASTER_UNIT_TABLE_VIZ_CONFIG,
  viz_type: 'table',
};

export const COL_DEF_OVERRIDES: Record<string, Partial<ColDef>> = {
  leased_status: {
    cellRenderer: UNIT_STATUS_CELL_RENDERER,
    valueFormatter: unitStatusValueFormatter,
  },
  occupied_status: {
    cellRenderer: UNIT_STATUS_CELL_RENDERER,
    valueFormatter: unitStatusValueFormatter,
  },
};
