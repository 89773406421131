import { DateSSN } from '@/shared/lib/dateSSN/ssn';

import {
  VariableValueTypeValue,
  VariableValueType,
} from '@/entities/valueType/model';

import InputNumber from '@/bundles/Shared/components/GroupForm/FormItems/InputNumber';

import CurrencyInputNumber from '@/bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import {
  convertDecimalToPercentage,
  convertPercentageToDecimal,
} from '@/shared/lib/converters';
import { ReactDatePickerWithState } from '@/widgets/report/manualVariable/settings/lib';
import { VALUE_TYPE_ICON } from '@/entities/valueType/config';
import { Input } from '@/stories';

interface ValueTypeInputProps<T extends VariableValueType> {
  valueType: T;
  value: VariableValueTypeValue<T>;
  onChange: (value: VariableValueTypeValue<T>) => void;
}

const NumberValueTypeInput = ({
  value,
  onChange,
}: ValueTypeInputProps<'number'>) => (
  <InputNumber
    defaultValue={value}
    size="m"
    onBlur={(newValue) => {
      onChange(newValue ?? 0);
    }}
  />
);

const PercentageValueTypeInput = ({
  value,
  onChange,
}: ValueTypeInputProps<'percentage'>) => (
  <InputNumber
    rightIcon={VALUE_TYPE_ICON.percentage}
    defaultValue={convertDecimalToPercentage(value)}
    size="m"
    onBlur={(newValue) => {
      onChange(convertPercentageToDecimal(newValue ?? 0));
    }}
  />
);

const CurrencyValueTypeInput = ({
  value,
  onChange,
}: ValueTypeInputProps<'currency'>) => (
  <CurrencyInputNumber defaultValue={value} onBlur={onChange} />
);

const SqftValueTypeInput = ({
  value,
  onChange,
}: ValueTypeInputProps<'sqft'>) => (
  <InputNumber
    rightIcon={VALUE_TYPE_ICON.sqft}
    defaultValue={value}
    size="m"
    onBlur={onChange}
  />
);

const MetricValueTypeInput = ({
  value,
  onChange,
}: ValueTypeInputProps<'metric'>) => (
  <InputNumber
    rightIcon={VALUE_TYPE_ICON.metric}
    defaultValue={value}
    size="m"
    onBlur={onChange}
  />
);

const DateValueTypeInput = ({
  value,
  onChange,
}: ValueTypeInputProps<'date'>) => {
  const currentDate = new DateSSN(value).toDate();
  const handleUpdate = (date: Date | null) => {
    const newValue = new DateSSN(date ?? new Date()).toNumber();
    onChange(newValue);
  };
  return (
    <ReactDatePickerWithState
      defaultValue={currentDate}
      onUpdate={handleUpdate}
    />
  );
};

const TextValueTypeInput = ({
  value,
  onChange,
}: ValueTypeInputProps<'text'>) => (
  <Input
    defaultValue={value}
    size="m"
    onBlur={(e) => {
      onChange(e.target.value);
    }}
  />
);

const VALUE_TYPE_INPUT_MAP = {
  number: NumberValueTypeInput,
  percentage: PercentageValueTypeInput,
  currency: CurrencyValueTypeInput,
  sqft: SqftValueTypeInput,
  metric: MetricValueTypeInput,
  date: DateValueTypeInput,
  text: TextValueTypeInput,
} as const satisfies Record<
  VariableValueType,
  React.FC<ValueTypeInputProps<VariableValueType>>
>;

export const ValueTypeInput = <T extends VariableValueType>({
  ...props
}: ValueTypeInputProps<T>) => {
  const InputComponent = VALUE_TYPE_INPUT_MAP[props.valueType];
  if (!InputComponent) {
    return <TextValueTypeInput {...(props as ValueTypeInputProps<'text'>)} />;
  }

  return <InputComponent {...(props as unknown as ValueTypeInputProps<T>)} />;
};
