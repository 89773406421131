import { XYChartWidgetState } from '@/bundles/Shared/widgets/dashboard/widgets/xyChart/widget';
import { diffDates } from '@/shared/lib/date';
import dayjs from 'dayjs';
import * as am5 from '@amcharts/amcharts5';
import { AxisRenderer, DateAxis } from '@amcharts/amcharts5/xy';
import { formatDateToQuarterWithPrefix } from '@/shared/lib/formatting/dates';

// Create axis ranges
const createDateAxisRange = (
  dateAxis: DateAxis<AxisRenderer>,
  startDate: Date,
  endDate: Date,
) => {
  const rangeDataItem = dateAxis.makeDataItem({
    value: startDate.getTime(),
    endValue: endDate.getTime(),
  });

  const range = dateAxis.createAxisRange(rangeDataItem);

  range.get('label')?.setAll({
    fill: am5.color(0x000000),
    text: formatDateToQuarterWithPrefix(startDate),
    location: 0.5,
    dy: 0,
  });
};

const removeNonRangeLabels = (dateAxis: DateAxis<AxisRenderer>) => {
  dateAxis
    .get('renderer')
    ?.labels.template.adapters.add('text', (text, target) => {
      return target.dataItem?._settings?.isRange ? text : '';
    });
};

export const replaceDateLabelsWithQuarters = (
  dateAxis: DateAxis<AxisRenderer>,
  state: XYChartWidgetState,
) => {
  removeNonRangeLabels(dateAxis);

  const dateFromStartOfQuarter = dayjs(state.dateFrom).startOf('quarter');
  const dateToNextQuarter = dayjs(state.dateTo).endOf('quarter').add(1, 'day');
  const quartersCountInRange = diffDates(
    dateToNextQuarter.toDate(),
    dateFromStartOfQuarter.toDate(),
    'quarter',
  );

  Array.from({
    length: quartersCountInRange,
  }).forEach((_, index) => {
    const startOfQuarter = dateFromStartOfQuarter.add(index, 'quarter');
    const endOfQuarter = startOfQuarter.endOf('quarter');

    createDateAxisRange(
      dateAxis,
      startOfQuarter.toDate(),
      endOfQuarter.toDate(),
    );
  });
};
