import { isTextTableVizConfigRow } from './../common/ui/table/model';
import {
  CellClassParams,
  ColDef,
  FirstDataRenderedEvent,
  ICellRendererParams,
} from 'ag-grid-community';
import { DashboardHistoricalReviewTableWidgetDto } from 'bundles/Shared/shared/api/dashboardsGeneratedApi';
import {
  ColDefBuilder,
  resolveBackgroundAndTextColor,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/ColumnDefsBuilder';
import {
  TableVizConfigColumn,
  TableVizConfigRow,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';

import {
  getDefaultAgGridNumberColDef,
  StyledBasicCellRendererProps,
} from '@/shared/lib/formatting/table';
import { WidgetViewMode } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { sleepUntilAgGridSetsRowDataInTime } from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib/utils';
import { buildExcelStyleId } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import { WidgetTableTextCellRenderer } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/cellRenderers/TextCellRenderer';

type HistoryRow = {
  value: string;
  date_from: DateString;
  date_to: DateString;
};

type HistoricalTableWidgetDtoRow =
  DashboardHistoricalReviewTableWidgetDto['data'][0] & {
    key?: number;
    history?: {
      [key: number]: HistoryRow[];
    };
  };

const isLabelColumn = (columnSettings: TableVizConfigColumn) => {
  return columnSettings.key == 'label';
};

export class RowColDefBuilder<
  Column extends {
    label: string;
    key: number;
  },
> extends ColDefBuilder<Column> {
  rows: TableVizConfigRow[];
  constructor({
    mode,
    onPinColumn,
    rows,
  }: {
    mode: WidgetViewMode;
    rows: TableVizConfigRow[];
    onPinColumn?: (colId: string) => unknown;
  }) {
    super({
      mode,
      onPinColumn,
    });
    this.rows = rows ?? [];
  }

  findRowSettings(rowKey: string | undefined) {
    return this.rows.find((r) => r.key === rowKey);
  }

  build({
    column,
    columnSettings,
  }: {
    columnSettings: TableVizConfigColumn;
    column?: Column;
  }): ColDef {
    const { cellRenderer: _, ...inheritedColDef } = super.build({
      column,
      columnSettings,
    });
    return {
      ...inheritedColDef,
      sortable: false,
      comparator: undefined,
      headerName: isLabelColumn(columnSettings)
        ? ''
        : inheritedColDef.headerName,
      // row excel styles works only with cellClassRules
      cellClassRules: Object.fromEntries(
        this.rows.map((row) => [
          buildExcelStyleId({
            id: row.key,
            type: 'row',
          }),
          (params: CellClassParams) => {
            const currentRow = params.data as HistoricalTableWidgetDtoRow;
            return (
              currentRow.key?.toString() === row.key &&
              !isLabelColumn(columnSettings)
            );
          },
        ]),
      ),
      cellRendererParams: (
        params: ICellRendererParams<HistoricalTableWidgetDtoRow>,
      ) => {
        const row = params.data;
        const rowSettings = this.findRowSettings(row?.key?.toString());

        const inheritedParams = super
          .build({ column, columnSettings })
          .cellRendererParams(params);

        const resolveAlignment = () => {
          if (isLabelColumn(columnSettings)) {
            return 'left';
          }
          return columnSettings.align ?? 'right';
        };

        return {
          ...inheritedParams,
          ...this.buildAlignmentCellParams(resolveAlignment(), params),
          styles: {
            ...inheritedParams.styles,
            ...(isLabelColumn(columnSettings)
              ? {}
              : resolveBackgroundAndTextColor({
                  params,
                  direction: 'row',
                  shouldApplyGradient: () => !isLabelColumn(columnSettings),
                  background: rowSettings?.background,
                  comparison: rowSettings?.comparison,
                })),
            fontWeight: rowSettings?.font_weight ?? 'normal',
          },
        } satisfies StyledBasicCellRendererProps;
      },
      cellRendererSelector: (params: ICellRendererParams) => {
        if (isLabelColumn(columnSettings)) {
          const { cellRenderer } = getDefaultAgGridNumberColDef({
            type: 'text',
          });
          return {
            component: cellRenderer,
            params,
          };
        }
        const row = params.data as HistoricalTableWidgetDtoRow;
        const rowSettings = this.findRowSettings(row.key?.toString());

        if (rowSettings && isTextTableVizConfigRow(rowSettings)) {
          return {
            component: WidgetTableTextCellRenderer,
            params: {
              ...params,
              config: rowSettings.cell_renderer,
            },
          };
        }

        const { cellRenderer } = getDefaultAgGridNumberColDef(
          rowSettings?.value_display_options ?? {
            type: 'number',
          },
        );

        return {
          component: cellRenderer,
          params,
        };
      },
    };
  }
}

export const handleHistoricalWidgetFirstDataRendered = async (
  e: FirstDataRenderedEvent,
) => {
  await sleepUntilAgGridSetsRowDataInTime();
  e.columnApi.autoSizeColumn('label');
  e.api.sizeColumnsToFit();
};
