import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiEmailBuilderCustomTemplates: build.mutation<
      PostApiEmailBuilderCustomTemplatesApiResponse,
      PostApiEmailBuilderCustomTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/email_builder/custom_templates`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    patchApiEmailBuilderCustomTemplatesById: build.mutation<
      PatchApiEmailBuilderCustomTemplatesByIdApiResponse,
      PatchApiEmailBuilderCustomTemplatesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/email_builder/custom_templates/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    getApiEmailBuilderCustomTemplatesMeta: build.query<
      GetApiEmailBuilderCustomTemplatesMetaApiResponse,
      GetApiEmailBuilderCustomTemplatesMetaApiArg
    >({
      query: () => ({ url: `/api/email_builder/custom_templates/meta` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as settingsEmailsCustomTemplateGeneratedApi };
export type PostApiEmailBuilderCustomTemplatesApiResponse = unknown;
export type PostApiEmailBuilderCustomTemplatesApiArg = {
  body: {
    custom_template: {
      name: string;
      schedule: string;
      template_attributes: {
        subject: string;
        active: boolean;
      };
      sections_attributes: {
        position: number;
        type: string;
        text_section_attributes?: {
          text: string;
          id?: number;
          _destroy?: boolean;
        };
        header_section_attributes?: {
          text_attributes: {
            content: string;
            text_variables_attributes: {
              variable_id: string;
              name_in_text: string;
            }[];
          };
        };
      }[];
      reports_attributes: {
        report_builder_template_id: string;
        asset_id: number;
        id?: string;
        _destroy?: boolean;
      }[];
    };
  };
};
export type PatchApiEmailBuilderCustomTemplatesByIdApiResponse = unknown;
export type PatchApiEmailBuilderCustomTemplatesByIdApiArg = {
  id: string;
  body: {
    custom_template: {
      name: string;
      schedule: string;
      template_attributes: {
        subject: string;
        active: boolean;
      };
      sections_attributes: {
        position: number;
        type: string;
        text_section_attributes?: {
          text: string;
          id?: number;
          _destroy?: boolean;
        };
        header_section_attributes?: {
          text_attributes: {
            content: string;
            text_variables_attributes: {
              variable_id: string;
              name_in_text: string;
            }[];
          };
        };
      }[];
      reports_attributes: {
        report_builder_template_id: string;
        asset_id: number;
      }[];
    };
  };
};
export type GetApiEmailBuilderCustomTemplatesMetaApiResponse =
  /** status 200 success */ {
    assets: CustomTemplateAssetDto[];
    reportBuilderTemplates?: CustomTemplateReportBuilderTemplateDto[];
  };
export type GetApiEmailBuilderCustomTemplatesMetaApiArg = void;
export type CustomTemplateAssetDto = {
  id: number;
  name: string;
  slug: string;
};
export type CustomTemplateReportBuilderTemplateDto = {
  id: string;
  name: string;
  tags: string[];
};
