// Move to: src/bundles/Shared/components/Filters/SetFilter.tsx ?
import React, { ComponentProps } from 'react';
import { Popover, SearchInput } from '@/stories';
import SplittedCheckList from 'stories/Checkbox/SplittedCheckList';
import { ListOption } from 'stories/Checkbox/CheckList';
import UniversalFilterButton from 'bundles/Shared/components/Filters/buttons/universalFilterButton/UniversalFilterButton';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import fuzzysort from 'fuzzysort';
import { useItemsFilterByText } from '@/shared/lib/hooks/useItemsFilterByText';
import { GrowDiv } from '@/shared/ui/GrowDiv';

type PopoverProps = Omit<
  ComponentProps<typeof Popover>,
  'template' | 'trigger'
>;
interface Props extends PopoverProps {
  items: ListOption[];
  value: ListOption[];
  onChange: (items: ListOption[]) => void;
  label: string;
  filterName?: string;
  actions?: React.ReactNode;
}

/** @deprecated use src/bundles/Shared/components/AgGrid/Table/filters/SetFilterComponent.tsx instead */
function SetFilter(props: Props) {
  const {
    items,
    value,
    onChange,
    label,
    filterName = '',
    actions,
    placement = 'bottom-start',
    ...popoverProps
  } = props;

  const { filteredItems, inputProps, searchText, setSearchText } =
    useItemsFilterByText<{
      value: string | number;
      label: string;
    }>(
      items,
      (item, searchText) => fuzzysort.go(searchText, [item.label]).length > 0,
    );

  return (
    <div className="flex w-full items-center gap-tw-2">
      {filterName}
      <Popover
        className="p-m"
        trigger="click"
        hiddenArrow
        placement={placement}
        maxWidth="14rem"
        offset={[0, 2]}
        appendToBody
        classes={{
          spanContainer: 'w-full',
        }}
        template={
          <div>
            <SearchInput
              value={searchText}
              resetValue={() => setSearchText('')}
              size="s"
              placeholder={label}
              className="mb-m"
              {...inputProps}
            />
            <OverlayScrollbarsComponent>
              <div style={{ maxHeight: '450px' }}>
                <SplittedCheckList
                  items={filteredItems}
                  value={value}
                  onChange={onChange}
                />
              </div>
            </OverlayScrollbarsComponent>
          </div>
        }
        {...popoverProps}
      >
        <UniversalFilterButton
          filtered={value.length > 0}
          onClose={() => onChange([])}
        />
      </Popover>
      <GrowDiv />
      {actions}
    </div>
  );
}

export default SetFilter;
