import { cn } from '@/shared/lib/css/cn';
import {
  DEFAULT_CURRENCY_FALLBACK,
  getAbbreviateNumber,
} from '@/shared/lib/formatting/number';
import React, { CSSProperties, FC } from 'react';
import Fractions from 'stories/ValueFormatters/components/Fractions';
import SignWrapper from 'stories/ValueFormatters/components/SignWrapper';
import {
  GetClassesObject,
  GetStylesObject,
  NumberFormatterProps,
} from 'stories/ValueFormatters/types';
import {
  getFormattedValueParts,
  getMergedClasses,
  getMergedStyles,
} from 'stories/ValueFormatters/utils';
import {
  OUTPUT_DEFAULT_CLASSES,
  OutputFormatter,
  OutputFormatterClassKeys,
} from './OutputFormatter';

export type CurrencyFormatterClasses = GetClassesObject<
  OutputFormatterClassKeys | 'fractions' | 'signSymbol' | 'suffix' | 'brackets'
>;
export type CurrencyFormatterStyles = GetStylesObject<
  OutputFormatterClassKeys | 'fractions' | 'signSymbol' | 'suffix' | 'brackets'
>;

interface ICurrencyFormatterProps
  extends NumberFormatterProps<
    CurrencyFormatterClasses,
    CurrencyFormatterStyles
  > {
  abbreviate?: boolean;
  fallbackValue?: React.ReactNode;
  // todo replace it with class
  negativeGray?: boolean;
  negativeVariant?: React.ComponentProps<typeof SignWrapper>['variant'];
  precision?: number;
}

const DEFAULT_CLASSES = {
  ...OUTPUT_DEFAULT_CLASSES,
  value: ({ value }) => (value === 0 ? 'text-neutral-400' : undefined),
  fractions: 'text-neutral-400',
  suffix: 'text-neutral-400',
  signSymbol: 'text-neutral-400',
} as const satisfies CurrencyFormatterClasses;

export const CurrencyFormatter: FC<ICurrencyFormatterProps> = ({
  value: initialValue,
  toLocalStringOptions,
  abbreviate,
  negativeVariant,
  fallbackValue = DEFAULT_CURRENCY_FALLBACK,
  ...props
}) => {
  if (initialValue == null || isNaN(initialValue))
    return <span>{fallbackValue}</span>;

  const hideFractions = props.hideFractions ?? abbreviate;
  const value = Number(initialValue);
  const options: Intl.NumberFormatOptions = {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...toLocalStringOptions,
  };
  const formattedValue = Math.abs(value).toLocaleString('en-US', options);

  const { displayValue } = getFormattedValueParts(formattedValue);

  const { scaled, suffix } = getAbbreviateNumber(value);

  const classes = getMergedClasses({
    classes: props.classes,
    allParts: props.classes?.allParts,
    defaultClasses: DEFAULT_CLASSES,
    value,
  });

  const styles = getMergedStyles({
    ...(props.styles ?? {}),
    // @ts-expect-error
    partNames: [
      'brackets',
      'endSymbol',
      'fractions',
      'partNames',
      'startSymbol',
      'value',
      'wrapper',
      'suffix',
    ] as const,
  });

  return (
    <SignWrapper
      negative={value < 0}
      className={classes?.brackets}
      styles={styles as { brackets: CSSProperties }}
      variant={negativeVariant}
    >
      <OutputFormatter classes={classes} styles={styles} startSymbol="$">
        {abbreviate
          ? scaled.toFixed(options.minimumFractionDigits)
          : displayValue}
        {!hideFractions && (
          <Fractions
            style={styles.fractions}
            formattedValue={formattedValue}
            className={cn(classes?.fractions)}
          />
        )}
        {abbreviate && (
          <span className={cn(classes?.suffix)} style={styles.suffix}>
            {suffix}
          </span>
        )}
      </OutputFormatter>
    </SignWrapper>
  );
};
