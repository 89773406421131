import { ReportComparisonDashboardsMenuItem } from '@/bundles/Shared/components/LeftSidebar/menuItems/ReportComparisonDashboardsMenuItem';
import { ReportFinancialsTableMenuItem } from '@/bundles/Shared/components/LeftSidebar/menuItems/ReportFinancialsTableMenuItem';
import { ReportPortfolioMenuItem } from '@/bundles/Shared/components/LeftSidebar/menuItems/ReportPortfolioMenuItem';
import { currentUserCanSeeComparisonPage } from '@/pages/comparisonDashboards/permissions';
import {
  allSubPathMatches,
  generatePath,
  ROUTES_ROOT,
} from '@/shared/lib/hooks/useNavigation';
import { useMatch } from '@reach/router';
import { useMenuItemsPermissions } from 'bundles/Shared/components/LeftSidebar/hooks/useMenuItemsPermissions';
import MainMenuItem from 'bundles/Shared/components/LeftSidebar/items/MainMenuItem';
import { ReportDashboardsMenuItem } from 'bundles/Shared/components/LeftSidebar/menuItems/ReportDashboardsMenuItem';

function ReportMenuItem() {
  const menuItemsPermissions = useMenuItemsPermissions();
  const isMatchReport =
    useMatch(allSubPathMatches(ROUTES_ROOT.report.fullPath)) ||
    useMatch(allSubPathMatches(ROUTES_ROOT.scoreboards.fullPath));

  return (
    <>
      <MainMenuItem
        label="REport"
        path={generatePath(ROUTES_ROOT.report.financials.fullPath, {
          reportName: '',
        })}
        disabled
        iconName="fin"
        // todo remove it when MainMenuItem click behavior will be fixed
        onClick={() => {}}
        isActive={() => isMatchReport!}
      >
        {menuItemsPermissions.scoreboardItemPermissions.scoreboardVisible && (
          <ReportPortfolioMenuItem />
        )}
        {menuItemsPermissions.reportOperationalVisible && (
          <ReportDashboardsMenuItem />
        )}
        {menuItemsPermissions.reportFinancialsVisible && (
          <ReportFinancialsTableMenuItem />
        )}
        {currentUserCanSeeComparisonPage() && (
          <ReportComparisonDashboardsMenuItem />
        )}
      </MainMenuItem>
    </>
  );
}

export default ReportMenuItem;
