import { insertBetweenPlaceholderColumnGroupDef } from '@/bundles/Shared/components/ReportTable/helpers/hooks/useColGroupDefs';
import { REPORT_BUILDER_WIDGET_TYPES } from '@/entities/report/reportBuilder';
import { PostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiResponse } from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { ColGroupDef } from 'ag-grid-community';
import { isEmpty } from 'lodash-es';

const DEFAULT_PLACEHOLDER_WIDTH = 8;
export const insertBetweenPlaceholderColumnGroupDefFinancialTableWidget = (
  colGroupDefs: ColGroupDef[],
) => {
  return insertBetweenPlaceholderColumnGroupDef(
    colGroupDefs,
    {
      resizable: false,
      width: DEFAULT_PLACEHOLDER_WIDTH,
      maxWidth: DEFAULT_PLACEHOLDER_WIDTH,
      headerComponentParams: {
        classes: {
          wrapper: '!border-neutral-000 !bg-neutral-000',
        },
      },
      cellRendererParams: {
        classes: {
          wrapper: '!border-neutral-000 !bg-neutral-000',
        },
        formatterParams: {
          fallbackValue: '',
        },
      },
    },
    {
      headerGroupComponentParams: {
        classes: {
          wrapper: '!border-neutral-000 !bg-neutral-000',
        },
      },
    },
  );
};

export const widgetHasData = (
  data:
    | PostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiResponse
    | undefined,
): boolean => {
  switch (data?.widget?.widgetType) {
    case REPORT_BUILDER_WIDGET_TYPES.UNIT_MIX_TABLE:
    case REPORT_BUILDER_WIDGET_TYPES.UNIT_MIX_SUMMARY_TABLE:
    case REPORT_BUILDER_WIDGET_TYPES.FINANCIAL_TABLE_SINGLE_DATE: {
      return data.widget.rows.length !== 0;
    }
    case REPORT_BUILDER_WIDGET_TYPES.UNIT_TYPE_TABLE: {
      return data.widget.data.length !== 0;
    }
    case REPORT_BUILDER_WIDGET_TYPES.XY_CHART: {
      return data.widget.items.length !== 0;
    }
    default: {
      return true;
    }
  }
};

export const isWidgetHasNoDataAndHiddenWidgetPlaceholders = (
  isHidePlaceholdersQueryParam: boolean,
  widgetData:
    | PostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiResponse
    | undefined,
): boolean => {
  if (!isHidePlaceholdersQueryParam) return false;

  switch (widgetData?.widget?.widgetType) {
    case 'text_area': {
      return isEmpty(widgetData.widget.text);
    }
    case 'media': {
      return widgetData.widget.items.length === 0;
    }

    default: {
      return false;
    }
  }
};
