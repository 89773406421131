import {
  WidgetDataRowHistoryEntry,
  WidgetDataHistoryRow,
} from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { formatDate } from '@/shared/lib/formatting/dates';
import { ICellRendererParams } from 'ag-grid-community';
import { orderBy } from 'lodash-es';

export const formatWidgetDataRowHistoryDates = (
  history: WidgetDataRowHistoryEntry,
) => {
  return `${formatDate(history.dateFrom, 'MM-DD-YYYY')} - ${formatDate(
    history.dateTo,
    'MM-DD-YYYY',
  )}`;
};

export const orderHistoryByDateFromDesc = (
  history: WidgetDataRowHistoryEntry[],
) => orderBy(history, 'date_from', 'desc');

export const defaultHistoryValueGetter = (
  params: ICellRendererParams,
): WidgetDataRowHistoryEntry[] =>
  params.data.history[params?.column?.getColDef().field ?? ''];

export const getHistoryForField = (row: WidgetDataHistoryRow, field: number) =>
  row.history?.[field];

export const columnHistoryHasMoreThanOneEntry = (
  history: WidgetDataRowHistoryEntry[],
) => history.length > 1;
