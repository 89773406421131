import { NEXT_GEN_TABLE_CLASS_NAME } from '@/bundles/Shared/components/AgGrid/Table/SymmetreAgGridTable';
import { cn } from '@/shared/lib/css/cn';
import { DateSSN } from '@/shared/lib/dateSSN/ssn';
import { DEFAULT_DATE_FORMAT, formatDate } from '@/shared/lib/formatting/dates';
import Calendar from 'stories/FlexibleFilterByPeriods/calendar/Calendar';
import type { PopoverRef } from 'stories/Popover/Popover';
import { ICellEditorParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import NumberFormat from 'react-number-format';
import styles from '@/entities/valueType/ui/CellEditor/styles.module.scss';

interface Props extends ICellEditorParams {
  height: number;
  value: string | number | null | undefined;
}

const MIN_DOLLAR_DECIMALS = 2;
const MAX_POSSIBLE_VALUE = 999_999_999_999;
export const DEFAULT_CELL_EDITOR_FALLBACK = '–';

// Docs link https://www.ag-grid.com/react-data-grid/component-cell-editor/
export const ValueTypeCellEditor = forwardRef<unknown, Props>((props, ref) => {
  const [value, setValue] = useState<string | null>(() => {
    const notUndefined = props.value ?? null;
    if (typeof notUndefined === 'number') {
      return String(notUndefined);
    }
    return notUndefined;
  });
  const refInput = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<PopoverRef>(null);

  useEffect(() => {
    refInput?.current?.focus();
    refInput?.current?.select();

    popoverRef.current?.show();
  }, []);

  useImperativeHandle(ref, () => ({
    getValue() {
      return value;
    },
  }));

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    const { value: newValue } = target;

    setValue(newValue === '' ? null : newValue);
  };

  if (props.data.valueType === 'date') {
    const elem = document.querySelector(`.${NEXT_GEN_TABLE_CLASS_NAME}`);

    const getValue = () => {
      if (value == null) return null;

      if (typeof value === 'number') {
        return [dayjs(new DateSSN(value).toDate())] as const;
      }

      if (typeof value === 'string') {
        return [dayjs(new DateSSN(Number(value)).toDate())] as const;
      }
      return null;
    };

    const calendarValue = getValue();
    return (
      <Calendar
        ref={popoverRef}
        value={getValue()}
        popoverProps={{
          appendTo: elem,
          placement: 'top-start',
          classes: { spanContainer: 'cell-wrapper cell-wrapper_basic' },
          children: (
            <div className="cell-inner cell-inner_basic min-w-0">
              {calendarValue
                ? formatDate(calendarValue[0], DEFAULT_DATE_FORMAT)
                : DEFAULT_CELL_EDITOR_FALLBACK}
            </div>
          ),
        }}
        onChange={([item]) => {
          const newValue = new DateSSN(item.toDate()).toNumber();
          setValue(newValue);
        }}
        closeOnDateUpdate
        selectionMode="daily"
      />
    );
  }

  return (
    <NumberFormat
      getInputRef={refInput}
      className={cn(
        styles.input,
        'inline-regular h-full w-full px-tw-2 text-right',
      )}
      decimalSeparator="."
      maxLength={18}
      max={MAX_POSSIBLE_VALUE}
      min={MAX_POSSIBLE_VALUE}
      thousandSeparator=","
      decimalScale={MIN_DOLLAR_DECIMALS}
      value={value}
      onChange={handleChange}
    />
  );
});
