import { ROUTES_ROOT, generateUrl } from '@/shared/lib/hooks/useNavigation';
import { Button } from '@/stories';
import { navigate } from '@reach/router';
import { IColumn } from 'bundles/Shared/components/Table/types';
import {
  isApiExtractions,
  isEmailExtractions,
  isSftpExtractions,
} from 'bundles/Shared/entities/connection/lib';
import { IConnection } from 'bundles/Shared/entities/connection/model';
import { ParserStatus } from 'bundles/Shared/entities/connection/ui/ConnectionParserStatus';
import { ConnectionReportType } from 'bundles/Shared/entities/connection/ui/ConnectionReportType';
import { ConnectionStatus } from 'bundles/Shared/entities/connection/ui/ConnectionStatus';
import { ERPSystemLabel } from 'bundles/Shared/entities/erpsystem/ui/ERPSystemLabel';
import { capitalize, upperCase } from 'lodash-es';
import pluralize from 'pluralize';

export enum TColumnIdsConnection {
  NAME = 'name',
  REPORT_TYPE = 'reportType',
  TYPE = 'type',
  CONNECTION_STATUS = 'connectionStatus',
  PARSER_STATUS = 'parserStatus',
  ERP_SYSTEM = 'erpSystem',
  EXTRACTION_COUNT = 'extractionCount',
}

export const useConnectionsColumns = (): {
  columns: IColumn<IConnection>[];
} => {
  const openConnectionPage = (id: IConnection['id']) => {
    navigate(
      generateUrl(ROUTES_ROOT.settings.report.connections.connection.fullPath, {
        pathParams: {
          connectionId: String(id),
        },
      }),
    );
  };

  return {
    columns: [
      {
        dataField: TColumnIdsConnection.NAME,
        text: 'Connection Name',
        formatter: ({ row }) => (
          <div className="group inline-semibold flex justify-between text-neutral-900">
            <div>{row.name}</div>
            <Button
              variant="secondary"
              size="xs"
              onClick={() => openConnectionPage(row.id)}
              className="pointer-events-none px-tw-2 py-tw-0.5 opacity-0 group-hover:pointer-events-auto group-hover:opacity-100 "
            >
              Open
            </Button>
          </div>
        ),
        headerStyle: {
          width: 400,
        },
      },
      {
        dataField: TColumnIdsConnection.REPORT_TYPE,
        text: 'Report Type',
        formatter: ({ row }) => <ConnectionReportType type={row.reportType} />,
        headerClasses: 'min-w-[110px]',
      },
      {
        dataField: TColumnIdsConnection.ERP_SYSTEM,
        text: 'ERP System',
        formatter: ({ row }) => <ERPSystemLabel system={row.erpSystem} />,
        headerClasses: 'min-w-[180px]',
      },
      {
        dataField: TColumnIdsConnection.TYPE,
        text: 'Connection Type',
        headerClasses: 'min-w-[110px]',
        formatter: ({ row }) => (
          <div>
            {isEmailExtractions(row) && capitalize(row.type)}
            {isSftpExtractions(row) && upperCase(row.type)}
            {isApiExtractions(row) && upperCase(row.type)}
          </div>
        ),
      },
      {
        dataField: TColumnIdsConnection.CONNECTION_STATUS,
        text: 'Connection Status',
        headerClasses: 'w-[150px]',
        formatter: ({ row }) => (
          <div className="w-full text-center">
            <ConnectionStatus
              description={
                row.connectionStatus === 'inactive'
                  ? 'Idle: Waiting for data'
                  : capitalize(row.connectionStatus)
              }
              status={row.connectionStatus}
            />
          </div>
        ),
      },
      {
        dataField: TColumnIdsConnection.PARSER_STATUS,
        text: 'Parser Status',
        headerClasses: 'w-[150px]',
        formatter: ({ row }) => (
          <div className="w-full text-center">
            <ParserStatus
              description={capitalize(row.parserStatus)}
              status={row.parserStatus}
            />
          </div>
        ),
      },
      {
        dataField: TColumnIdsConnection.EXTRACTION_COUNT,
        text: 'Extractions',
        headerClasses: 'min-w-[110px]',
        formatter: ({ row }) => (
          <div className="text-neutral-500">
            {row.extractionCount}
            {pluralize(
              // eslint-disable-next-line no-nested-ternary
              isEmailExtractions(row)
                ? ' email'
                : isSftpExtractions(row)
                ? ' sync'
                : '',
              row.extractionCount,
            )}
          </div>
        ),
      },
    ],
  };
};
