import { FormulaVariable } from '@/bundles/Shared/entities/formula';
import { VariableList } from '@/bundles/Shared/widgets/formula/list';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { SidePanel } from '@/stories/Modals/Modal/Modal';
import React from 'react';

export const VariablesPanel = ({
  onClose,
  onSubmit,
  ...props
}: DialogProps<FormulaVariable['reference']> &
  Omit<React.ComponentProps<typeof VariableList>, 'onSelect'>) => {
  return (
    <SidePanel header="Variables" toggle={onClose}>
      <div className="flex max-h-[calc(100vh_-_66px)] min-h-[calc(100vh_-_66px)] flex-col gap-tw-4 overflow-auto bg-neutral-100">
        <VariableList {...props} onSelect={onSubmit} />
      </div>
    </SidePanel>
  );
};
