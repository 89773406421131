import { useManualVariableSettingsModal } from '@/entities/report/manualVariable/lib/useManualVariableSettingsModal';
import { createReportManualVariable } from '@/features/report/manualVariable/createVariable/lib/utils';
import { Button } from '@/stories';

export const CreateManualVariable = () => {
  const openManVarModal = useManualVariableSettingsModal();
  const [createManVar, options] = createReportManualVariable();

  const handleCreateManVar = async () => {
    const res = await openManVarModal();

    if (res == null) return;

    createManVar(res);
  };

  return (
    <Button
      disabled={options.isLoading}
      onClick={handleCreateManVar}
      size="xs"
      iconName="addSmall"
      iconPosition="left"
      variant="primary"
    >
      New
    </Button>
  );
};
