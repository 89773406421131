import { ExternalSourceType } from '@/entities/core/legalEntity';
import { ClassNameProps } from '@/types/Props';
import { ERPSystemIcon } from '@/bundles/Shared/entities/erpsystem/ui/ERPSystemIcon';
import { SOURCE_TYPE_SETTINGS } from 'lib/sourceType';

type IERPSystem = {
  system: ExternalSourceType;
  typeThemeColor?: 'mono' | 'colorful';
} & ClassNameProps;

export const getNameERPSystem = (system: ExternalSourceType): string =>
  SOURCE_TYPE_SETTINGS[system]?.title;

export const ERPSystemLabel = ({ system }: IERPSystem) => (
  <div className="group flex shrink-0 items-center gap-tw-2 [&>svg]:shrink-0">
    <ERPSystemIcon
      system={system}
      className="transition-filter grayscale duration-300 group-hover:grayscale-0"
    />
    <div className="text-neutral-900">{getNameERPSystem(system)}</div>
  </div>
);
