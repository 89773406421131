import { STATIC_DATE_PICKER_PROPS } from 'bundles/REturn/components/Ownership/modals/consts';
import CurrencyInputNumber from 'bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import { SettingsModal } from 'bundles/Shared/components/SettingsModal/SettingsModal';
import dayjs from 'dayjs';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ComponentProps } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Input } from 'stories/index';

type AccruedAdjustmentForm = {
  amount: number;
  date: DateString | undefined;
  accruedPreferredReturnId: string;
  note?: string;
};

type Params = {
  initialForm: AccruedAdjustmentForm;
} & Partial<
  Pick<
    ComponentProps<typeof SettingsModal>,
    'modalHeaderProps' | 'submitButtonText'
  >
>;

export function useAddAccruedAdjustmentModal({
  investmentEntityName,
}: {
  investmentEntityName: string;
}) {
  const { openModal } = useModal();

  return async (
    { initialForm, modalHeaderProps, submitButtonText }: Params = {
      initialForm: {
        amount: undefined,
        date: undefined,
        note: '',
      },
    },
  ) => {
    const res = await openModal(SettingsModal<AccruedAdjustmentForm>, {
      initialForm,
      classes: {
        fieldsContainer: 'flex-wrap flex-row',
      },
      modalHeaderProps: modalHeaderProps ?? {
        title: 'Add Adjustment',
        subtitle: investmentEntityName,
      },
      submitButtonText: submitButtonText ?? 'Add Adjustment',
      isDisabled: (form) => {
        if (form.date == null) return true;
        if (form.amount == null) return true;
        return false;
      },
      fields: [
        {
          name: 'date',
          fieldProps: {
            className: 'w-full',
          },
          renderer({ form, setForm }) {
            return (
              <ReactDatePicker
                {...STATIC_DATE_PICKER_PROPS}
                selected={form.date ? dayjs(form.date).toDate() : null}
                onChange={(newDate) => {
                  setForm((prev) => ({
                    ...prev,
                    date: newDate,
                  }));
                }}
              />
            );
          },
        },
        {
          name: 'amount',
          fieldProps: {
            className: 'w-full',
          },
          renderer({ form, setForm }) {
            return (
              <CurrencyInputNumber
                selectOnFocus
                size="m"
                value={form.amount}
                onValueChange={(value) => {
                  setForm((prev) => ({
                    ...prev,
                    amount: value,
                  }));
                }}
              />
            );
          },
        },
        {
          name: 'note',
          optional: true,
          fieldProps: {
            className: 'w-full',
          },
          renderer({ form, setForm }) {
            return (
              <Input
                size="m"
                value={form.note}
                onChange={(e) => {
                  setForm((prev) => ({
                    ...prev,
                    note: e.target.value,
                  }));
                }}
              />
            );
          },
        },
      ],
    });

    return res;
  };
}
