import { cn } from '@/shared/lib/css/cn';
import { IHeaderParams } from 'ag-grid-community';
import { GroupedByTableActions } from 'bundles/Settings/components/REport/GeneralLedgers/Table/useColumnDefs';
import { FinancialCategory } from '@/entities/finanicalCategory/model';
import { Button } from 'stories';
import { ManageFinancialCategoriesSettingsButton } from '@/widgets/financialCategory/manage/ui/ManageFinancialCategoriesSettingsButton';

type Props = IHeaderParams & {
  actions: GroupedByTableActions;
  categoriesTree: FinancialCategory[];
  flaggedSize: number;
  generalLedgersFlagged?: boolean;
};

export function CategoriesHeader({
  displayName,
  flaggedSize,
  actions,
  generalLedgersFlagged,
}: Props) {
  return (
    <div className="flex items-center gap-tw-2">
      {displayName}
      <ManageFinancialCategoriesSettingsButton />
      {flaggedSize > 0 && (
        <Button
          size="xs"
          iconName="flagOn"
          className={cn(
            'h-[20px]',
            generalLedgersFlagged
              ? '!bg-attention-055 !text-white'
              : '!text-attention-055',
          )}
          onClick={actions.onFlagFilterChange}
          iconClasses={cn(
            generalLedgersFlagged
              ? '!bg-attention-055 !text-white'
              : '!text-attention-055',
          )}
          variant="secondary"
        >
          {flaggedSize}
        </Button>
      )}
    </div>
  );
}
