import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import { settingsEmailsCustomTemplateEnhancedApi } from '@/entities/emails/customTemplates/api/settingsEmailsCustomTemplateEnhancedApi';
import { BuilderUI } from '@/shared/ui/BuilderUI';
import { EmailCustomTemplateBuilder } from '@/widgets/email/custom/template/builder';
import { type RouteComponentProps } from '@reach/router';
import { type FC } from 'react';

export const NewEmailCustomTemplateEditorPage: FC<RouteComponentProps> = () => {
  const { data: customTemplateVariables, isLoading } =
    settingsEmailsCustomTemplateEnhancedApi.useGetEmailCustomTemplateVariablesQuery();

  settingsEmailsCustomTemplateEnhancedApi.useGetApiEmailBuilderCustomTemplatesMetaQuery();

  if (isLoading) {
    return <BuilderUI.Loading />;
  }

  if (customTemplateVariables == null) {
    return <NoDataOverlay title="No data" />;
  }

  return <EmailCustomTemplateBuilder />;
};
