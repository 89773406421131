import { Button } from 'stories';

interface Props {
  title: string;
  text: string;
  buttonText: string;
  clickBtnHandler: () => void;
}

const SectionToCreateEmailCard = ({
  title,
  text,
  buttonText,
  clickBtnHandler,
}: Props) => (
  <div className="border-1 flex h-full w-full flex-col items-center justify-center gap-tw-2 overflow-hidden rounded-[1rem] border-solid border-light-30 bg-light-10 p-tw-5 text-center">
    <h4 className="light-90 header5-regular">{title}</h4>
    <p className="light-60 inline-regular">{text}</p>
    <Button variant="primary" onClick={clickBtnHandler}>
      {buttonText}
    </Button>
  </div>
);

export default SectionToCreateEmailCard;
