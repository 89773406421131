import { ExternalSourceType } from '@/entities/core/legalEntity/model';

type SourceTypeSettings = {
  title: string;
  site: string;
  /**
   * @deprecated use ERPSystemIcon instead
   */
  image?: string;
};

export const SOURCE_TYPE_SETTINGS: Record<
  ExternalSourceType,
  SourceTypeSettings
> = {
  [ExternalSourceType.QUICKBOOKS_DESKTOP]: {
    title: 'Quickbooks Desktop',
    site: 'quickbooks.intuit.com/desktop',
  },
  [ExternalSourceType.QUICKBOOKS_ONLINE]: {
    title: 'Quickbooks Online',
    site: 'quickbooks.intuit.com/online',
  },
  [ExternalSourceType.YARDI]: {
    title: 'Yardi',
    site: 'www.yardi.com',
  },
  [ExternalSourceType.ENTRATA]: {
    title: 'Entrata',
    site: 'www.entrata.com',
  },
  [ExternalSourceType.M3]: {
    title: 'M3',
    site: 'www.m3as.com',
  },
  [ExternalSourceType.MRI]: {
    title: 'MRI Software',
    site: 'www.mrisoftware.com',
  },
  [ExternalSourceType.REALPAGE]: {
    title: 'Realpage',
    site: 'realpage.com',
  },
  [ExternalSourceType.RESMAN]: {
    title: 'Resman',
    site: 'myresman.com',
  },
  [ExternalSourceType.CYMA]: {
    title: 'CYMA',
    site: 'cyma.com',
  },
  [ExternalSourceType.SAGE]: {
    title: 'Sage',
    site: 'www.sage.com',
  },
  [ExternalSourceType.SYMMETRE]: {
    title: 'symmetRE',
    site: 'symmetre.com',
  },
  [ExternalSourceType.NET_SUITE]: {
    title: 'NetSuite',
    site: 'netsuite.com',
  },
  [ExternalSourceType.FORTRESS]: {
    title: 'Fortress',
    site: 'fortress.com',
  },
  [ExternalSourceType.APP_FOLIO]: {
    title: 'AppFolio',
    site: 'appfolio.com',
  },
  [ExternalSourceType.RENTEGI]: {
    title: 'Rentegi',
    site: 'rentegi.com',
  },
  [ExternalSourceType.RASI]: {
    title: 'RASI',
    site: 'rasiusa.com',
  },
  [ExternalSourceType.XERO]: {
    title: 'Xero',
    site: 'xero.com',
  },
  [ExternalSourceType.RENT_MANAGER]: {
    title: 'Rent Manager',
    site: 'rentmanager.com',
  },
  [ExternalSourceType.BUILDIUM]: {
    title: 'Buildium',
    site: 'buildium.com',
  },
  [ExternalSourceType.PROPERTYWARE]: {
    title: 'Propertyware',
    site: 'propertyware.com',
  },
  [ExternalSourceType.RENTVINE]: {
    title: 'Rentvine',
    site: 'rentvine.com',
  },
  [ExternalSourceType.ZOHO]: {
    title: 'ZOHO',
    site: 'zoho.com',
  },
  [ExternalSourceType.MICROSOFT_DYNAMICS_365]: {
    title: 'Microsoft Dynamics 365',
    site: 'microsoft.com',
  },
  [ExternalSourceType.ACUMATICA]: {
    title: 'Acumatica',
    site: 'acumatica.com',
  },
  [ExternalSourceType.INFOR]: {
    title: 'Infor',
    site: 'infor.com',
  },
  [ExternalSourceType.RADIX]: {
    title: 'Radix',
    site: 'radix.com',
  },
  [ExternalSourceType.ANYONE_HOME]: {
    title: 'Anyone Home',
    site: 'anyonehome.com',
  },
};
