import { Link, useLocation } from '@reach/router';
import InvestmentObjectImage from 'bundles/InvestmentObjects/components/investmentObjectImage/InvestmentObjectImage';
import YieldBadge from 'bundles/REturn/components/Home/formatters/assetInformation/YieldBadge';
import LegalEntitiesLinkWithPopover from 'bundles/REturn/components/LegalEntitiesLinkWithPopover';
import PublishedStatusBadge from 'bundles/REturn/components/Ownership/ownershipsHeader/publishedStatus/PublishedStatusBadge';
import {
  IReturnInvestmentObject,
  TPublishableLegalEntity,
} from 'bundles/REturn/types';
import { ROUTES_ROOT, generatePath } from '@/shared/lib/hooks/useNavigation';
import { percentNumberFormat } from '@/shared/lib/formatting/number';
import { LegalEntity } from '@/entities/core/legalEntity';

interface Props {
  entity: Pick<
    IReturnInvestmentObject['object'],
    'id' | 'smallPictureUrl' | 'name' | 'slug' | 'objectableType'
  >;
  legalEntities: (TPublishableLegalEntity & Pick<LegalEntity, 'name'>)[];
  yieldValue: number;
}

const ROUTE_OBJECTABLE_TYPE_MAP = {
  asset: ROUTES_ROOT.assets.asset.fullPath,
  fund: ROUTES_ROOT.funds.fund.fullPath,
} as const satisfies Record<
  IReturnInvestmentObject['object']['objectableType'],
  string
>;

function ObjectInformationCell({ entity, legalEntities, yieldValue }: Props) {
  const { pathname } = useLocation();

  return (
    <div className="rounded-right-0 flex h-full items-center gap-tw-4 rounded-2xl bg-white p-tw-4">
      <Link
        to={generatePath(
          ROUTE_OBJECTABLE_TYPE_MAP[entity.objectableType],
          {
            assetSlug: entity.slug,
            fundSlug: entity.slug,
          },
        )}
        state={{ from: pathname }}
        className="relative"
      >
        <InvestmentObjectImage pictureUrl={entity.smallPictureUrl} />
        <YieldBadge
          className="absolute -bottom-tw-2 -right-tw-1 shadow-s"
          value={percentNumberFormat(yieldValue)}
        />
      </Link>
      <div className="flex flex-col items-start gap-tw-2">
        <span className="header6-bold leading-4 text-neutral-850">
          {entity.name}
        </span>
        <LegalEntitiesLinkWithPopover legalEntities={legalEntities} />
        <PublishedStatusBadge
          legalEntitiesCount={legalEntities.length}
          publishedCount={legalEntities.filter((le) => le.isPublished).length}
        />
      </div>
    </div>
  );
}

export default ObjectInformationCell;
