import {
  LegalEntityManagmentPeriodsInline,
  useCombinedPeriods,
  useLegalEntityManagementPeriodsColorsMap,
} from '@/bundles/Settings/components/Portal/LegalEntities/components/LegalEntityManagmentPeriodsInline';
import { ManagementPeriodCard } from '@/bundles/Settings/components/Portal/LegalEntities/components/ManagementPeriodCard';
import { TConnectionReportType } from '@/bundles/Shared/entities/connection/model';
import { CONNECTION_REPORT_TYPE_LABELS } from '@/bundles/Shared/entities/connection/ui/ConnectionReportTypeLabel';
import {
  useGetApiSettingsCoreLegalEntitiesByIdQuery,
  usePutApiSettingsCoreLegalEntitiesByIdManagementPeriodGroupsAndTypeMutation,
} from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesEnhancedApi';
import { CoreLegalEntitiesManagementPeriodDto } from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesGeneratedApi';
import { formatDate } from '@/shared/lib/formatting/dates';
import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import { AnimationLoader, Button, Icon, ThinTabGroup } from '@/stories';
import { SidePanel } from '@/stories/Modals/Modal/Modal';
import ModalHeaderWithSubtitle from '@/stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import {
  orderPeriodsByDateFrom,
  updatePeriodByDateFrom,
} from '@/shared/lib/period';
import { useParams } from '@reach/router';
import { groupBy, mapValues } from 'lodash-es';
import pluralize from 'pluralize';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { formatToDateStringForRequest } from '@/shared/lib/converters';

export type LegalEntitiesManagementPeriodFormValues = {
  selectedTab: TConnectionReportType | null;
  editMode: boolean;
  periods: CoreLegalEntitiesManagementPeriodDto[];
};

export const LegalEntitiyManagementPeriods = () => {
  const { legalEntityId } = useParams();
  const { navigateBack } = useNavigateBack({ fallbackUrl: '..' });

  const query = useGetApiSettingsCoreLegalEntitiesByIdQuery(
    {
      id: legalEntityId,
    },
    {
      selectFromResult: ({ data, isLoading }) => {
        const periods = groupBy(data?.managementPeriods, 'type');
        const orderedPeriods = mapValues(periods, orderPeriodsByDateFrom);

        return {
          data: orderedPeriods,
          tabs: Object.keys(periods).map((tab) => ({
            id: tab,
            label: CONNECTION_REPORT_TYPE_LABELS[tab as TConnectionReportType],
          })),
          legalEntityName: data?.name ?? '',
          isLoading,
          length: data?.managementPeriods.length ?? 0,
        };
      },
    },
  );
  const {
    data: managementPeriods,
    tabs,
    legalEntityName,
    isLoading,
    length,
  } = query;
  const [editMode, setEditMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState<TConnectionReportType | null>(
    null,
  );

  const [updatePeriods, { isLoading: isSaveLoading }] =
    usePutApiSettingsCoreLegalEntitiesByIdManagementPeriodGroupsAndTypeMutation();

  const {
    reset,
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<{
    selectedTab: TConnectionReportType | null;
    editMode: boolean;
    periods: CoreLegalEntitiesManagementPeriodDto[];
  }>({
    defaultValues: {
      periods: [],
    },
  });

  const {
    fields: periods,
    replace,
    update,
    remove,
  } = useFieldArray({
    name: 'periods',
    control,
  });
  const combinedPeriods = useCombinedPeriods(periods);

  const colorsMap = useLegalEntityManagementPeriodsColorsMap(periods);

  useEffect(() => {
    if (tabs.length > 0 && selectedTab === null) {
      setSelectedTab(tabs[0]?.id as TConnectionReportType);
    }
  }, [tabs]);

  useEffect(() => {
    if (!selectedTab) return;

    setEditMode(false);
    replace(managementPeriods[selectedTab]);
  }, [selectedTab]);

  const isLastPeriod = (index: number) => index === periods.length - 1;

  const onSubmit = async (data: LegalEntitiesManagementPeriodFormValues) => {
    if (!selectedTab) {
      toastr.error(
        'Something went wrong. Please select a data sources type to update periods.',
      );
      return;
    }

    const res = await updatePeriods({
      id: legalEntityId,
      type: selectedTab as 'financial' | 'operational' | 'market_data',
      body: data.periods.map((period) => ({
        pmc_managed_object_id: period.pmcManagedObjectId,
        date_from: period.dateFrom,
        date_to: period.dateTo,
      })),
    });

    if ('error' in res) {
      return;
    }

    toastr.success('Periods updated');
    setEditMode(false);

    if (data.periods.length === 0) {
      navigateBack();
    }
  };

  return (
    <SidePanel
      size="500"
      header={
        <ModalHeaderWithSubtitle
          title="Data Sources"
          subtitle={`Legal Entities • ${legalEntityName}`}
          order="subtitle-title"
          classes={{
            title: 'text-base uppercase',
          }}
        />
      }
      toggle={navigateBack}
      classes={{
        body: 'space-y-tw-6',
      }}
    >
      {isLoading ? (
        <AnimationLoader />
      ) : (
        <div className="space-y-tw-5">
          {tabs.length > 1 ? (
            <ThinTabGroup
              classes={{
                itemContainer: 'flex-grow',
                item: 'w-full justify-center',
              }}
              className="flex"
              selectedItem={selectedTab ?? undefined}
              items={tabs}
              onSelectedItemChange={(item) =>
                setSelectedTab(item.id as TConnectionReportType)
              }
            />
          ) : (
            tabs[0] && (
              <div className="text-base font-semibold text-neutral-650">
                {tabs[0].label}
              </div>
            )
          )}

          {(periods.length > 1 ||
            (periods.length === 1 && periods[0].dateTo)) && (
            <LegalEntityManagmentPeriodsInline
              periods={combinedPeriods}
              colorsMap={colorsMap}
            />
          )}
          {length > 0 ? (
            <>
              <div className="flex items-center justify-between gap-tw-4 text-xs font-semibold uppercase text-neutral-550">
                <span className="secondary-regular">
                  {pluralize('data source', periods.length, true)}
                </span>
                {!editMode ? (
                  <Button
                    variant="secondary"
                    size="xs"
                    onClick={() => setEditMode(true)}
                  >
                    Edit
                  </Button>
                ) : (
                  <div className="flex gap-tw-2">
                    <Button
                      variant="secondary"
                      size="xs"
                      onClick={() => {
                        setEditMode(false);
                        reset({
                          periods: selectedTab
                            ? managementPeriods[selectedTab]
                            : [],
                        });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="success"
                      size="xs"
                      onClick={handleSubmit(onSubmit)}
                      disabled={!isValid || isSaveLoading}
                      loading={isSaveLoading}
                    >
                      Save Updates
                    </Button>
                  </div>
                )}
              </div>
              <div className="flex flex-col-reverse gap-tw-4">
                {periods.length === 0 && (
                  <div className="flex items-center justify-center">
                    <span className="secondary-regular">
                      All data sources unmapped
                    </span>
                  </div>
                )}
                {periods.map((period, index) => (
                  <ManagementPeriodCard
                    key={period.id}
                    period={period}
                    className="shadow-[0px_4px_24px_8px_rgba(62,74,99,0.05)]"
                  >
                    <div className="flex min-h-[1.5rem] items-center justify-between gap-tw-4">
                      <ManagementPeriodCard.Header
                        indicatorColor={colorsMap.get(period.id)}
                      />
                      {editMode && (
                        <div className="flex shrink-0 items-center gap-tw-2">
                          {isLastPeriod(index) &&
                            (!period.dateTo ? (
                              <Button
                                variant="secondary"
                                size="xs"
                                onClick={() => {
                                  update(index, {
                                    ...period,
                                    dateTo: formatDate(
                                      new Date(),
                                      'YYYY-MM-DD',
                                    ),
                                  });
                                }}
                              >
                                Add Date To
                              </Button>
                            ) : (
                              <Button
                                variant="secondary"
                                size="xs"
                                onClick={() => {
                                  update(index, {
                                    ...period,
                                    dateTo: null,
                                  });
                                }}
                              >
                                Remove Date To
                              </Button>
                            ))}
                          <Button
                            variant="secondary"
                            size="xs"
                            className="px-tw-1.5 text-neutral-500"
                            onClick={() => remove(index)}
                          >
                            <Icon iconName="closeSmall" />
                          </Button>
                        </div>
                      )}
                    </div>

                    {editMode && (
                      <ManagementPeriodCard.Body className="flex gap-tw-4">
                        <div className="flex-1">
                          <ManagementPeriodCard.DateForm
                            value={
                              period.dateFrom ? new Date(period.dateFrom) : null
                            }
                            label="Date From"
                            onChange={(date) => {
                              replace(
                                updatePeriodByDateFrom(periods, {
                                  id: period.id,
                                  dateFrom: date
                                    ? formatToDateStringForRequest(date)
                                    : null,
                                }),
                              );
                            }}
                          />
                        </div>
                        {isLastPeriod(index) && period.dateTo && (
                          <div className="flex-1">
                            <ManagementPeriodCard.DateForm
                              value={
                                period.dateTo ? new Date(period.dateTo) : null
                              }
                              label="Date To"
                              minDate={
                                period.dateFrom
                                  ? new Date(period.dateFrom)
                                  : null
                              }
                              onChange={(date) => {
                                update(index, {
                                  ...period,
                                  dateTo: date
                                    ? formatToDateStringForRequest(date)
                                    : null,
                                });
                              }}
                            />
                          </div>
                        )}
                      </ManagementPeriodCard.Body>
                    )}

                    <ManagementPeriodCard.Body>
                      <div className="flex flex-col gap-tw-4">
                        <ManagementPeriodCard.Dates
                          key={`${period.id}-dates`}
                        />
                      </div>
                    </ManagementPeriodCard.Body>
                  </ManagementPeriodCard>
                ))}
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center">
              <span className="secondary-regular">No data sources found</span>
            </div>
          )}
        </div>
      )}
    </SidePanel>
  );
};
