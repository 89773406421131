import { LegalEntityFieldOption } from 'bundles/Settings/components/Portal/LegalEntities/UpdateLegalEntityModals';
import PermissionList from 'bundles/Shared/components/PermissionList';
import PermissionsModal from 'bundles/Shared/components/PermissionsModal';
import {
  AssetClassSelect,
  LeClassificationSelectField,
  LeClassification,
  findAssetClassOptionByValue,
} from 'bundles/Shared/entities/leClasssification';
import { CoreLegalEntitiesLegalEntitiables } from 'bundles/Shared/entities/legalEntity/api/settingsCoreLegalEntitiesApi';
import { legalEntityPermissions } from 'bundles/Shared/legalEntityPermissions';
import {
  ASSET_PORTAL_PRODUCT_NAME,
  FUND_PORTAL_PRODUCT_NAME,
} from 'lib/permissions';
import { useMemo, useState } from 'react';
import Select from 'react-select';
import { Badge, Button, Input, Modal, ModalActions } from 'stories';
import { LegalEntity } from '@/entities/core/legalEntity';
import { TInvestmentObjectClass } from '@/types/IInvestmentObject';
import { selectStyles } from './LEModalSelectStyles';
import { splitWithUnderscore } from '@/shared/lib/string';
import {
  usePostApiSettingsCoreLegalEntitiesMutation,
  usePutApiSettingsCoreLegalEntitiesByIdMutation,
} from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesEnhancedApi';
import {
  PostApiSettingsCoreLegalEntitiesApiArg,
  PutApiSettingsCoreLegalEntitiesByIdApiArg,
} from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesGeneratedApi';
import * as Sentry from '@sentry/react';
import { ObjectNullable } from '@/shared/types/utils';

interface Props {
  id: string;
  setLegalEntityModalOpened: (value: boolean) => void;
  assets: CoreLegalEntitiesLegalEntitiables[];
  funds: CoreLegalEntitiesLegalEntitiables[];
  onSubmitCallback: () => void;
  currentLegalEntity: LegalEntity;
}

export type LEFieldsToUpdate<New = false> = New extends true
  ? PostApiSettingsCoreLegalEntitiesApiArg['body']
  : PutApiSettingsCoreLegalEntitiesByIdApiArg['body'];

const LegalEntityModal = ({
  currentLegalEntity,
  setLegalEntityModalOpened,
  assets,
  funds,
  onSubmitCallback,
}: Props) => {
  const [legalEntity, setLegalEntity] =
    useState<LegalEntity>(currentLegalEntity);
  const [createLegalEntity] = usePostApiSettingsCoreLegalEntitiesMutation();
  const [updateLegalEntity] = usePutApiSettingsCoreLegalEntitiesByIdMutation();

  const setLegalEntityAttribute = (
    attribute: 'name' | 'code' | 'permitted',
    value: unknown,
  ) => {
    setLegalEntity({
      ...legalEntity,
      [attribute]: value,
    });
  };

  const [permissionsModalOpened, setPermissionsModalOpened] = useState(false);

  const handleCreate = async (params: LEFieldsToUpdate<true>) => {
    const success = await createLegalEntity({ body: params });
    if (success) {
      setLegalEntityModalOpened(false);
      onSubmitCallback();
    }
  };

  const handleUpdate = async (params: LEFieldsToUpdate) => {
    const success = await updateLegalEntity({
      id: legalEntity.id,
      body: params,
    });
    if (success) {
      setLegalEntityModalOpened(false);
      onSubmitCallback();
    }
  };

  const onSubmit = async () => {
    const params = {
      name: legalEntity.name,
      legal_entitiable_id: legalEntity.legalEntitiableId,
      legal_entitiable_type: legalEntity.legalEntitiableType,
      classification: legalEntity.classification,
    } satisfies ObjectNullable<LEFieldsToUpdate>;

    const permitted = legalEntity.permitted
      ? legalEntityPermissions(legalEntity.permitted)
      : {};

    if (!params.name) {
      Sentry.captureMessage(
        `Update LE (${legalEntity.id}) with legalEntity.name not found`,
      );
      return;
    }

    if (!params.classification) {
      Sentry.captureMessage(
        `Update LE (${legalEntity.id}) with legalEntity.classification not found`,
      );
      return;
    }

    if (legalEntity.id) {
      await handleUpdate({ ...(params as LEFieldsToUpdate), ...permitted });
    } else {
      if (!params.classification) {
        Sentry.captureMessage(
          `Create LE (${legalEntity.id}) with legalEntity.legalEntitiableType not found`,
        );
        return;
      }

      await handleCreate({
        ...(params as LEFieldsToUpdate<true>),
        ...permitted,
      });
    }
  };

  const assetOptions = useMemo(
    () =>
      assets.map((asset) => ({
        value: `Asset_${asset.id}`,
        label: asset.name,
      })),
    [assets],
  );

  const fundOptions = useMemo(
    () => funds.map((fund) => ({ value: `Fund_${fund.id}`, label: fund.name })),
    [funds],
  );

  const onInvestmentObjectChange = (option: LegalEntityFieldOption) => {
    if (!option?.value) {
      setLegalEntity({
        ...legalEntity,
      });
      return;
    }

    const [legalEntitiableType, legalEntitiableId] = splitWithUnderscore(
      option.value,
    );

    setLegalEntity({
      ...legalEntity,
      legalEntitiableId: Number(legalEntitiableId),
      legalEntitiableType: legalEntitiableType as TInvestmentObjectClass,
    });
  };

  const onClassificationChange = (value: LeClassification) => {
    setLegalEntity({
      ...legalEntity,
      classification: value,
    });
  };

  const defaultInvestmentObject = useMemo(
    () =>
      [...assetOptions, ...fundOptions].find(
        (option) =>
          option.value ===
          `${legalEntity.legalEntitiableType ?? ''}_${
            legalEntity.legalEntitiableId ?? ''
          }`,
      ),
    [legalEntity],
  );

  const headerText = legalEntity?.id ? 'Edit Legal Entity' : 'Add Legal Entity';
  const successActionText = legalEntity?.id
    ? 'Edit Legal Entity'
    : 'Add Legal Entity';

  return (
    <Modal
      toggle={() => setLegalEntityModalOpened(false)}
      header={headerText}
      actions={
        <ModalActions>
          <Button
            variant="secondary"
            onClick={() => setLegalEntityModalOpened(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={!legalEntity.name || !legalEntity.classification}
            variant="success"
            onClick={onSubmit}
          >
            {successActionText}
          </Button>
        </ModalActions>
      }
    >
      <h6 className="header6-regular mb-m">Basic</h6>
      <label htmlFor="le-name" className="mb-s">
        Name <span className="red">*</span>
      </label>
      <Input
        onChange={(e) => setLegalEntityAttribute('name', e.target.value)}
        placeholder="Enter Entity Name"
        id="le-name"
        defaultValue={legalEntity?.name || ''}
        className="mb-m"
      />
      <label htmlFor="object" className="mb-s">
        Object
      </label>
      <Select
        menuPosition="fixed"
        menuShouldBlockScroll
        className="mb-m"
        styles={selectStyles}
        onChange={onInvestmentObjectChange}
        options={[
          {
            label: 'Assets',
            options: assetOptions,
          },
          {
            label: 'Funds',
            options: fundOptions,
          },
        ]}
        classNamePrefix="react-select"
        placeholder="Select object..."
        defaultValue={defaultInvestmentObject}
      />
      <LeClassificationSelectField className="mb-m">
        <AssetClassSelect
          defaultValue={
            legalEntity.classification
              ? findAssetClassOptionByValue(legalEntity.classification)
              : undefined
          }
          onChange={onClassificationChange}
        />
      </LeClassificationSelectField>

      <label htmlFor="permissions" className="mb-m">
        Permissions
      </label>
      {legalEntity.permitted && (
        <div className="flex">
          <PermissionList
            permissions={legalEntity.permitted}
            productName={
              legalEntity.legalEntitiableType == 'Fund'
                ? FUND_PORTAL_PRODUCT_NAME
                : ASSET_PORTAL_PRODUCT_NAME
            }
            investmentObject={{
              type: legalEntity.legalEntitiableType,
              entity: { id: legalEntity.legalEntitiableId },
            }}
            hideActions={false}
            onSubmit={(permissions) =>
              setLegalEntityAttribute('permitted', permissions)
            }
            objectableId={legalEntity.id}
            objectableType="LegalEntity"
            itemType="Legal Entity"
          />
        </div>
      )}
      {!legalEntity.permitted && (
        <Badge
          backgroundColor="var(--blue-light-4)"
          textColor="var(--bl)"
          className="flex w-max cursor-pointer"
          onClick={() => setPermissionsModalOpened(true)}
        >
          Set Permissions
        </Badge>
      )}
      {permissionsModalOpened && (
        <PermissionsModal
          onClose={() => {
            setPermissionsModalOpened(false);
          }}
          onSubmit={(permissions) =>
            setLegalEntityAttribute('permitted', permissions)
          }
          initialState={legalEntity?.permitted || null}
          initialTab={legalEntity?.permitted?.public ? 'public' : 'restricted'}
          productName={ASSET_PORTAL_PRODUCT_NAME}
          itemType="Legal Entity"
        />
      )}
    </Modal>
  );
};

export default LegalEntityModal;
