import { CssVar } from '@/shared/config/cssVar';
import { ICellRendererParams } from 'ag-grid-community';
import {
  isTableVizConfigZeroComparison,
  TableVizConfigComparison,
  TableVizConfigZeroComparison,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';

export const COMPARISON_RED_COLOR = CssVar.danger070;
export const COMPARISON_GREEN_COLOR = CssVar.success070;

export const resolveTextColorByComparisonRule = (
  columnValue: number | null,
  compareToColumnValue: number | null,
  rule: TableVizConfigComparison['rule'],
): string | undefined => {
  if (columnValue == null || columnValue === compareToColumnValue)
    return undefined;

  if (rule === 'negative_red') {
    return compareToColumnValue == null || columnValue < compareToColumnValue
      ? COMPARISON_RED_COLOR
      : undefined;
  }
  if (rule === 'negative_red_positive_green') {
    return compareToColumnValue == null || columnValue < compareToColumnValue
      ? COMPARISON_RED_COLOR
      : COMPARISON_GREEN_COLOR;
  }
  if (rule === 'negative_green_positive_red') {
    return compareToColumnValue == null || columnValue < compareToColumnValue
      ? COMPARISON_GREEN_COLOR
      : COMPARISON_RED_COLOR;
  }
};

export function resolveComparisonTextColorForColDef(
  comparison: TableVizConfigComparison | TableVizConfigZeroComparison,
  params: Pick<ICellRendererParams, 'data' | 'value'>,
  valueGetter: (
    data: Record<string, number> | undefined,
    key: string,
  ) => number | null | undefined,
) {
  // if compareTo is not provided, we use 0 as the comparing value
  const compareToValue = isTableVizConfigZeroComparison(comparison)
    ? 0
    : valueGetter(params.data, comparison.to_col_id);
  const currentColumnValue = params.value;

  return resolveTextColorByComparisonRule(
    currentColumnValue,
    compareToValue,
    comparison.rule,
  );
}
