import EmailTemplateCard from '@/bundles/Settings/components/EmailSettings/EmailTemplateCard';
import {
  PageParamsProvider,
  PageParamsSearch,
} from '@/bundles/Shared/components/pageParams';
import { settingsEmailsCustomTemplateEnhancedApi } from '@/entities/emails/customTemplates/api/settingsEmailsCustomTemplateEnhancedApi';
import type { EmailCustomTemplate } from '@/entities/emails/customTemplates/model';
import { useDeleteEmailCustomTemplateMutation } from '@/features/emails/custom/deleteTemplate/lib';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import { useAppNavigate } from '@/shared/lib/navigation/useAppNavigate';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { TileList } from '@/shared/ui/TileList';
import type { IThinTabItem } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import type { IQueryParams } from '@/types/Pagination';
import type { LayoutProps } from '@/types/Props';
import { Link, type RouteComponentProps } from '@reach/router';
import { groupBy, isEmpty } from 'lodash-es';
import { useMemo, useState } from 'react';
import { RRule } from 'rrule';
import { ThinTabGroup } from 'stories';

const getRRuleFrequency = (schedule: string) =>
  RRule.fromString(schedule)?.options?.freq;

const THIN_TAB_ALL_ID = 'All';

export const CustomTemplatesPage = ({
  children,
}: RouteComponentProps<LayoutProps>) => {
  const { data, isFetching, isLoading } =
    settingsEmailsCustomTemplateEnhancedApi.useGetAllEmailCustomTemplateQuery();

  const [handleDelete] = useDeleteEmailCustomTemplateMutation({
    shouldNavigateBack: true,
  });
  const navigate = useAppNavigate();

  const [pageParams, setPageParams] = useState<Required<IQueryParams>>({
    query: '',
  });

  const initialTemplates = data ?? [];

  const frequencyTabItems = useMemo<IThinTabItem[]>(() => {
    const groupedByFrequency = groupBy(
      initialTemplates,
      (template) => getRRuleFrequency(template.schedule) ?? 'Unknown',
    );
    return [
      { label: THIN_TAB_ALL_ID, id: THIN_TAB_ALL_ID, visible: true },
      {
        id: RRule.DAILY,
        value: RRule.DAILY,
        label: 'Daily',
        visible: (groupedByFrequency[RRule.DAILY]?.length ?? 0) > 0,
      },
      {
        id: RRule.WEEKLY,
        value: RRule.WEEKLY,
        label: 'Weekly',
        visible: (groupedByFrequency[RRule.WEEKLY]?.length ?? 0) > 0,
      },
      {
        id: RRule.MONTHLY,
        value: RRule.MONTHLY,
        label: 'Monthly',
        visible: (groupedByFrequency[RRule.MONTHLY]?.length ?? 0) > 0,
      },
      {
        id: RRule.YEARLY,
        value: RRule.YEARLY,
        label: 'Yearly',
        visible: (groupedByFrequency[RRule.YEARLY]?.length ?? 0) > 0,
      },
    ].filter((predicate) => predicate.visible);
  }, [initialTemplates]);

  const [currentFrequencyTabItem, setCurrentFrequencyTab] = useState(
    frequencyTabItems[0].id,
  );

  const templates = useMemo(() => {
    let res = initialTemplates;
    if (!isEmpty(pageParams.query)) {
      res = res.filter((i) => includesInLowerCase(i.name, pageParams.query));
    }

    if (currentFrequencyTabItem !== THIN_TAB_ALL_ID) {
      res = res.filter(
        (template) =>
          getRRuleFrequency(template.schedule) === currentFrequencyTabItem,
      );
    }
    return res;
  }, [pageParams.query, initialTemplates, currentFrequencyTabItem]);

  const handleNavigate = (templateId: EmailCustomTemplate['id']) => {
    navigate(
      ROUTES_ROOT.settings.emails.templates.custom.customTemplate.edit.fullPath,
      {
        pathParams: {
          id: String(templateId),
        },
      },
    );
  };

  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <TileList className="px-tw-2">
        <TileList.Header>
          {!isLoading && (
            <div className="flex items-center gap-tw-2">
              <p className="inline-regular text-neutral-450 ">Reccurence</p>
              <ThinTabGroup
                selectedItem={currentFrequencyTabItem}
                onSelectedItemChange={(item) => setCurrentFrequencyTab(item.id)}
                items={frequencyTabItems}
              />
            </div>
          )}
          <GrowDiv />
          <PageParamsSearch />
          <Link
            to={generateUrl(
              ROUTES_ROOT.settings.emails.templates.custom.new.fullPath,
            )}
          >
            <TileList.CreateButton entityName="Custom Template" />
          </Link>
        </TileList.Header>
        <TileList.Items
          isListEmpty={templates.length === 0}
          isLoading={isLoading || isFetching}
          isQueryEmpty={isEmpty(pageParams.query)}
          noDataOverlayTitle="No templates found"
        >
          {templates.map((template) => (
            <EmailTemplateCard
              key={template.id}
              name={template.name}
              subtitle={template.subject}
              onNavigate={() => handleNavigate(template.id)}
              onDelete={() => handleDelete(template.id)}
              permitted={{
                directRoles: template.recipients.roles,
                directInvestmentEntities:
                  template.recipients.investmentEntities,
                directTags: template.recipients.tags,
                directUsers: template.recipients.users,
              }}
              schedule={template.schedule}
            />
          ))}
        </TileList.Items>
      </TileList>
      {children}
    </PageParamsProvider>
  );
};
