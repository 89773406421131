import { Permitted } from 'bundles/Shared/entities/permissions';
import { IUser } from 'types/User';
import { MetaState } from './types';

export function getIndirectUsersList(
  meta: MetaState,
  permittedState: Permitted,
): IUser[] {
  const indirectUsers = meta.userOptions.filter((u) => {
    const existsInRoles = permittedState.directRoles.some(
      ({ id }) => id === u.role?.id,
    );

    if (existsInRoles) return true;

    const existsInTags = permittedState.directTags.some(({ id }) =>
      u.tags.map((tag) => tag.id).includes(id),
    );

    if (existsInTags) return true;

    const existsInInvestmentEntity =
      permittedState.directInvestmentEntities.some(
        ({ id }) =>
          u.investmentEntities?.map((entity) => entity.id).includes(id),
      );

    return existsInInvestmentEntity;
  });

  return indirectUsers;
}
