import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiSettingsCoreLegalEntitiesAvailableRawDataPeriods: build.query<
      GetApiSettingsCoreLegalEntitiesAvailableRawDataPeriodsApiResponse,
      GetApiSettingsCoreLegalEntitiesAvailableRawDataPeriodsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/core/legal_entities/available_raw_data_periods`,
        params: {
          page: queryArg.page,
          per_page: queryArg.perPage,
          type: queryArg['type'],
          pmc_ids: queryArg.pmcIds,
          erp: queryArg.erp,
        },
      }),
    }),
    putApiSettingsCoreLegalEntitiesBulkExclude: build.mutation<
      PutApiSettingsCoreLegalEntitiesBulkExcludeApiResponse,
      PutApiSettingsCoreLegalEntitiesBulkExcludeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/core/legal_entities/bulk/exclude`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    putApiSettingsCoreLegalEntitiesBulkSetObject: build.mutation<
      PutApiSettingsCoreLegalEntitiesBulkSetObjectApiResponse,
      PutApiSettingsCoreLegalEntitiesBulkSetObjectApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/core/legal_entities/bulk/set_object`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    putApiSettingsCoreLegalEntitiesBulkSetClassification: build.mutation<
      PutApiSettingsCoreLegalEntitiesBulkSetClassificationApiResponse,
      PutApiSettingsCoreLegalEntitiesBulkSetClassificationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/core/legal_entities/bulk/set_classification`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    putApiSettingsCoreLegalEntitiesBulkUpdatePermissions: build.mutation<
      PutApiSettingsCoreLegalEntitiesBulkUpdatePermissionsApiResponse,
      PutApiSettingsCoreLegalEntitiesBulkUpdatePermissionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/core/legal_entities/bulk/update_permissions`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiSettingsCoreLegalEntitiesLegalEntitiables: build.query<
      GetApiSettingsCoreLegalEntitiesLegalEntitiablesApiResponse,
      GetApiSettingsCoreLegalEntitiesLegalEntitiablesApiArg
    >({
      query: () => ({
        url: `/api/settings/core/legal_entities/legal_entitiables`,
      }),
    }),
    putApiSettingsCoreLegalEntitiesByIdManagementPeriodGroupsAndType:
      build.mutation<
        PutApiSettingsCoreLegalEntitiesByIdManagementPeriodGroupsAndTypeApiResponse,
        PutApiSettingsCoreLegalEntitiesByIdManagementPeriodGroupsAndTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/settings/core/legal_entities/${queryArg.id}/management_period_groups/${queryArg['type']}`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
    getApiSettingsCoreLegalEntities: build.query<
      GetApiSettingsCoreLegalEntitiesApiResponse,
      GetApiSettingsCoreLegalEntitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/core/legal_entities`,
        params: {
          page: queryArg.page,
          per_page: queryArg.perPage,
          query: queryArg.query,
          filters: queryArg.filters,
          scope: queryArg.scope,
          fire_permissions: queryArg.firePermissions,
          sort: queryArg.sort,
        },
      }),
    }),
    postApiSettingsCoreLegalEntities: build.mutation<
      PostApiSettingsCoreLegalEntitiesApiResponse,
      PostApiSettingsCoreLegalEntitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/core/legal_entities`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiSettingsCoreLegalEntitiesMeta: build.query<
      GetApiSettingsCoreLegalEntitiesMetaApiResponse,
      GetApiSettingsCoreLegalEntitiesMetaApiArg
    >({
      query: () => ({ url: `/api/settings/core/legal_entities/meta` }),
    }),
    getApiSettingsCoreLegalEntitiesById: build.query<
      GetApiSettingsCoreLegalEntitiesByIdApiResponse,
      GetApiSettingsCoreLegalEntitiesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/core/legal_entities/${queryArg.id}`,
      }),
    }),
    putApiSettingsCoreLegalEntitiesById: build.mutation<
      PutApiSettingsCoreLegalEntitiesByIdApiResponse,
      PutApiSettingsCoreLegalEntitiesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/core/legal_entities/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getApiSettingsCoreLegalEntitiesByIdPermissions: build.query<
      GetApiSettingsCoreLegalEntitiesByIdPermissionsApiResponse,
      GetApiSettingsCoreLegalEntitiesByIdPermissionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/core/legal_entities/${queryArg.id}/permissions`,
      }),
    }),
    postApiSettingsCoreLegalEntitiesByIdExclude: build.mutation<
      PostApiSettingsCoreLegalEntitiesByIdExcludeApiResponse,
      PostApiSettingsCoreLegalEntitiesByIdExcludeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/settings/core/legal_entities/${queryArg.id}/exclude`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as settingsCoreLegalEntitiesGeneratedApi };
export type GetApiSettingsCoreLegalEntitiesAvailableRawDataPeriodsApiResponse =
  /** status 200 success */ {
    items: CoreLegalEntitiesRawDataPeriodDto[];
    meta: {
      totalSize: number;
      perPage: number | null;
    };
  };
export type GetApiSettingsCoreLegalEntitiesAvailableRawDataPeriodsApiArg = {
  page?: number;
  perPage?: number;
  type?: {
    ''?: string[];
  };
  pmcIds?: {
    ''?: number[];
  };
  erp?: {
    ''?: string[];
  };
};
export type PutApiSettingsCoreLegalEntitiesBulkExcludeApiResponse = unknown;
export type PutApiSettingsCoreLegalEntitiesBulkExcludeApiArg = {
  body: {
    legal_entity_ids: string[];
    excluded: boolean;
  };
};
export type PutApiSettingsCoreLegalEntitiesBulkSetObjectApiResponse = unknown;
export type PutApiSettingsCoreLegalEntitiesBulkSetObjectApiArg = {
  body: {
    legal_entity_ids: string[];
    legal_entitiable_type: 'Fund' | 'Asset';
    legal_entitiable_id: number;
  };
};
export type PutApiSettingsCoreLegalEntitiesBulkSetClassificationApiResponse =
  unknown;
export type PutApiSettingsCoreLegalEntitiesBulkSetClassificationApiArg = {
  body: {
    legal_entity_ids: string[];
    classification: string;
  };
};
export type PutApiSettingsCoreLegalEntitiesBulkUpdatePermissionsApiResponse =
  unknown;
export type PutApiSettingsCoreLegalEntitiesBulkUpdatePermissionsApiArg = {
  body: {
    legal_entity_ids: string[];
    public_permission_attributes: {
      _destroy?: boolean;
    };
    directly_permitted_user_role_ids: string[];
    directly_permitted_user_ids: string[];
    directly_permitted_investment_entity_ids: string[];
    directly_permitted_user_tag_ids: string[];
  };
};
export type GetApiSettingsCoreLegalEntitiesLegalEntitiablesApiResponse =
  /** status 200 success */ CoreLegalEntitiesLegalEntitiablesDto[];
export type GetApiSettingsCoreLegalEntitiesLegalEntitiablesApiArg = void;
export type PutApiSettingsCoreLegalEntitiesByIdManagementPeriodGroupsAndTypeApiResponse =
  /** status 200 success */ {
    items: CoreLegalEntitiesManagementPeriodDto[];
  };
export type PutApiSettingsCoreLegalEntitiesByIdManagementPeriodGroupsAndTypeApiArg =
  {
    id: string;
    type: 'financial' | 'operational' | 'market_data';
    body: {
      pmc_managed_object_id: string;
      date_from: string | null;
      date_to: string | null;
    }[];
  };
export type GetApiSettingsCoreLegalEntitiesApiResponse =
  /** status 200 success */ {
    items: CoreLegalEntityDto[];
    meta: {
      totalSize: number;
      perPage: number;
      totalFireStationPermissions: number;
    };
  };
export type GetApiSettingsCoreLegalEntitiesApiArg = {
  page?: number;
  perPage?: number;
  query?: string;
  filters?: {
    asset_ids?: number[];
    fund_ids?: number[];
  };
  scope?:
    | 'active-and-mapped'
    | 'active-and-not-mapped'
    | 'mapped-to-asset'
    | 'excluded';
  firePermissions?: boolean;
  sort?: {
    field?: 'code' | 'name' | 'asset';
    order?: 'asc' | 'desc';
  };
};
export type PostApiSettingsCoreLegalEntitiesApiResponse = unknown;
export type PostApiSettingsCoreLegalEntitiesApiArg = {
  body: {
    name: string;
    classification: string;
    legal_entitiable_type?: 'Asset' | 'Fund';
    legal_entitiable_id?: number;
    public_permission_attributes?: {
      _destroy?: boolean;
    };
    directly_permitted_user_role_ids?: string[];
    directly_permitted_user_ids?: string[];
    directly_permitted_investment_entity_ids?: string[];
    directly_permitted_user_tag_ids?: string[];
  };
};
export type GetApiSettingsCoreLegalEntitiesMetaApiResponse =
  /** status 200 success */ {
    mappedSize: number;
    notMappedSize: number;
    excludedSize: number;
    rawDataPeriodsAvailableForMappingSize: number;
  };
export type GetApiSettingsCoreLegalEntitiesMetaApiArg = void;
export type GetApiSettingsCoreLegalEntitiesByIdApiResponse =
  /** status 200 success */ CoreLegalEntityDto;
export type GetApiSettingsCoreLegalEntitiesByIdApiArg = {
  id: string;
};
export type PutApiSettingsCoreLegalEntitiesByIdApiResponse = unknown;
export type PutApiSettingsCoreLegalEntitiesByIdApiArg = {
  id: string;
  body: {
    name?: string;
    classification?: string;
    excluded?: boolean;
    legal_entitiable_type?: 'Asset' | 'Fund';
    legal_entitiable_id?: number;
    public_permission_attributes?: {
      _destroy?: boolean;
    };
    directly_permitted_user_role_ids?: string[];
    directly_permitted_user_ids?: string[];
    directly_permitted_investment_entity_ids?: string[];
    directly_permitted_user_tag_ids?: string[];
  };
};
export type GetApiSettingsCoreLegalEntitiesByIdPermissionsApiResponse =
  /** status 200 success */ {
    users: object[];
    meta: {
      roles: object[];
      tags: object[];
    };
  };
export type GetApiSettingsCoreLegalEntitiesByIdPermissionsApiArg = {
  id: string;
};
export type PostApiSettingsCoreLegalEntitiesByIdExcludeApiResponse = unknown;
export type PostApiSettingsCoreLegalEntitiesByIdExcludeApiArg = {
  id: string;
};
export type ErpsystemDto =
  | 'yardi'
  | 'entrata'
  | 'quickbooks_desktop'
  | 'quickbooks_online'
  | 'resman'
  | 'm3'
  | 'mri'
  | 'cyma'
  | 'fortress'
  | 'app_folio'
  | 'realpage'
  | 'sage'
  | 'rentegi'
  | 'net_suite'
  | 'rasi'
  | 'xero'
  | 'rent_manager'
  | 'buildium'
  | 'propertyware'
  | 'rentvine'
  | 'zoho'
  | 'microsoft_dynamics_365'
  | 'acumatica'
  | 'infor'
  | 'radix'
  | 'anyone_home'
  | 'hello_data_ai';
export type CoreLegalEntitiesRawDataPeriodDto = {
  id: string;
  dateFrom: string;
  dateTo: string;
  type: 'financial' | 'operational' | 'market_data';
  pmcManagedObjectId: string;
  erpSystem: ErpsystemDto;
  externalIdentifier: string;
  propertyManagementCompanyName: string;
};
export type CoreLegalEntitiesLegalEntitiablesDto = {
  id: number;
  slug: string;
  name: string;
  aasmState: 'published' | 'draft' | 'archived';
  objectType: 'Asset' | 'Fund';
  pictureUrl: string | null;
};
export type CoreLegalEntitiesManagementPeriodDto = {
  id: string;
  dateFrom: string | null;
  dateTo: string | null;
  type: 'financial' | 'operational' | 'market_data';
  pmcManagedObjectId: string;
  erpSystem: ErpsystemDto;
  externalIdentifier: string;
  propertyManagementCompanyName: string;
  rawDataPeriod: {
    dateFrom?: string;
    dateTo?: string;
  } | null;
};
export type UnprocessableentityDto = {
  errors?: string[];
};
export type CoreLegalEntityDto = {
  id: string;
  code: string;
  name: string;
  excluded: boolean;
  source: string;
  legalEntitiableType: string | null;
  legalEntitiableId: number | null;
  classification: string | null;
  lastActivity: object | null;
  permitted: {
    public: boolean;
    users: object[];
    roles: object[];
    tags: object[];
    investmentEntities: object[];
    autoSelectedUsers: object[];
  };
  legalEntitiable: {
    id?: number;
    name?: string;
    slug?: string;
    aasmState?: 'published' | 'draft' | 'archived';
    objectType?: 'Asset' | 'Fund';
    pictureUrl?: string | null;
  } | null;
  managementPeriods: CoreLegalEntitiesManagementPeriodDto[];
};
