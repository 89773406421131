import { widgetHasData } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib';
import { WidgetConfigCardProps } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { TextAreaWidgetSettingsModal } from '@/bundles/Shared/widgets/dashboard/widgets/textArea/ui/TextAreaWidgetSettingsModal';
import { usePutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotMutation } from '@/entities/report/reportBuilder';
import { BaseWidgetConfigCard } from '@/entities/report/reportBuilder/ui/BaseWidgetConfigCard';
import { DEFAULT_SANITIZE_HTML_OPTIONS } from '@/shared/lib/browser/config';
import { useModal } from '@/shared/lib/hooks/useModal';
import { IconButton } from '@/stories';
import sanitizeHtml from 'sanitize-html';
import styles from './styles.module.css';

export const TextAreaWidgetConfigCard = (props: WidgetConfigCardProps) => {
  const [updateWidget] =
    usePutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotMutation();
  const { openModal } = useModal();

  const textData = props.data?.widget.text as undefined | string;

  return (
    <BaseWidgetConfigCard
      {...props}
      title={
        <span className="text-ellipsis whitespace-nowrap">
          {props.title}{' '}
          {textData && (
            <>
              <span className="inline-regular">| </span>
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(textData, DEFAULT_SANITIZE_HTML_OPTIONS),
                }}
                className={
                  styles.textAreaContentPreviewResetAllBlockEditorStyles
                }
              />
            </>
          )}
        </span>
      }
      hasData={widgetHasData(props.data)}
      labelSlot={
        <BaseWidgetConfigCard.Actions>
          <IconButton
            isLoading={props.isLoading}
            disabled={props.disabled}
            iconName="edit"
            onClick={async () => {
              if (props.data?.widget && 'text' in props.data.widget) {
                const res = await openModal(TextAreaWidgetSettingsModal, {
                  html: props.data.widget?.text,
                });
                if (res == null) return;
                await updateWidget({
                  assetId: Number(props.assetId),
                  reportId: props.reportId,
                  id: props.widgetSection.id,
                  body: {
                    snapshot_data: {
                      text: res.html!,
                    },
                  },
                });
              }
            }}
          />
        </BaseWidgetConfigCard.Actions>
      }
    />
  );
};
