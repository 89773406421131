import { DateSSN } from '@/shared/lib/dateSSN/ssn';

import { VariableValueType } from '@/entities/valueType/model';

export const getDefaultValueForValueType = (valueType: VariableValueType) => {
  switch (valueType) {
    case 'date':
      return new DateSSN(new Date()).toNumber();
    case 'text':
      return 'Sample text';
    default:
      return 0;
  }
};
