import React, { useMemo } from 'react';
import { nameOf } from 'types/utils';
import {
  ILineItem,
  ILineItemTransactionDetails,
} from 'bundles/REconcile/types/ILineItem';
import { IColumn } from 'bundles/Shared/components/Table/types';
import {
  DISABLE_CURRENCY_SYMBOL_OPTIONS,
  formatAmount,
} from '@/shared/lib/formatting/number';
import {
  getRemarkFormatter,
  getTransactionDateFormatter,
  getVendorEntityFormatter,
} from 'bundles/Shared/components/lineItems/formatters';
import QuickFilterFireButton from 'bundles/Shared/components/Table/filters/QuickFilterFireButton';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import { SOURCE_TYPE_SETTINGS } from 'lib/sourceType';
import SetGeneralLedgerTooltip from 'bundles/Shared/components/ReportTable/groupRenderer/SetGeneralLedgerTooltip';
import { Button, Icon, Tooltip } from 'stories';
import styles from 'bundles/Construction/styles.module.scss';
import { cn } from '@/shared/lib/css/cn';
import { ERPSystemIcon } from '@/bundles/Shared/entities/erpsystem/ui/ERPSystemIcon';
import { ERPSystem } from '@/bundles/Shared/entities/erpsystem/model';

export const developmentLineItemsColumns = ({
  withoutInvoicesCount,
  onInvoiceClick,
  withoutSourceCount,
  onSourceLinkClick,
  hasGeneralLedger,
}) =>
  useMemo<IColumn<ILineItemTransactionDetails>[]>(
    () => [
      {
        text: 'TX Date',
        dataField: nameOf<ILineItem>('date'),
        formatter: getTransactionDateFormatter<ILineItem>((row) => row.date),
        sortable: true,
        headerStyle: {
          width: 200,
        },
      },
      {
        text: 'Vendor',
        dataField: nameOf<ILineItem>('vendor'),
        headerStyle: {
          width: 274,
        },
        formatter: getVendorEntityFormatter<ILineItem>((item) => item.vendor),
      },
      {
        text: 'Amount',
        dataField: nameOf<ILineItem>('amount'),
        formatter: ({ row }) => (
          <>
            <span className="light-60 inline-regular mr-1">$</span>
            {formatAmount(
              parseFloat(row.amount),
              DISABLE_CURRENCY_SYMBOL_OPTIONS,
            )}
          </>
        ),
        sortable: true,
        headerStyle: {
          width: 140,
        },
        classes: 'text-right',
        headerAlign: 'end',
      },
      {
        text: 'Remark',
        dataField: nameOf<ILineItem>('remark'),
        headerStyle: {
          width: 274,
        },
        formatter: getRemarkFormatter<ILineItem>((item) => item.remark),
      },
      {
        text: 'Invoice',
        dataField: nameOf<ILineItem>('invoice'),
        headerStyle: {
          width: 274,
        },
        filterComponent: QuickFilterFireButton,
        filterComponentParams: {
          count: withoutInvoicesCount,
        },
        formatter: ({ row }) => {
          if (!row.invoice) {
            return (
              <>
                <div className="mb-tw-2 flex items-center">
                  <span className="light-60 secondary-regular">No Invoice</span>
                  <FireIcon priority="high" className="ml-tw-1.5" />
                </div>
                <Button variant="secondary" size="xs" onClick={onInvoiceClick}>
                  Invoice Management
                </Button>
              </>
            );
          }

          const { title, url } = row.invoice.document;

          return (
            <div>
              <div className="secondary-regular mb-tw-2 text-light-60">
                Invoice #{row.invoice.number}
              </div>
              <Tooltip titleText={title}>
                <a target="blank" href={url} className={styles.invoiceDocument}>
                  <Icon
                    className={cn('red', styles.invoiceCompanyIcon)}
                    iconName="docPdf"
                  />
                  <span className="secondary-regular text-ellipsis">
                    {title}
                  </span>
                </a>
              </Tooltip>
            </div>
          );
        },
      },
      {
        text: 'Linked with source',
        dataField: nameOf<ILineItem>('source'),
        headerStyle: {
          width: 300,
        },
        filterComponent: QuickFilterFireButton,
        filterComponentParams: {
          count: withoutSourceCount,
          priority: 'low',
        },
        formatter: ({ row }) => {
          if (!row.transactionDetails) {
            return (
              <>
                <div className="mb-tw-2 flex items-center">
                  <span className="light-60 secondary-regular">
                    No Transaction Yet
                  </span>
                  <FireIcon priority="low" className="ml-tw-1.5" />
                </div>
                {hasGeneralLedger && (
                  <Button
                    variant="secondary"
                    size="xs"
                    onClick={() => onSourceLinkClick(row)}
                  >
                    Link Transaction
                  </Button>
                )}
                {!hasGeneralLedger && (
                  <SetGeneralLedgerTooltip>
                    <Button
                      disabled
                      iconName="lock"
                      iconPosition="right"
                      size="xs"
                    >
                      Link Transaction
                    </Button>
                  </SetGeneralLedgerTooltip>
                )}
              </>
            );
          }

          return (
            <>
              <div className="mb-tw-2">
                <div className="light-60 secondary-regular">
                  General Ledger:
                </div>
                <div>
                  {row.source.number} - {row.source.title}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <Button
                  variant="secondary"
                  size="xs"
                  onClick={() => onSourceLinkClick(row)}
                >
                  Details
                </Button>
                <ERPSystemIcon
                  // @ts-expect-error: replace with correct type
                  system={row.transactionDetails.source}
                  className="size-tw-4"
                  alt={
                    SOURCE_TYPE_SETTINGS[
                      row.transactionDetails.source as ERPSystem
                    ].title
                  }
                />
              </div>
            </>
          );
        },
      },
    ],
    [withoutInvoicesCount, withoutSourceCount],
  );
