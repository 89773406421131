import { COLUMN_CONFIGURATION_LABEL_INFO_TEXT } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { ColumnHeaderField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColumnHeaderField';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { yupResolver } from '@hookform/resolvers/yup';
import { EAGLE_EYE_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import {
  AdjustmentField,
  CreateNewForm,
  EditPanelActions,
  FormulaField,
  PeriodShiftField,
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { ColumnSettingsFields } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColumnSettingsFields';
import { TotalCalculationStrategyField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/TotalCalculationStrategyField';
import { TableVizConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  DEFAULT_KPI_TABLE_WIDGET_CONFIG_COLUMN,
  KPI_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA,
  KpiTableWidgetConfigColumn,
  KpiTableWidgetConfigColumnForm,
  removeColumn,
  transformColumnConfigToForm,
  upsertColumn,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { FormProvider, useForm } from 'react-hook-form';
import { Field, Input } from 'stories';
import { FieldsContainer } from 'stories/Field/Field';
import { SectionField } from 'stories/Field/FieldsWrappers';
import { SidePanel } from 'stories/Modals/Modal/Modal';

interface Props extends DialogProps<CreateNewForm> {
  type: 'create' | 'edit';
  columnConfig?: KpiTableWidgetConfigColumn;
  columnSettings?: TableVizConfigColumn;
  groupId?: string;
}

export function KpiTableWidgetConfigColumnPanel({
  type,
  columnConfig,
  columnSettings,
  groupId,
  onClose,
  onSubmit,
}: Props) {
  const resolver = yupResolver(KPI_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA);
  const methods = useForm<KpiTableWidgetConfigColumnForm & CreateNewForm>({
    resolver,
    mode: 'all',
    defaultValues: {
      ...DEFAULT_KPI_TABLE_WIDGET_CONFIG_COLUMN,
      ...(columnConfig &&
        columnSettings &&
        transformColumnConfigToForm(columnConfig, columnSettings)),
    },
  });

  const { control, register, handleSubmit } = methods;
  const { widget } =
    useWidgetConfig<typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE>();
  const [updateConfig] = useUpdateWidgetConfig(
    EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE,
  );

  const handleRemove = () => {
    updateConfig({
      config: removeColumn(
        {
          columnKey: columnConfig!.key.toString(),
        },
        widget.widgetConfig,
      ),
    });
    onClose();
  };

  const handleSubmitClick = handleSubmit(async (values) => {
    const config = upsertColumn(values, widget.widgetConfig, { groupId });
    await updateConfig({ config });

    onSubmit?.({ createNew: values.createNew });
  });

  return (
    <FormProvider {...methods}>
      <SidePanel
        header={type === 'create' ? 'Create' : 'Edit'}
        toggle={onClose}
        actions={
          <EditPanelActions
            type={type}
            onClose={onClose}
            onSubmit={handleSubmitClick}
            onRemove={handleRemove}
          />
        }
      >
        <FieldsContainer>
          <Field note={COLUMN_CONFIGURATION_LABEL_INFO_TEXT} labelText="Title">
            <Input
              placeholder="Enter Primary Title"
              className="w-full"
              {...register('label')}
            />
          </Field>
          <FormulaField control={control} name="expression" required />
          <AdjustmentField control={control} name="adjustment" />
          <SectionField labelText="Period Shift">
            <PeriodShiftField control={control} name="period_shift" />
          </SectionField>
          <TotalCalculationStrategyField
            control={control}
            name="total_calculation_strategy"
          />
          <ColumnSettingsFields
            columnsConfig={widget.widgetConfig.columns}
            vizConfig={widget.widgetConfig.viz_config}
            control={control}
          />
          <ColumnHeaderField name="header" control={control} />
        </FieldsContainer>
      </SidePanel>
    </FormProvider>
  );
}
