import React from 'react';
import { ExternalSourceType } from '@/entities/core/legalEntity';
import { ERPSystemLabel } from '@/bundles/Shared/entities/erpsystem';

interface Props {
  sourceType: ExternalSourceType;
}

function SourceTypeColumn({ sourceType }: Props) {
  return <ERPSystemLabel system={sourceType} />;
}

export default SourceTypeColumn;
