import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import { LegalEntity } from '@/entities/core/legalEntity';
import { selectStyles } from './LEModalSelectStyles';
import { ModalHeaderForUpdatingLE } from './ModalHeaderForSetObjectLE';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { CoreLegalEntitiesLegalEntitiables } from 'bundles/Shared/entities/legalEntity/api/settingsCoreLegalEntitiesApi';
import { Button, Modal } from '@/stories';

interface Props extends DialogProps<LegalEntityFieldOption> {
  assets: CoreLegalEntitiesLegalEntitiables[];
  funds: CoreLegalEntitiesLegalEntitiables[];
  currentLegalEntities: LegalEntity[];
}

export type LegalEntityFieldOption = {
  value: string;
  label: string;
};

export function SetObjectForLEModal({
  currentLegalEntities,
  assets,
  funds,
  onClose,
  onSubmit,
}: Props) {
  const [selectedObject, setSelectedObject] =
    useState<LegalEntityFieldOption>();
  const assetOptions = useMemo<LegalEntityFieldOption[]>(
    () =>
      assets.map((asset) => ({
        value: `Asset_${asset.id}`,
        label: asset.name,
      })),
    [assets],
  );

  const fundOptions = useMemo(
    () => funds.map((fund) => ({ value: `Fund_${fund.id}`, label: fund.name })),
    [funds],
  );
  const handleSubmit = () => {
    onSubmit?.(selectedObject);
  };
  const onCloseModal = () => {
    setSelectedObject(undefined);
    onClose();
  };

  return (
    <Modal
      toggle={onCloseModal}
      header={
        <ModalHeaderForUpdatingLE
          title="Set object for"
          currentLegalEntities={currentLegalEntities}
        />
      }
      actions={
        <>
          <Button onClick={onCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={!selectedObject}
          >
            Set Object
          </Button>
        </>
      }
      bodyPadding="py-m px-l"
      size="500"
    >
      <label className="mb-tw-2">
        Object <span className="red">*</span>
      </label>
      <Select
        menuPosition="fixed"
        menuShouldBlockScroll
        isClearable
        onChange={(option: unknown) =>
          setSelectedObject(option as LegalEntityFieldOption)
        } // Wrong type in react-select
        styles={selectStyles}
        options={[
          {
            label: 'Assets',
            options: assetOptions,
          },
          {
            label: 'Funds',
            options: fundOptions,
          },
        ]}
        classNamePrefix="react-select"
        placeholder="Select Asset or Fund"
      />
    </Modal>
  );
}
