import { CssCustomProperty } from '@/shared/lib/css/cssCustomProperty';

const COLOR_CSS_CUSTOM_PROPERTY_INSTANCE = new CssCustomProperty('color');
const COLOR_CSS_VAR_NAME = COLOR_CSS_CUSTOM_PROPERTY_INSTANCE.name;
const COLOR_CSS_VAR_REF = COLOR_CSS_CUSTOM_PROPERTY_INSTANCE.ref;

const TABLE_CELL_FONT_SIZE_CSS_CUSTOM_PROPERTY_INSTANCE = new CssCustomProperty(
  'fontSize',
);
const FONT_SIZE_CSS_VAR_NAME =
  TABLE_CELL_FONT_SIZE_CSS_CUSTOM_PROPERTY_INSTANCE.name;
export const TABLE_CELL_FONT_SIZE_CSS_VAR_REF =
  TABLE_CELL_FONT_SIZE_CSS_CUSTOM_PROPERTY_INSTANCE.ref;

export const getTableCellBackgroundStyle = (
  color: React.CSSProperties['color'],
): React.CSSProperties =>
  ({
    [COLOR_CSS_VAR_NAME]: color,
    background: COLOR_CSS_VAR_REF,
    borderColor: `color-mix(in srgb, ${COLOR_CSS_VAR_REF} 75%, white)`,
  }) as React.CSSProperties;

export const getTableCellFontSizeStyle = (
  fontSize: number,
): React.CSSProperties =>
  ({
    [FONT_SIZE_CSS_VAR_NAME]: `${fontSize}px`,
    fontSize: TABLE_CELL_FONT_SIZE_CSS_VAR_REF,
  }) as React.CSSProperties;
