import React from 'react';
import { cn } from '@/shared/lib/css/cn';

interface Props {
  title?: string;
  subtitle?: React.ReactNode;
  order?: 'title-subtitle' | 'subtitle-title';
  classes?: {
    title?: string;
    subtitle?: string;
  };
}

export const ModalHeaderWithSubtitle = ({
  title,
  subtitle,
  order,
  classes,
}: Props) => {
  return (
    <div
      className={cn('flex flex-grow flex-col justify-stretch', {
        'flex-col-reverse': order === 'subtitle-title',
      })}
    >
      <div
        className={cn(
          'dark-60 font-weight-700 word-break inline-semibold',
          classes?.title,
        )}
      >
        {title}
      </div>
      <div className={cn('light-60 secondary-regular', classes?.subtitle)}>
        {subtitle}
      </div>
    </div>
  );
};

export default ModalHeaderWithSubtitle;
