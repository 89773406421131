import React, { useCallback, useState, useEffect } from 'react';
import Table from 'bundles/Shared/components/Table/Table';
import { useParams, navigate } from '@reach/router';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { HugeModalHeaderForCe, AnimationLoader } from 'stories';
import { useGetInvoicesWithRequisitionQuery } from 'bundles/Construction/api/invoices';
import {
  fetchCompanies,
  selectCompanies,
} from 'bundles/Construction/reducers/ReconcileSlice';
import {
  generatePath,
  ROUTES_ROOT,
  useNavigation,
} from '@/shared/lib/hooks/useNavigation';
import { usePageParamsTableSorting } from '@/shared/lib/hooks/usePageParamsTableSorting';
import {
  PageParamsProvider,
  PageParamsPagination,
  PageParamsSearch,
} from 'bundles/Shared/components/pageParams';
import { formatDate } from '@/shared/lib/formatting/dates';
import TotalInfoBlock from '../components/TotalInfoBlock.tsx/TotalInfoBlock';
import { TFilterModelPayableInvoices } from '../PayableInvoices/components/useColumns';
import { getTotalData } from './components/utils';
import { requisitionInvoicesColumns } from './components/useColumns';

const InvoicesWithRequisition = ({ children }) => {
  const companies = useAppSelector(selectCompanies);
  const legalEntity = useAppSelector(
    (store) => store.developmentBudget.legalEntity,
  );
  const dispatch = useAppDispatch();

  const params = useParams();
  const { getUrl } = useNavigation();

  const [pageParams, setPageParams] = useState({
    status: 'all',
    amount: {
      from: '',
      to: '',
    },
    vendor_ids: [],
    page: 1,
  });

  const { data, isLoading } = useGetInvoicesWithRequisitionQuery({
    legalEntityCode: params?.legalEntityCode || legalEntity?.code,
    requisitionId: params?.requisitionId,
    pageParams,
  });

  const { settings, setSettings } = usePageParamsTableSorting(
    pageParams,
    setPageParams,
  );

  const handleFilterModelChange = useCallback(
    (filterModel: TFilterModelPayableInvoices) =>
      setPageParams((prevParams) => {
        return {
          ...prevParams,
          status: filterModel.status,
          vendor_ids: filterModel.vendor_ids?.map((item) => item.id),
          amount: filterModel.amount,
        };
      }),
    [],
  );

  useEffect(() => {
    dispatch(fetchCompanies());
  }, []);

  if (isLoading) return <AnimationLoader />;

  const { requisition } = data!.meta;

  const onCloseModal = () => {
    navigate(
      getUrl('RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS', {
        legalEntityCode: params.legalEntityCode,
      }),
    );
  };

  const showDetails = (id) => {
    navigate(
      generatePath(
        ROUTES_ROOT.reconcile.development.legalEntity.requisitionInvoices
          .requisition.invoice.fullPath,
        {
          legalEntityCode: params.legalEntityCode,
          requisitionId: params.requisitionId,
          invoiceId: id,
        },
      ),
    );
  };

  const datePeriodSubtitle = [
    formatDate(requisition.beginningDate, 'MMM DD'),
    formatDate(requisition.endingDate, 'MMM DD, YYYY'),
  ].join(' - ');
  return (
    <div>
      <div className="bg-white px-l py-m">
        <HugeModalHeaderForCe
          title="Requisition"
          subtitle={<div className="label-regular">{datePeriodSubtitle}</div>}
          onClose={onCloseModal}
          content={<TotalInfoBlock metaData={getTotalData(data?.meta || [])} />}
        />
      </div>
      <div className="custom-container">
        <h2 className="font-weight-700 mb-l mt-xl">Invoices</h2>
        <PageParamsProvider
          pageParams={pageParams}
          setPageParams={setPageParams}
        >
          <div>
            <div className="flex items-center justify-between mb-m">
              <div>
                <PageParamsPagination
                  loading={isLoading}
                  totalSize={data?.meta.totalSize || 1}
                  sizePerPage={data?.meta.perPage || 1}
                />
              </div>
              <PageParamsSearch />
            </div>
            <div>
              <Table
                loading={isLoading}
                columns={requisitionInvoicesColumns(companies, showDetails)}
                settings={settings}
                setSettings={setSettings}
                onFilterModelChange={handleFilterModelChange}
                items={data?.items || []}
              />
            </div>
          </div>
        </PageParamsProvider>
      </div>
      {params['*'] && children}
    </div>
  );
};

export default InvoicesWithRequisition;
