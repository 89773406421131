import { EMAIL_RRULE_TIMEZONE_TZID_MAP } from '@/entities/emails/customTemplates/config';
import { formatDate } from '@/shared/lib/formatting/dates';
import { RRule } from 'rrule';

const START_STRING = 'Occurs';

const getTimezone = (rruleOptions: RRule['options']): string | null => {
  const timezoneOption =
    (EMAIL_RRULE_TIMEZONE_TZID_MAP[(rruleOptions.tzid as string) ?? '']
      ?.label as string | undefined) ?? null;

  return timezoneOption;
};

const getTimeFromRruleOptions = (rruleOptions: RRule['options']): Date => {
  return new Date(
    0,
    0,
    0,
    rruleOptions.byhour.at(0) ?? 0,
    rruleOptions.byminute.at(0) ?? 0,
    0,
    0,
  );
};

const REPLACE_AT_STR_PARAMS = [' at', ''] as const;

export const rruleToText = (rruleOptions: RRule['options']) => {
  const ruleOptionWithoutTimeOptions = new RRule(rruleOptions).options;
  ruleOptionWithoutTimeOptions.byhour = [];
  ruleOptionWithoutTimeOptions.byminute = [];
  ruleOptionWithoutTimeOptions.bysecond = [];

  const resStrings = [START_STRING];

  const time = getTimeFromRruleOptions(rruleOptions);

  const ogRruleToText = new RRule(ruleOptionWithoutTimeOptions)
    .toText()
    .replace(...REPLACE_AT_STR_PARAMS);

  resStrings.push(ogRruleToText);

  const formattedTime = ` at ${formatDate(time, 'hh:mm A')}`;

  resStrings.push(formattedTime);

  const timezoneOption = getTimezone(rruleOptions);

  if (timezoneOption) {
    resStrings.push(timezoneOption);
  }

  return resStrings.join(' ');
};
