import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { DateRangeBase } from '@/shared/lib/date';
import dayjs from 'dayjs';
import { produce } from 'immer';
import { sortBy } from 'lodash-es';

export const orderPeriodsByDateFrom = <T extends DateRangeBase>(
  periods: T[],
) => {
  return sortBy(periods, (period) =>
    period.dateFrom ? new Date(period.dateFrom).getTime() : 0,
  );
};

export const fillPeriodsDateTo = <T extends DateRangeBase>(periods: T[]) => {
  return produce(periods, (draft) => {
    for (let i = 0; i < draft.length; i++) {
      const period = draft[i];
      const prevPeriod = draft[i - 1];

      if (!prevPeriod?.dateFrom) {
        continue;
      }

      prevPeriod.dateTo = formatToDateStringForRequest(
        dayjs(period.dateFrom).subtract(1, 'day'),
      );
    }
  });
};

export const updatePeriodByDateFrom = <T extends DateRangeBase, U = string>(
  periods: (T & { id: U })[],
  update: { id: U; dateFrom: string | null },
) => {
  const replacedPeriods = produce(periods, (draft) => {
    const periodIndex = draft.findIndex((p) => p.id === update.id);

    draft[periodIndex].dateFrom = update.dateFrom;

    if (
      (draft[periodIndex].dateTo &&
        dayjs(draft[periodIndex].dateTo).isBefore(dayjs(update.dateFrom))) ??
      periodIndex === draft.length - 1
    ) {
      draft[periodIndex].dateTo = null;
    }
  });
  const orderedPeriods = orderPeriodsByDateFrom(replacedPeriods);
  const filledPeriods = fillPeriodsDateTo(orderedPeriods);

  return filledPeriods;
};
