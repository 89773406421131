import type {
  EmailCustomTemplateMutationArgs,
  EmailCustomTemplateSection,
  EmailCustomTemplateUpdateArgs,
  EmailCustomTemplateVariables,
} from '@/entities/emails/customTemplates/model';
import { isFormEmailTemplateReportUniqueId } from '@/features/emails/custom/addAttachments/lib';
import type { RubyOnRailsDestroyAttributes } from '@/shared/types/RubyOnRails';
import { RRule } from 'rrule';

export const getEmailCustomTemplateUpdateBody = ({
  template,
  usedVariables,
}: Partial<EmailCustomTemplateMutationArgs>): EmailCustomTemplateUpdateArgs => ({
  id: template?.id,
  custom_template: {
    user_role_ids: template?.recipients?.roles?.map((userRole) => userRole.id),
    user_tag_ids: template?.recipients?.tags?.map((tag) => tag.id),
    investment_entity_ids: template?.recipients?.investmentEntities?.map(
      (investmentEntity) => investmentEntity.id,
    ),
    user_ids: template?.recipients?.users?.map((user) => user.id),

    schedule: template?.schedule ? template.schedule : null,
    schedule_timezone: template?.schedule
      ? RRule.fromString(template.schedule).options?.tzid
      : null,
    name: template?.name,
    template_attributes: {
      subject: template?.subject,
    },
    reports_attributes: template?.reports?.map((report) => ({
      asset_id: report.asset.id,
      report_builder_template_id: report.reportBuilderTemplate.id,
      id: isFormEmailTemplateReportUniqueId(report.id) ? undefined : report.id,
      _destroy: report._destroy,
    })),
    sections_attributes: template?.sections?.map(
      (section: EmailCustomTemplateSection & RubyOnRailsDestroyAttributes) => {
        const result: {
          position: number | undefined;
          _destroy: boolean | undefined;
          id: string | undefined;
          type: string | undefined;
          text_section_attributes?: {
            text: string | undefined;
          };
          header_section_attributes?: {
            text_attributes: {
              text_variables_attributes:
                | EmailCustomTemplateVariables[]
                | undefined;
              content: string | undefined;
            };
          };
        } = {
          position: section.position,
          _destroy: section._destroy,
          id: section.id,
          type: section.type,
        };

        if (section.type === 'text') {
          result.text_section_attributes = {
            text: section.textSection?.text,
          };
        }

        if (section.type === 'header') {
          result.header_section_attributes = {
            text_attributes: {
              text_variables_attributes: usedVariables?.header,
              content: section.headerSection?.text,
            },
          };
        }

        return result;
      },
    ),
  },
});
