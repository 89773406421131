import {
  OBJECT_DASHBOARD_SECTION_TYPE,
  ReportObjectDashboardSection,
  TrailingPeriodType,
} from 'bundles/Shared/entities/dashboard';
import { TableVizConfig } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  AdjustmentExpressionDto,
  HistoricalReviewTableWidgetConfigDto,
} from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { ValueDisplayOptions } from '@/shared/lib/formatting/displayOptions';
import { TimePeriodType } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';

export const HISTORICAL_TABLE_WIDGET_ROW_TYPES = {
  NUMERIC: 'numeric',
  TEXT: 'text',
} as const;

export type HistoricalTableWidgetRowType =
  (typeof HISTORICAL_TABLE_WIDGET_ROW_TYPES)[keyof typeof HISTORICAL_TABLE_WIDGET_ROW_TYPES];

export type HistoricalReviewTableWidgetConfigModel = {
  columns: {
    key: number;
    label: string;
    period_type: TrailingPeriodType | TimePeriodType;
    period_shift?: {
      days?: number;
      weeks?: number;
      months?: number;
      quarters?: number;
      years?: number;
    };
  }[];
  viz_type: 'table';
  viz_config: TableVizConfig;
  rows: {
    key: number;
    label: string;
    expression: string;
    value_display_options?: ValueDisplayOptions;
    adjustment_expression?: AdjustmentExpressionDto;
    type: HistoricalTableWidgetRowType;
    history_size: number;
  }[];
};
export type HistoricalReviewTableWidgetSection =
  ReportObjectDashboardSection & {
    widgetType: typeof OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE;
    widgetConfig: HistoricalReviewTableWidgetConfigDto;
    defaultOptions: {
      date: DateString;
    };
  };
