import { NotFound } from '@/bundles/Errors';
import { SettingsEmailsPage } from '@/pages/settings/emails/page';
import { EmailCustomTemplateEditorPage } from '@/pages/settings/emails/templates/custom/[customTemplateId]/page';
import { NewEmailCustomTemplateEditorPage } from '@/pages/settings/emails/templates/custom/new/page';
import { CustomTemplatesPage } from '@/pages/settings/emails/templates/page';
import {
  allSubPathMatches,
  ROUTES_ROOT,
} from '@/shared/lib/hooks/useNavigation';
import { Router } from '@reach/router';
import { SettingsReportDashboard } from 'bundles/Settings/components/REport/Dashboards/Dashboard/SettingsReportDashboard';
import { SettingsReportDashboardWidget } from 'bundles/Settings/components/REport/Dashboards/Dashboard/Widget/SettingsReportDashboardWidget';
import { SettingsReportDashboardsNew } from 'bundles/Settings/components/REport/Dashboards/SettingsReportDashboardsNew';
import {
  ASSET_PORTAL_PRODUCT_NAME,
  currentUserAllowedTo,
  currentUserIsSreAdmin,
  currentUserIsSuperAdmin,
  PIPELINE_PRODUCT_NAME,
  REPORT_PRODUCT_NAME,
} from 'lib/permissions';
import FeedMessageTemplateEdit from '../components/EmailSettings/editor/FeedMessageTemplateEdit';
import ForgotPasswordTemplateEdit from '../components/EmailSettings/editor/ForgotPasswordTemplateEdit';
import InvitationTemplateEdit from '../components/EmailSettings/editor/InvitationTemplateEdit';
import FeedMessageCampaignsTab from '../components/EmailSettings/tabs/FeedMessageCampaignsTab';
import FeedMessageTemplatesTab from '../components/EmailSettings/tabs/FeedMessageTemplatesTab';
import ForgotPasswordTemplatesTab from '../components/EmailSettings/tabs/ForgotPasswordTemplatesTab';
import InvitationTemplatesTab from '../components/EmailSettings/tabs/InvitationTemplatesTab';
import TemplatesTab from '../components/EmailSettings/tabs/TemplatesTab';
import ReportSettings from '../components/REport/ReportSettings';
import ReportTableConfig from '../components/REport/TableBuilder/ReportTableConfig';
import PipelineSettings from './PipelineSettings';
import PortalSettings from './PortalSettings';

const removeSettingsFromPath = (path: string) =>
  path.replace(ROUTES_ROOT.settings.fullPath, '');

const Settings = () => (
  <Router>
    <NotFound default />
    {currentUserAllowedTo('configure', PIPELINE_PRODUCT_NAME) && (
      <PipelineSettings path="/pipeline/*" />
    )}
    {currentUserAllowedTo('configure', REPORT_PRODUCT_NAME) && (
      <>
        <SettingsReportDashboardsNew
          path={removeSettingsFromPath(
            ROUTES_ROOT.settings.report.dashboards.new.fullPath,
          )}
        />
        <SettingsReportDashboardWidget
          path={removeSettingsFromPath(
            ROUTES_ROOT.settings.report.dashboards.dashboard.widget.fullPath,
          )}
        />
        <SettingsReportDashboard
          path={allSubPathMatches(
            removeSettingsFromPath(
              ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath,
            ),
          )}
        />
        <ReportSettings path="/report/*" />
        {/*
          NOTE: Vlad K. have placed following routes here only cause navigation of
            Report Settings should not be visible
        */}
        <ReportTableConfig path="/report/table-builder/:tableSlug" />
      </>
    )}
    {currentUserAllowedTo('configure', ASSET_PORTAL_PRODUCT_NAME) && (
      <PortalSettings path="/portal/*" />
    )}
    <SettingsEmailsPage path={ROUTES_ROOT.settings.emails.path}>
      <TemplatesTab path={ROUTES_ROOT.settings.emails.templates.path}>
        <InvitationTemplatesTab
          path={ROUTES_ROOT.settings.emails.templates.invitations.path}
        />
        <FeedMessageTemplatesTab
          path={ROUTES_ROOT.settings.emails.templates.feedMessages.path}
        />
        <ForgotPasswordTemplatesTab
          path={ROUTES_ROOT.settings.emails.templates.forgotPassword.path}
        />
        <CustomTemplatesPage
          path={ROUTES_ROOT.settings.emails.templates.custom.path}
        />
      </TemplatesTab>
      {(currentUserIsSreAdmin() || currentUserIsSuperAdmin()) && (
        <FeedMessageCampaignsTab path="campaigns" />
      )}
    </SettingsEmailsPage>
    <InvitationTemplateEdit path="/emails/templates/invitations/:invitationTemplateId/edit" />
    <InvitationTemplateEdit
      path={removeSettingsFromPath(
        ROUTES_ROOT.settings.emails.templates.invitations.new.fullPath,
      )}
    />
    <EmailCustomTemplateEditorPage
      path={removeSettingsFromPath(
        ROUTES_ROOT.settings.emails.templates.custom.customTemplate.edit
          .fullPath,
      )}
    />
    <NewEmailCustomTemplateEditorPage
      path={removeSettingsFromPath(
        ROUTES_ROOT.settings.emails.templates.custom.new.fullPath,
      )}
    />
    <FeedMessageTemplateEdit
      path={removeSettingsFromPath(
        ROUTES_ROOT.settings.emails.templates.feedMessages.feedMessage.edit
          .fullPath,
      )}
    />
    <ForgotPasswordTemplateEdit
      path={removeSettingsFromPath(
        ROUTES_ROOT.settings.emails.templates.forgotPassword.forgotPassword.edit
          .fullPath,
      )}
    />
  </Router>
);

export default Settings;
