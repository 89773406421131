import { GetApiPortfolioApiResponse } from 'bundles/Shared/shared/api/portfolioGeneratedApi';

export const EMPTY_PORTFOLIO: GetApiPortfolioApiResponse = {
  capitalDetails: {
    asOfDate: Date.now(),
    rows: [],
  },
  capitalHistory: [],
  capitalBreakdown: [],
  contributionOverview: [],
  snapshots: {
    contributed: {
      total: 0,
      contributedPercent: 0,
      activeInvestmentsCount: 0,
    },
    distributed: {
      totalDistributionsCount: 0,
      cashOnCashPercent: 0,
      total: 0,
    },
  },
};
