/* eslint-disable max-params */
import { useMemo } from 'react';
// import { capitalize } from 'lodash-es';
import http from 'lib/http';
import { Button, IconButton } from '@/stories';
import { InvestmentObjectHoverable } from 'bundles/Shared/components/InvestmentObjectHoverable';
// import QuickFilterByObject from 'bundles/Assets/components/SharedFiles/Table/Filters/QuickFilterByObject';
import FireIcon from 'bundles/FireStation/components/FireIcon/FireIcon';
import { TConfirmFunc } from '@/shared/lib/hooks/useModal';
import QuickFilterFireButton, {
  TQuickFilterFireButtonProps,
} from 'bundles/Shared/components/Table/filters/QuickFilterFireButton';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { LEConfigsMeta, LegalEntity } from '@/entities/core/legalEntity';
import LastActivityOfLEPopover from './LastActivityOfLEPopover';
import { PermissionListLine } from 'bundles/Shared/components/Permissions/PermissionListLine';
import { CoreLegalEntitiesLegalEntitiables } from 'bundles/Shared/entities/legalEntity/api/settingsCoreLegalEntitiesApi';
import { LeClassificationFormatter } from 'bundles/Shared/entities/leClasssification/ui/LeClassificationFormatters';
import { LegalEntityManagmentPeriods } from './components/LegalEntityManagmentPeriods';
import { GetApiSettingsCoreLegalEntitiesApiArg } from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesGeneratedApi';
import { navigate } from '@reach/router';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

export type TFilterModel = {
  permissions_fire?: boolean;
};

const sortOptions: Pick<IColumn<LegalEntity>, 'sortable'> = {
  sortable: true,
};

const getSortOptions = (hasSort: boolean) => (hasSort ? sortOptions : {});

const name = (sort: typeof sortOptions): IColumn<LegalEntity> => ({
  dataField: 'name',
  text: 'Legal Entity',
  ...sort,
});

const dataSources = (): IColumn<LegalEntity> => ({
  dataField: 'managementPeriods',
  text: 'Data Sources',
  headerStyle: { width: 200 },
  classes: '!p-tw-3',
  formatter: ({ row }) => {
    const url = generateUrl(
      ROUTES_ROOT.settings.portal.legalEntities.periods.fullPath,
      {
        pathParams: {
          id: row.id,
        },
      },
    );

    return (
      <div className="flex justify-between gap-tw-4">
        <LegalEntityManagmentPeriods row={row} />

        {row.managementPeriods.length > 0 && (
          <IconButton
            tooltipProps={{
              mainText: 'Edit',
            }}
            variant="secondary"
            onClick={() => navigate(url)}
            iconName="edit"
            className="invisible group-hover:visible"
          />
        )}
      </div>
    );
  },
});

const leClassification = (actions: Actions): IColumn<LegalEntity> => ({
  dataField: 'classification',
  text: 'Class',
  headerStyle: { width: 200 },
  formatter: ({ row }) => (
    <LeClassificationFormatter
      classification={row.classification}
      onClick={() => {
        actions.openSetLEClassifaction(row);
      }}
      showFireIcon={row.classification == null && row.legalEntitiableId != null}
    />
  ),
});

const lastUpdated = (): IColumn<LegalEntity> => ({
  dataField: 'lastUpdated',
  text: 'Last updated',
  headerStyle: { width: 185 },
  formatter: ({ row }) =>
    row.lastActivity ? <LastActivityOfLEPopover legalEntity={row} /> : '-',
});

// eslint-disable-next-line
const legalEntitiable = (actions: Actions): IColumn<LegalEntity> => {
  // const investmentObjects =
  //   allAssets && allFunds
  //     ? [...allAssets, ...allFunds].map((obj) => ({
  //         id: obj.id,
  //         name: obj.name,
  //         klass: capitalize(obj.objectType),
  //         uniqId: `${obj.id}-${obj.objectType}`,
  //       }))
  //     : [];

  return {
    dataField: 'legalEntitiable',
    text: 'Object',
    classes: 'min-w-[160px] !py-tw-3',
    // filterComponent:
    //   actions.legalEntitiesScope === 'active-and-mapped'
    //     ? QuickFilterByObject
    //     : undefined,
    // filterComponentParams: {
    //   investmentObjects,
    //   selectedObjects: selectedFilters.investmentObjects,
    //   setSelectedObjects: (items) => {
    //     actions.setSelectedFilters({ asset_ids: items });
    //   },
    //   resetFilter: () => {
    //     actions.localResetFilter('investmentObjects');
    //   },
    // } satisfies ComponentProps<typeof QuickFilterByObject>,
    formatter: ({ row }) => {
      if (!row.legalEntitiable) {
        return (
          <div className="flex items-center gap-tw-2">
            <Button
              size="xs"
              variant="secondary"
              onClick={() => {
                actions.setCurrentLegalEntity(row);
                actions.openSetObjModalForSingleLE(row);
              }}
            >
              Set Object
            </Button>
            <FireIcon priority="medium" />
          </div>
        );
      }

      return (
        <InvestmentObjectHoverable
          object={row.legalEntitiable}
          forcedKey={`le-${row.id}`}
        />
      );
    },
    ...getSortOptions(actions.legalEntitiesScope === 'active-and-mapped'),
  };
};

const permissions = (
  actions: Actions,
  fireStationOptions: LEFireStationOptions,
): IColumn<LegalEntity> => ({
  dataField: 'permissions',
  text: 'Permissions',
  filterComponent: QuickFilterFireButton,
  filterComponentParams: {
    count: fireStationOptions.totals.totalFireStationPermissions,
  } satisfies TQuickFilterFireButtonProps<LegalEntity>,
  formatter: ({ row }) => (
    <PermissionListLine
      onClick={() => actions.onViewPermissions(row)}
      permissions={{
        directInvestmentEntities: row.permitted.investmentEntities,
        directRoles: row.permitted.roles,
        directTags: row.permitted.tags,
        directUsers: row.permitted.users,
        indirectUsers: row.permitted.autoSelectedUsers,
        isPublic: row.permitted.public,
      }}
    />
  ),
});

const leActions = (
  actions: Actions,
  confirm: TConfirmFunc,
): IColumn<LegalEntity> => ({
  dataField: 'actions',
  headerStyle: { width: 82 },
  classes: '!py-tw-3',
  formatter: ({ row }) => (
    <div className="flex w-max items-center gap-tw-2">
      <IconButton
        tooltipProps={{
          mainText: row.excluded ? 'Set Active' : 'Exclude',
        }}
        variant="secondary"
        onClick={async () => {
          const result = await confirm({
            title: 'Warning',
            subtitle: 'Are you sure?',
          });
          if (result) {
            http
              .post(`/legal_entities/${row.id}/exclude`, {})
              .then((res) => {
                // TODO: error handling
                if (res.ok)
                  toastr.success('Legal Entity has been successfully updated');
              })
              .then(() =>
                actions.setLegalEntitiesScope(actions.legalEntitiesScope),
              )
              .then(() => {
                actions.refreshMeta();
                actions.setSelectedRows([]);
              });
          }
        }}
        iconName={row.excluded ? 'eye' : 'eyeSlash'}
      />
      <IconButton
        tooltipProps={{
          mainText: 'Edit',
        }}
        variant="secondary"
        onClick={() => actions.setLegalEntityModalOpened(row)}
        iconName="edit"
      />
    </div>
  ),
});

interface Actions {
  onViewPermissions: (le: LegalEntity) => Promise<void>;
  setLegalEntityModalOpened: (v: boolean) => void;
  setPermissionsModalOpened: (v: boolean) => void;
  setCurrentLegalEntity: (le: LegalEntity) => void;
  openSetObjModalForSingleLE: (le: LegalEntity) => void;
  openSetLEClassifaction: (le: LegalEntity) => void;
  legalEntitiesScope: GetApiSettingsCoreLegalEntitiesApiArg['scope'];
  setLegalEntitiesScope: (
    scope: GetApiSettingsCoreLegalEntitiesApiArg['scope'],
  ) => void;
  refreshMeta: () => void;
  setSelectedRows: (rows: LegalEntity[]) => void;
  setSelectedFilters: (
    filters: GetApiSettingsCoreLegalEntitiesApiArg['filters'],
  ) => void;
  localResetFilter: (filterName: string) => void;
}

export interface LEFireStationOptions {
  totals: Omit<LEConfigsMeta, 'perPage'>;
}

interface Params {
  actions: Actions;
  allAssets: CoreLegalEntitiesLegalEntitiables[] | undefined;
  allFunds: CoreLegalEntitiesLegalEntitiables[] | undefined;
  selectedFilters: GetApiSettingsCoreLegalEntitiesApiArg['filters'];
  fireStationOptions: LEFireStationOptions;
  confirm: TConfirmFunc;
}

export const useLegalEntityColumns = ({
  actions,
  allAssets = [],
  allFunds = [],
  selectedFilters,
  fireStationOptions,
  confirm,
}: Params): IColumn<LegalEntity>[] => {
  const tableColumns = useMemo(
    () => [
      name(sortOptions),
      legalEntitiable(actions),
      leClassification(actions),
      permissions(actions, fireStationOptions),
      dataSources(),
      lastUpdated(),
      leActions(actions, confirm),
    ],
    [
      actions,
      allAssets,
      allFunds,
      selectedFilters,
      fireStationOptions,
      confirm,
    ],
  );

  return tableColumns;
};
