import { cn } from '@/shared/lib/css/cn';
import { ClassNameProps } from 'types/Props';

interface Props extends ClassNameProps {
  classes?: {
    content?: string;
  };
}

export function DashboardBoards(props: React.PropsWithChildren<Props>) {
  const { classes, children, className } = props;

  return (
    <div className={cn('flex grow flex-col', className)}>
      <div className={cn('grow', classes?.content)}>{children}</div>
    </div>
  );
}
