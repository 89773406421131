import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { ObjectDashboardXYChartWidgetConfig } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/config/component';
import { XYChartWidget } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/widget';
import { WidgetContextCalendarRangeWithGranularityCard } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetContextCalendarRangeCard';

export const XY_CHART_WIDGET_CONFIG = {
  layout: {
    w: 2,
    h: 13,
    maxH: 10,
    minH: 6,
    minW: 1,
    maxW: 2,
  },
  title: 'XY Chart',
  icon: 'table',
  Component: XYChartWidget,
  ConfigComponent: ObjectDashboardXYChartWidgetConfig,
  CardConfigComponent: WidgetContextCalendarRangeWithGranularityCard,
} as const satisfies WidgetConfiguration;
