import { Frequency, RRule } from 'rrule';

export const EMAIL_RRULE_TIMEZONE_OPTIONS = [
  { label: 'Central Time (CT)', value: 'America/Chicago' },
  { label: 'Pacific Time (PT)', value: 'America/Los_Angeles' },
  { label: 'Mountain Time (MT)', value: 'America/Denver' },
  { label: 'Eastern Time (ET)', value: 'America/New_York' },
] as const;

export const EMAIL_RRULE_TIMEZONE_TZID_MAP = Object.fromEntries(
  EMAIL_RRULE_TIMEZONE_OPTIONS.map((o) => [o.value, o]),
) as Record<
  (typeof EMAIL_RRULE_TIMEZONE_OPTIONS)[number]['value'],
  (typeof EMAIL_RRULE_TIMEZONE_OPTIONS)[number]
>;

export const MONTH_NAMES = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
] as const;

export const RRULE_WEEKDAY_MAP = {
  monday: RRule.MO,
  tuesday: RRule.TU,
  wednesday: RRule.WE,
  thursday: RRule.TH,
  friday: RRule.FR,
  saturday: RRule.SA,
  sunday: RRule.SU,
} as const;

export const RRULE_SET_POS_OPTIONS = {
  first: 1,
  second: 2,
  third: 3,
  fourth: 4,
  last: -1,
} as const;

export const RESET_RRULE_OPTIONS = {
  bymonth: undefined,
  bymonthday: undefined,
  byweekday: undefined,
  bysetpos: undefined,
  bysecond: undefined,
} as const satisfies Partial<RRule['options']>;

export const INITIAL_RRULE = new RRule({
  dtstart: new Date(),
  until: undefined,
  byhour: [12],
  byminute: [0],
  bysecond: [],
  interval: 1,
  tzid: EMAIL_RRULE_TIMEZONE_OPTIONS['0'].value,
});
const defaultDailyRules = {
  ...INITIAL_RRULE.options,
  freq: Frequency.DAILY,
} satisfies RRule['options'];

const defaultWeeklyRules = {
  ...INITIAL_RRULE.options,
  freq: Frequency.WEEKLY,
} satisfies RRule['options'];

const defaultMonthlyRules = {
  ...INITIAL_RRULE.options,
  freq: Frequency.MONTHLY,
} satisfies RRule['options'];

const defaultYearlyRules = {
  ...INITIAL_RRULE.options,
  freq: Frequency.YEARLY,
} satisfies RRule['options'];

const everyAllWeekdayRules = {
  ...INITIAL_RRULE.options,
  freq: Frequency.WEEKLY,
  byweekday: [
    RRule.MO.weekday,
    RRule.TU.weekday,
    RRule.WE.weekday,
    RRule.TH.weekday,
    RRule.FR.weekday,
  ],
} satisfies RRule['options'];

export const RRULE_DROPDOWN_ITEMS = [
  {
    label: 'Every weekday (Mon - Fri)',
    rule: everyAllWeekdayRules,
  },
  {
    label: 'Daily',
    rule: defaultDailyRules,
  },
  {
    label: 'Weekly',
    rule: defaultWeeklyRules,
  },
  {
    label: 'Monthly',
    rule: defaultMonthlyRules,
  },
  {
    label: 'Yearly',
    rule: defaultYearlyRules,
  },
] satisfies {
  label: string;
  rule: RRule['options'];
}[];
