import { cn } from '@/shared/lib/css/cn';
import type { LayoutProps } from '@/types/Props';

export function DashboardCardLabel({ children, className }: LayoutProps) {
  return (
    <div
      className={cn(
        'flex gap-tw-1 !rounded-lg bg-neutral-100 px-tw-1.5 py-tw-1',
        className,
      )}
    >
      {children}
    </div>
  );
}
