import * as React from 'react';
import { Dayjs } from 'dayjs';
import { Badge, Icon } from 'stories';
import { cn } from '@/shared/lib/css/cn';

interface Props {
  date: Dayjs;
  iconIsBadge?: boolean;
  block?: boolean;
  background?: string;
  badgeBackgroundColor?: string;
  variant?: 'v1' | 'v2' | 'v3';
}

export const AsOfDate = ({
  date,
  iconIsBadge = false,
  badgeBackgroundColor = 'var(--light-10)',
  background = 'var(--white)',
  block = false,
  variant = 'v1',
}: Props) => {
  const styleVarianst = {
    v1: {
      title: 'line-height-[1rem] secondary-regular light-60',
      date: 'line-height-[1rem] secondary-regular light-90 font-weight-600',
    },
    v2: {
      title: 'inline-regular',
      date: 'inline-semibold dark-60',
    },
    v3: {
      title: 'inline-regular mr-xs',
      date: 'inline-semibold',
    },
  };
  const content = (
    <>
      <div className={styleVarianst[variant].title}>As of Date:</div>
      <div className={styleVarianst[variant].date}>
        {date.format('MMMM DD, YYYY')}
      </div>
    </>
  );

  return (
    <div
      style={{ background }}
      className={cn('items-center', block ? 'd-inline-flex' : 'flex')}
    >
      {iconIsBadge ? (
        <Badge
          backgroundColor={badgeBackgroundColor}
          className="mr-tw-2 h-[1.5rem]"
          classes={{ value: 'p-xs' }}
        >
          <Icon iconName="clock" className="light-60" />
        </Badge>
      ) : (
        <Icon iconName="clock" className="mr-xs" />
      )}
      {block ? <div>{content}</div> : content}
    </div>
  );
};

export default AsOfDate;
