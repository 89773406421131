/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-param-reassign */

import * as d3 from 'd3';
import LeftSvg from 'fonts/sre-icons/Icons/arrowLeft.svg';
import CheckS from 'fonts/sre-icons/Icons/checkSmall.svg';
import InfoSvg from 'fonts/sre-icons/Icons/info.svg';
import LinkSvg from 'fonts/sre-icons/Icons/link.svg';
import LockSvg from 'fonts/sre-icons/Icons/lock.svg';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styles from './TreeView.module.scss';
import type { NodeType } from './types';
import { updateTree } from './updateTree';

export type Node = NodeType;
export type Selection = d3.Selection<
  SVGTextElement,
  d3.HierarchyNode<DatumNode>,
  SVGGElement,
  unknown
>;

export interface Action {
  text: string;
  handler: (data: DatumNode) => void;
  disabled?: (data: DatumNode) => boolean;
}

// todo replace with type from ./types
export interface DatumNode {
  id: number | string;
  name: string;
  children?: DatumNode[];
  disableSelection?: boolean;
}

export interface TreeViewProps<T extends DatumNode> {
  data: T | T[];
  showMultipleRootsParent?: boolean;
  multipleRootsParentName?: string;
  selected?: T | T[] | null;
  onSelectedChange?: (d: T) => void;
  actions?: Action[];
  handleOpenMoreLinkedItems?: (node: d3.HierarchyNode<DatumNode>) => void;
  handleManageAssetClasses?: (node: d3.HierarchyNode<DatumNode>) => void;
  expandOnSelection?: boolean;
  everyNodeWithCheckbox?: boolean;
}

interface TreeParams {
  dx: number;
  dy: number;
  tree: d3.TreeLayout<unknown>;
  zoomBehaviour: d3.ZoomBehavior<Element, unknown>;
}

export interface IIcons {
  leftIcon: XMLDocument;
  checkIcon: XMLDocument;
  linkIcon: XMLDocument;
  lockIcon: XMLDocument;
  infoIcon: XMLDocument;
}

export const CONTAINER_GROUP_COMPONENT = 'Container';
export const LINKS_GROUP_COMPONENT = 'Links';
export const NODES_GROUP_COMPONENT = 'Nodes';
export const MULTIPLE_ROOTS_PARENT_ID = 'MULTIPLE_ROOTS_PARENT';

export const getComponentSelector = (component: string) =>
  `[data-component="${component}"]`;

function applyZoomBehaviour(
  initialSvg: SVGSVGElement,
  initialPosition?: {
    x: number;
    y: number;
  },
) {
  const svg = d3.select(initialSvg);
  const containerGroup = svg.select(
    getComponentSelector(CONTAINER_GROUP_COMPONENT),
  );

  const zoomBehaviours = d3
    .zoom()
    .scaleExtent([1, 2])
    .on('zoom', (e) => containerGroup.attr('transform', e.transform));

  svg.call(zoomBehaviours).on('dblclick.zoom', null);

  setTimeout(
    () =>
      zoomBehaviours.translateTo(
        svg,
        initialPosition?.x ?? 0,
        initialPosition?.y ?? 0,
      ),
    100,
  );

  return zoomBehaviours;
}

const updateSelection = (
  root: Node,
  selectedNode: Node,
  expandOnSelection?: boolean,
  everyNodeSelectable?: boolean,
) => {
  const updateParents = (node: Node) => {
    node
      .ancestors()
      .filter((n: Node) => n !== selectedNode)
      .forEach((n: Node) => {
        if (everyNodeSelectable) {
          n._selectedFromChild = !selectedNode.selected;
          return;
        }
        n.selected = !n.selected;
        if (expandOnSelection && n.selected) {
          n.children = n._children;
        }
      });
  };

  const updateChildren = (node: Node) => {
    if (everyNodeSelectable) {
      return;
    }
    node
      .descendants()
      .filter((n) => n !== selectedNode)
      .forEach((n) => {
        n.selected = false;
      });
  };
  selectedNode.selected = !selectedNode.selected;
  updateParents(selectedNode);
  updateChildren(selectedNode);
};

const getTreeRootBuilder =
  (
    data: DatumNode | DatumNode[],
    treeParams: TreeParams,
    selected: DatumNode | DatumNode[] | null,
    multipleRootsParentName = 'root',
    expandOnSelection = false,
  ) =>
  (previousRoot: Node | null) => {
    // todo simplify
    const updateNode = (d: Node) => {
      d.id = d.data.id;
      d._children = d.children;
      d.selected = false;
    };
    let root: null | DatumNode | DatumNode[];
    if (Array.isArray(data)) {
      root =
        data.length === 1
          ? data[0]
          : {
              id: MULTIPLE_ROOTS_PARENT_ID,
              name: multipleRootsParentName,
              children: data,
            };
    } else {
      root = data;
    }
    const tempRoot = d3.hierarchy<DatumNode>(root);
    const descendants = tempRoot.descendants();

    tempRoot.x0 = 0;
    tempRoot.y0 = treeParams.dy / 2;
    // compare expanded nodes with previous tree
    descendants.forEach((d) => {
      updateNode(d);
      if (previousRoot != null) {
        const prevDescendants = previousRoot.descendants();
        const prevNode = prevDescendants.find((dx) => dx.data.id === d.data.id);
        if (prevNode != null && prevNode.children == null) {
          d.children = undefined;
        }
      } else {
        if (d.depth) d.children = undefined;
      }

      const includesSelected = Array.isArray(selected)
        ? selected.find((s) => s.id === d.id)
        : d.id === selected?.id;

      if (includesSelected) {
        updateSelection(
          tempRoot,
          d,
          expandOnSelection,
          Array.isArray(selected),
        );
      }
    });

    return tempRoot;
  };

export function TreeView<T extends DatumNode>({
  actions,
  handleOpenMoreLinkedItems,
  handleManageAssetClasses,
  data,
  onSelectedChange,
  selected,
  showMultipleRootsParent,
  multipleRootsParentName,
  expandOnSelection,
  everyNodeWithCheckbox,
}: TreeViewProps<T>) {
  const [icons, setIcons] = useState<IIcons | null>(null);
  const [root, setRoot] = useState<Node | null>(null);
  const [treeParams, setTreeParams] = useState<TreeParams | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const measuredRef = useCallback<(node: SVGSVGElement | null) => void>(
    (node) => {
      if (node !== null) {
        svgRef.current = node;
        const dy = 360;
        const dx = 60;

        const tree = d3
          .tree()
          .nodeSize([dx, dy])
          .separation(() => 0.5);

        const zoomBehaviour = applyZoomBehaviour(svgRef.current, {
          x: showMultipleRootsParent ? dy : dy * 2,
          y: dx,
        });

        setTreeParams({
          dy,
          dx,
          tree,
          zoomBehaviour,
        });
      }
    },
    [],
  );

  const handleSelectedChange = (newSelected: DatumNode | null) => {
    if (Array.isArray(selected)) {
      onSelectedChange?.(selected.id === newSelected.id ? null : newSelected);
      return;
    }
    onSelectedChange?.(selected === newSelected ? null : newSelected);
  };

  useEffect(() => {
    const fetchData = async () => {
      const promises = [
        d3.xml(LeftSvg),
        d3.xml(CheckS),
        d3.xml(LinkSvg),
        d3.xml(LockSvg),
        d3.xml(InfoSvg),
      ];
      const [leftIcon, checkIcon, linkIcon, lockIcon, infoIcon] =
        await Promise.all(promises);
      setIcons({
        leftIcon,
        checkIcon,
        linkIcon,
        lockIcon,
        infoIcon,
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (data != null && treeParams != null) {
      setRoot(
        getTreeRootBuilder(
          data,
          treeParams,
          selected,
          multipleRootsParentName,
          expandOnSelection,
        ),
      );
    }
  }, [data, selected, treeParams]);

  useLayoutEffect(() => {
    if (root != null && icons != null) {
      updateTree({
        root,
        svg: svgRef.current,
        tree: treeParams.tree,
        zoomBehaviours: treeParams.zoomBehaviour,
        source: root,
        icons,
        actions,
        handleOpenMoreLinkedItems,
        withCheckbox: onSelectedChange != null,
        onSelectedChange: handleSelectedChange,
        showMultipleRootsParent,
        handleManageAssetClasses,
        everyNodeWithCheckbox,
      });
    }
  }, [root, icons]);

  return (
    <svg className={styles.treeViewSvg} ref={measuredRef}>
      <g data-component={CONTAINER_GROUP_COMPONENT}>
        <g fill="none" strokeWidth={2} data-component={LINKS_GROUP_COMPONENT} />
        <g pointerEvents="all" data-component={NODES_GROUP_COMPONENT} />
      </g>
    </svg>
  );
}

export default TreeView;
