import { FC, useEffect, useMemo, useRef } from 'react';

import { useTableRefCallback } from '@/bundles/Settings/components/REport/GeneralLedgers/hooks/useTableRefCallback';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import useEvent from '@/shared/lib/hooks/useEvent';
import { useEventListener } from '@/shared/lib/hooks/useEventListener';
import { useModal } from '@/shared/lib/hooks/useModal';
import { mapItemsToListOption } from '@/shared/lib/listHelpers';
import { RouteComponentProps } from '@reach/router';
import { AgGridReact } from 'ag-grid-react';
import { updateLegalEntity } from 'bundles/Settings/actions/legalEntity';
import {
  REPORT_GENERAL_LEDGERS_TAG,
  reportGeneralLedgersApiEnhanced,
  useGetApiSettingsReportGeneralLedgersMetaQuery,
  useGetApiSettingsReportGeneralLedgersQuery,
  usePutApiSettingsReportGeneralLedgersExcludeAllMutation,
  usePutApiSettingsReportGeneralLedgersFlagMutation,
  usePutApiSettingsReportGeneralLedgersIncludeAllMutation,
  usePutApiSettingsReportGeneralLedgersSetCategoryMutation,
  usePutApiSettingsReportGeneralLedgersUnflagMutation,
} from 'bundles/Settings/actions/report/generalLedgersApiEnhanced';
import ExportGeneralLedgerButton from 'bundles/Settings/components/REport/GeneralLedgers/ExportGeneralLedgerButton';
import GeneralLedgerScopeCardList from 'bundles/Settings/components/REport/GeneralLedgers/GeneralLedgerScopeCardList';
import GroupByPopover from 'bundles/Settings/components/REport/GeneralLedgers/GroupByPopover';
import { SetCategoryWithGLsHeader } from 'bundles/Settings/components/REport/GeneralLedgers/SetCategoryWithGLsHeader';
import GeneralLedgersTable from 'bundles/Settings/components/REport/GeneralLedgers/Table/GeneralLedgersTable';
import GroupRowInnerRenderer from 'bundles/Settings/components/REport/GeneralLedgers/Table/GroupRowInnerRenderer';
import {
  AUTOSIZED_GENERAL_LEDGERS_COLUMN_IDS,
  GENERAL_LEDGERS_COLUMN_IDS,
  useColumnDefs,
} from 'bundles/Settings/components/REport/GeneralLedgers/Table/useColumnDefs';
import QuickMappingButton from 'bundles/Settings/components/REport/GeneralLedgers/quickMapping/QuickMappingButton';
import { setGroupedColumns } from 'bundles/Settings/components/REport/GeneralLedgers/utils';
import ReportSettingsScreenLayout from 'bundles/Settings/shared/ReportSettingsScreenLayout';
import BulkActionsPanel from 'bundles/Shared/components/BulkActionsPanel/BulkActionsPanel';
import TableSearch from 'bundles/Shared/components/Table/TableSearch';
import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';
import { FinancialCategory } from '@/entities/finanicalCategory/model';
import { LeClassification } from 'bundles/Shared/entities/leClasssification';
import { useGetApiReportFinancialCategoriesTreeQuery } from 'bundles/Shared/shared/api/reportFinancialCategoriesApiEnhanced';
import SetFinancialCategoryModal from '@/widgets/financialCategory/set/ui/SetFinancialCategoryModal';
import useGridSelectedRows from 'lib/ag-grid/useGridSelectedRows';
import { autoSizeColumnsHandler } from 'lib/ag-grid/utils';
import { treeDFS } from 'lib/treeHelpers';
import { mustFind } from 'lib/typeHelpers';
import { partition, uniq } from 'lodash-es';
import { ListOption } from 'stories/Checkbox/CheckList';
import { AnimationLoader } from 'stories/index';
import { IGeneralLedgerOnIndex } from 'types/GeneralLedger';
import { useGLPageParams } from './hooks/useGLPageParams';
import type * as Local from './types';

export interface LegalEntityHeaderParams {
  allLEOptions: ListOption[];
  setLegalEntityIds: (legalEntityIds: Local.GLLegalEntityIds) => void;
  selectedLEOptions: ListOption[];
}

export interface LegalEntitySourceHeaderParams {
  allSources: ListOption[];
  setSource: (legalEntityIds: Local.GLLegalEntityIds) => void;
  selectedSourceOptions: ListOption[];
}

const GeneralLedgers: FC<RouteComponentProps> = () => {
  const dispatch = useAppDispatch();
  const { openModal } = useModal();
  const tableRef = useRef<AgGridReact>();
  const {
    pageParams,
    setCurrentPage,
    setGLScope,
    setLegalEntityIds,
    setSources,
    setSearchQuery,
    setSortParams,
    setGroup,
    toggleFlagged,
    toggleMisclassified,
    disableMisclassified,
  } = useGLPageParams();
  const { data: categoriesData } =
    useGetApiReportFinancialCategoriesTreeQuery();
  const categoriesTree = categoriesData?.tree ?? [];
  const { data: meta } = useGetApiSettingsReportGeneralLedgersMetaQuery();
  const {
    data: glData,
    isLoading,
    isFetching,
  } = useGetApiSettingsReportGeneralLedgersQuery(pageParams);
  const {
    selectedRows: selectedGLs,
    deselectAll: deselectAllGLs,
    handleRowCheck,
    allShownRowsChecked,
    handleAllShownRowsCheck,
    handleGroupCheck,
    resolveGroupCheck,
  } = useGridSelectedRows<IGeneralLedgerOnIndex>(tableRef, glData?.items ?? []);
  const [excludeAll] =
    usePutApiSettingsReportGeneralLedgersExcludeAllMutation();
  const [includeAll] =
    usePutApiSettingsReportGeneralLedgersIncludeAllMutation();
  const [setCategory] =
    usePutApiSettingsReportGeneralLedgersSetCategoryMutation();
  const [flag] = usePutApiSettingsReportGeneralLedgersFlagMutation();
  const [unflag] = usePutApiSettingsReportGeneralLedgersUnflagMutation();

  const { allLEOptions, selectedLEOptions } = useMemo(() => {
    const allOptions = mapItemsToListOption(meta?.legalEntities ?? [], 'name');

    const selected = pageParams.legalEntityIds
      .map((id) => allOptions.find((option) => option.value === id))
      .filter((option) => option !== undefined) as ListOption[];

    return {
      allLEOptions: allOptions,
      selectedLEOptions: selected,
    };
  }, [pageParams, setLegalEntityIds]);

  const { allSources, selectedSourceOptions } = useMemo(() => {
    const allOptions = meta?.sources ?? [];
    const selected = pageParams.sources as ListOption[];

    return {
      allSources: allOptions,
      selectedSourceOptions: selected,
    };
  }, [pageParams, meta]);

  const onSetCategory = useEvent(
    async (categoryId: number | null, glIds: number[]) => {
      await setCategory({
        body: {
          ids: glIds,
          report_financial_category_id: categoryId,
        },
      });
      deselectAllGLs();
    },
  );

  const handleBulkActions = async () => {
    const [excludedIds, includedIds] = partition(
      selectedGLs,
      (gl) => gl.excluded,
    ).map((arr) => arr.map((gl) => gl.id));
    await Promise.all([
      excludedIds.length > 0
        ? includeAll({
            body: {
              ids: excludedIds,
            },
          })
        : Promise.resolve(true),
      includedIds.length > 0
        ? excludeAll({
            body: {
              ids: includedIds,
            },
          })
        : Promise.resolve(true),
    ]);

    deselectAllGLs();
  };

  const onExcludeClick = useEvent((row: IGeneralLedgerOnIndex) => {
    const body = {
      ids: [row.id],
    };
    row.excluded
      ? includeAll({
          body,
        })
      : excludeAll({
          body,
        });
  });

  const forceUpdateRow = (rowId: string) => {
    // dirty hack to update categories cell flag (it's not updated cause of report_financial_category_id is not changed)
    setTimeout(() => {
      tableRef.current?.api.refreshCells({
        force: true,
        columns: [GENERAL_LEDGERS_COLUMN_IDS.categories],
        rowNodes: [tableRef.current.api.getRowNode(rowId)!],
      });
    }, 1000);
  };

  const onFlagClick = async (row: IGeneralLedgerOnIndex) => {
    const mutate = row.flagged ? unflag : flag;

    await mutate({
      body: {
        ids: [row.id],
      },
    });
    if (tableRef.current == null) {
      return;
    }
    forceUpdateRow(row.id.toString());
  };

  const onFlagFilterChange = useEvent(() => {
    toggleFlagged();
  });

  const onSetClassification = useEvent(
    async (classification: LeClassification, gl: IGeneralLedgerOnIndex) => {
      await updateLegalEntity({
        id: gl.legalEntity!.id,
        classification,
      });
      dispatch(
        reportGeneralLedgersApiEnhanced.util.invalidateTags([
          REPORT_GENERAL_LEDGERS_TAG,
        ]),
      );
      forceUpdateRow(gl.id.toString());
    },
  );

  const refCallBack = useTableRefCallback({
    setSortParams,
    tableRef,
  });

  useEffect(() => {
    const fetch = () => {
      if (tableRef.current && pageParams.scope !== 'active-and-mapped') {
        setGroupedColumns(
          {
            colIds: pageParams.group ?? [],
          },
          tableRef.current,
        );
      }
    };
    fetch();
  }, [pageParams.scope, pageParams.group]);

  const columnDefs = useColumnDefs({
    actions: {
      onExcludeClick,
      onSetCategory,
      onFlagClick,
      onFlagFilterChange,
      onSetClassification,
      onMisclassifiedFilterChange: toggleMisclassified,
      onDisableMisclassified: disableMisclassified,
    },
    selection: {
      selectedRows: selectedGLs,
      handleRowCheck,
      allShownRowsChecked,
      handleAllShownRowsCheck,
    },
    categoriesTree,
    generalLedgersScope: pageParams.scope!,
    generalLedgersGroup: pageParams.group!,
    generalLedgersFlagged: pageParams.flagged,
    flaggedMeta: {
      misclassified: Boolean(pageParams.misclassified),
    },
    flaggedSize: glData?.meta.flaggedSize ?? 0,
    meta: {
      misclassifiedSize: glData?.meta.misclassifiedSize ?? 0,
    },
    legalEntityHeaderParams: {
      allLEOptions,
      selectedLEOptions,
      setLegalEntityIds,
    },
    legalEntitySourceHeaderParams: {
      allSources,
      selectedSourceOptions,
      setSources,
    },
  });

  const hasSelectedGLsWithCategory = useMemo(
    () => selectedGLs.some((gl) => gl.reportFinancialCategoryId != null),
    [selectedGLs],
  );

  const hasSelectedGLsWithNoClass = useMemo(
    () => selectedGLs.some((gl) => gl.legalEntity?.classification == null),
    [selectedGLs],
  );

  const handleBulkResetCategories = () => {
    onSetCategory(
      null,
      selectedGLs.map(({ id }) => id),
    );
  };

  const handleBulkSetCategories = async () => {
    const glCategoryIds = uniq(
      selectedGLs
        .map((gl) => gl.reportFinancialCategoryId)
        .filter((id) => id !== null),
    );
    let selectedCategory: null | FinancialCategory = null;
    if (glCategoryIds.length >= 1) {
      const path = treeDFS(categoriesTree, ({ id }) => id === glCategoryIds[0]);
      selectedCategory = path?.at(-1) as FinancialCategory | null;
    }

    const res = await openModal(SetFinancialCategoryModal, {
      selectedCategory,
      categories: categoriesTree,
      canResetCategory: (
        [
          'excluded',
          'active-and-mapped',
          'inactive',
        ] as (typeof pageParams.scope)[]
      ).includes(pageParams.scope),
      noSelectedPlaceholder:
        pageParams.scope === 'active-and-not-mapped'
          ? 'Select Category'
          : undefined,
      header: <SetCategoryWithGLsHeader selectedGLs={selectedGLs} />,
    });
    if (res !== undefined) {
      onSetCategory(
        res?.id ?? null,
        selectedGLs.map(({ id }) => id),
      );
    }
  };

  useEventListener('keydown', (e) => {
    // ctrl + c
    if (e.ctrlKey && e.key === 'c') {
      handleBulkSetCategories();
    }
  });

  const handleBulkSetFlag = async () => {
    const [flaggedIds, unflaggedIds] = partition(
      selectedGLs,
      (gl) => gl.flagged,
    ).map((arr) => arr.map((gl) => gl.id));

    await Promise.all([
      flaggedIds.length > 0
        ? unflag({
            body: {
              ids: flaggedIds,
            },
          })
        : Promise.resolve(true),
      unflaggedIds.length > 0
        ? flag({
            body: {
              ids: unflaggedIds,
            },
          })
        : Promise.resolve(true),
    ]);

    deselectAllGLs();
  };

  if (meta === undefined) {
    return (
      <ReportSettingsScreenLayout.Content className="flex-grow">
        <AnimationLoader />
      </ReportSettingsScreenLayout.Content>
    );
  }

  return (
    <ReportSettingsScreenLayout.Content className="flex-grow gap-tw-6">
      <GeneralLedgerScopeCardList
        onCardClick={(scope) => {
          setGLScope(scope);
          deselectAllGLs();
        }}
        generalLedgersScope={pageParams.scope!}
      />
      <div className="flex flex-grow flex-col gap-tw-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <TablePagination
              loading={isLoading}
              currentPage={pageParams.page ?? 0}
              setCurrentPage={setCurrentPage}
              totalSize={glData?.meta?.totalSize ?? 0}
              sizePerPage={pageParams.perPage ?? 0}
            />
          </div>
          <div className="flex items-center gap-tw-4">
            <QuickMappingButton />
            <GroupByPopover
              disabled={pageParams.scope === 'active-and-mapped'}
              items={columnDefs
                .filter((c) => c.enableRowGroup)
                .map((c) => ({
                  value: c.colId!,
                  label: c.headerName!,
                }))}
              value={(pageParams.group ?? [])
                .map((c) => mustFind(columnDefs, (tc) => tc.colId === c))
                .map((c) => ({
                  value: c.colId!,
                  label: c.headerName!,
                }))}
              onChange={(options) => {
                setGroup(options.map(({ value }) => value) as Local.GLGroup);
              }}
            />
            {/* DEPRECATED: FE-2167 use SearchInput */}
            <TableSearch size="m" setSearchQuery={setSearchQuery} />
            <ExportGeneralLedgerButton />
          </div>
        </div>
        <GeneralLedgersTable
          className="ag-theme-light_small-padding flex-grow"
          ref={refCallBack}
          fetching={isFetching}
          loading={isLoading}
          columnDefs={columnDefs}
          rowData={glData?.items}
          onFirstDataRendered={(e) => {
            autoSizeColumnsHandler(e, AUTOSIZED_GENERAL_LEDGERS_COLUMN_IDS);
          }}
          onRowClicked={(e) => {
            const event = e.event as MouseEvent;
            if (!event.shiftKey) {
              return;
            }
            handleRowCheck(e.data, event.shiftKey);
          }}
          groupRowRendererParams={{
            suppressCount: true,
            innerRenderer: GroupRowInnerRenderer,
            handleGroupCheck,
            resolveGroupCheck,
          }}
        />
      </div>
      {selectedGLs.length > 0 && (
        <BulkActionsPanel
          selectedRows={selectedGLs}
          setSelectedRows={() => deselectAllGLs()}
          actions={[
            {
              title: pageParams.scope === 'excluded' ? 'Include' : 'Exclude',
              handleClick: handleBulkActions,
              icon: pageParams.scope === 'excluded' ? 'eye' : 'eyeSlash',
              hidden: pageParams.scope === 'inactive',
            },
            {
              title: 'Set Category',
              icon: 'edit',
              handleClick: handleBulkSetCategories,
              tooltip: 'Ctrl + C',
              hidden: hasSelectedGLsWithNoClass,
            },
            {
              title: 'Reset Category',
              icon: 'trash',
              handleClick: handleBulkResetCategories,
              hidden:
                pageParams.scope === 'active-and-not-mapped' ||
                !hasSelectedGLsWithCategory,
            },
            {
              title: 'Set flag',
              icon: 'flag',
              handleClick: handleBulkSetFlag,
            },
          ]}
        />
      )}
    </ReportSettingsScreenLayout.Content>
  );
};

export default GeneralLedgers;
