import { settingsEmailsCustomTemplateGeneratedApi } from '@/entities/emails/customTemplates/api/settingsEmailsCustomTemplateGeneratedApi';
import { getEmailCustomTemplateUpdateBody } from '@/entities/emails/customTemplates/lib/utils';
import type {
  EmailCustomTemplate,
  EmailCustomTemplateMutationArgs,
  EmailCustomTemplateMutationResponse,
  EmailCustomTemplateVariables,
} from '@/entities/emails/customTemplates/model';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';

const etc = new EntityToastrCreator('Email');

export const settingsEmailsCustomTemplateEnhancedApi =
  settingsEmailsCustomTemplateGeneratedApi
    .injectEndpoints({
      endpoints: (build) => ({
        getAllEmailCustomTemplate: build.query<EmailCustomTemplate[], void>({
          query: () => ({
            url: `/api/custom_templates/`,
          }),
        }),

        getEmailCustomTemplate: build.query<
          EmailCustomTemplate,
          { templateId: EmailCustomTemplate['id'] }
        >({
          query: (params) => ({
            url: `/api/custom_templates/${params.templateId}`,
          }),
        }),

        getEmailCustomTemplateVariables: build.query<
          EmailCustomTemplateVariables,
          void
        >({
          query: () => ({
            url: `/api/custom_template_variables`,
          }),
        }),

        postEmailCustomTemplatePreview: build.query<
          { body: string },
          EmailCustomTemplateMutationArgs
        >({
          query: (params) => ({
            url: `/api/custom_template_previews`,
            method: 'POST',
            body: getEmailCustomTemplateUpdateBody(params),
          }),
        }),

        deleteEmailCustomTemplate: build.mutation<
          void,
          { templateId: EmailCustomTemplate['id'] }
        >({
          query: (params) => ({
            url: `/api/custom_templates/${params.templateId}`,
            method: 'DELETE',
          }),
          transformResponse: (response) => {
            toastr.success(etc.delete().toString());
            return response;
          },
        }),

        updateEmailCustomTemplate: build.mutation<
          EmailCustomTemplateMutationResponse,
          EmailCustomTemplateMutationArgs
        >({
          query: (params) => ({
            url: `/api/custom_templates/${params.template.id}`,
            method: 'PUT',
            body: getEmailCustomTemplateUpdateBody(params),
          }),
          transformResponse: (response) => {
            toastr.success(etc.update().toString());
            return response;
          },
        }),

        createEmailCustomTemplate: build.mutation<
          EmailCustomTemplateMutationResponse,
          EmailCustomTemplateMutationArgs
        >({
          query: (params) => ({
            url: `/api/custom_templates/`,
            method: 'POST',
            body: getEmailCustomTemplateUpdateBody(params),
          }),
          transformResponse: (response) => {
            toastr.success(etc.create().toString());
            return response;
          },
        }),

        sendMeEmailCustomTemplate: build.mutation<
          EmailCustomTemplateMutationResponse,
          EmailCustomTemplateMutationArgs
        >({
          query: (params) => ({
            url: `/api/custom_template_emails/`,
            method: 'POST',
            body: getEmailCustomTemplateUpdateBody(params),
          }),
          transformResponse: (response) => {
            toastr.success(etc.send().toString());
            return response;
          },
        }),
      }),
    })
    .enhanceEndpoints({
      addTagTypes: [
        'allTemplates',
        'template',
        'templateVariables',
        'templatePreview',
        'meta',
      ],
      endpoints: {
        getAllEmailCustomTemplate: {
          providesTags: ['allTemplates'],
        },
        getEmailCustomTemplate: {
          providesTags: ['template'],
        },
        getEmailCustomTemplateVariables: {
          providesTags: ['templateVariables'],
        },

        postEmailCustomTemplatePreview: {
          providesTags: ['templatePreview'],
        },

        createEmailCustomTemplate: {
          invalidatesTags: ['allTemplates'],
        },
        deleteEmailCustomTemplate: {
          invalidatesTags: ['template', 'allTemplates'],
        },
        updateEmailCustomTemplate: {
          invalidatesTags: ['template', 'allTemplates'],
        },

        getApiEmailBuilderCustomTemplatesMeta: {
          providesTags: ['meta'],
        },
        patchApiEmailBuilderCustomTemplatesById: {
          invalidatesTags: ['template'],
          transformResponse: (r) => {
            toastr.success(etc.update().toString());
            return r;
          },
        },
        postApiEmailBuilderCustomTemplates: {
          invalidatesTags: ['template'],
          transformResponse: (r) => {
            toastr.success(etc.create().toString());
            return r;
          },
        },
      },
    });
