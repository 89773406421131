import React from 'react';
import { CssVar } from '@/shared/config/cssVar';
import { capitalize, startCase } from 'lodash-es';
import { Badge } from '@/stories';
import { DISTRIBUTION_LABEL_MAPPINGS } from '@/bundles/REturn/components/Ownership/modals/addDistributionsModal/AddDistributionsModal';

interface Props extends React.ComponentProps<typeof Badge> {
  kind: string;
}

export const ACCRUED_PREF_KIND = 'Accrued Pref.';
const COLORS = {
  [ACCRUED_PREF_KIND]: {
    backgroundColor: CssVar.violet000,
    textColor: CssVar.violet055,
  },
  default: {
    backgroundColor: CssVar.light,
    textColor: CssVar.dark60,
  },
} as const satisfies Record<
  string,
  {
    backgroundColor: CssVar;
    textColor: CssVar;
  }
>;

export function DistributionKindBadge({ kind, ...props }: Props) {
  const { backgroundColor } = COLORS[kind] || COLORS.default;
  const { textColor } = COLORS[kind] || COLORS.default;

  return (
    <Badge backgroundColor={backgroundColor} textColor={textColor} {...props}>
      <span className="secondary-regular">
        {DISTRIBUTION_LABEL_MAPPINGS[kind] || startCase(capitalize(kind))}
      </span>
    </Badge>
  );
}
