import React, { useMemo } from 'react';
import { Link, LinkProps } from '@reach/router';
import { capitalize } from 'lodash-es';
import { Icon } from 'stories/index';
import { Popover } from '@/stories';
import InvestmentObjectImage from '@/bundles/InvestmentObjects/components/investmentObjectImage/InvestmentObjectImage';
import AssetStageBadge from '@/bundles/Assets/components/AssetBadge/AssetStageBadge';
import { IAsset } from '@/types/Asset';
import { ILegalEntitiable } from '@/types/LegalEntitiable';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { IconsId } from '@/types/sre-icons';
import { cn } from '@/shared/lib/css/cn';

const ICONS_BY_OBJECT_TYPE = {
  asset: 'building',
  fund: 'funds',
} satisfies Record<ILegalEntitiable['objectType'], IconsId>;

type InvestmentObjectHoverableProps = Pick<
  ILegalEntitiable,
  'id' | 'objectType' | 'name' | 'pictureUrl' | 'aasmState' | 'slug'
>;

const PopoverObjectPreview = (props: InvestmentObjectHoverableProps) => {
  const { name, pictureUrl, aasmState } = props;

  return (
    <div className="space-y-tw-3 p-tw-3">
      <div className="font-medium">{name}</div>
      <InvestmentObjectImage pictureUrl={pictureUrl} className="border-0" />
      <div className="flex">
        {aasmState && (
          <AssetStageBadge asset={{ stage: { name: aasmState } } as IAsset} />
        )}
      </div>
    </div>
  );
};

export const InvestmentObjectHoverable = (props: {
  object: InvestmentObjectHoverableProps;
  forcedKey: string;
  link?: boolean;
}) => {
  const { object, forcedKey = null, link = true } = props;
  const { objectType, name, pictureUrl, aasmState, slug } = object;

  const objectData = useMemo(() => {
    return {
      key: forcedKey ?? `ioh-a-${object.id}`,
      icon: ICONS_BY_OBJECT_TYPE[objectType],
      href: generateUrl(ROUTES_ROOT.assets.asset.fullPath, {
        pathParams: { assetSlug: slug },
      }),
      name,
      aasmState,
      objectType: capitalize(objectType),
      pictureUrl,
    };
  }, [object, forcedKey]);

  if (!objectData) return <span className="grey-badge">Unknown Object</span>;

  const LinkComponent = ({ to, ...linkProps }: LinkProps<unknown>) =>
    // @ts-expect-error
    link ? <Link to={to} {...linkProps} /> : <div {...linkProps} />;

  return (
    <Popover
      className={cn('!p-0', link ? 'cursor-pointer' : 'cursor-default')}
      placement="top-start"
      arrowPosition="start"
      template={<PopoverObjectPreview {...object} />}
      appendTo={document.body}
      classes={{
        spanContainer: ' max-w-full',
      }}
    >
      <LinkComponent
        id={objectData.key}
        to={objectData.href}
        className={cn(
          'flex max-w-full items-center gap-tw-1 rounded-[8px] bg-neutral-050 px-tw-2 py-tw-1 text-xs leading-snug text-neutral-850 transition-colors duration-200 hover:bg-neutral-250',
          link ? 'cursor-pointer' : 'cursor-default',
        )}
      >
        <Icon iconName="asset" className="size-tw-4 text-neutral-500" />
        <span className="overflow-hidden truncate text-nowrap">
          {objectData.name}
        </span>
      </LinkComponent>
    </Popover>
  );
};
