import { WidgetProps } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { useMemo } from 'react';

export const useWidgetFlags = (
  props: Pick<
    WidgetProps,
    'isLoading' | 'isError' | 'isUninitialized' | 'mode' | 'isFetching'
  >,
) => {
  const shouldDisplayData = useMemo(() => {
    return !props.isLoading && !props.isError && !props.isUninitialized;
  }, [props.isLoading, props.isError, props.isUninitialized]);

  const isPdfMode = useMemo(() => {
    return props.mode === 'pdf';
  }, [props.mode]);

  const shouldDisplayLoader = useMemo(() => {
    return props.isFetching && !props.isUninitialized;
  }, [props.isFetching, props.isUninitialized]);

  const shouldDisplayPlaceholder = useMemo(() => {
    return props.isLoading && props.isUninitialized;
  }, [props.isLoading, props.isUninitialized]);

  const shouldDisplayError = useMemo(() => {
    return props.isError && !props.isLoading;
  }, [props.isError, props.isLoading]);

  return {
    shouldDisplayData,
    isPdfMode,
    shouldDisplayLoader,
    shouldDisplayError,
    shouldDisplayPlaceholder,
  };
};
