/* eslint-disable check-file/no-index */
import { useGetApiSettingsReportFormulasQuery } from 'bundles/Shared/shared/api/settingsReportFormulasEnhanced';
import {
  Formula,
  formulaHasInvalidReference,
  transformFormulaReferencingEntities,
} from 'bundles/Shared/entities/formula';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import {
  GetApiSettingsReportFormulasApiResponse,
  ReportFormula,
} from 'bundles/Shared/shared/api/settingsReportFormulasGeneratedApi';
import { partition } from 'lodash-es';
import { isEntityTagged } from 'bundles/Shared/entities/tag/lib';

const useSelectFormulas = () =>
  useMemo(() => {
    const emptyArray: ReportFormula[] = [];
    return createSelector(
      (res: { data: GetApiSettingsReportFormulasApiResponse | undefined }) =>
        res.data ?? emptyArray,
      (data) =>
        data.map((formula) => ({
          ...formula,
          referencedInEntities: transformFormulaReferencingEntities(formula),
        })) as unknown as Formula[],
    );
  }, []);

export const useReportFormulasQuery = (
  options?: Parameters<typeof useGetApiSettingsReportFormulasQuery>[1],
) => {
  const selectFormulas = useSelectFormulas();

  const selectUntaggedFormulas = useMemo(() => {
    return createSelector(selectFormulas, (formulas) =>
      partition(formulas, (formula) => isEntityTagged(formula)),
    );
  }, []);

  const selectFormulaWithErrors = useMemo(() => {
    return createSelector(selectFormulas, (formulas) =>
      formulas.filter((formula) => formulaHasInvalidReference(formula)),
    );
  }, []);

  return useGetApiSettingsReportFormulasQuery(undefined, {
    ...options,
    selectFromResult: ({ data, isLoading }) => ({
      isLoading,
      formulas: selectFormulas({ data }),
      tagFormulasPartition: selectUntaggedFormulas({ data }),
      formulasWithErrors: selectFormulaWithErrors({ data }),
    }),
  });
};

export const useReportFormulasQueryById = (id: Formula['id'] | null) => {
  const selectFormulas = useSelectFormulas();

  const selectFormula = useMemo(() => {
    return createSelector(
      selectFormulas,
      (_, formulaId) => formulaId,
      (formulas: Formula[], formulaId) => {
        if (!formulas || !formulaId) {
          return null;
        }
        return formulas.find((f) => f.id === formulaId)!;
      },
    );
  }, []);

  return useGetApiSettingsReportFormulasQuery(undefined, {
    selectFromResult: ({ data }) => ({
      formula: selectFormula({ data }, id),
    }),
  });
};
