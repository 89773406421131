import React, { useRef, useState } from 'react';
import {
  buildWaterfallData,
  generateXLSX,
  saveAsPDF,
} from '@/shared/lib/deprecated/constructionReport';
import { currentUserAllowedTo, TProductNames } from 'lib/permissions';
import { snapshotSettingsMissingWarning } from 'lib/dictionaries';
import { generatePath, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { LegalEntity } from '@/entities/core/legalEntity';
import WaterfallChart from 'bundles/Shared/components/Charts/WaterfallChart';
import ExportDropdownButton from './components/ExportDropdownButton';
import { GoToSettingsPageWarning } from './components/Shared/GoToSettingsPageWarning';
import ExecutiveSummarySnapshot from './components/Shared/ExecutiveSummarySnapshot';
import { requiredBudgetTypesSpecified } from './components/helpers';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import { AnimationLoader, Button } from 'stories';
import { Link } from '@reach/router';
import { useGetConstructionSnapshotQuery } from 'bundles/Construction/api/constructionSnapshot';
import LegalEntitiesFilter from 'bundles/REport/components/financials/filter/LegalEntitiesFilter';
import { useGetLegalEntityQuery } from 'bundles/REconcile/components/development/api/legalEntities';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';

interface Props {
  initialLegalEntityCode: string;
  legalEntities?: LegalEntity[];
}

const LEGAL_ENTITY_SETTINGS_PATH =
  ROUTES_ROOT.reconcile.development.legalEntity.settings.fullPath;

const Snapshot = (props: Props) => {
  const { initialLegalEntityCode, legalEntities } = props;
  const [selectedLegalEntity, setSelectedLegalEntity] = useState(
    legalEntities?.find((le) => le.code === initialLegalEntityCode),
  );
  const [widthContainerChart, setWidthContainerChart] = useState<
    number | string
  >('auto');
  const legalEntityCode = selectedLegalEntity?.code ?? initialLegalEntityCode;
  const { data: legalEntity, isLoading: isLegalEntityLoading } =
    useGetLegalEntityQuery({
      legalEntityCode,
    });
  const { data: construction, isFetching } = useGetConstructionSnapshotQuery(
    {
      legalEntityCode,
      view: 'contingency',
    },
    {
      // refetchOnMountOrArgChange: true
    },
  );
  const contingencyRef = useRef();

  const handlePdfClick = () => {
    const { report, changeOrderEvents, changeOrders, reallocations } =
      construction;

    setWidthContainerChart(1920);
    saveAsPDF({
      contingencyChart: contingencyRef.current,
      changeOrderEvents,
      changeOrders,
      reallocations,
      report,
    }).finally(() => setWidthContainerChart('auto'));
  };

  const topBar = (
    <div className="mb-tw-6 mt-tw-8 flex items-center justify-between gap-tw-4">
      <h2 className="font-bold	">Snapshot</h2>
      {legalEntities && selectedLegalEntity && (
        <LegalEntitiesFilter
          legalEntities={legalEntities}
          selectedLegalEntities={[selectedLegalEntity]}
          onSelectedChange={(newLegalEntities) => {
            const newSelectedLegalEntity = newLegalEntities.at(-1);
            if (newSelectedLegalEntity == null) return;
            setSelectedLegalEntity(newLegalEntities.at(-1));
          }}
        />
      )}
      {currentUserAllowedTo('manage', TProductNames.CHANGE_MANAGEMENT) && (
        <div className="flex gap-tw-2">
          <ExportDropdownButton
            disabled={isFetching}
            onClickExcel={() => generateXLSX(legalEntityCode)}
            onClickPDF={handlePdfClick}
          />
          <VerticalSeparator />
          <Link
            to={generatePath(LEGAL_ENTITY_SETTINGS_PATH, {
              legalEntityCode,
              tab: 'snapshot',
            })}
          >
            <Button
              variant="secondary"
              iconPosition="right"
              iconName="settings"
            >
              Settings
            </Button>
          </Link>
        </div>
      )}
    </div>
  );

  if (isFetching || isLegalEntityLoading)
    return (
      <div className="custom-container">
        {topBar}
        <div className="relative h-[300px]">
          <AnimationLoader />
        </div>
      </div>
    );

  if (legalEntity == null)
    return <NoDataOverlay title="Legal Entity Not Found" />;

  const { report } = construction;
  const { hardCostContingency, hardConstructionCost } = legalEntity.config;
  const adjustBudgetRequired = !hardConstructionCost || !hardCostContingency;
  const hasNoSnapshot =
    adjustBudgetRequired || !requiredBudgetTypesSpecified(legalEntity.config);
  const getData = (name: string) => buildWaterfallData({ type: name, report });

  return (
    <div className="custom-container">
      {topBar}
      {hasNoSnapshot && (
        <div className="card card-body">
          {snapshotSettingsMissingWarning}
          <GoToSettingsPageWarning
            link={generatePath(LEGAL_ENTITY_SETTINGS_PATH, {
              legalEntityCode: legalEntity.code,
              tab: 'legacy',
            })}
          />
        </div>
      )}
      {!hasNoSnapshot && (
        <>
          <ExecutiveSummarySnapshot report={report} />
          <div className="card bg-white p-tw-4">
            <h5>Hard Cost Contingency - Waterfall</h5>
            <div className="mt-tw-4" style={{ width: widthContainerChart }}>
              <WaterfallChart
                chartName="contingency"
                chartRef={contingencyRef}
                data={getData('contingency')}
                blockRefresh
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Snapshot;
