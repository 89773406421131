import { cn } from '@/shared/lib/css/cn';
import Tooltip from '@/stories/Tooltip/Tooltip';
import React, { ReactNode, type ComponentProps } from 'react';
import './radioButton.css';

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  classes?: {
    label?: string;
    text?: string;
  };
  children?: ReactNode | ReactNode[];
  labelProps?: React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >;
  actionsSlot?: React.ReactNode;
  tooltipProps?: ComponentProps<typeof Tooltip>;
  noInnerLabel?: boolean;
}

export const RadioButton = ({
  children,
  classes,
  labelProps,
  actionsSlot,
  tooltipProps = {
    disabled: true,
  },
  noInnerLabel = false,
  ...props
}: Props) => {
  const ButtonWrapTag = noInnerLabel ? 'div' : 'label';
  const rawRadioButton = (
    <ButtonWrapTag className={cn('sre-radio', classes?.label)} {...labelProps}>
      <input className="sre-radio__input" type="radio" {...props} />
      <div className="sre-radio__content">
        {children && (
          <span className={cn('sre-radio__text', classes?.text)}>
            {children}
          </span>
        )}
        {actionsSlot}
      </div>
    </ButtonWrapTag>
  );

  if (!tooltipProps.disabled) {
    return <Tooltip {...tooltipProps}>{rawRadioButton}</Tooltip>;
  }

  return rawRadioButton;
};

export default RadioButton;
