import { VariableValueType } from '@/entities/valueType/model';
import { IconsId } from 'types/sre-icons';

export const VALUE_TYPE_ICON = {
  currency: undefined,
  metric: undefined,
  number: undefined,
  percentage: 'percent',
  sqft: undefined,
  date: undefined,
  text: undefined,
} as const satisfies Record<VariableValueType, IconsId | undefined>;

export const VARIABLE_VALUE_TYPE_OPTIONS = [
  {
    value: 'number',
    label: 'Number',
  },
  {
    value: 'percentage',
    label: 'Percentage',
  },
  {
    value: 'currency',
    label: 'Currency',
  },
  {
    value: 'sqft',
    label: 'Sqft',
  },
  {
    value: 'metric',
    label: 'Metric',
  },
  {
    value: 'date',
    label: 'Date',
  },
  {
    value: 'text',
    label: 'Text',
  },
] as const satisfies readonly {
  value: VariableValueType;
  label: string;
}[];
