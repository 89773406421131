import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import { cn } from '@/shared/lib/css/cn';
import { AnimationLoader, Field, IconButton } from '@/stories';
import type { ClassNameProps, LayoutProps } from '@/types/Props';
import React, { ComponentProps, PropsWithChildren } from 'react';

export const BuilderUI = (props: LayoutProps) => (
  <div className={cn('grid min-h-screen', props.className)}>
    {props.children}
  </div>
);

const Settings = (props: LayoutProps) => (
  <div
    className={cn(
      'fixed z-[1000] flex max-h-screen min-h-screen w-[500px] flex-col bg-neutral-100 print:hidden',
      props.className,
    )}
  >
    {props.children}
  </div>
);

const Header = (props: LayoutProps) => (
  <div
    className={cn(
      'flex items-center gap-tw-4 px-tw-6 py-tw-4',
      props.className,
    )}
  >
    {props.children}
  </div>
);
const Footer = (props: LayoutProps) => (
  <div
    className={cn(
      'flex gap-tw-2 bg-neutral-000 px-tw-6 py-tw-4',
      props.className,
    )}
  >
    {props.children}
  </div>
);

Header.BackIconButton = (
  props: Omit<ComponentProps<typeof IconButton>, 'iconName'>,
) => (
  <IconButton
    iconName="arrowLeft"
    {...props}
    className={cn('h-[54px]', props.className)}
  />
);
Header.DeleteIconButton = (
  props: Omit<
    ComponentProps<typeof IconButton>,
    'iconName' | 'size' | 'variant'
  >,
) => <IconButton iconName="trash" size="m" variant="secondary" {...props} />;

Header.ViewIconButton = (
  props: Omit<
    ComponentProps<typeof IconButton>,
    'iconName' | 'size' | 'variant'
  >,
) => (
  <IconButton
    tooltipProps={{
      mainText:
        'Navigate to the page where the PDF snapshot is generated. (Only Symmetre Admins are allowed to see this page and button.)',
    }}
    iconName="eye"
    size="m"
    variant="secondary"
    {...props}
  />
);

Header.TitleInfo = (
  props: {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
  } & PropsWithChildren,
) => (
  <div className="flex flex-col gap-tw-1">
    {props.subtitle && (
      <p className="secondary-semibold text-neutral-550">{props.subtitle}</p>
    )}
    <p className="header6-bold text-neutral-800">{props.title}</p>
    {props.children}
  </div>
);

Header.Actions = (props: LayoutProps) => (
  <div className={cn('flex items-center gap-tw-2', props.className)}>
    {props.children}
  </div>
);

const Main = (props: LayoutProps) => (
  <div
    className={cn(
      'flex grow flex-col gap-tw-6 overflow-y-auto px-tw-6 py-tw-2',
      props.className,
    )}
  >
    {props.children}
  </div>
);

const Basic = (props: LayoutProps) => (
  <div
    className={cn(
      'flex flex-col gap-tw-4 rounded-[8px] bg-neutral-000 p-tw-2',
      props.className,
    )}
  >
    {props.children}
  </div>
);

Basic.Hr = (props: ClassNameProps) => (
  <hr className={cn('m-0 h-[1px] bg-neutral-150', props.className)} />
);

Basic.Field = ({
  label,
  children,
  className,
  required,
}: LayoutProps & {
  label?: React.ReactNode;
} & Pick<ComponentProps<typeof Field>, 'required'>) => (
  <Field
    labelText={label}
    required={required}
    className={cn('flex flex-col px-tw-1', className)}
  >
    {children}
  </Field>
);

const WidgetLayout = (props: LayoutProps) => (
  <div className={cn('flex flex-col gap-tw-4', props.className)}>
    {props.children}
  </div>
);

const WidgetGroup = (props: LayoutProps) => (
  <div
    className={cn(
      'flex flex-col rounded-[8px] bg-neutral-000',
      props.className,
    )}
  >
    {props.children}
  </div>
);

WidgetGroup.Header = (props: LayoutProps) => (
  <div
    className={cn(
      'flex items-center gap-tw-2 p-tw-4 text-neutral-550',
      props.className,
    )}
  >
    {props.children}
  </div>
);

WidgetGroup.Title = (props: LayoutProps) => (
  <p
    className={cn(
      'inline-semibold overflow-hidden text-ellipsis whitespace-nowrap text-neutral-550',
      props.className,
    )}
  >
    {props.children}
  </p>
);

WidgetGroup.Children = (props: LayoutProps) => (
  <div className={cn('flex flex-col px-tw-2', props.className)}>
    {props.children}
  </div>
);

const WidgetCard = ({ children, className, ...props }: LayoutProps) => (
  <div
    className={cn(
      'mb-tw-2 flex h-[56px] items-center gap-tw-2 rounded-[8px] border-2 border-solid border-neutral-100 bg-neutral-000 px-tw-4',
      className,
    )}
    {...props}
  >
    {children}
  </div>
);

WidgetCard.Title = (props: LayoutProps) => (
  <p
    className={cn(
      'body-semibold overflow-hidden text-ellipsis whitespace-nowrap text-neutral-800',
      props.className,
    )}
  >
    {props.children}
  </p>
);

WidgetLayout.Header = (
  props: LayoutProps & {
    title?: string;
  },
) => (
  <div className={cn('flex items-center justify-between', props.className)}>
    {props.title && (
      <p className="secondary-semibold uppercase text-neutral-550">
        {props.title}
      </p>
    )}
    {props.children}
  </div>
);

const PdfPreview = (props: LayoutProps) => (
  <div className="relative ml-[500px]">{props.children}</div>
);

PdfPreview.Loading = () => (
  <div className="relative ml-[500px]">
    <AnimationLoader className="static h-full w-full" />
  </div>
);

PdfPreview.IframeFetching = () => (
  <AnimationLoader withBg className="h-full w-full" />
);

PdfPreview.Iframe = (
  props: LayoutProps & {
    header?: React.ReactNode;
  } & Pick<React.IframeHTMLAttributes<HTMLIFrameElement>, 'src'>,
) => (
  <div className="relative ml-auto flex h-full w-[calc(100%_-_500px)] flex-col ">
    {props.header}
    <iframe
      title="pdf-preview"
      src={props.src}
      className="flex h-full w-full"
    />
    {props.children}
  </div>
);

PdfPreview.NoData = () => (
  <div className="ml-auto flex h-full w-[calc(100%_-_500px)] flex-col">
    <NoDataOverlay title="Couldn't generate PDF" />
  </div>
);

BuilderUI.Loading = () => <AnimationLoader className="static min-h-screen" />;
BuilderUI.PdfPreview = PdfPreview;

WidgetGroup.WidgetCard = WidgetCard;
WidgetLayout.WidgetGroup = WidgetGroup;
Main.Basic = Basic;
Settings.Header = Header;
Settings.Footer = Footer;
Settings.Main = Main;
Settings.WidgetLayout = WidgetLayout;
BuilderUI.Settings = Settings;
