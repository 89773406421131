/* eslint-disable check-file/no-index */

import { ColDef, ColGroupDef } from 'ag-grid-community';
import { TableVizConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  KpiTableWidgetConfigColumn,
  KpiTableWidgetDataColumn,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';

export * from './table';

export const GROUPS_DROPPABLE_ID = 'groups';

export const buildDraggableId = (
  id: string,
  type: 'group' | 'column',
): string => {
  return `${type}-${id}`;
};

export const getIdFromDraggableId = (draggableId: string): string => {
  return draggableId.split('-')[1];
};

export const findColumnSettingsByColumnKey = (
  columns: TableVizConfigColumn[],
  columnKey: number,
) => {
  return columns.find((c) => c.key === columnKey.toString());
};

export const columnToColumnSettingsByKeyMatcher = (
  column: { key: number },
  columnSettings: TableVizConfigColumn,
) => String(column.key) === String(columnSettings.key);

export const findColumnByColumnSettingsKey = <
  T extends {
    key: number;
  },
>(
  columns: T[],
  key: number | string,
): T | undefined => {
  return columns.find((c) => String(c.key) === String(key));
};

export const findColumnByColDef = <
  T extends {
    key: number;
  },
>(
  columns: T[],
  colDef: ColDef,
): T | undefined => {
  return columns.find((cx) => cx.key.toString() === colDef.colId);
};

export const getUngroupedColumns = <
  T extends KpiTableWidgetDataColumn | KpiTableWidgetConfigColumn,
>(
  columnDefs: ColGroupDef[],
  columns: T[],
): T[] => {
  const groupedColumnIds: string[] = columnDefs
    .flatMap((cg) => cg.children ?? [])
    .map((c: ColDef) => c.colId as string);
  return columns.filter((c) => !groupedColumnIds.includes(c.key.toString()));
};
