/* eslint-disable check-file/no-index */
import { PermittedStateContext } from '@/bundles/Shared/components/Permissions/EditPermissionsModal/lib/PermittedStateContext';
import { concatUndeselectableRoleOptions } from '@/bundles/Shared/components/Permissions/helpers';
import { cn } from '@/shared/lib/css/cn';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import {
  Permitted,
  UserForPermitted,
} from 'bundles/Shared/entities/permissions';
import { objectEntries } from 'lib/typeHelpers';
import { isEqual, omit, uniqBy, values } from 'lodash-es';
import pluralize from 'pluralize';
import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { AnimationLoader, Button, Modal, ThinTabGroup } from 'stories/index';
import { EDIT_PERMISSIONS_SIDE_TABS } from '../const';
import {
  EDIT_PERMISSIONS_MODAL_TAB_ITEMS,
  EMPTY_PERMISSIONS,
} from '../helpers/constants';
import { PermissionsModalBody } from './components/PermissionsModalBody';
import { PermissionsSideTab } from './components/PermissionsSideTab';
import { SideTabItem } from './components/SideTabItem';
import { EMPTY_MODAL_STATE, MODAL_PROPS } from './consts';
import { FetchSubjectablesResponse, MetaState, SideTabKey } from './types';
import { getIndirectUsersList } from './utils';

interface Props extends DialogProps<Permitted> {
  fetchSubjectables: () => Promise<FetchSubjectablesResponse>;
  objectableName?: string;
  modalProps?: Partial<Omit<ComponentProps<typeof Modal>, 'toggle'>>;
  onlyRestrictedTab?: boolean;
  ignoreUndeselectable?: boolean;
}

export function EditPermissionsModal({
  onClose,
  onSubmit,
  objectableName,
  fetchSubjectables,
  modalProps,
  onlyRestrictedTab,
  ignoreUndeselectable = false,
}: Props) {
  const [sideTab, setSideTab] = useState<SideTabKey>('directRoles');
  const [permittedState, setPermittedState] =
    useState<Permitted>(EMPTY_PERMISSIONS);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [meta, setMeta] = useState<MetaState>(EMPTY_MODAL_STATE);

  useEffect(() => {
    const asyncFetch = async () => {
      setIsLoading(true);
      const resp = await fetchSubjectables();
      const permissions = omit(resp, [
        'allUsers',
        'allTags',
        'allRoles',
        'allInvestmentEntities',
      ]);
      const userOptions = ignoreUndeselectable
        ? resp.allUsers.map((u) => omit(u, 'undeselectable'))
        : resp.allUsers;

      const roleOptions = ignoreUndeselectable
        ? resp.allRoles.map((r) => omit(r, 'undeselectable'))
        : resp.allRoles;

      const initialMeta = {
        initialState: permissions,
        userOptions,
        roleOptions,
        tagOptions: resp.allTags,
        investementEntityOptions: resp.allInvestmentEntities,
      };
      setMeta(initialMeta);

      const initialPermissions = {
        ...permissions,
        directRoles: ignoreUndeselectable
          ? permissions.directRoles
          : concatUndeselectableRoleOptions(
              resp.allRoles ?? [],
              permissions.directRoles,
            ),
      };
      setPermittedState({
        ...initialPermissions,
        indirectUsers: getIndirectUsersList(initialMeta, initialPermissions),
      });
      setIsLoading(false);
    };
    asyncFetch();
  }, []);

  const { isPublic: isPublicTab } = permittedState;

  const handleUpdate = (
    key: SideTabKey,
    newValue:
      | (typeof permittedState)[SideTabKey]
      | (typeof permittedState)[SideTabKey][number],
  ) => {
    const currentValue = permittedState[key] ?? [];

    if (typeof currentValue === 'boolean') return;

    let newValues: Values<Omit<typeof permittedState, 'isPublic'>> = [];

    if (Array.isArray(newValue)) {
      newValues = newValue;
    } else {
      // @ts-ignore
      const isChecked = currentValue.find((v) => v.id === newValue.id);

      // @ts-ignore
      newValues = isChecked
        ? // @ts-ignore
          currentValue.filter((v) => v.id !== newValue.id)
        : [...currentValue, newValue];
    }

    setPermittedState((prevState) => {
      const newState = {
        ...prevState,
        [key]: newValues,
      } satisfies Permitted;
      return {
        ...newState,
        indirectUsers: getIndirectUsersList(meta, newState),
      };
    });
  };

  const handleSubmit = () => {
    onSubmit?.(permittedState);
  };

  const isEmpty = values(
    omit(permittedState, 'isPublic', 'indirectUsers'),
  ).every((arr) => arr.length === 0);

  const isNotChanged = () => isEqual(meta.initialState, permittedState);

  const undeselectableUsers = useMemo(
    () => meta.userOptions.filter((u) => u.undeselectable),
    [meta.userOptions],
  );
  const countSideTabItems = (permittedKey: SideTabKey) => {
    if (permittedKey !== 'directUsers')
      return permittedState[permittedKey].length;

    return uniqBy(
      permittedState.directUsers
        .concat(undeselectableUsers)
        .concat(permittedState.indirectUsers!),
      'id',
    ).length;
  };

  return (
    <Modal
      toggle={onClose}
      {...MODAL_PROPS}
      actions={
        !isLoading && (
          <>
            <Button onClick={onClose} variant="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={!isPublicTab && (isEmpty || isNotChanged())}
              variant="success"
            >
              {isPublicTab ? 'Set Public Access' : 'Set Restricted Access'}
            </Button>
          </>
        )
      }
      size={isPublicTab ? '700' : 'permission-lg'}
      {...modalProps}
    >
      <PermittedStateContext.Provider
        value={{
          ...permittedState,
          indirectUsers: uniqBy(
            permittedState.indirectUsers!.concat(undeselectableUsers),
            'id',
          ) as unknown as UserForPermitted[],
        }}
      >
        {isLoading && <AnimationLoader className="static min-h-[240px]" />}
        {!isLoading && (
          <div className="mx-0">
            {!onlyRestrictedTab && (
              <div
                className={cn(
                  'flex items-center justify-center gap-tw-4 border bg-light-5 p-tw-3',
                )}
              >
                {objectableName && (
                  <span className="inline-regular text-light-90">
                    What rights do you want to set for these{' '}
                    {pluralize(objectableName, 2)}?
                  </span>
                )}
                <ThinTabGroup
                  onSelectedItemChange={(selected) =>
                    setPermittedState((prev) => ({
                      ...prev,
                      indirectUsers:
                        selected.id === 'public' ? prev.indirectUsers : [],
                      isPublic: selected.id === 'public',
                    }))
                  }
                  selectedItem={EDIT_PERMISSIONS_MODAL_TAB_ITEMS.find(
                    ({ id }) =>
                      permittedState.isPublic
                        ? id === 'public'
                        : id === 'restricted',
                  )}
                  items={EDIT_PERMISSIONS_MODAL_TAB_ITEMS}
                />
              </div>
            )}
            <div
              className={cn('flex h-[508px]', onlyRestrictedTab && 'h-[564px]')}
            >
              {!isPublicTab && (
                <section className="border-right flex">
                  <aside className="flex w-[6.125rem] flex-col">
                    <ul>
                      {objectEntries(EDIT_PERMISSIONS_SIDE_TABS).map(
                        ([localTab, localTitle]) => (
                          <SideTabItem
                            tabKey={localTab}
                            title={localTitle}
                            key={localTab}
                            counter={countSideTabItems(localTab)}
                            currentSideTab={sideTab}
                            onSideTabChange={setSideTab}
                          />
                        ),
                      )}
                    </ul>
                  </aside>
                  <PermissionsSideTab
                    currentSideTab={sideTab}
                    metaState={meta}
                    handleUpdate={handleUpdate}
                    permittedState={permittedState}
                    computedIndirectUsers={[
                      ...permittedState.indirectUsers!,
                      ...undeselectableUsers,
                    ]}
                  />
                </section>
              )}
              <div className="mnw-0 w-full">
                <PermissionsModalBody
                  handleUpdate={handleUpdate}
                  allUsers={meta.userOptions}
                />
              </div>
            </div>
          </div>
        )}
      </PermittedStateContext.Provider>
    </Modal>
  );
}
