import {
  ACCRUED_PREF_KIND,
  Contribution,
  Distribution,
} from '@/entities/return';
import { useMemo } from 'react';
import { IRowsEntity } from '../types';
import { convertCentsToDollars } from '@/shared/lib/converters';
import { sumBy, maxBy } from 'lodash-es';
import { TOpenDialog, useModal } from '@/shared/lib/hooks/useModal';
import { BreakdownModal } from '@/pages/portfolio/widgets/Dashboard/cards/BreakdownModal';
import { ICellRendererParams } from 'ag-grid-community';
import { formatDate } from '@/shared/lib/formatting/dates';

function groupDistributions(distributions: Distribution[]) {
  const groupedDistributionsMap = new Map<
    Distribution['kind'],
    { items: Distribution[]; totalAmount: number }
  >();

  const addDistribution = (kind: string, distribution: Distribution) => {
    let items = [distribution];
    let totalAmount = distribution.amount;

    if (groupedDistributionsMap.has(kind)) {
      const oldDistr = groupedDistributionsMap.get(kind)!;
      items = [...oldDistr.items, distribution];
      totalAmount = oldDistr.totalAmount + distribution.amount;
    }

    groupedDistributionsMap.set(kind, { items, totalAmount });
  };

  distributions.forEach((distr) => {
    if (distr.kind === 'split_items') {
      distr.splitItems.forEach((splitDistr) => {
        const splitDistrWithDate = {
          ...splitDistr,
          amount: convertCentsToDollars(splitDistr.amountCents),
          date: distr.date,
        } as unknown as Distribution;

        addDistribution(splitDistr.kind, splitDistrWithDate);
      });
    } else {
      addDistribution(distr.kind, distr);
    }
  });

  return Object.fromEntries(groupedDistributionsMap.entries());
}

function createTotalRow(transformedRows: IRowsEntity[]) {
  const sumByField = (field: string) => sumBy(transformedRows, field);

  return {
    isFooter: true,
    id: 'total',
    investmentObjectName: 'Total',
    capitalInvested: sumByField('capitalInvested'),
    capitalDistributed: sumByField('capitalDistributed'),
    capitalBalance: sumByField('capitalBalance'),
    groupedDistributions: {
      return_of_capital: {
        totalAmount: sumByField(
          'groupedDistributions.return_of_capital.totalAmount',
        ),
      },
      preferred: {
        totalAmount: sumByField('groupedDistributions.preferred.totalAmount'),
      },
      excess_cash: {
        totalAmount: sumByField('groupedDistributions.excess_cash.totalAmount'),
      },
    },
  };
}

const getItemsByField = (
  field: string | undefined,
  params: ICellRendererParams,
) => {
  const mappedFields = {
    capitalInvested: params?.data?.contributions,
    'groupedDistributions.return_of_capital.totalAmount':
      params.data?.groupedDistributions?.return_of_capital?.items,
    'groupedDistributions.preferred.totalAmount':
      params.data?.groupedDistributions?.preferred?.items,
    'groupedDistributions.excess_cash.totalAmount':
      params.data?.groupedDistributions?.excess_cash?.items,
    capitalDistributed: params.data?.distributions,
    accruedEndingBalanceAmount: params.data?.accruedPrefEntries,
  };

  return mappedFields[field];
};

const setOnRowClick = ({
  investmentEntityName,
  transactionType,
  items,
  openModal,
  multiplier,
}: {
  investmentEntityName: string;
  transactionType: 'contribution' | 'distribution';
  items: Contribution[];
  openModal: TOpenDialog;
  multiplier: number;
}) => {
  if (!items.length) return;

  openModal(BreakdownModal, {
    investmentEntityName,
    type: transactionType,
    items,
    multiplier,
  });
};

export function useCapitalDetailRows(initialRows: IRowsEntity[]) {
  const { openModal } = useModal();

  const rows = useMemo(() => {
    const transformedRows = initialRows.map((row) => {
      const accruedPrefEntries = [
        ...row.accruedEntries.map((entry) => ({
          ...entry,
          kind: ACCRUED_PREF_KIND,
        })),
        ...row.accruedAdjustments.map((entry) => ({
          ...entry,
          kind: 'Adjustment',
        })),
        ...(groupDistributions(row.distributions).preferred?.items ?? []),
      ];

      const asOfDate = maxBy(
        [
          ...accruedPrefEntries,
          ...(groupDistributions(row.distributions).excess_cash?.items ?? []),
          ...(groupDistributions(row.distributions).return_of_capital?.items ??
            []),
        ],
        'date',
      )?.date;

      return {
        ...row,
        asOfDate: asOfDate ? formatDate(asOfDate, 'MMM DD, YYYY') : '-',
        capitalBalance: convertCentsToDollars(row.capitalBalanceCents),
        accruedEndingBalanceAmount: convertCentsToDollars(
          row.accruedEndingBalanceAmountCents,
        ),
        accruedEntries: row.accruedEntries.map((entry) => ({
          ...entry,
          kind: ACCRUED_PREF_KIND,
        })),
        accruedPrefEntries,
        groupedDistributions: groupDistributions(row.distributions),
        onClick: (params: ICellRendererParams) => {
          const field = params?.colDef?.field;

          if (params.data == 0) return;
          if (
            field === 'accruedEndingBalanceAmount' &&
            params.data.accruedStatus !== 'live'
          ) {
            return;
          }
          const transactionType =
            field === 'capitalInvested' ? 'contribution' : 'distribution';
          const multiplier = field === 'accruedEndingBalanceAmount' ? -1 : 1;

          setOnRowClick({
            investmentEntityName: row.investmentEntityName,
            transactionType,
            items: getItemsByField(field, params),
            openModal,
            multiplier,
          });
        },
      };
    });

    const assetRows = transformedRows.filter(
      (item) => item.investmentObjectType == 'Asset',
    );
    const fundRows = transformedRows.filter(
      (item) => item.investmentObjectType == 'Fund',
    );

    const assetTotalRow = assetRows.length
      ? createTotalRow(assetRows)
      : undefined;
    const fundTotalRow = fundRows.length ? createTotalRow(fundRows) : undefined;

    return {
      assets: [...assetRows, assetTotalRow].filter(Boolean),
      funds: [...fundRows, fundTotalRow].filter(Boolean),
    };
  }, [initialRows]);

  return rows;
}
