import {
  BASE_WIDGET_CONFIG_CARD_INFO_LINE_CONFIG,
  BASE_WIDGET_CONFIG_CARD_INFO_LINE_TYPE,
} from '@/entities/report/reportBuilder/config';
import { cn } from '@/shared/lib/css/cn';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { BuilderUI } from '@/shared/ui/BuilderUI';
import { Icon, IconButton } from '@/stories';
import { PropsWithChildren } from 'react';
import AnimateHeight from 'react-animate-height';

export const BaseWidgetConfigCard = (
  props: {
    title: React.ReactNode;
    labelSlot: React.ReactNode;
    hasData?: boolean;
    isError?: boolean;
    errorMessage?: string | string[];
    classes?: {
      wrapper?: string;
      label?: string;
    };
  } & PropsWithChildren,
) => {
  return (
    <BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCard
      className={cn(
        'group h-[initial] min-h-[56px] flex-col items-start justify-between py-tw-2',
        props.classes?.wrapper,
      )}
    >
      <div
        className={cn(
          'flex w-full flex-grow items-center justify-between gap-tw-1',
          props.classes?.label,
        )}
      >
        <div className="flex gap-tw-2 overflow-hidden">
          <p className="inline-semibold text-neutral-999">{props.title}</p>
        </div>

        {props.labelSlot}
      </div>

      {!props.hasData && <BaseWidgetConfigCard.InfoLine type="no-data" />}
      {props.isError && (
        <BaseWidgetConfigCard.InfoLine
          message={props.errorMessage}
          type="error"
        />
      )}
      {props.children}
    </BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCard>
  );
};

BaseWidgetConfigCard.InfoLine = ({
  type,
  message,
}: {
  type: BASE_WIDGET_CONFIG_CARD_INFO_LINE_TYPE;
  message?: string | string[];
}) => {
  const config = BASE_WIDGET_CONFIG_CARD_INFO_LINE_CONFIG[type];

  return (
    <div className="secondary-regular flex items-center gap-tw-1 py-tw-2">
      <Icon iconName="info" className={config.iconClassName} />
      <p className=" text-neutral-850">
        {Array.isArray(message)
          ? message.join(', ')
          : message ?? config.defaultMessage}
      </p>
    </div>
  );
};

BaseWidgetConfigCard.Actions = (props: PropsWithChildren) => (
  <div className="flex items-center gap-tw-2 transition-opacity group-hover:opacity-100">
    {props.children}
  </div>
);

export const ExpandableWidgetConfigCard = ({
  children,
  ...props
}: Omit<React.ComponentProps<typeof BaseWidgetConfigCard>, 'labelSlot'> & {
  disabled?: boolean;
  classes?: {
    content?: string;
  };
}) => {
  const { value: expandedWidget, toggle } = useBoolean();

  return (
    <BaseWidgetConfigCard
      {...props}
      classes={{
        ...props.classes,
        wrapper: cn(
          'h-[initial] items-stretch py-tw-4 flex-col gap-0',
          props.classes?.wrapper,
        ),
      }}
      labelSlot={
        <BaseWidgetConfigCard.Actions>
          <IconButton
            disabled={props.disabled}
            onClick={toggle}
            iconName={expandedWidget ? 'arrowTop' : 'arrowBottom'}
          />
        </BaseWidgetConfigCard.Actions>
      }
    >
      <AnimateHeight
        className={cn(props.classes?.content)}
        duration={200}
        height={expandedWidget ? 'auto' : 0}
      >
        <div className="flex h-tw-2" />
        {children}
      </AnimateHeight>
    </BaseWidgetConfigCard>
  );
};
