import { BaseFinancialTableWidgetConfigBaseColumnDto } from '@/bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import {
  DEFAULT_FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN,
  FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA,
  transformFinancialTableWidgetAdjustmentFormToConfig,
  transformFinancialTableWidgetColumnConfigToForm,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/config/column.form';
import { TableVizConfigColumn } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import * as yup from 'yup';

export const FINANCIAL_TABLE_SINGLE_PERIOD_WIDGET_CONFIG_COLUMN_SCHEMA =
  FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA.shape({});

export type FinancialTableSinglePeriodWidgetConfigColumnForm = yup.InferType<
  typeof FINANCIAL_TABLE_SINGLE_PERIOD_WIDGET_CONFIG_COLUMN_SCHEMA
>;

export const transformFinancialTableSinglePeriodWidgetColumnConfigToForm = (
  columnConfig: BaseFinancialTableWidgetConfigBaseColumnDto,
  columnSettings?: TableVizConfigColumn,
): Pick<
  FinancialTableSinglePeriodWidgetConfigColumnForm,
  'adjustment_expression' | 'adjustment_type' | 'key' | 'period_shift'
> => ({
  ...transformFinancialTableWidgetColumnConfigToForm(
    columnConfig,
    columnSettings,
  ),
});
export const transformFinancialTableSinglePeriodWidgetAdjustmentFormToConfig = (
  fields: Pick<
    FinancialTableSinglePeriodWidgetConfigColumnForm,
    'adjustment_expression' | 'adjustment_type'
  >,
): Pick<
  BaseFinancialTableWidgetConfigBaseColumnDto,
  'adjustment_average_per' | 'adjustment_expression_name'
> => ({
  ...transformFinancialTableWidgetAdjustmentFormToConfig(fields),
});

export const DEFAULT_FINANCIAL_TABLE_SINGLE_PERIOD_WIDGET_CONFIG_COLUMN =
  DEFAULT_FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN;
