import { Monaco } from '@monaco-editor/react';
import { EntityTag } from 'bundles/Shared/entities/tag';
import { IThinTabItem } from 'stories/Tabs/ThinTabGroup/ThinTabGroup';

export const FORMULA_LANGUAGE_ID = 'formula';
export const FORMULA_LANGUAGE_THEME = `${FORMULA_LANGUAGE_ID}-theme`;
export const FORMULA_KEYWORDS = [
  'SUM',
  'MIN',
  'MAX',
  'AVG',
  'COUNT',
  'ROUND',
  'ROUNDDOWN',
  'ROUNDUP',
  'ABS',
] as const;

const FORMULA_OPERATORS = ['+', '-', '*', '/'];

export const REFERENCE_FIELD_NOTE =
  'Only lower case letters, underscores, numbers can be used';

export const UNTAGGED_FORMULA_TAG = {
  id: 'untagged',
  name: 'Untagged',
} as const satisfies Pick<EntityTag, 'id' | 'name'>;

export type UntaggedFormulaTag = typeof UNTAGGED_FORMULA_TAG;

export const setupMonaco = (monaco: Monaco) => {
  monaco.languages.register({
    id: FORMULA_LANGUAGE_ID,
  });
  monaco.languages.setMonarchTokensProvider(FORMULA_LANGUAGE_ID, {
    keywords: FORMULA_KEYWORDS,
    operators: FORMULA_OPERATORS,
    symbols: /[=><!~?:&|+\-*\/\^%]+/,
    tokenizer: {
      root: [
        [/\w+\.\w+/, 'variable'],
        [
          /@?[a-zA-Z_][\w$]*/,
          {
            cases: {
              '@keywords': 'keyword',
              '@default': 'identifier',
            },
          },
        ],
        [
          /@symbols/,
          {
            cases: {
              '@operators': 'operator',
              '@default': '',
            },
          },
        ],
        [/\d+/, 'number'],
      ],
    },
  });

  monaco.editor.defineTheme(FORMULA_LANGUAGE_THEME, {
    base: 'vs-dark',
    inherit: true,
    rules: [{ token: 'variable', foreground: '#6DC885' }],
    colors: {},
  });
};

export const FORMULA_AND_VARIABLES_TABS = [
  {
    id: 'formulas',
    label: 'Formulas',
  },
  {
    id: 'variables',
    label: 'Variables',
  },
] as const satisfies readonly IThinTabItem[];
