import SourceTypeImage from '@/bundles/Shared/components/SourceType/SourceTypeImage';
import { LegalEntityManagementPeriod } from '@/entities/core/legalEntity/model';
import { cn } from '@/shared/lib/css/cn';
import { formatDate } from '@/shared/lib/formatting/dates';
import { ClassNameProps } from '@/types/Props';
import { useMemo } from 'react';

type ManagementPeriodItemProps = Partial<LegalEntityManagementPeriod> &
  ClassNameProps;

export const ManagementPeriodItem = (props: ManagementPeriodItemProps) => {
  const {
    propertyManagementCompanyName,
    externalIdentifier,
    dateFrom,
    dateTo,
    erpSystem,
    className,
  } = props;

  const formattedDate = useMemo(() => {
    if (!dateFrom && !dateTo) return;

    if (dateFrom && !dateTo) {
      return `${formatDate(dateFrom, 'MMM DD, YYYY')} - Present`;
    }

    if (!dateFrom && dateTo)
      return `Until ${formatDate(dateTo, 'MMM DD, YYYY')}`;

    return `${formatDate(dateFrom, 'MMM DD, YYYY')} - ${formatDate(
      dateTo,
      'MMM DD, YYYY',
    )}`;
  }, [dateFrom, dateTo]);

  return (
    <div className={cn('flex gap-tw-2 p-tw-2', className)}>
      <div className="flex-grow space-y-tw-2">
        <div className="space-y-tw-1">
          <div className="truncate text-sm leading-none text-neutral-850">
            {propertyManagementCompanyName}
          </div>

          {externalIdentifier && (
            <div className="text-xs leading-tight text-neutral-550">
              {externalIdentifier}
            </div>
          )}
        </div>
        {formattedDate && (
          <div className="text-xs leading-tight text-neutral-850">
            {formattedDate}
          </div>
        )}
      </div>
      {erpSystem && (
        <SourceTypeImage
          sourceType={erpSystem}
          className="size-tw-4 shrink-0"
        />
      )}
    </div>
  );
};
