import rootReducer from '@/app/reducers';
import { emptySplitApi } from '@/app/stores/api';
import { rtkQueryErrorLogger } from '@/app/stores/errorHandling';
import { listenerMiddleware } from '@/app/stores/listener';
import { settingsCoreLegalEntitiesEnhancedApi } from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesEnhancedApi';
import { impersonationApi } from '@/entities/impersonation/api';
import { capitalGeneralLedgersApi } from '@/entities/return/api/capitalGeneralLedgersApi';
import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import { objectFoldersApi } from 'bundles/Assets/components/Media/api/objectFoldersApi';
import { coContractFileApi } from 'bundles/Construction/api/coContractFile';
import { reconcileCompanies } from 'bundles/Construction/api/companies';
import { reconcileCoreApi } from 'bundles/Construction/api/core';
import { reconcileInvoiceApi } from 'bundles/Construction/api/invoices';
import { reconcileLegalEntityProjectSummaryApi } from 'bundles/Construction/api/projectSummary';
import { feedMessagesApi } from 'bundles/InvestmentObjects/components/Overview/Posts/api/feedMessagesApi';
import { reconcileChangeOrderApproversApi } from 'bundles/REconcile/components/development/api/changeOrderApprovers';
import { reconcileDevelopmentLegalEntitiesApi } from 'bundles/REconcile/components/development/api/legalEntities';
import { reconcileLineItemApi } from 'bundles/REconcile/components/development/api/lineItems';
import { reconcileDevelopmentProjectsApi } from 'bundles/REconcile/components/development/api/projects';
import { reconcileSignatureFieldsApi } from 'bundles/REconcile/components/development/api/signatureFields';
import { reconcileDevelopmentSnapshotSettingsApi } from 'bundles/REconcile/components/development/api/snapthotSettings';
import { reconcileRequisitionTransactionsApi } from 'bundles/REconcile/components/development/api/transactions';
import { reconcileFundingSourcesApi } from 'bundles/REconcile/components/development/home/legalEntitySettingsModal/fundingSources/api/reconcileFundingSourcesApi';
import { reconcileOperationalBudgetsApi } from 'bundles/REconcile/components/operational/api/legalEntities';
import { reportFinancialsApi } from 'bundles/REport/api/financialsApi';
import { coreAssetsApi } from 'bundles/Settings/components/Portal/AssetDashboard/api/coreAssetsApi';
import { coreFundsApi } from 'bundles/Settings/components/Portal/FundDashboard/api/coreFundsApi';
import { investmentEntitiesApi } from 'bundles/Settings/components/Portal/InvestmentEntities/api/investmentEntitiesApi';
import { settingsCoreLegalEntityActivityLogsApi } from 'bundles/Settings/components/Portal/LegalEntities/api/settingsCoreLegalEntityActivityLogsApi';
import { settingsCoreTagsApi } from 'bundles/UserManagement/api/settingsCoreTagsApi';
import { settingsCoreUsersApi } from 'bundles/UserManagement/api/settingsCoreUsersApi';

const settingsMiddlewares = [
  settingsCoreTagsApi.middleware,
  settingsCoreUsersApi.middleware,
  settingsCoreLegalEntityActivityLogsApi.middleware,
  settingsCoreLegalEntitiesEnhancedApi.middleware,
];

const coreMiddlewares = [
  feedMessagesApi.middleware,
  coreFundsApi.middleware,
  coreAssetsApi.middleware,
  objectFoldersApi.middleware,
  impersonationApi.middleware,
];

export const apiMiddlewares = [
  emptySplitApi.middleware,
  reconcileCoreApi.middleware,
  reconcileInvoiceApi.middleware,
  reconcileLineItemApi.middleware,
  reconcileRequisitionTransactionsApi.middleware,
  reconcileDevelopmentProjectsApi.middleware,
  reconcileChangeOrderApproversApi.middleware,
  reconcileSignatureFieldsApi.middleware,
  coContractFileApi.middleware,
  reconcileDevelopmentSnapshotSettingsApi.middleware,
  reconcileDevelopmentLegalEntitiesApi.middleware,
  reconcileLegalEntityProjectSummaryApi.middleware,
  reconcileFundingSourcesApi.middleware,
  reconcileCompanies.middleware,
  reconcileOperationalBudgetsApi.middleware,
  reportFinancialsApi.middleware,
  investmentEntitiesApi.middleware,
  capitalGeneralLedgersApi.middleware,
  ...settingsMiddlewares,
  ...coreMiddlewares,
];

export const setupStore = (preloadedState?: PreloadedState<TRootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // todo figure out disable specific path with wildcard
        immutableCheck: false,
      }).concat(apiMiddlewares),
  });

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // todo figure out disable specific path with wildcard
      immutableCheck: false,
    })
      .prepend(listenerMiddleware.middleware)
      .concat([rtkQueryErrorLogger, ...apiMiddlewares]),
});

export type TRootState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;
export type TAppStore = typeof store;

export default store;
