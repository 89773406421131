import {
  DateFormat,
  DEFAULT_DATE_FORMAT,
  formatDateByDisplayOptions,
} from '@/shared/lib/formatting/dates';
import { DEFAULT_NUMBER_FALLBACK } from '@/shared/lib/formatting/number';

import { OutputFormatter } from '@/stories/ValueFormatters/OutputFormatter';
import { getMergedClasses } from '@/stories/ValueFormatters/utils';

export const DateFormatter = ({
  value,
  dateFormat = DEFAULT_DATE_FORMAT,
  fallbackValue = DEFAULT_NUMBER_FALLBACK,
  ...props
}: {
  value: Date | null;
  fallbackValue?: string;
  dateFormat?: DateFormat;
  classes?: {
    allParts?: string;
  };
}) => {
  if (value == null) return fallbackValue;

  const classes = getMergedClasses({
    classes: props.classes,
    allParts: props.classes?.allParts,
  });

  return (
    <>
      <OutputFormatter
        classes={{
          ...classes,
        }}
      >
        {formatDateByDisplayOptions(value, {
          date_format: dateFormat,
        })}
      </OutputFormatter>
    </>
  );
};
