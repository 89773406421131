import { Tumbler } from '@/stories/Tumbler/Tumbler';

import { Tooltip } from '@/stories/Tooltip/Tooltip';

import { GlobalLeaseTableWidgetState } from '@/bundles/Shared/widgets/dashboard/widgets/globalLeaseTable/widget';
import { useGetApiSettingsReportThresholdsQuery } from '@/bundles/Shared/shared/api/settingsReportThresholdsEnhancedApi';
import VerticalSeparator from '@/bundles/Shared/components/VerticalSeparator/VerticalSeparator';

export const GlobalLeaseLtoState = (props: {
  state: GlobalLeaseTableWidgetState;
  onStateChange: (state: GlobalLeaseTableWidgetState) => void;
}) => {
  const { state, onStateChange } = props;
  const { data: thresholdData, isLoading: isThresholdLoading } =
    useGetApiSettingsReportThresholdsQuery();

  const getLtoValues = (k: string) => {
    if (!state.useLtoThresholds) return '-';

    const value =
      thresholdData?.items?.find(({ kind }) => kind === k)?.globalValue ?? '-';

    return ` ${value}  mo.`;
  };

  const ltoMinTerm = getLtoValues('lto_min_term');
  const ltoMaxVariance = getLtoValues('lto_max_variance');

  if (isThresholdLoading) return null;

  return (
    <div className="mt-8 flex items-center gap-s">
      <Tooltip
        mainText={`${
          state.useLtoThresholds ? 'Disable' : 'Enable'
        } filtering by Lease Trade Out Threshold settings`}
        theme="light"
        placement="bottom-start"
        arrowPosition="start"
      >
        <Tumbler
          checked={state.useLtoThresholds}
          onChange={() => {
            onStateChange({
              ...state,
              useLtoThresholds: state.useLtoThresholds ? undefined : true,
            });
          }}
        />
      </Tooltip>
      <span className="label-regular text-neutral-500">
        MIN Lease Terms: <span className="text-neutral-650">{ltoMinTerm}</span>
      </span>
      <VerticalSeparator />
      <span className="label-regular text-neutral-500">
        MAX Term Variance:{' '}
        <span className="text-neutral-650">{ltoMaxVariance}</span>
      </span>
    </div>
  );
};
