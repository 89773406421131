export const KPI_CARD_MAX_WIDTH = 6;

export const KPI_CARD_MAX_WIDTH_VARIANTS = {
  0: '',
  1: '',
  2: 'max-w-1/2',
  3: 'max-w-1/3',
  4: 'max-w-1/4',
  5: 'max-w-1/5',
  6: 'max-w-1/6',
} as const;

export type KpiCardMaxWidthVariants = [
  keyof typeof KPI_CARD_MAX_WIDTH_VARIANTS,
][number];
