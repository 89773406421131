import { PERMITTED_SCHEMA } from '@/bundles/Shared/entities/permissions';
import type { EmailCustomTemplate } from '@/entities/emails/customTemplates/model';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';

export const EMAIL_CUSTOM_TEMPLATE_FORM_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  subject: yup.string().required(),
  schedule: yup.string().nonNullable().required(),
  permitted: PERMITTED_SCHEMA,
  sections: yup.array().of(
    yup.object().shape({
      id: yup.number(),
      headerSection: yup.object().shape({
        id: yup.number(),
        text: yup.string(),
      }),
      position: yup.number().required(),
      textSection: yup.object().shape({
        id: yup.number(),
        text: yup.string(),
      }),
      type: yup.string().oneOf(['text', 'header']).required(),
    }),
  ),
  reports: yup.array().of(
    yup.object().shape({
      id: yup.string().required(),
      _destroy: yup.boolean(),
      asset: yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(),
      }),
      reportBuilderTemplate: yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required(),
      }),
    }),
  ),
});

type EmailCustomTemplateFormValues = yup.InferType<
  typeof EMAIL_CUSTOM_TEMPLATE_FORM_SCHEMA
>;

export const useEmailCustomTemplateFormSchemaFormContext = () =>
  useFormContext<EmailCustomTemplateFormValues>();

export const getEmailCustomTemplateFormDefaultValues = (
  template?: EmailCustomTemplate,
): EmailCustomTemplateFormValues => ({
  name: template?.name ?? '',
  subject: template?.subject ?? '',
  schedule: template?.schedule,
  permitted: {
    directUsers: template?.recipients?.users ?? [],
    directRoles: template?.recipients?.roles ?? [],
    directTags: template?.recipients?.tags ?? [],
    directInvestmentEntities: template?.recipients?.investmentEntities ?? [],
  },
  sections:
    template?.sections.map((section) => ({
      id: section.id,
      headerSection: {
        id: section.headerSection?.id,
        text: section.type === 'header' ? section.headerSection?.text : '',
      },
      position: section.position,
      textSection: {
        id: section.textSection?.id,
        text: section.type === 'text' ? section.textSection?.text : '',
      },
      type: section.type,
    })) ?? [],
  reports:
    template?.reports.map((report) => ({
      id: report.id,
      asset: report.asset,
      reportBuilderTemplate: report.reportBuilderTemplate,
    })) ?? [],
});

export const useEmailCustomTemplateFormSchemaForm = (
  template?: EmailCustomTemplate,
) =>
  useForm<EmailCustomTemplateFormValues>({
    resolver: yupResolver(EMAIL_CUSTOM_TEMPLATE_FORM_SCHEMA),
    defaultValues: getEmailCustomTemplateFormDefaultValues(template),
  });
