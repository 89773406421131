import { navigate, type RouteComponentProps } from '@reach/router';
import {
  currentUserIsSreAdmin,
  currentUserIsSuperAdmin,
} from 'lib/permissions';
import React, { useMemo } from 'react';
import { ThinTabGroup } from 'stories';

interface Props {
  children: React.ReactNode;
}

export const SettingsEmailsPage = ({
  children,
}: RouteComponentProps<Props>) => {
  const tabs = useMemo(() => {
    const items = [
      {
        label: 'Templates',
        path: '/settings/emails/templates/invitations',
        id: 'templates',
      },
    ];

    if (currentUserIsSreAdmin() || currentUserIsSuperAdmin()) {
      items.push({
        label: 'Campaigns',
        path: '/settings/emails/campaigns',
        id: 'campaigns',
      });
    }

    return items;
  }, []);

  return (
    <div className="custom-container mt-l">
      <h3 className="dark-60 header3-bold mb-m">Emails</h3>
      <ThinTabGroup
        selectedItem={
          tabs.find((tab) => tab.path === window.location.pathname) || tabs[0]
        }
        onSelectedItemChange={({ path }) => navigate(path)}
        items={tabs}
      />
      {children}
    </div>
  );
};
