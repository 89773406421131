import { ColDef, ColGroupDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
  KpiTableSingleDateWidgetDto,
  KpiTableWidgetDto,
} from 'bundles/Shared/shared/api/dashboardsGeneratedApi';
import {
  formatDateRangeForWidgetColumnSubHeader,
  formatDateRangeForWidgetGroupHeaderName,
  getFakeRowData,
  useWidgetTableDefaultColDef,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  ColDefBuilder,
  ColGroupDefBuilder,
  ColumnDefsBuilder,
  ExcelStyleBuilder,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/ColumnDefsBuilder';
import { TableVizConfig } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { usePinColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/updaters';
import { WidgetTable } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetTable';
import {
  findColumnByColumnSettingsKey,
  getDataPath,
  getRowHeight,
  getRowId,
  KpiTableWidgetConfig,
  KpiTableWidgetSection,
  KpiTableWidgetState,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import {
  KpiTableSingleDateWidgetConfig,
  KpiTableSingleDateWidgetSection,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate';
import { findFirstColumnSettingsInGroup } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/updaters';
import {
  WidgetConfigProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import React, { forwardRef, useMemo } from 'react';
import { UnknownRecord } from 'type-fest/source/internal';

type Props = Pick<
  WidgetProps<
    KpiTableWidgetDto | KpiTableSingleDateWidgetDto,
    KpiTableWidgetSection | KpiTableSingleDateWidgetSection
  >,
  'data' | 'mode'
> &
  WidgetStateProps<KpiTableWidgetState> &
  WidgetConfigProps<KpiTableWidgetConfig | KpiTableSingleDateWidgetConfig> & {
    vizConfig: TableVizConfig;
    columnsConfig: UnknownRecord[];
  };

type OverridableTableProps = React.ComponentProps<typeof WidgetTable>;

export const KpiTableWidgetTable = forwardRef<
  AgGridReact,
  Props & OverridableTableProps
>(
  (
    {
      vizConfig,
      columnsConfig,
      state,
      data,
      mode,
      settings,
      onSettingsChange,
      excelStyles,
      autoGroupColumnDef,
      ...props
    },
    externalRef,
  ) => {
    const onPinColumn = usePinColumn({
      settings,
      onSettingsChange,
    });
    const defaultColDef = useWidgetTableDefaultColDef({
      mode,
      onPinColumn,
      isCompactSelected: state.selectedColumnSize === 'compact',
      deps: [state.selectedColumnSize, state.displayedGroups],
    });

    const columnDefsBuilder = useMemo(() => {
      const colDefBuilder = new ColDefBuilder<{
        label: string;
        key: number;
        dateFrom: string;
        dateTo: string;
      }>({
        mode,
        onPinColumn,
      }).withSubHeaderName((params) => {
        const columnConfig = findColumnByColumnSettingsKey(
          columnsConfig ?? [],
          Number(params.columnSettings.key),
        );
        return formatDateRangeForWidgetColumnSubHeader(
          params.column,
          columnConfig,
          params.columnSettings,
        );
      });

      const colGroupDefBuilder = new ColGroupDefBuilder({
        mode,
      }).withHeaderName(({ group: g }) => {
        const { firstColumn: firstColumnConfig, firstColumnSettingsInGroup } =
          findFirstColumnSettingsInGroup({
            groupId: g.group_id,
            columnsConfig,
            viz_config: vizConfig,
          });
        const firstColumnInGroup = findColumnByColumnSettingsKey(
          data?.columns ?? [],
          Number(firstColumnSettingsInGroup?.key),
        )!;
        return formatDateRangeForWidgetGroupHeaderName(
          firstColumnInGroup,
          firstColumnConfig,
          g,
        );
      });
      const excelStyleBuilder = new ExcelStyleBuilder({
        vizConfig,
        mode,
      }).withIndentationStyles();
      const colDefsBuilder = new ColumnDefsBuilder<{
        label: string;
        key: number;
        dateFrom: string;
        dateTo: string;
      }>({
        vizConfig,
        colDefBuilder,
        colGroupDefBuilder,
        excelStyleBuilder,
        mode,
      });
      return colDefsBuilder;
    }, [vizConfig, mode, columnsConfig, data?.columns]);

    const columnDefs = useMemo<(ColDef | ColGroupDef)[]>(() => {
      return columnDefsBuilder.build({
        columns: data?.columns ?? [],
      });
    }, [columnDefsBuilder]);

    const rowData = useMemo(() => {
      const rows = data?.data ?? [];
      const columns = data?.columns ?? [];

      if (rows.length === 0) {
        return getFakeRowData(columns);
      }
      return rows.filter((row) => row.type !== 'total');
    }, [data]);

    const minMaxValues = useMemo(() => {
      const rows = data?.data ?? [];
      const columns = data?.columns ?? [];

      if (rows.length === 0) {
        return {};
      }
      const filteredRows = rows.filter((row) => {
        const rowType = state.groupingType === 'assets' ? 'asset' : 'segment';
        return row.type !== 'total' && row.type === rowType;
      });
      return columns.reduce((acc, column) => {
        const values = filteredRows.map((row) => row[column.key]);
        const valuesWithoutZero = values.filter((v) => v !== 0);
        return {
          ...acc,
          [column.key]: {
            min: Math.min(...values),
            max: Math.max(...values),
            minWithoutZero: Math.min(...valuesWithoutZero),
            maxWithoutZero: Math.max(...valuesWithoutZero),
          },
        };
      }, {});
    }, [data, state.groupingType]);

    const total = useMemo(
      () => data?.data?.find((row) => row.type === 'total'),
      [data],
    );

    return (
      <WidgetTable
        ref={externalRef}
        autoGroupColumnDef={autoGroupColumnDef}
        context={{
          minMaxValues,
          groupingType: state.groupingType,
        }}
        mode={mode}
        rowData={rowData}
        treeData
        defaultColDef={defaultColDef}
        getRowHeight={getRowHeight}
        getDataPath={getDataPath}
        getRowId={getRowId}
        columnDefs={columnDefs}
        pinnedBottomRowData={total && [total]}
        excelStyles={columnDefsBuilder.buildExcelStyles()}
        expensivePropSuppressColumnVirtualisation
        defaultParams={{
          sizeColumnsToFit: true,
        }}
        {...props}
      />
    );
  },
);
