import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import useDebounce from '@/shared/lib/hooks/useDebounce';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { BuilderUI } from '@/shared/ui/BuilderUI';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { useNavigate, type RouteComponentProps } from '@reach/router';
import { plainFetchReplyRecipients } from 'bundles/Settings/actions/customTemplates';
import { useEffect, useState } from 'react';
import { AnimationLoader, Button } from 'stories/index';
import {
  createFeedMessageTemplate,
  feedMessageTemplatePreviews,
  getFeedMessageTemplate,
  getFeedMessageTemplateVariables,
  IFeedMessageTemplateParams,
  sendFeedMessageTemplateEmail,
  updateFeedMessageTemplate,
} from '../../../actions/feedMessageTemplates';
import FeedMessageTemplateForm from '../forms/FeedMessageTemplateForm';
import TemplatePreview from '../TemplatePreview';
import { canSaveTemplate } from '../utils/canSaveTemplate';
import { initFeedMessageTemplateVars } from '../utils/consts';
import type * as Type from './types';

interface Props {
  feedMessageTemplateId?: number;
}

const FeedMessageTemplateEdit = ({
  feedMessageTemplateId,
}: RouteComponentProps<Props>) => {
  const navigate = useNavigate();
  const [template, setTemplate] =
    useState<Type.FeedMessageTemplateState | null>(null);
  const [variables, setVariables] = useState(initFeedMessageTemplateVars);
  const [usedVariables, setUsedVariables] = useState(
    initFeedMessageTemplateVars,
  );
  const [replyRecipients, setReplyRecipients] = useState<Type.ReplyRecipient[]>(
    [],
  );
  const { navigateBack } = useNavigateBack({
    fallbackUrl: ROUTES_ROOT.settings.emails.templates.feedMessages.fullPath,
  });
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [templatePreview, setTemplatePreview] = useState();
  const [_, setIsDefault] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const debouncedTemplate = useDebounce(template, 500);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);

      const [feedMessageTemplateJson, variablesJson, replyRecipientsJson] =
        await Promise.all([
          feedMessageTemplateId
            ? getFeedMessageTemplate(feedMessageTemplateId)
            : null,
          getFeedMessageTemplateVariables(),
          plainFetchReplyRecipients(),
        ]);

      setReplyRecipients(replyRecipientsJson);

      setTemplate({
        subject: feedMessageTemplateJson?.subject,
        greeting: feedMessageTemplateJson?.rawGreeting,
        callToAction: feedMessageTemplateJson?.rawCallToAction,
        additionalInfo: feedMessageTemplateJson?.rawAdditionalInfo,
        replyRecipients: feedMessageTemplateJson?.replyRecipients,
      });
      setUsedVariables({
        additionalInfo: feedMessageTemplateJson?.additionalInfoVariables ?? [],
        callToAction: feedMessageTemplateJson?.callToActionVariables ?? [],
        greeting: feedMessageTemplateJson?.greetingVariables ?? [],
      });
      setVariables({
        greeting: variablesJson.greeting,
        callToAction: variablesJson.call_to_action,
        additionalInfo: variablesJson.additional_info,
      });
      setIsDefault(Boolean(feedMessageTemplateJson?.default));

      setIsLoading(false);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (template === null) return;
    const fetchPreview = async () => {
      setIsPreviewLoading(true);

      const previewJson = await feedMessageTemplatePreviews({
        feedMessageTemplateId,
        template,
        usedVariables,
      });

      setTemplatePreview(previewJson.body);

      setIsPreviewLoading(false);
    };
    fetchPreview();
  }, [debouncedTemplate]);

  const handleSubmit = async () => {
    if (template === null) return;
    const params: IFeedMessageTemplateParams = {
      template,
      feedMessageTemplateId,
      usedVariables,
    };
    const feedMessageTemplate = feedMessageTemplateId
      ? await updateFeedMessageTemplate(params)
      : await createFeedMessageTemplate(params);

    if (feedMessageTemplate.errors === undefined) {
      navigate(-1);
    }
  };

  const handleSendEmail = async () => {
    await sendFeedMessageTemplateEmail({
      feedMessageTemplateId,
      template,
      usedVariables,
    });
  };

  const isPreviewReady = () => {
    if (isLoading || isPreviewLoading) return false;

    if (feedMessageTemplateId) {
      return debouncedTemplate !== null;
    }

    return true;
  };

  const saveButtonEnabled = canSaveTemplate({
    isLoading,
    template,
    initialFieldsToCheck: ['callToAction', 'greeting', 'subject'],
  });

  const handleNavigateBack = () => {
    navigateBack();
  };

  return (
    <BuilderUI>
      <BuilderUI.Settings>
        <BuilderUI.Settings.Header>
          <BuilderUI.Settings.Header.BackIconButton
            disabled={isLoading}
            onClick={handleNavigateBack}
          />
          <BuilderUI.Settings.Header.TitleInfo title={'Feed Message'} />
        </BuilderUI.Settings.Header>
        <BuilderUI.Settings.Main>
          <FeedMessageTemplateForm
            template={template ?? {}}
            variables={variables}
            usedVariables={usedVariables}
            replyRecipients={replyRecipients}
            onTemplateChange={(newTemplate) => setTemplate(newTemplate)}
            onUsedVariablesChange={(newVariables) =>
              setUsedVariables(newVariables)
            }
            isLoading={isLoading}
          />
        </BuilderUI.Settings.Main>
        <BuilderUI.Settings.Footer>
          <Button onClick={() => handleNavigateBack()}>Cancel</Button>
          <GrowDiv />
          <Button
            variant="secondary"
            size="m"
            onClick={() => handleSendEmail()}
            className="inline-semibold"
          >
            Send me an email
          </Button>
          <Button
            variant="success"
            size="m"
            disabled={!saveButtonEnabled}
            onClick={() => handleSubmit()}
            className="inline-semibold"
          >
            Save
          </Button>
        </BuilderUI.Settings.Footer>
      </BuilderUI.Settings>
      <BuilderUI.PdfPreview>
        {isPreviewReady() ? (
          <TemplatePreview
            subject={debouncedTemplate?.subject ?? 'Feed Message'}
            templatePreview={templatePreview}
          />
        ) : (
          <AnimationLoader />
        )}
      </BuilderUI.PdfPreview>
    </BuilderUI>
  );
};

export default FeedMessageTemplateEdit;
