import { CssVar } from '@/shared/config/cssVar';

// Figma ref: https://www.figma.com/design/UptjrVeF4wyYbXRk5BUf6H/symmetRE-UI-Library?node-id=9351-27440
export const TAILWIND_ORDERED_COLOR_PALETTE = [
  CssVar.violet055,
  CssVar.indigo055,
  CssVar.info055,
  CssVar.success055,
  CssVar.teal040,
  CssVar.teal055,
  CssVar.danger040,
  CssVar.pink055,
  CssVar.attention040,
  CssVar.pumpkin040,
  CssVar.pumpkin055,
  CssVar.pink040,
  CssVar.danger055,
  CssVar.attention055,
  CssVar.neutral550,
  CssVar.primary055,
  CssVar.primary040,
  CssVar.neutral400,
  CssVar.success040,
  CssVar.info040,
  CssVar.indigo040,
  CssVar.violet040,
] as const satisfies CssVar[];

export const getPaletteColorByIndex = (index: number) => {
  const realIndex = index % TAILWIND_ORDERED_COLOR_PALETTE.length;

  return TAILWIND_ORDERED_COLOR_PALETTE[realIndex];
};
