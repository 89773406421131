import {
  PostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishApiResponse,
  PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
  PutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiResponse,
  coreAssetsReportBuilderReportsGeneratedApi,
} from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';

const t = new EntityToastrCreator('Report');

const createReportTag = (id: string) => ({ type: 'Report', id }) as const;

export const coreAssetsReportBuilderReportsEnhancedApi =
  coreAssetsReportBuilderReportsGeneratedApi.enhanceEndpoints({
    addTagTypes: ['ReportList', 'Report', 'ReportWidget', 'Snapshot'],
    endpoints: {
      getApiCoreAssetsByAssetIdReportBuilderReports: {
        providesTags: ['ReportList'],
      },
      postApiCoreAssetsByAssetIdReportBuilderReports: {
        invalidatesTags: ['ReportList'],
      },
      getApiCoreAssetsByAssetIdReportBuilderReportsAndId: {
        providesTags: (result) => {
          if (result?.report == null) return [];

          return [createReportTag(result.report.id)];
        },
      },
      deleteApiCoreAssetsByAssetIdReportBuilderReportsAndId: {
        invalidatesTags: ['ReportList'],
        transformResponse: (
          response: PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
        ) => {
          toastr.success(t.delete().toString());
          return response;
        },
      },
      postApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshot:
        {
          providesTags: ['Snapshot'],
        },
      putApiCoreAssetsByAssetIdReportBuilderReportsAndId: {
        transformResponse: (
          response: PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
        ) => {
          toastr.success(t.update().toString());
          return response;
        },
        invalidatesTags: (_, __, args) => {
          const reportId = args.id;

          const tags = ['ReportList', createReportTag(reportId)] as const;

          return tags;
        },
      },
      postApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublish: {
        invalidatesTags: ['ReportList', 'Report'],
        transformResponse: (
          response: PostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishApiResponse,
        ) => {
          toastr.success(t.publish().toString());
          return response;
        },
      },
      putApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshot:
        {
          transformResponse: (
            response: PutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiResponse,
          ) => {
            toastr.success(t.update().toString());
            return response;
          },
          invalidatesTags: ['Report', 'ReportWidget', 'Snapshot'],
        },
    },
  });

export const {
  useGetApiCoreAssetsByAssetIdReportBuilderReportsQuery,
  usePostApiCoreAssetsByAssetIdReportBuilderReportsMutation,
  useDeleteApiCoreAssetsByAssetIdReportBuilderReportsAndIdMutation,
  usePutApiCoreAssetsByAssetIdReportBuilderReportsAndIdMutation,
  useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdQuery,
  usePutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotMutation,
  usePostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotQuery,

  usePostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishMutation,
} = coreAssetsReportBuilderReportsEnhancedApi;
