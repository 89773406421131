import {
  EditableReportManualVariable,
  usePostApiReportManualVariablesMutation,
} from '@/entities/report/manualVariable';
import { isTagNew } from 'bundles/Shared/entities/tag';

export const createReportManualVariable = () => {
  const [create, options] = usePostApiReportManualVariablesMutation();
  return [
    (args: EditableReportManualVariable) =>
      create({
        body: {
          label: args.name,
          name: args.reference,
          period_aggregation_strategy: args.periodAggregationStrategy ?? 'last',
          value_type: args.valueType,
          description: args.description,
          tags:
            args.tags?.map((tag) => ({
              name: isTagNew(tag) ? tag.name : undefined,
              id: isTagNew(tag) ? undefined : tag.id,
            })) ?? [],
        },
      }),
    options,
  ] as const;
};
