import { Contribution } from '@/entities/return';
import FullWhiteTable from '@/bundles/Shared/components/Table/variants/FullWhiteTable';
import dayjs from 'dayjs';
import { orderBy } from 'lodash-es';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import { ModalHeaderWithSubtitle } from 'stories/index';
import {
  capitalEntryDateColumn,
  getCapitalEntryAmountColumn,
  getCapitalEntryKindColumn,
} from '@/bundles/REturn/components/Ownership/modals/linkTxModals/linkTxToManuallyAdded/hooks/tableColumns/useEntryColumns';

interface Props {
  investmentEntityName: string;
  onClose: () => void;
  items: Contribution[];
  type: 'contribution' | 'distribution';
}
export const BreakdownModal = ({
  investmentEntityName,
  onClose,
  type,
  items,
}: Props) => {
  const orderedItems = orderBy(items, ({ date }) => dayjs(date).unix(), 'desc');

  return (
    <SidePanel
      toggle={onClose}
      header={
        <ModalHeaderWithSubtitle
          title={investmentEntityName}
          subtitle="Investment Entity"
          order="subtitle-title"
        />
      }
      classes={{
        body: 'flex flex-col gap-tw-4',
      }}
    >
      <div className="w-full">
        <FullWhiteTable
          items={orderedItems}
          columns={[
            capitalEntryDateColumn,
            getCapitalEntryAmountColumn({
              hasUnits: type === 'contribution',
              headerAlign: type === 'contribution' ? 'end' : 'start',
            }),
            getCapitalEntryKindColumn(type),
          ]}
        />
      </div>
    </SidePanel>
  );
};
