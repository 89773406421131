import {
  UNGROUPED_GROUP_KEY,
  WidgetDateGranularity,
} from '@/bundles/Shared/entities/dashboard';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { shiftDateBackward } from '@/shared/lib/date';
import {
  CalendarPeriod,
  RangePeriod,
  ReckonerPeriod,
  ToDatePeriod,
  TrailingPeriod,
  TrailingPeriodAlias,
} from '@/shared/lib/reckoner/model';
import { reckonerPeriod } from '@/shared/lib/reckoner/period';
import { HeaderComponentWithSubHeader } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/HeaderComponentWithSubHeader';
import { TableVizConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { groupBy, pick } from 'lodash-es';
import { ComponentProps } from 'react';
import { UnknownRecord } from 'type-fest';
import { TimeUnit } from '@amcharts/amcharts5/.internal/core/util/Time';

export const resolveHeaderWithSubheaderComponentProps = ({
  headerName,
  subHeaderName,
  hide_title,
  hide_subtitle,
}: TableVizConfigColumn['header'] &
  Pick<
    ComponentProps<typeof HeaderComponentWithSubHeader>,
    'headerName' | 'subHeaderName'
  >): Pick<
  ComponentProps<typeof HeaderComponentWithSubHeader>,
  'headerName' | 'subHeaderName'
> => {
  return {
    headerName: hide_title ? '' : headerName,
    subHeaderName: hide_subtitle ? '' : subHeaderName,
  };
};

type RecordWithKey = { key: number | string };
type ColumnSettingRecord = { key: number | string; group_id?: string | null };
type Merged<C extends RecordWithKey, CS extends ColumnSettingRecord> = (C & {
  viz_config: CS | undefined;
})[];

export const createMapBy = <R extends UnknownRecord>(
  items: R[],
  keyFn: ((item: R) => string) | keyof R,
) => {
  const fn =
    typeof keyFn === 'function' ? keyFn : (item: R) => String(item[keyFn]);
  return new Map(items.map((c) => [fn(c), c]));
};

export const createMapByKey = <R extends RecordWithKey>(items: R[]) =>
  createMapBy(items, 'key');

export const createColumnGroupSettingsMap = <
  CGS extends { group_id: number | string },
>(
  columnGroupSettings: CGS[],
) => new Map(columnGroupSettings.map((c) => [String(c.group_id), c]));

export const mergeColumnsAndColumnSettings = <
  C extends RecordWithKey,
  CS extends ColumnSettingRecord,
>(
  columns: C[],
  columnSettingsMap: Map<string, CS>,
): Merged<C, CS> =>
  columns.map((c) => {
    return {
      ...c,
      viz_config: columnSettingsMap.get(String(c.key)),
    };
  });

export const groupColumnsAndColumnsSettingsByGroupId = <
  C extends RecordWithKey,
  CS extends ColumnSettingRecord,
>(
  merged: Merged<C, CS>,
) => groupBy(merged, (c) => c.viz_config?.group_id ?? UNGROUPED_GROUP_KEY);

const DEFAULT_TRAILING_PERIOD_COUNT = 3;
const DEFAULT_SHIFT_DURATION_BACK_FOR_FROM_DATE = 2;
const DEFAULT_SHIFT_UNIT_BACK_FOR_FROM_DATE = 'month';

export const sortByOrder = <T extends { order: number }>(a: T, b: T): number =>
  a.order - b.order;

export const getReckonerPeriodByPeriodTypeAndDate = (
  type: ReckonerPeriod['type'],
  date: DateString,
): ReckonerPeriod => {
  const period = reckonerPeriod.getRaw({
    type,
    last_date: date,
    from_date: formatToDateStringForRequest(
      shiftDateBackward(
        new Date(date),
        DEFAULT_SHIFT_DURATION_BACK_FOR_FROM_DATE,
        DEFAULT_SHIFT_UNIT_BACK_FOR_FROM_DATE,
      ),
    ),
    to_date: date,
    count: DEFAULT_TRAILING_PERIOD_COUNT,
    date,
  });
  switch (true) {
    case reckonerPeriod.isCalendarPeriod(period):
    case reckonerPeriod.isTrailingAliasPeriod(period): {
      return pick(period, 'type', 'date') as
        | CalendarPeriod
        | TrailingPeriodAlias;
    }

    case reckonerPeriod.isRangePeriod(period): {
      return pick(period, 'type', 'from_date', 'to_date') as RangePeriod;
    }

    case reckonerPeriod.isToDatePeriod(period): {
      return pick(period, 'type', 'last_date') as ToDatePeriod;
    }

    case reckonerPeriod.isTrailingPeriod(period): {
      return pick(period, 'type', 'date', 'count') as TrailingPeriod;
    }

    default: {
      return period;
    }
  }
};

export const getDefaultBaseIntervalForGranularity = (
  granularity: WidgetDateGranularity,
): { timeUnit: TimeUnit; count: number } => {
  if (granularity === 'quarter') {
    return {
      timeUnit: 'month',
      count: 3,
    };
  }
  return {
    timeUnit: granularity,
    count: 1,
  };
};
