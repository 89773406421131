import FilterButton from '@/bundles/Shared/components/Filters/buttons/filterButton/FilterButton';
import NavigationCardList from '@/bundles/Shared/components/layouts/screenWithListNavigationLayout/NavigationCardList';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import { cn } from '@/shared/lib/css/cn';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import { useVirtuosoOverlayScrollbarsScroller } from '@/shared/lib/virtuoso';
import { Button, Dropdown, DropdownItem, SearchInput } from '@/stories';
import {
  FormulaCard,
  FormulaVariable,
  getHumanReadableVariableNameSpace,
} from 'bundles/Shared/entities/formula';
import { useGetApiSettingsReportVariablesQuery } from 'bundles/Shared/shared/api/settingsReportFormulasEnhanced';
import { uniq } from 'lodash-es';
import React, { useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';

export function VariableList({
  onSelect,
  ...props
}: {
  onSelect?: (variable: FormulaVariable['reference']) => void;
  selectedNamespace?: string;
  excludeNamespaces?: string[];
  hideNamespaceFilter?: boolean;
}) {
  const { data, isLoading, isFetching, refetch } =
    useGetApiSettingsReportVariablesQuery();
  const [searchText, setSearchText] = useState('');
  const [selectedNamespace, setSelectedNamespace] = useState<string | null>(
    props.selectedNamespace ?? null,
  );
  const { virtuosoProps, rootRef } = useVirtuosoOverlayScrollbarsScroller();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const namespaces = useMemo(() => {
    if (data == null) {
      return [];
    }
    return uniq(
      data
        .filter(
          (variable) => !props.excludeNamespaces?.includes(variable.namespace),
        )
        .map((variable) => variable.namespace),
    );
  }, [data, props.excludeNamespaces]);

  const filteredVariables = useMemo(() => {
    if (data == null) {
      return [];
    }
    return data.filter((variable) => {
      const matchNamespace =
        selectedNamespace === null || selectedNamespace === variable.namespace;
      const matchSearchText = includesInLowerCase(
        [variable.label, variable.reference],
        searchText,
      );
      return matchNamespace && matchSearchText;
    });
  }, [data, searchText, selectedNamespace]);

  const renderVariableItem = (index: number) => {
    const variable = filteredVariables[index];
    return (
      <FormulaCard
        className={cn(
          'group w-full border-x-0 border-b border-t-0 border-solid border-neutral-200',
          index === filteredVariables.length - 1 && 'border-b-0',
        )}
        key={variable.reference}
        label={variable.label}
        reference={variable.reference}
        description=""
        labelInfo={
          <>
            <div className="grow" />
            {onSelect && (
              <Button
                className="invisible group-hover:!visible"
                size="xs"
                variant="secondary"
                onClick={() => onSelect(variable.reference)}
              >
                Select
              </Button>
            )}
          </>
        }
      />
    );
  };

  const NamespaceFilter = () => {
    return (
      <Dropdown
        placement="bottom-start"
        maxWidth="max-content"
        visible={isFilterOpen}
        onClickOutside={() => setIsFilterOpen(false)}
        items={namespaces.map((namespace) => (
          <DropdownItem
            active={namespace === selectedNamespace}
            key={namespace}
            onClick={() => {
              setSelectedNamespace(namespace);
              setIsFilterOpen(false);
            }}
          >
            {getHumanReadableVariableNameSpace(namespace)}
          </DropdownItem>
        ))}
      >
        <FilterButton
          className="px-tw-2"
          size="m"
          variant="secondary"
          iconName="bottom"
          iconPosition="right"
          onClick={() => setIsFilterOpen(true)}
          onClose={() => setSelectedNamespace(null)}
          filtered={selectedNamespace !== null}
          classes={{
            container: 'h-auto',
          }}
        >
          {selectedNamespace
            ? getHumanReadableVariableNameSpace(selectedNamespace)
            : 'Namespace'}
        </FilterButton>
      </Dropdown>
    );
  };

  return (
    <>
      <div className="flex gap-tw-2">
        <SearchInput
          className="grow"
          placeholder="Search By Variable Name"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Button
          onClick={refetch}
          size="xs"
          variant="secondary"
          iconName="sync"
          loading={isFetching}
          disabled={isFetching}
        >
          Refresh
        </Button>
      </div>
      {!props.hideNamespaceFilter && <NamespaceFilter />}

      {filteredVariables.length > 0 && (
        <div className="grow" ref={rootRef}>
          <Virtuoso
            {...virtuosoProps}
            totalCount={filteredVariables.length}
            itemContent={renderVariableItem}
          />
        </div>
      )}
      <NavigationCardList>
        {!isLoading && filteredVariables.length === 0 && (
          <NoDataOverlay title="No Variables Found" />
        )}
        {isLoading &&
          Array.from({ length: 5 }).map((_, i) => (
            <SkeletonBlock className="h-[50px] w-full" key={i} />
          ))}
      </NavigationCardList>
    </>
  );
}
