import { cn } from '@/shared/lib/css/cn';
import { CELL_CLASS_NAMES } from 'bundles/Shared/components/AgGrid/Table/classNames';
import {
  CellRendererClasses,
  LinkableCellRendererProps,
} from 'bundles/Shared/components/AgGrid/Table/types';
import { PropsWithChildren } from 'react';
import { CornerArrow } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CornerArrow';

const { CommonCell } = CELL_CLASS_NAMES;

/**
 * @param {{wrapper?: string, inner?: string }} classes - classes to override default styles,
 * wrapper should be used for managing background color and border color, inner only for border color of inner div
 * @default { wrapper: "cell-wrapper cell-wrapper_basic", inner: "cell-inner cell-inner_basic" }
 */

export type CellRendererStylesProps = {
  styles?: {
    wrapper?: React.CSSProperties;
    inner?: React.CSSProperties;
  };
} & LinkableCellRendererProps;

export type BasicCellRendererProps = CellRendererClasses &
  PropsWithChildren &
  CellRendererStylesProps;

export function BasicCellRenderer({
  classes,
  children,
  styles,
  linkable,
}: BasicCellRendererProps) {
  return (
    <div
      className={cn(
        CommonCell.wrapper.default,
        linkable && 'cursor-pointer',
        classes?.wrapper ?? CommonCell.wrapper.basic,
      )}
      style={styles?.wrapper}
    >
      <div
        style={styles?.inner}
        className={cn(
          CommonCell.inner.default,
          classes?.inner ?? CommonCell.inner.basic,
          linkable && 'pointer-events-none',
          'min-w-0',
        )}
      >
        {children}
        {linkable && <CornerArrow.Svg />}
      </div>
    </div>
  );
}
