import React from 'react';
import * as Sentry from '@sentry/react';
import { UnknownRecord } from 'type-fest';

export interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary<
  Props extends React.ComponentProps<typeof React.Component> = UnknownRecord,
> extends React.Component<ErrorBoundaryProps & Props, State> {
  constructor(props: ErrorBoundaryProps & Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(error);
    Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    if (this.state.hasError) return this.props.fallback;

    return this.props.children;
  }
}

export default ErrorBoundary;
