import { cn } from '@/shared/lib/css/cn';
import { CssCustomProperty } from '@/shared/lib/css/cssCustomProperty';
import { Tooltip } from '@/stories';
import { IHeaderParams } from 'ag-grid-community';
import {
  BasicHeaderComponent,
  HeaderComponentClasses,
  HeaderFilterButton,
  HeaderSortButton,
} from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderComponent';
import { useTruncatedHeaderTextFlags } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/lib';
import { isEmpty } from 'lodash-es';
import React, { CSSProperties } from 'react';

export type HeaderWithSubHeaderParams = {
  subHeaderName?: string;
  headerName?: string;
  style?: CSSProperties;
  actions?: React.ReactNode;
} & HeaderComponentClasses & {
    classes?: {
      header?: string;
      container?: string;
      subHeader?: string;
    };
  };

export const HEADER_CELL_WRAPPER_BG_COLOR_CSS_CUSTOM_PROPERTY =
  new CssCustomProperty('bgColor', 'black');

export const HeaderComponentWithSubHeader = (
  params: IHeaderParams & HeaderWithSubHeaderParams,
) => {
  const headerName = params.headerName ?? params.displayName;

  const { headerElementRef, subheaderElementRef, truncatedTextFlags } =
    useTruncatedHeaderTextFlags();

  return (
    <BasicHeaderComponent
      {...params}
      classes={{
        ...params.classes,
        inner: cn(params.classes?.inner, 'overflow-hidden'),
      }}
    >
      <div
        className={cn(
          'flex items-center gap-tw-2 overflow-hidden',
          params.classes?.container,
        )}
      >
        <Tooltip
          disabled={!truncatedTextFlags.header && !truncatedTextFlags.subheader}
          classes={{ spanContainer: 'overflow-hidden' }}
          maxWidth={600}
          theme="light"
          mainText={
            <div className="flex flex-col gap-tw-2">
              {truncatedTextFlags.header && (
                <p className="secondary-semibold">{headerName}</p>
              )}
              {truncatedTextFlags.subheader && (
                <p className="secondary-semibold">{params.subHeaderName}</p>
              )}
            </div>
          }
        >
          <div className="flex flex-col items-end overflow-hidden">
            {!isEmpty(headerName) && (
              <p
                ref={headerElementRef}
                className={cn(
                  'secondary-semibold w-full overflow-hidden text-ellipsis whitespace-nowrap text-right text-neutral-000',
                  params.classes?.header,
                )}
              >
                {headerName}
              </p>
            )}

            {!isEmpty(params.subHeaderName) && (
              <p
                ref={subheaderElementRef}
                className={cn(
                  'secondary-semibold w-full overflow-hidden text-ellipsis whitespace-nowrap text-right text-neutral-000',
                  params.classes?.subHeader,
                )}
              >
                {params.subHeaderName}
              </p>
            )}
          </div>
        </Tooltip>
        {params.enableSorting && <HeaderSortButton {...params} />}
        <HeaderFilterButton {...params} />
        {params.actions}
      </div>
    </BasicHeaderComponent>
  );
};
