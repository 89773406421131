import {
  formatWidgetDataRowHistoryDates,
  orderHistoryByDateFromDesc,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/history';
import { WidgetDataRowHistoryEntry } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { InfoList } from '@/stories/InfoList/component';
import { Popover } from '@/stories/Popover/Popover';
import React, { useMemo } from 'react';

const getItemProps = () => ({
  classes: {
    label: 'secondary-regular text-neutral-850',
    value: 'secondary-regular text-neutral-850',
  },
});

export const HistoryDataTooltip = (
  props: {
    history: WidgetDataRowHistoryEntry[];
  } & Omit<React.ComponentProps<typeof Popover>, 'template'>,
) => {
  const items = useMemo(() => {
    return orderHistoryByDateFromDesc(props.history).map((item) => ({
      label: item.value,
      value: formatWidgetDataRowHistoryDates(item),
    }));
  }, [props.history]);

  return (
    <Popover
      maxWidth="350px"
      appendToBody
      placement="bottom-start"
      template={<InfoList getItemProps={getItemProps} items={items} />}
      {...props}
    />
  );
};
