import * as yup from 'yup';
import {
  ADJUSTMENT_EXPRESSION_SCHEMA,
  FONT_STYLES,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { HistoricalReviewTableWidgetConfigDto } from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { TableVizConfigRow } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { transformRowSettingsToForm } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import {
  DEFAULT_NUMBER_VALUE_DISPLAY_OPTIONS,
  DEFAULT_TEXT_VALUE_DISPLAY_OPTIONS,
  VALUE_DISPLAY_OPTIONS_SCHEMA,
} from '@/shared/lib/formatting/displayOptions';
import { HIGHLITHING_RULES_SCHEMA } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HighlightingRulesField';
import { TEXT_DISPLAY_STRATEGIES } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/TextDisplayStrategyField';
import { HISTORICAL_TABLE_WIDGET_ROW_TYPES } from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/model';

export const HISTORICAL_TABLE_WIDGET_CONFIG_ROW_SCHEMA = yup.object().shape({
  key: yup.number(),
  label: yup.string().required(),
  expression: yup.string().required(),
  value_display_options: VALUE_DISPLAY_OPTIONS_SCHEMA.nullable().required(),
  adjustment: ADJUSTMENT_EXPRESSION_SCHEMA.nullable().optional(),
  font_weight: yup.string().oneOf(FONT_STYLES).optional().default(undefined),
  highlighting_rules: HIGHLITHING_RULES_SCHEMA.optional()
    .nullable()
    .default(undefined),
  type: yup
    .string()
    .oneOf(Object.values(HISTORICAL_TABLE_WIDGET_ROW_TYPES))
    .required(),
});

export const HISTORICAL_TABLE_WIDGET_CONFIG_TEXT_ROW_SCHEMA = yup
  .object()
  .shape({
    key: yup.number(),
    label: yup.string().required(),
    expression: yup.string().required(),
    font_weight: yup.string().oneOf(FONT_STYLES).optional().default(undefined),
    type: yup
      .string()
      .oneOf(Object.values(HISTORICAL_TABLE_WIDGET_ROW_TYPES))
      .required(),
    text_display_strategy: yup
      .string()
      .oneOf(Object.values(TEXT_DISPLAY_STRATEGIES)),
  });

export type HistoricalTableWidgetTextRowForm = yup.InferType<
  typeof HISTORICAL_TABLE_WIDGET_CONFIG_TEXT_ROW_SCHEMA
>;

export type HistoricalTableWidgetRowForm = yup.InferType<
  typeof HISTORICAL_TABLE_WIDGET_CONFIG_ROW_SCHEMA
>;

export const DEFAULT_HISTORICAL_TABLE_WIDGET_ROW_FORM = {
  type: 'numeric',
  value_display_options: DEFAULT_NUMBER_VALUE_DISPLAY_OPTIONS,
} as const satisfies Partial<HistoricalTableWidgetRowForm>;

export const DEFAULT_HISTORICAL_TABLE_WIDGET_TEXT_ROW_FORM = {
  type: 'text',
  value_display_options: DEFAULT_TEXT_VALUE_DISPLAY_OPTIONS,
  text_display_strategy: TEXT_DISPLAY_STRATEGIES.TEXT_DEFAULT,
} as const satisfies Partial<HistoricalTableWidgetTextRowForm>;

export type HistoricalReviewTableWidgetConfigRow =
  HistoricalReviewTableWidgetConfigDto['rows'][number];

export const transformRowConfigToForm = (
  rowConfig: HistoricalReviewTableWidgetConfigRow,
  rowSettings: TableVizConfigRow,
): HistoricalTableWidgetRowForm | HistoricalTableWidgetTextRowForm => {
  return {
    key: rowConfig.key,
    label: rowConfig.label,
    expression: rowConfig.expression,
    adjustment: rowConfig.adjustment_expression,
    type: rowConfig.type ?? 'numeric',
    text_display_strategy: rowSettings?.cell_renderer?.type,
    ...transformRowSettingsToForm(rowSettings),
  };
};
