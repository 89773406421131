import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from '@/stories';
import {
  DASHBOARD_FILTER_OBJECT_ICONS,
  DashboardFilterObjectTypes,
} from 'bundles/Shared/entities/dashboard';
import pluralize from 'pluralize';
import { capitalize } from 'lodash-es';

export const DashboardFilterObjectItem = ({
  disabled,
  type,
  count,
  ...props
}: {
  type: DashboardFilterObjectTypes;
  count: number;
  disabled?: boolean;
} & Pick<React.HTMLAttributes<HTMLButtonElement>, 'onClick'>) => {
  return (
    <button
      type="button"
      className={cn(
        'group flex cursor-pointer flex-col items-center justify-center gap-tw-2 !rounded-lg border-2 border-solid border-neutral-100 bg-tw-white p-tw-2 text-neutral-550',
        'hover:bg-neutral-100',
        'disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-400',
      )}
      disabled={disabled}
      {...props}
    >
      <Icon
        className="text-[24px]"
        iconName={DASHBOARD_FILTER_OBJECT_ICONS[type]}
      />
      <span className="secondary-semibold">
        {count} {pluralize(capitalize(type), count)}
      </span>
    </button>
  );
};
