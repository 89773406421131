import {
  DashboardIcon,
  ReportDashboardType,
} from 'bundles/Shared/entities/dashboard';
import { ComponentProps } from 'react';
import { ClassNameProps } from 'types/Props';
import { DashboardCardLabel } from '../card/DashboardCardLabel';

interface Props extends ClassNameProps {
  type: ReportDashboardType | `${ReportDashboardType}`;
}

const DASHBOARD_TYPE_CARD_LABEL_MAP = {
  comparison_mode: {
    iconName: 'compare',
    label: 'Comparison',
  },
  eagle_eye: {
    iconName: 'eagleEyeDashboard',
    label: 'Eagle Eye',
  },
  object_level: {
    iconName: 'objectLevelDashboard',
    label: 'Object Level',
  },
  report_builder_template: {
    iconName: 'objectLevelDashboardGreyscale',
    label: 'Report Builder Template',
  },
} as const satisfies Record<
  ReportDashboardType,
  {
    label: string;
    iconName: ComponentProps<typeof DashboardIcon>['iconName'];
  }
>;

export function DasbhoardCardTypeLabel({ type }: Props) {
  return (
    <DashboardCardLabel>
      <DashboardIcon iconName={DASHBOARD_TYPE_CARD_LABEL_MAP[type].iconName} />
      <span className="secondary-semibold text-neutral-900">
        {DASHBOARD_TYPE_CARD_LABEL_MAP[type].label}
      </span>
    </DashboardCardLabel>
  );
}
