import { cn } from '@/shared/lib/css/cn';
import React from 'react';
import { IconButton } from 'stories';
import Chip from '../chips/chip/Chip';
import styles from './FilterItem.module.scss';

interface Props
  extends Omit<React.ComponentProps<typeof Chip>, 'leftIcon'>,
    Partial<Pick<React.ComponentProps<typeof IconButton>, 'tooltipProps'>> {
  hidden?: boolean;
  onHide?: () => void;
  hideBtnDisabled?: boolean;
}

function FilterItem({
  hidden,
  onHide,
  className,
  tooltipProps,
  hideBtnDisabled = false,
  ...props
}: Props) {
  const canHide = onHide != null;
  return (
    <Chip
      className={cn(
        styles.filterItem,
        {
          [styles.filterItem_hidden]: hidden,
        },
        'shadow-z-020',
        className,
      )}
      leftIcon={
        canHide && (
          <IconButton
            variant="default"
            onClick={onHide}
            className={cn(styles.hideButton)}
            offHoverStyles
            tooltipProps={tooltipProps}
            iconName={hidden ? 'eyeSlash' : 'eye'}
            disabled={hideBtnDisabled}
          />
        )
      }
      {...props}
    />
  );
}

export default FilterItem;
