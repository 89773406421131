import { IInvestmentEntity } from 'types/IInvestmentEntity';
import { IUserTag } from 'types/IUserTag';
import { IUser, IUserRole } from 'types/User';
import * as yup from 'yup';

const USERS_SCHEMA = yup.array().of(
  yup.object().shape({
    id: yup.string().required(),
    role: yup.object().shape({
      id: yup.string().required(),
    }),
  }),
);

export const PERMITTED_SCHEMA = yup.object().shape({
  public: yup.boolean().optional(),
  directUsers: USERS_SCHEMA.optional(),
  indirectUsers: USERS_SCHEMA.optional(),
  directInvestmentEntities: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
      }),
    )
    .optional(),
  directRoles: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
      }),
    )
    .optional(),
  directTags: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
      }),
    )
    .optional(),
});

export type PermittedFormModel = yup.InferType<typeof PERMITTED_SCHEMA>;

export type UserForPermitted = Pick<
  IUser,
  'id' | 'fullName' | 'email' | 'initials' | 'investmentEntities'
> & {
  tags: Pick<IUserTag, 'id' | 'name'>[];
  role?: Pick<
    IUserRole,
    'id' | 'name' | 'group' | 'category' | 'undeselectable'
  >;
};

export interface Permitted {
  isPublic: boolean;
  directUsers: UserForPermitted[];
  directRoles: Pick<IUserRole, 'id' | 'name'>[];
  directTags: Pick<IUserTag, 'id' | 'name'>[];
  directInvestmentEntities: Pick<IInvestmentEntity, 'id' | 'name'>[];
  indirectUsers?: UserForPermitted[];
}
