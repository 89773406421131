import { HighlightedText } from '@/bundles/Settings/components/REport/CategoriesList/SearchableTree/HighlightedText';
import { cn } from '@/shared/lib/css/cn';
import styles from 'bundles/Settings/components/REport/CategoriesList/SearchableTree/styles.module.scss';
import useDebounce from '@/shared/lib/hooks/useDebounce';
import useEvent from '@/shared/lib/hooks/useEvent';
import { useIsTextTruncated } from '@/shared/lib/hooks/useIsTextTruncated';
import { transformTree } from 'lib/treeHelpers';
import { omit } from 'lodash-es';
import Tree, { TreeProps } from 'rc-tree';
import { DataNode, IconType } from 'rc-tree/lib/interface';
import React, {
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Icon, SearchInput, Tooltip } from 'stories/index';
import { Arrow } from './Arrow';
import {
  DataNodeAndExtraProps,
  RC_TREE_MOTION,
  addExtraProps,
  getNodeParentKey,
  getTreeDataList,
} from './utils';

const NODE_CLASS_NAMES =
  'flex w-full items-center w-full hover:bg-info-020 transition-colors rounded px-tw-1 py-tw-0.5 text-ellipsis';

function NodeTitle({
  onClick,
  query,
  item,
  checked,
  readonly,
  onCheck,
  flatView,
  everyNodeWithCheckbox,
  nodeLabelKey = 'title',
}: {
  onClick: () => void;
  query: string;
  item: DataNodeAndExtraProps;
  checked: boolean;
  readonly?: boolean;
  onCheck?: () => void;
  everyNodeWithCheckbox?: boolean;
  flatView?: boolean;
  nodeLabelKey?: keyof DataNode;
}) {
  const nodeLabel = item[nodeLabelKey] as string;
  const nodeSpanRef = useRef<HTMLSpanElement>(null);
  const isTextTruncated = useIsTextTruncated(nodeSpanRef, nodeLabel);

  const hasChildren = (item.children?.length ?? 0) > 0 || item.hasChildren;

  const getRadioButton = () => {
    if (item.locked) return null;

    const handleCheck: React.MouseEventHandler<HTMLDivElement> = (e) => {
      e.stopPropagation();
      onCheck?.();
    };

    if (everyNodeWithCheckbox) {
      if (checked) {
        return (
          <div
            onClick={handleCheck}
            className="ml-auto flex h-[16px] w-[17.1px] cursor-pointer items-center  justify-center rounded-full bg-info-055"
          >
            <Icon iconName="check" className=" text-sm text-white" />
          </div>
        );
      }
      return (
        <div
          onClick={handleCheck}
          className={cn(
            'sre-radio__content ml-auto w-min opacity-0 transition-opacity group-hover:opacity-100',
            checked ? 'checked opacity-100' : '',
          )}
        />
      );
    }

    if (readonly || hasChildren) return null;

    return (
      <div
        className={cn(
          'sre-radio__content ml-auto w-min opacity-0 transition-opacity group-hover:opacity-100',
          checked ? 'checked opacity-100' : '',
        )}
      />
    );
  };

  return (
    <div className="group flex w-full items-center">
      <span
        onClick={onClick}
        className={cn(
          NODE_CLASS_NAMES,
          hasChildren || flatView
            ? 'inline-semibold cursor-pointer text-neutral-900'
            : 'inline-regular text-neutral-500',
        )}
        ref={nodeSpanRef}
      >
        <HighlightedText
          {...HighlightedText.getProps({
            query,
            text: nodeLabel,
          })}
        />
        {getRadioButton()}
      </span>
      <Tooltip
        reference={nodeSpanRef}
        mainText={nodeLabel}
        disabled={!isTextTruncated}
        maxWidth="max-content"
      />
    </div>
  );
}

const Switcher: IconType = ({ expanded, isLeaf, data }) => {
  if (!data.hasChildren) return null;

  if (expanded) {
    return <Arrow expanded />;
  }
  if (isLeaf) return null;

  return <Arrow />;
};

const PATH_SEPARATOR = '_';

export const SearchableTree = ({
  data,
  selectedId,
  selectedListIds,
  onSelect,
  readonly,
  treeProps,
  itemActions,
}: {
  data: DataNode[];
  selectedId: number | undefined;
  onSelect: (id: number | undefined) => void;
  selectedListIds?: number[];
  readonly?: boolean;
  itemActions?: (item: DataNodeAndExtraProps) => ReactNode;
  treeProps?: Omit<
    TreeProps,
    'treeData' | 'onExpand' | 'expandedKeys' | 'prefixCls'
  >;
}) => {
  const [expandedKeys, setExpandedKeys] = useState<number[]>(
    data.map((item) => item.id as number),
  );
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 200);
  const trimmedQuery = debouncedQuery.trim();
  const queryHasPathSeparator = trimmedQuery.includes(PATH_SEPARATOR);
  const searchRefCallback = useCallback((ref: HTMLInputElement | null) => {
    setTimeout(() => {
      if (!ref) return;
      ref.focus();
    });
  }, []);
  const onCheck = (key: number) => {
    onSelect(selectedId === key ? undefined : key);
  };

  const onExpand = (newExpandedKeys: number[]) => {
    setExpandedKeys(newExpandedKeys);
  };

  const onExpandEach = (key: number) => {
    const newExpandedKeys = expandedKeys.includes(key)
      ? expandedKeys.filter((k) => k !== key)
      : [...expandedKeys, key];
    setExpandedKeys(newExpandedKeys);
  };

  const treeDataAndExtraProps = useMemo(() => addExtraProps(data), [data]);

  const onChange = useEvent((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const dataList = getTreeDataList(treeDataAndExtraProps);

    const newExpandedKeys = dataList
      .map((item) => {
        if (item.title?.indexOf(value) > -1) {
          return getNodeParentKey(item.key, treeDataAndExtraProps);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);

    setExpandedKeys(newExpandedKeys as number[]);
    setQuery(value);
  });

  const handleClick = (item: DataNodeAndExtraProps) => {
    if (item.children?.length) {
      onExpandEach(item.key);
      return;
    }
    if (readonly) return;
    if (Array.isArray(selectedListIds)) {
      onSelect(item);
      return;
    }
    onCheck(item.key);
  };

  const treeData = useMemo(() => {
    function filterTree(
      node: DataNode,
      searchQuery: string,
    ): DataNodeAndExtraProps | null {
      // Check if current node matches the search query
      const nodeTitle = node.title as string;
      const matches =
        nodeTitle.includes(searchQuery) || node.path.includes(searchQuery);

      // Filter the children of the current node
      const filteredChildren: unknown[] = node.children
        ? node.children
            .map((child) => filterTree(child, searchQuery))
            .filter((child) => child)
        : [];

      // If current node matches the search query, or has any matching descendants, return a new tree
      if (matches || filteredChildren.length > 0) {
        return {
          ...node,
          children: node.children,
        };
      }

      return null;
    }
    const everyNodeWithCheckbox = Array.isArray(selectedListIds);

    const loopTreeData = (
      dataWithProps: DataNodeAndExtraProps[],
      options?: {
        nodeLabelKey: keyof DataNodeAndExtraProps;
      },
    ): DataNode[] =>
      dataWithProps.map((item, index) => {
        const resolveChecked = () => {
          if (everyNodeWithCheckbox) {
            return selectedListIds?.includes(item.key) ?? false;
          }

          return selectedId === item.key;
        };

        const title = (
          <>
            <NodeTitle
              onClick={() => {
                if (queryHasPathSeparator) {
                  if (everyNodeWithCheckbox) {
                    onSelect(item);
                    return;
                  }
                  onCheck(item.key);
                  return;
                }
                handleClick(item);
              }}
              query={trimmedQuery}
              item={item}
              checked={resolveChecked()}
              readonly={readonly}
              flatView={queryHasPathSeparator}
              everyNodeWithCheckbox={everyNodeWithCheckbox}
              onCheck={() => {
                if (!everyNodeWithCheckbox) {
                  onCheck(item.key);
                  return;
                }
                onSelect(item);
              }}
              nodeLabelKey={options?.nodeLabelKey ?? 'title'}
            />
            {itemActions?.(item)}
          </>
        );

        const props = {
          index,
          title,
          key: item.key,
          hasChildren: item.hasChildren,
        };

        if (item.children && item.children.length > 0) {
          return {
            ...props,
            children: loopTreeData(item.children, options),
          };
        }

        return {
          ...props,
        };
      });

    if (queryHasPathSeparator) {
      const arr: Omit<DataNodeAndExtraProps, 'children'>[] = [];
      const root: DataNodeAndExtraProps = { children: treeDataAndExtraProps };

      transformTree(root, (node) => {
        if (node.path?.includes(trimmedQuery)) {
          if (!everyNodeWithCheckbox && (node.children?.length ?? 0) > 0)
            return;

          arr.push(
            omit(node, 'children') as Omit<DataNodeAndExtraProps, 'children'>,
          );
        }
      });

      return loopTreeData(arr, { nodeLabelKey: 'path' });
    }
    const filtered = treeDataAndExtraProps
      .map((n) => {
        return filterTree(n, trimmedQuery);
      })
      .filter((n): n is NonNullish<DataNodeAndExtraProps> => Boolean(n));

    return loopTreeData(filtered);
  }, [
    trimmedQuery,
    selectedId,
    selectedListIds,
    expandedKeys,
    treeDataAndExtraProps,
  ]);

  return (
    <aside className={cn(styles.sidebar, 'custom-tree-config')}>
      <SearchInput
        placeholder="Search"
        className={styles.searchInput}
        value={query}
        onChange={onChange}
        resetValue={() => setQuery('')}
        ref={searchRefCallback}
      />
      <div className="grid">
        <Tree
          className="min-w-0"
          showIcon={false}
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent
          motion={RC_TREE_MOTION}
          selectable={false}
          itemHeight={36}
          treeData={treeData}
          switcherIcon={Switcher}
          {...treeProps}
        />
      </div>
    </aside>
  );
};
