import type { ReportBuilderTemplateGroupWidgetSectionDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';

export const isReportBuilderTempalteWidgetEditable = (
  widgetType: ReportBuilderTemplateGroupWidgetSectionDto['widgetType'],
) => {
  switch (widgetType) {
    case 'kpi_card':
    case 'xy_chart':
    case 'historical_review_table':
    case 'delinquency_tracker_table':
    case 'financial_table_single_period':
    case 'financial_table_single_date':
    case 'unit_type_table':
    case 'global_lease_table': {
      return true;
    }
    default: {
      return false;
    }
  }
};
export const isReportBuilderTempalteWidgetIncludeSourceUnitTypesEditable = (
  widgetType: ReportBuilderTemplateGroupWidgetSectionDto['widgetType'],
) => {
  switch (widgetType) {
    case 'recent_rent_roll_table': {
      return true;
    }
    default: {
      return false;
    }
  }
};
