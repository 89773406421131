import React from 'react';
import { groupBy } from 'lodash-es';
import { LinkButton, Popover, PseudoLink } from 'stories/index';
import { resolveAccessItemIcon } from './helpers/resolveAccessItemIcon';
import { singlePermittedMemberJSX } from './helpers/singlePermittedMemberJSX';
import { resolveAccessItemLabel } from './helpers/resolveAccessItemLabel';
import { resolveLinkLabelText } from './helpers/resolveLinkLabelText';
import { IAccessListItem, TAccessItemType } from './types';
import { AccessItemType } from './helpers/constants';
import { PopoverProps } from 'stories/Popover/Popover';
import { cn } from '@/shared/lib/css/cn';

interface Props {
  accessList: IAccessListItem[];
  isPublic: boolean;
  onClick: () => void;
  popoverProps?: Pick<PopoverProps, 'placement'>;
  classes?: {
    pseudoLink?: string;
  };
}

const DISPLAY_MEMBERS_COUNT = 3;

export const PermissionListPopover = ({
  accessList,
  onClick,
  isPublic,
  classes,
  popoverProps,
}: Props) => {
  let content: React.ReactNode;

  const isSinglePermittedMember =
    !isPublic && accessList.length === 1 && accessList[0].type === 'member';

  if (isSinglePermittedMember) {
    content = singlePermittedMemberJSX(accessList[0]);
  } else if (accessList.length >= 1) {
    content = (
      <div className="flex flex-col gap-tw-2">
        {Object.entries(groupBy(accessList, 'type')).map(
          ([itemsType, items]: [TAccessItemType, IAccessListItem[]]) => {
            const list =
              itemsType === 'member'
                ? items.slice(0, DISPLAY_MEMBERS_COUNT)
                : items;

            return (
              <div
                className="view-permission-type mnw-200p p-tw-2"
                key={itemsType}
              >
                <div className="label-semibold flex text-neutral-900">
                  <div>{resolveAccessItemIcon(AccessItemType[itemsType])}</div>
                  &nbsp;
                  {resolveAccessItemLabel(itemsType)}
                </div>
                <div className="label-regular text-neutral-900">
                  {list.map((item) => item.value).join(', ')}
                </div>
                {itemsType === 'member' &&
                  items.length > DISPLAY_MEMBERS_COUNT && (
                    <LinkButton onClick={onClick} className="secondary-regular">
                      +{items.length - DISPLAY_MEMBERS_COUNT} more
                    </LinkButton>
                  )}
              </div>
            );
          },
        )}
      </div>
    );
  }

  return (
    <Popover
      template={content}
      disabled={isPublic}
      maxWidth="400px"
      trigger="mouseenter"
      touch={false}
      hideOnExpandedAreaClick
      appendToBody
      placement="left-end"
      arrowPosition="center"
      classes={{
        spanContainer: 'd-inline-flex mnw-0',
      }}
      {...popoverProps}
    >
      <PseudoLink
        className={cn(
          'inline-semibold text-ellipsis whitespace-nowrap',
          classes?.pseudoLink,
        )}
        onClick={onClick}
      >
        {resolveLinkLabelText(isPublic, accessList)}
      </PseudoLink>
    </Popover>
  );
};
