import { cn } from '@/shared/lib/css/cn';
import { Checkbox, RadioButton } from '@/stories';
import type { LayoutProps } from '@/types/Props';
import type { ComponentProps } from 'react';

export const AvailableItemsUI = ({ children, className }: LayoutProps) => {
  return (
    <div
      className={cn(
        'flex flex-col gap overflow-y-scroll rounded-[16px]',
        className,
      )}
    >
      {/* Overlayscrollable component */}
      {children}
    </div>
  );
};

AvailableItemsUI.Card = ({
  children,
  className,
  withCheckbox,
  withRadioButton,
  checked,
  disabled,
  onChange,
  tooltipProps,
}: Pick<
  React.HTMLAttributes<HTMLDivElement>,
  'children' | 'className' | 'onClick'
> & {
  onChange: () => void;
  withRadioButton?: boolean;
  withCheckbox?: boolean;
} & Pick<
    ComponentProps<typeof RadioButton>,
    'disabled' | 'checked' | 'tooltipProps'
  >) => (
  <label
    className={cn(
      'group/item flex min-h-[72px] cursor-pointer items-center gap-tw-4 bg-neutral-000 p-tw-4',
      className,
    )}
  >
    {withRadioButton != null && (
      <RadioButton
        tooltipProps={tooltipProps}
        disabled={disabled}
        onChange={() => onChange()}
        noInnerLabel
        checked={checked}
      />
    )}
    {withCheckbox && (
      <Checkbox
        tooltipProps={tooltipProps}
        disabled={disabled}
        noInnerLabel
        onChange={() => onChange()}
        checked={checked}
      />
    )}
    {children}
  </label>
);
