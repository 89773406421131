/* eslint-disable check-file/no-index */
import {
  Formula,
  FormulaInvalidReference,
  FormulaReferencedInEntity,
  FormulaReferences,
  FormulaVariable,
} from 'bundles/Shared/entities/formula';
import { snakeCase, startCase } from 'lodash-es';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import {
  UNTAGGED_FORMULA_TAG,
  UntaggedFormulaTag,
} from 'bundles/Shared/entities/formula/config';
import {
  EntityTag,
  EntityTagKind,
  isSystemFormulaAndNotUniform,
  NewEntityTag,
} from 'bundles/Shared/entities/tag';

export const getHumanReadableVariableNameSpace = (
  variableName: FormulaVariable['namespace'],
): string => startCase(variableName);

export const FORMULA_REFERENCE_PREFIX = 'formulas';
export const TEXT_VARIABLE_REFERENCE_PREFIX = 'text';

export const addPrefixToFormulaReference = (
  formulaReference: Formula['reference'],
) =>
  formulaReference.includes(FORMULA_REFERENCE_PREFIX)
    ? formulaReference
    : `${FORMULA_REFERENCE_PREFIX}.${formulaReference}`;

export function referenceRegExpTester(ref: string): boolean {
  return /^[a-z_0-9]+$/g.test(ref);
}

export const prepareFormulaReferenceForInput = (
  formulaReference: Formula['reference'],
) => formulaReference.replace(`${FORMULA_REFERENCE_PREFIX}.`, '');

export const transformFormulaLabelToReference = (label: Formula['reference']) =>
  snakeCase(label);

export const transformFormulaReferencingEntities = ({
  referencingFormulas,
  referencingTables,
  referencingScoreboardWidgets,
  referencingReportBuilderTemplateWidgets,
}: FormulaReferences): FormulaReferencedInEntity[] => [
  ...referencingFormulas.map(({ id, label }) => ({
    id,
    name: label,
    type: 'formula' as const,
  })),
  ...referencingTables.map(({ id, name }) => ({
    id: id.toString(),
    name,
    type: 'table' as const,
  })),
  ...referencingScoreboardWidgets.map(
    ({ id, title, boardName, scoreboardName: dashboardName }) => ({
      id,
      name: `${boardName} / ${dashboardName} / ${title}`,
      type: 'dashboardWidget' as const,
    }),
  ),
  ...(referencingReportBuilderTemplateWidgets ?? []).map(({ id, title }) => ({
    id,
    name: title,
    type: 'reportBuilderTemplateWidget' as const,
  })),
];

export const createNewTagFromName = (
  name: NewEntityTag['name'],
): NewEntityTag => ({
  id: name,
  isNew: true,
  name,
  kind: EntityTagKind.Other,
});

export const findFormulaOrVariableByReference = <
  T extends Pick<Formula, 'reference'> | Pick<FormulaVariable, 'reference'>,
>(
  formulas: T[],
  reference: Formula['reference'] | undefined | null,
) => formulas.find((formula) => formula.reference === reference);

export const filterFormulaOrVariableByInUse = <
  T extends Pick<Formula, 'id' | 'referencedInEntities'>,
>(
  formula: T,
) => formula.referencedInEntities.length > 0;

export const filterFormulaOrVariableByTags = <T extends Pick<Formula, 'tags'>>(
  formula: T,
  tags: (EntityTag | UntaggedFormulaTag)[],
) =>
  (tags.some((tag) => tag.id === UNTAGGED_FORMULA_TAG.id) &&
    formula.tags.length === 0) ||
  tags.some((tag) => formula.tags.some((tagX) => tag.id === tagX.id));

export const filterFormulaBySearch = <
  T extends Pick<Formula, 'label' | 'reference' | 'description'>,
>(
  formula: T,
  search: string,
) =>
  includesInLowerCase(
    [formula.label, formula.description ?? '', formula.reference],
    search,
  );

export const formulaHasInvalidReference = (
  formula: Pick<Formula, 'invalidReferences'>,
) => formula.invalidReferences?.length > 0;

export const formulaExpressionIncludesInvalidReference = (
  expression: Formula['expression'],
  error: FormulaInvalidReference,
) => expression.includes(error.reference);

export const canRemoveFormula = (formula: Formula) =>
  !formula.referencedInEntities?.length &&
  !isSystemFormulaAndNotUniform(formula);

export * from './expressionEditor';
export { useFormulaLinkHoverProviderEffect } from 'bundles/Shared/entities/formula/lib/linkHoverProvider';
export { ShowAutocompletion } from 'bundles/Shared/entities/formula/lib/suggestionProvider';
