import { FromTo } from '@/bundles/Settings/components/Portal/LegalEntities/components/LegalEntityManagmentPeriodsMappingPeriods';
import { ERPSystemLabel } from '@/bundles/Shared/entities/erpsystem';
import { ExternalSourceType } from '@/entities/core/legalEntity';
import { CoreLegalEntitiesManagementPeriodDto } from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesGeneratedApi';
import { cn } from '@/shared/lib/css/cn';
import { Input } from '@/stories';
import { InfoList } from '@/stories/InfoList/component';
import { ClassNameProps } from '@/types/Props';
import { createContext, PropsWithChildren, useContext, useId } from 'react';
import ReactDatePicker from 'react-datepicker';

export type ManagmentPeriodProps = Pick<
  CoreLegalEntitiesManagementPeriodDto,
  | 'id'
  | 'propertyManagementCompanyName'
  | 'externalIdentifier'
  | 'dateFrom'
  | 'dateTo'
  | 'erpSystem'
  | 'rawDataPeriod'
>;

const ManagementPeriodCardContext =
  createContext<Partial<ManagmentPeriodProps> | null>(null);

const useManagementPeriodCard = () => {
  const context = useContext(ManagementPeriodCardContext);

  if (!context) {
    throw new Error(
      'useManagementPeriodCard must be used within a ManagementPeriodCardProvider',
    );
  }

  return context;
};

type ManagementPeriodCardHeaderProps = {
  indicatorColor?: string;
};

const ManagementPeriodCardHeader = (props: ManagementPeriodCardHeaderProps) => {
  const { indicatorColor } = props;
  const { propertyManagementCompanyName, externalIdentifier, erpSystem } =
    useManagementPeriodCard();

  return (
    <div className="overflow-hidden font-semibold text-neutral-850">
      <div className="flex items-center gap-x-tw-2">
        <div className="flex items-center gap-tw-1 truncate">
          {indicatorColor && (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="shrink-0"
              style={{
                color: indicatorColor,
              }}
            >
              <circle cx="8" cy="8" r="4" fill="currentColor" />
            </svg>
          )}
          <div className="truncate">{`${propertyManagementCompanyName} – ${externalIdentifier}`}</div>
        </div>
        <div className="select-none">|</div>
        {erpSystem && (
          <ERPSystemLabel
            system={erpSystem as ExternalSourceType}
            className="gap-tw-2"
            typeThemeColor="mono"
          />
        )}
      </div>
    </div>
  );
};

type ManagementPeriodCardBodyProps = PropsWithChildren & ClassNameProps;

const ManagementPeriodCardBody = ({
  children,
  className,
}: ManagementPeriodCardBodyProps) => (
  <div className={cn('pl-tw-5', className)}>{children}</div>
);

const ManagementPeriodCardDates = () => {
  const { dateFrom, dateTo, rawDataPeriod } = useManagementPeriodCard();

  const usedData =
    dateFrom ?? dateTo
      ? {
          label: 'Used data',
          value: <FromTo from={dateFrom} to={dateTo} />,
        }
      : null;

  const receivedData =
    rawDataPeriod && (rawDataPeriod.dateFrom ?? rawDataPeriod.dateTo)
      ? {
          label: 'Received data',
          value: (
            <FromTo from={rawDataPeriod.dateFrom} to={rawDataPeriod.dateTo} />
          ),
        }
      : null;

  return <InfoList items={[usedData, receivedData].filter(Boolean)} />;
};

type ManagementPeriodCardProviderProps = PropsWithChildren & {
  period: Partial<ManagmentPeriodProps>;
};

const ManagementPeriodCardProvider = ({
  children,
  period,
}: ManagementPeriodCardProviderProps) => {
  return (
    <ManagementPeriodCardContext.Provider value={period}>
      {children}
    </ManagementPeriodCardContext.Provider>
  );
};

type ManagementPeriodCardDateFormProps = {
  onChange: (date: Date | null) => void;
  label: string;
  value?: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
};

const ManagementPeriodCardDateForm = ({
  value,
  onChange,
  label,
  minDate,
  maxDate,
}: ManagementPeriodCardDateFormProps) => {
  const id = useId();

  return (
    <>
      <label
        htmlFor={id}
        className="font-meduim mb-tw-2 text-xs text-neutral-850"
      >
        {label} {/* {required && <span className="text-red">*</span>} */}
      </label>
      <ReactDatePicker
        id={id}
        portalId="datepicker-portal"
        placeholderText="Select Date"
        selected={value}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        customInput={
          <Input
            classes={{
              input:
                'bg-white shadow-[0px_2px_2px_0px_rgba(12,25,54,0.08)] border-2 border-solid border-neutral-250 h-tw-5',
            }}
            autoComplete="off"
          />
        }
        wrapperClassName="w-full"
      />
    </>
  );
};

type ManagementPeriodCardProps = PropsWithChildren<
  {
    period: Partial<ManagmentPeriodProps>;
  } & ClassNameProps
>;

export const ManagementPeriodCard = (props: ManagementPeriodCardProps) => {
  const { children, period, className } = props;

  return (
    <ManagementPeriodCardProvider period={period}>
      <div
        className={cn(
          'radius008 flex flex-col gap-tw-4 bg-white py-tw-4 pl-tw-3 pr-tw-4 text-sm shadow-sm',
          className,
        )}
      >
        {children}
      </div>
    </ManagementPeriodCardProvider>
  );
};

ManagementPeriodCard.Header = ManagementPeriodCardHeader;
ManagementPeriodCard.Body = ManagementPeriodCardBody;
ManagementPeriodCard.Dates = ManagementPeriodCardDates;
ManagementPeriodCard.DateForm = ManagementPeriodCardDateForm;
