export const DASH_SIGN = '-';
export const UNDERSCORE_SIGN = '_';

export const joinWith = (arr: string[], delim: string): string =>
  arr.join(delim);

export const splitWith = (iterable: string, delim: string): string[] =>
  iterable.split(delim);

export const joinWithDash = (arr: string[]): string => joinWith(arr, DASH_SIGN);

export const splitWithDash = (iterable: string): string[] =>
  splitWith(iterable, DASH_SIGN);

export const splitWithUnderscore = (iterable: string): string[] =>
  splitWith(iterable, UNDERSCORE_SIGN);

export const joinWithUnderscore = (arr: string[]): string =>
  joinWith(arr, UNDERSCORE_SIGN);
