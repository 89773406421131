import ThinTabGroup from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import { memo } from 'react';
import { Props, Board } from './types';

type DashboardBoardNavigationItemProps = Pick<
  Props,
  'selectedBoardId' | 'onBoardChange'
> & {
  board: Board;
} & React.ComponentProps<typeof ThinTabGroup.Item>;

export const DashboardBoardNavigationItem = memo(
  (props: DashboardBoardNavigationItemProps) => {
    const { board, selectedBoardId, onBoardChange, ...otherProps } = props;

    const handleBoardClick = () => {
      onBoardChange?.(board);
    };

    return (
      <ThinTabGroup.Item
        id={board.id}
        key={board.id}
        selected={board.id === selectedBoardId}
        onClick={handleBoardClick}
        {...otherProps}
      >
        {board.name}
      </ThinTabGroup.Item>
    );
  },
);
