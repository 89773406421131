import * as React from 'react';
import { Button, Field, Input, Modal, ModalActions } from '@/stories';
import { useForm } from 'react-hook-form';
import {
  EDIT_FINANCIAL_CATEGORY_FORM_SCHEMA,
  EditFinancialCategoryForm,
} from '@/features/financialCategory/edit/config';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogProps } from '@/shared/lib/hooks/useModal';

interface Props extends DialogProps<EditFinancialCategoryForm> {
  category: EditFinancialCategoryForm;
}

function EditFinancialCategoryModal({ onClose, ...props }: Props) {
  const { register, ...methods } = useForm<EditFinancialCategoryForm>({
    resolver: yupResolver(EDIT_FINANCIAL_CATEGORY_FORM_SCHEMA),
    defaultValues: {
      ...props.category,
    },
  });

  const handleSubmit = methods.handleSubmit((data) => {
    props.onSubmit?.(data);
  });

  return (
    <Modal
      header="Edit category"
      toggle={() => onClose()}
      actions={
        <ModalActions>
          <Button variant="success" fluid onClick={handleSubmit}>
            Update Category
          </Button>
        </ModalActions>
      }
      classes={{
        body: 'bg-white',
        header: 'border-bottom',
      }}
      size="sm"
    >
      <Field labelText="Category Name">
        <Input {...register('name')} />
      </Field>
    </Modal>
  );
}

export default EditFinancialCategoryModal;
