import { CssVar } from '@/shared/config/cssVar';
import { contrastingTextColor, convertColorToHex } from '@/shared/lib/colors';
import { cn } from '@/shared/lib/css/cn';
import { ValueDisplayOptions } from '@/shared/lib/formatting/displayOptions';
import { getExcelStyleNumberFormat } from '@/shared/lib/formatting/excel';
import {
  defaultValueFallbackCondition,
  FormattedColDef,
  getDefaultAgGridNumberColDef,
  StyledBasicCellRendererProps,
} from '@/shared/lib/formatting/table';
import { rangePercentile } from '@/shared/lib/statistics';
import { joinWithDash } from '@/shared/lib/string';
import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  IHeaderParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { CELL_CLASS_NAMES } from 'bundles/Shared/components/AgGrid/Table/classNames';
import { SetFilterComponent } from 'bundles/Shared/components/AgGrid/Table/filters/SetFilterComponent';
import {
  DEFAULT_GROUP_BG_COLOR,
  DEFAULT_WIDGET_TABLE_PROPS,
  PinColumnAction,
  WidgetTableGroupHeaderGroup,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { resolveComparisonTextColorForColDef } from 'bundles/Shared/widgets/dashboard/widgets/common/lib/comparison';
import { resolveHeaderWithSubheaderComponentProps } from 'bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import {
  extractGradientFromThreshold,
  findMaxThreshold,
  findMinThreshold,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/GradientField';
import {
  HEADER_CELL_WRAPPER_BG_COLOR_CSS_CUSTOM_PROPERTY,
  HeaderComponentWithSubHeader,
  HeaderWithSubHeaderParams,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/HeaderComponentWithSubHeader';
import {
  TableVizConfig,
  TableVizConfigColorBackground,
  TableVizConfigColumn,
  TableVizConfigColumnGradientThreshold,
  TableVizConfigColumnGroup,
  TableVizConfigComparison,
  TableVizConfigGradientBackground,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  buildExcelStyleId,
  INDENTATION_EXCEL_STYLES,
  TABLE_EXCEL_STYLE_IDS,
  TABLE_EXCEL_STYLES,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import { WidgetTableHeaderGroupComponentParams } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetHeaderGroup';
import {
  columnToColumnSettingsByKeyMatcher,
  zeroAndNullToTheEndComparator,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { WidgetViewMode } from 'bundles/Shared/widgets/dashboard/widgets/model';
import * as d3 from 'd3';
import { produce } from 'immer';
import { orderBy } from 'lodash-es';
import { CSSProperties } from 'react';
import { IconsId } from 'types/sre-icons';

const COLORED_BORDER_WIDTH = '2px';
const TOTAL_COLOR_STYLES = {
  background: CssVar.neutral850,
  color: CssVar.neutral000,
  borderColor: CssVar.neutral700,
};
export const isTotalRow = (params: ICellRendererParams) =>
  params.data?.type === 'total';
const shouldApplyGradient = (params: ICellRendererParams) =>
  params.context.groupingType === 'assets'
    ? params.data?.type === 'asset'
    : params.data?.type === 'segment';

export const contrastingCellTextColor = (color: string) => {
  return contrastingTextColor(color, {
    dark: 'var(--next-get-table-cell-color)',
    light: CssVar.neutral000,
  });
};

export const resolveBackgroundAndTextColor = ({
  comparison,
  background,
  params,
  direction = 'column',
  totalColors,
  ...args
}: {
  params: ICellRendererParams;
  background?:
    | TableVizConfigGradientBackground
    | TableVizConfigColorBackground
    | undefined;
  comparison?: TableVizConfigComparison;
  shouldApplyGradient?: (params: ICellRendererParams) => boolean;
  direction?: 'row' | 'column';
  totalColors?: typeof TOTAL_COLOR_STYLES;
}) => {
  if (isTotalRow?.(params)) {
    return totalColors;
  }
  if (comparison) {
    return {
      color: resolveComparisonTextColorForColDef(
        comparison,
        params,
        (rowData, key) => rowData?.[key],
      ),
    };
  }

  if (background && args.shouldApplyGradient?.(params)) {
    const getBackgroundAndContrastingTextColor = (color: string) => {
      return {
        background: color,
        color: contrastingCellTextColor(color),
      };
    };
    if (background.type === 'color') {
      return getBackgroundAndContrastingTextColor(background.color);
    }
    const { value } = params;

    if (value == null || (background.ignore_zeros && value === 0)) {
      return {};
    }

    const getBackgroundColor = () => {
      const { minMaxValues } = params.context;
      const key =
        direction === 'row'
          ? params.data.key?.toString()
          : params.column!.getColId();

      const dataMin = background.ignore_zeros
        ? minMaxValues[key].minWithoutZero
        : minMaxValues[key].min;
      const dataMax = background.ignore_zeros
        ? minMaxValues[key].maxWithoutZero
        : minMaxValues[key].max;

      const { threshold } = background;
      const minThreshold = findMinThreshold(threshold);
      const maxThreshold = findMaxThreshold(threshold);

      if (value === dataMax && maxThreshold) {
        return maxThreshold.color;
      }

      if (value === dataMin && minThreshold) {
        return minThreshold.color;
      }

      const getDomainByThreshold = (
        t: TableVizConfigColumnGradientThreshold,
      ) => {
        if (t.type === 'min') {
          return dataMin;
        }
        if (t.type === 'max') {
          return dataMax;
        }
        if (t.type === 'percentile') {
          return rangePercentile({
            value: t.value!,
            rangeMin: dataMin,
            rangeMax: dataMax,
          });
        }
        return t.value!;
      };
      const gradient = extractGradientFromThreshold(threshold);
      const domain = threshold.map(getDomainByThreshold);
      const gradientScale = d3.scaleLinear().domain(domain).range(gradient);
      return gradientScale(value);
    };

    const backgroundColor = getBackgroundColor();

    return getBackgroundAndContrastingTextColor(backgroundColor);
  }

  return {};
};

export const buildGroupId = (id: string) => {
  return joinWithDash(['group', id]);
};

export type OverrideColDefFunction = (args: {
  columnSettings: TableVizConfigColumn;
  column?: {
    key: string;
  };
  mode: WidgetViewMode;
}) => Partial<ColDef>;

export type OverrideColDefObject = Partial<ColDef>;

export type OverrideColDef = OverrideColDefFunction | OverrideColDefObject;

export class ColDefBuilder<
  Column extends {
    label: string;
    key: number;
  },
> {
  mode: WidgetViewMode;
  totalColors: typeof TOTAL_COLOR_STYLES = TOTAL_COLOR_STYLES;
  override?: OverrideColDefFunction;
  onPinColumn?: (colId: string) => unknown;
  filterLabelOverrides?: Record<string, Record<string, string>>;

  subHeaderName?: (params: {
    mode: WidgetViewMode;
    columnSettings: TableVizConfigColumn;
    params: IHeaderParams;
    column?: Column;
  }) => string;

  constructor({
    mode,
    onPinColumn,
  }: {
    mode: WidgetViewMode;
    onPinColumn?: (colId: string) => unknown;
  }) {
    this.mode = mode;
    this.onPinColumn = onPinColumn;
  }

  withTotalColors(totalColors: typeof TOTAL_COLOR_STYLES) {
    this.totalColors = totalColors;
    return this;
  }

  withFilterLabelOverrides(
    filterLabelOverrides: Record<string, Record<string, string>>,
  ) {
    this.filterLabelOverrides = filterLabelOverrides;
    return this;
  }

  withSubHeaderName(
    subHeaderName: typeof ColDefBuilder.prototype.subHeaderName,
  ) {
    this.subHeaderName = subHeaderName;
    return this;
  }

  withOverride(override: OverrideColDef) {
    this.override = typeof override === 'function' ? override : () => override;
    return this;
  }

  private resolveCellBorderStyles = ({
    columnSettings,
    params,
  }: {
    columnSettings: TableVizConfigColumn;
    params: ICellRendererParams;
  }): CSSProperties => {
    const isTotal = isTotalRow(params);
    const sideBordersWidth = columnSettings.border_color
      ? COLORED_BORDER_WIDTH
      : undefined;
    const sideBordersColor =
      columnSettings.border_color ??
      (isTotal ? this.totalColors.borderColor : undefined);
    return {
      borderRightWidth: sideBordersWidth,
      borderLeftWidth: sideBordersWidth,
      borderBottomWidth: isTotal ? COLORED_BORDER_WIDTH : undefined,
      borderLeftColor: sideBordersColor,
      borderRightColor: sideBordersColor,
      borderBottomColor: isTotal
        ? columnSettings.border_color ?? this.totalColors.borderColor
        : undefined,
    };
  };

  buildAlignmentCellParams(
    align: 'left' | 'right' | 'center' | undefined,
    params: ICellRendererParams,
  ) {
    return {
      classes: {
        inner: cn(CELL_CLASS_NAMES.CommonCell.inner.basic, {
          '!justify-end': align === 'right',
          '!justify-center': align === 'center',
          '!justify-start': align === 'left' || align === undefined,
          // workaround for truncated last row in pdf
          '!items-start': this.mode === 'pdf' && params.node.lastChild,
        }),
      },
    };
  }

  private buildCellProperties({
    columnSettings,
  }: {
    columnSettings: TableVizConfigColumn;
  }): Pick<
    ColDef,
    | 'cellRenderer'
    | 'type'
    | 'cellClass'
    | 'cellClassRules'
    | 'cellRendererParams'
  > &
    FormattedColDef {
    const { value_display_options } = columnSettings;
    const fallbackCondition = (value: number) => {
      const shouldHideNegativeValues =
        columnSettings.comparison?.hide_negative_values === true;

      return (
        defaultValueFallbackCondition(value) ||
        (shouldHideNegativeValues && value < 0)
      );
    };

    const formattedColDef =
      value_display_options &&
      getDefaultAgGridNumberColDef({
        ...value_display_options,
        fallbackCondition,
      });

    return {
      cellRenderer:
        formattedColDef?.cellRenderer ??
        DEFAULT_WIDGET_TABLE_PROPS.defaultColDef.cellRenderer,
      type: formattedColDef?.type,
      cellClassRules: {
        [TABLE_EXCEL_STYLE_IDS.totalBackground]: (params) =>
          params.data?.type === 'total',
      },
      displayOptions: value_display_options,
      cellClass: ({ colDef }) =>
        colDef.colId && buildExcelStyleId({ id: colDef.colId }),
      cellRendererParams: (params: ICellRendererParams) => {
        const isTotal = isTotalRow(params);
        const hasLabelColor = isTotal;
        const { comparison, align = 'right' } = columnSettings;
        const compareColor = comparison
          ? resolveComparisonTextColorForColDef(
              comparison,
              params,
              (rowData, key) => rowData?.[key],
            )
          : undefined;

        return {
          labelColor: hasLabelColor ? compareColor : undefined,
          ...this.buildAlignmentCellParams(align, params),
          styles: {
            ...resolveBackgroundAndTextColor({
              background: columnSettings.background,
              comparison,
              params,
              shouldApplyGradient,
              totalColors: this.totalColors,
            }),
            ...this.resolveCellBorderStyles({
              columnSettings,
              params,
            }),
            fontWeight: isTotal ? 'bold' : columnSettings.font_weight,
          },
        } satisfies StyledBasicCellRendererProps;
      },
    };
  }

  private buildHeaderProperties({
    columnSettings,
    column,
  }: {
    columnSettings: TableVizConfigColumn;
    column?: Column;
  }): Pick<
    ColDef,
    'headerComponentParams' | 'headerComponent' | 'headerClass'
  > {
    return {
      headerComponent: HeaderComponentWithSubHeader,
      headerClass: (params) => {
        const parent = params.column?.getParent();

        const colGroupDef = parent?.getColGroupDef();

        return (
          colGroupDef?.groupId &&
          buildExcelStyleId({
            id: colGroupDef.groupId,
            type: 'headerGroup',
          })
        );
      },
      headerComponentParams: (params: IHeaderParams) => {
        const { hide_subtitle, hide_title, subtitle } =
          columnSettings.header ?? {};
        const subHeaderName =
          subtitle ??
          this.subHeaderName?.({
            column,
            columnSettings,
            params,
            mode: this.mode,
          });
        const parent = params.column?.getParent();

        const colGroupDef = parent?.getColGroupDef();
        const { style: groupStyle } =
          colGroupDef?.headerGroupComponentParams ?? {};
        const borderColor =
          columnSettings.border_color ?? groupStyle?.borderColor;
        return {
          ...resolveHeaderWithSubheaderComponentProps({
            headerName: column?.label,
            subHeaderName,
            hide_subtitle,
            hide_title,
          }),
          style: {
            ...groupStyle,
            borderWidth: columnSettings.border_color
              ? COLORED_BORDER_WIDTH
              : undefined,
            borderTopColor: borderColor,
            borderLeftColor: borderColor,
            borderRightColor: borderColor,
            [HEADER_CELL_WRAPPER_BG_COLOR_CSS_CUSTOM_PROPERTY.name]:
              groupStyle?.backgroundColor ?? DEFAULT_GROUP_BG_COLOR,
            backgroundColor:
              groupStyle?.backgroundColor ?? DEFAULT_GROUP_BG_COLOR,
          },
          actions: (
            <PinColumnAction
              {...params}
              mode={this.mode}
              onPinColumn={this.onPinColumn}
            />
          ),
        } satisfies HeaderWithSubHeaderParams;
      },
    };
  }

  private buildFilterProperties({
    columnSettings,
    column,
    filterOptions,
  }: {
    columnSettings: TableVizConfigColumn;
    column?: Column;
    filterOptions?: FilterOption[];
  }): Pick<ColDef, 'filter' | 'filterParams'> {
    const filterOption = filterOptions?.find((o) => o.column === column?.label);
    if (!filterOption || this.mode === 'pdf') {
      return {};
    }
    return {
      filter: SetFilterComponent,
      filterParams: {
        values: filterOption.options ?? [],
        valueFormatter: (params: ValueFormatterParams) =>
          this.filterLabelOverrides?.[columnSettings.key]?.[params.value] ??
          params.value,
      },
    };
  }

  build({
    column,
    columnSettings,
    filterOptions,
  }: {
    columnSettings: TableVizConfigColumn;
    column?: Column;
    filterOptions?: FilterOption[];
  }): ColDef {
    return {
      ...this.buildCellProperties({ columnSettings }),
      ...this.buildHeaderProperties({ column, columnSettings }),
      ...this.buildFilterProperties({ columnSettings, filterOptions, column }),
      suppressMovable: true,
      suppressMenu: true,
      resizable: true,
      flex: undefined,
      sortable: this.mode != 'pdf',
      headerName: column?.label,
      initialHide: columnSettings.hidden,
      colId: columnSettings.col_id,
      field: columnSettings.key,
      maxWidth: columnSettings?.max_width,
      minWidth: columnSettings?.min_width,
      columnGroupShow: columnSettings.column_group_show ?? undefined,
      initialSort: columnSettings.initial_sort,
      comparator: zeroAndNullToTheEndComparator,
      ...(this.override?.({
        column,
        columnSettings,
        mode: this.mode,
      }) ?? {}),
    };
  }
}

export class ColGroupDefBuilder {
  mode: WidgetViewMode;
  headerName: (params: {
    group: TableVizConfigColumnGroup;
    mode: WidgetViewMode;
  }) => string;

  constructor({ mode }: { mode: WidgetViewMode }) {
    this.mode = mode;
  }

  withHeaderName(
    headerName: (params: {
      group: TableVizConfigColumnGroup;
      mode: WidgetViewMode;
    }) => string,
  ) {
    this.headerName = headerName;
    return this;
  }

  build(group: TableVizConfigColumnGroup): Omit<ColGroupDef, 'children'> {
    const headerName =
      this.headerName?.({
        group,
        mode: this.mode,
      }) ?? group.header_name;
    return {
      groupId: buildGroupId(group.group_id),
      headerClass: buildExcelStyleId({
        id: buildGroupId(group.group_id),
        type: 'headerGroup',
      }),
      headerGroupComponent: WidgetTableGroupHeaderGroup,
      headerGroupComponentParams: {
        icon: group.icon as IconsId,
        style: {
          backgroundColor: group.background ?? DEFAULT_GROUP_BG_COLOR,
          borderColor: group.border_color,
        },
        mode: this.mode,
      } satisfies WidgetTableHeaderGroupComponentParams,
      headerName,
    };
  }
}

type ExcelStyleBuilderSource = 'columns' | 'rows' | 'columns-rows';

export class ExcelStyleBuilder {
  private vizConfig: TableVizConfig;
  private mode: WidgetViewMode;
  private source: ExcelStyleBuilderSource = 'columns';
  private indentation: boolean;
  private valueDisplayOptions: ValueDisplayOptions;

  constructor({
    vizConfig,
    mode,
  }: {
    vizConfig: TableVizConfig;
    mode: WidgetViewMode;
  }) {
    this.vizConfig = vizConfig;
    this.mode = mode;
  }

  withSource(source: ExcelStyleBuilderSource) {
    this.source = source;
    return this;
  }

  withIndentationStyles() {
    this.indentation = true;
    return this;
  }

  withDefaultValueDisplayOptionsStyles(
    valueDisplayOptions: ValueDisplayOptions,
  ) {
    this.valueDisplayOptions = valueDisplayOptions;
    return this;
  }

  buildExcelStyles() {
    let vizConfigStyles: ReturnType<typeof this.getExcelStylesFromRows> = [];

    switch (this.source) {
      case 'columns':
        vizConfigStyles = this.getExcelStylesFromColumns();
        break;
      case 'columns-rows':
        vizConfigStyles = this.getExcelStylesFromColumns().concat(
          this.getExcelStylesFromRows(),
        );
        break;
      case 'rows':
        vizConfigStyles = this.getExcelStylesFromRows();
        break;
    }
    const { header_background } = this.vizConfig;
    let defaultStyles = TABLE_EXCEL_STYLES;
    if (header_background) {
      defaultStyles = produce(defaultStyles, (draft) => {
        const headerStyle = draft.find(
          (style) => style.id === TABLE_EXCEL_STYLE_IDS.header,
        );
        if (headerStyle) {
          headerStyle.interior.color = convertColorToHex(header_background);
        }
      });
    }
    return vizConfigStyles
      .concat(defaultStyles)
      .concat(this.indentation ? INDENTATION_EXCEL_STYLES : [])
      .concat(this.getExcelStylesFromColumnGroups());
  }

  private getExcelStylesFromColumns() {
    return this.vizConfig.columns.map((column) => {
      const valueDisplayOptions =
        column.value_display_options ?? this.valueDisplayOptions;
      return {
        id: buildExcelStyleId({ id: column.col_id.toString() }),
        ...(valueDisplayOptions &&
          getExcelStyleNumberFormat(valueDisplayOptions)),
      };
    });
  }

  private getExcelStylesFromRows() {
    return (this.vizConfig.rows ?? []).map((row) => {
      const valueDisplayOptions =
        row.value_display_options ?? this.valueDisplayOptions;
      return {
        id: buildExcelStyleId({
          id: row.key.toString(),
          type: 'row',
        }),
        ...(valueDisplayOptions &&
          getExcelStyleNumberFormat(valueDisplayOptions)),
      };
    });
  }

  private getExcelStylesFromColumnGroups() {
    return (this.vizConfig.column_groups ?? []).map((group) => ({
      id: buildExcelStyleId({
        id: buildGroupId(group.group_id),
        type: 'headerGroup',
      }),
      ...(group.background && {
        interior: {
          color: convertColorToHex(group.background),
          pattern: 'Solid',
        },
      }),
    }));
  }
}

export class ColumnDefsBuilder<
  Column extends {
    label: string;
    key: number;
  },
> {
  columnDefs: (ColDef | ColGroupDef)[];
  vizConfig: TableVizConfig;
  mode: WidgetViewMode;
  colDefBuilder: ColDefBuilder<Column>;
  colGroupDefBuilder: ColGroupDefBuilder;
  excelStyleBuilder: ExcelStyleBuilder;
  columnMatcher: (
    column: Column,
    columnSettings: TableVizConfigColumn,
  ) => boolean = columnToColumnSettingsByKeyMatcher;

  constructor({
    vizConfig,
    mode,
    colDefBuilder,
    colGroupDefBuilder,
    excelStyleBuilder,
    columnMatcher,
  }: {
    vizConfig: TableVizConfig;
    mode: WidgetViewMode;
    colGroupDefBuilder: ColGroupDefBuilder;
    colDefBuilder: ColDefBuilder<Column>;
    excelStyleBuilder?: ExcelStyleBuilder;
    columnMatcher?: (
      column: Column,
      columnSettings: TableVizConfigColumn,
    ) => boolean;
  }) {
    this.colGroupDefBuilder = colGroupDefBuilder;
    this.colDefBuilder = colDefBuilder;
    this.excelStyleBuilder =
      excelStyleBuilder ?? new ExcelStyleBuilder({ vizConfig, mode });
    this.columnDefs = [];
    this.vizConfig = vizConfig;
    this.mode = mode;
    this.columnMatcher = columnMatcher ?? columnToColumnSettingsByKeyMatcher;
  }

  buildExcelStyles() {
    return this.excelStyleBuilder?.buildExcelStyles() ?? [];
  }

  build({
    columns,
    filterOptions,
  }: {
    columns: Column[];
    filterOptions?: FilterOption[];
  }) {
    if (columns.length === 0 && this.vizConfig.columns.length === 0) {
      return [];
    }
    const transformColumnSettingsToColDef = (cs: TableVizConfigColumn) => {
      const column = columns.find((c) => this.columnMatcher(c, cs))!;

      return this.colDefBuilder.build({
        column,
        columnSettings: cs,
        filterOptions,
      });
    };
    const ungroupedColumns = orderBy(
      this.vizConfig.columns.filter((column) => !column.group_id),
      'order',
    );
    const colGroupDefs = orderBy(this.vizConfig.column_groups, 'order').map(
      (group) => {
        const columnsSettings = orderBy(this.vizConfig.columns, 'order').filter(
          (c) => c.group_id === group.group_id,
        );
        return {
          ...this.colGroupDefBuilder.build(group),
          children: columnsSettings.map(transformColumnSettingsToColDef),
        };
      },
    );

    return [
      ...ungroupedColumns.map(transformColumnSettingsToColDef),
      ...colGroupDefs,
    ].filter((column) => column !== null) as (ColDef | ColGroupDef)[];
  }
}

export type FilterOption = {
  column?: string;
  options?: string[];
};
