import React, { useEffect, useState, FC } from 'react';
import http from 'lib/http';
import { stringify } from 'qs';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import { AnimationLoader, LoadMoreButton, Tab, TabItem } from 'stories';
import { TimelineDateCampaingsGroup } from './TimelineDateCampaingsGroup';
import type { RouteComponentProps } from '@reach/router';

// TODO: should be renamed to more common, like EmailCampaignsTab
const FeedMessageCampaignsTab: FC<RouteComponentProps> = () => {
  const DATES_PER_PAGE = 7;

  const [timelineDates, setTimelineDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const mapCampaignTypesToText = {
    invitation_emails: 'Invitation',
    feed_messages: 'Feed Notification',
    reset_password_emails: 'Reset Password',
    construction_workflow_events: 'Construction',
    email_builder_custom_templates: 'Custom Template Email',
  };

  const [campaignTypes, setCampaignTypes] = useState();
  const fetchCampaignTypes = () => {
    http
      .get('/email_campaign/timeline_dates/campaign_types')
      .then((response) => response.json())
      .then((data) => setCampaignTypes(data));
  };

  const [currentCampaignType, setCurrentCampaignType] = useState();

  const fetchFeedMessages = ({ per, currentPage }) => {
    const dataParams = {
      indices: false,
      arrayFormat: 'brackets',
      encode: false,
    };
    const data = {
      page: currentPage,
      per,
      with_campaign_types: [currentCampaignType],
    };
    return http
      .get(`/email_campaign/timeline_dates?${stringify(data, dataParams)}`)
      .then((res) => res.json());
  };

  const refetchFeedMessages = async (currentPage) => {
    setIsLoading(true);
    try {
      await fetchFeedMessages({
        per: DATES_PER_PAGE,
        currentPage: currentPage || 1,
      }).then((response) => {
        setTimelineDates((current) => [...current, ...response.timelineDates]);
        setTotalSize(response.meta.totalSize);
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaignTypes();
  }, []);

  useEffect(() => {
    setTimelineDates([]);
    setPage(1);
    refetchFeedMessages(1);
  }, [currentCampaignType]);

  const handleShowMore = () => {
    setPage(page + 1);
    refetchFeedMessages(page + 1, setIsLoadingMore);
  };

  return (
    <div className="mt-[1.5rem] flex flex-col">
      <div className="w-100 mb-l flex items-center">
        {campaignTypes && campaignTypes.length > 0 && (
          <Tab className="tabs-underline-light-30 flex-grow">
            <TabItem
              key="all"
              onClick={() => setCurrentCampaignType(undefined)}
              active={currentCampaignType === undefined}
            >
              All
            </TabItem>
            {campaignTypes.map((campaignType) => (
              <TabItem
                key={campaignType}
                onClick={() => setCurrentCampaignType(campaignType)}
                active={currentCampaignType === campaignType}
              >
                {mapCampaignTypesToText[campaignType]}
              </TabItem>
            ))}
          </Tab>
        )}
      </div>
      {isLoading && <AnimationLoader className="static min-h-[360px]" />}
      {timelineDates.length === 0 && !isLoading && (
        <NoDataOverlay title="Campaigns with selected types not found" />
      )}
      <div className="flex flex-col gap-m">
        {timelineDates.length > 0 && (
          <div className="flex flex-col gap-s">
            {timelineDates.map((timelineDate) => (
              <TimelineDateCampaingsGroup
                key={timelineDate.date}
                timelineDate={timelineDate}
                totalSize={totalSize}
                timelineDatesLength={timelineDates.length}
              />
            ))}
          </div>
        )}
        {!isLoading && totalSize > timelineDates.length && (
          <LoadMoreButton
            isLoading={isLoadingMore}
            size="m"
            fluid
            onClick={handleShowMore}
          >
            Load More
          </LoadMoreButton>
        )}
      </div>
    </div>
  );
};

export default FeedMessageCampaignsTab;
