import { LegalEntityManagmentPeriodsItem } from '@/bundles/Settings/components/Portal/LegalEntities/components/LegalEntityManagmentPeriodsItem';
import { TConnectionReportType } from '@/bundles/Shared/entities/connection/model';
import {
  LegalEntity,
  LegalEntityManagementPeriod,
} from '@/entities/core/legalEntity';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from '@/stories';
import { IconsId } from '@/types/sre-icons';
import { merge } from 'lodash-es';
import groupBy from 'lodash-es/groupBy';
import { useMemo } from 'react';

type LegalEntityManagmentPeriodsProps = {
  row: LegalEntity;
  showNumber?: boolean;
  hideInactive?: boolean;
};

type ConfigDataSource = {
  dataSources: LegalEntityManagementPeriod[];
  type: TConnectionReportType;
  icon: IconsId;
};

export const LegalEntityManagmentPeriods = ({
  row,
  showNumber = false,
  hideInactive = false,
}: LegalEntityManagmentPeriodsProps) => {
  const { managementPeriods } = row;

  const dataSources = useMemo(
    () => groupBy(managementPeriods, 'type'),
    [managementPeriods],
  );

  const operational = merge(
    dataSources[TConnectionReportType.OPERATIONAL],
    dataSources[TConnectionReportType.COMBINED],
  );

  const configDataSources = useMemo<ConfigDataSource[]>(() => {
    const result = [
      {
        dataSources: operational,
        type: TConnectionReportType.OPERATIONAL,
        icon: 'bag' as IconsId,
      },
      {
        dataSources: dataSources[TConnectionReportType.FINANCIAL],
        type: TConnectionReportType.FINANCIAL,
        icon: 'roundSumbolUSD' as IconsId,
      },
      {
        dataSources: dataSources[TConnectionReportType.MARKET_DATA],
        type: TConnectionReportType.MARKET_DATA,
        icon: 'analys' as IconsId,
      },
      {
        dataSources: dataSources[TConnectionReportType.CRM],
        type: TConnectionReportType.CRM,
        icon: 'extension' as IconsId,
      },
    ];

    if (hideInactive) {
      return result.filter((ds) => ds.dataSources?.length);
    }

    return result;
  }, [dataSources, hideInactive]);

  if (!configDataSources.length) return null;

  return (
    <div className="flex h-full gap-tw-2">
      {configDataSources.map((ds) => (
        <LegalEntityManagmentPeriodsItem
          key={ds.type}
          dataSources={ds.dataSources}
          type={ds.type}
          disabled={!ds.dataSources?.length}
        >
          <div
            className={cn(
              'flex h-full items-center justify-center gap-x-tw-1 p-tw-1 leading-[1.25rem] text-neutral-500',
              'aria-disabled:text-neutral-150',
            )}
          >
            <Icon iconName={ds.icon} className="size-tw-4 shrink-0" />
            {showNumber && (
              <span className="text-xs">{ds.dataSources?.length}</span>
            )}
          </div>
        </LegalEntityManagmentPeriodsItem>
      ))}
    </div>
  );
};
