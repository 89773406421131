import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { IOperationalBudgetsLineItemRequest } from 'bundles/REconcile/actions/operationalBudgetsTypes';
import { SymmetreAgGridTable } from '@/bundles/Shared/components/AgGrid/Table/SymmetreAgGridTable';
import { CurrencyCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CurrencyCellRenderer';
import { HeaderComponent } from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderComponent';
import {
  IColumnGroup,
  IRowBase,
} from 'bundles/Shared/components/ReportTable/types';
import { useGridDefaultParams } from 'lib/ag-grid/useGridDefaultParams';
import { useMemo, useRef, useState } from 'react';
import { IReportBudgetOnShow } from 'types/ReportBudget';
import { IAutoSavingStatus } from '../OperationalBudget';
import { useAutoGroupColumnDef } from './hooks/columns/useAutoGroupColumnDef';
import { useCellChangeHandler } from './hooks/useCellChangeHandler';
import { useColumnDefs } from './hooks/useColumnDefs';
import { useProcessDataFromClipboard } from './hooks/useProcessDataFromClipboard';
import { useRowData } from './hooks/useRowData';
import { useUpdateLineItemsEffect } from './hooks/useUpdateLineItemsEffect';

export const AG_GROUP_CELL_RENDERER = 'agGroupCellRenderer';

interface Props {
  rows: IRowBase[];
  columns: IColumnGroup[];
  budget: IReportBudgetOnShow;
  setAutoSavingStatus: React.Dispatch<React.SetStateAction<IAutoSavingStatus>>;
}

export function OperationBudgetEditorTable({
  rows,
  columns,
  budget,
  setAutoSavingStatus,
}: Props) {
  const { onGridReady } = useGridDefaultParams({
    redrawNodeOnExpand: true,
    enableExpandOnClick: true,
  });
  const gridRef = useRef<AgGridReact>(null);
  const [updatedLineItems, setUpdatedLineItems] = useState<
    IOperationalBudgetsLineItemRequest[]
  >([]);

  useUpdateLineItemsEffect({
    budget,
    updatedLineItems,
    setAutoSavingStatus,
  });

  const handleCellChange = useCellChangeHandler({
    setAutoSavingStatus,
    setUpdatedLineItems,
  });

  const columnDefs = useColumnDefs({
    budget,
    columns,
    handleCellChange,
  });

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      cellRenderer: CurrencyCellRenderer,
      headerComponent: HeaderComponent,
      suppressMovable: true,
      minWidth: 124,
    }),
    [columns],
  );

  const autoGroupColumnDef = useAutoGroupColumnDef({
    columns,
    budget,
  });

  const processDataFromClipboard = useProcessDataFromClipboard(gridRef);
  const rowData = useRowData({ rows });

  return (
    <SymmetreAgGridTable
      ref={gridRef}
      onGridReady={onGridReady}
      defaultColDef={defaultColDef}
      rowData={rowData}
      columnDefs={columnDefs}
      autoGroupColumnDef={autoGroupColumnDef}
      processDataFromClipboard={processDataFromClipboard}
    />
  );
}
