export enum EntityTagKind {
  System = 'system',
  Other = 'other',
}

export const TAG_ENTITIES = {
  FORMULA: 'formula',
  MANUAL_VARIABLE: 'manualVariable',
  REPORT_BUILDER_TEMPLATE: 'reportBuilderTemplate',
} as const;

export type TagEntity = (typeof TAG_ENTITIES)[keyof typeof TAG_ENTITIES];
export type TaggedEntity = {
  tags: EntityTag[];
};
export type SystemEntity = {
  tags: SystemEntityTag[];
};
export type UserEntity = {
  tags: UserEntityTag[];
};

export type EntityTag = {
  id: string;
  name: string;
  kind: FlattenEnum<EntityTagKind>;
};
export type UserEntityTag = EntityTag & {
  kind: FlattenEnum<EntityTagKind.Other>;
};
export type SystemEntityTag = EntityTag & {
  kind: FlattenEnum<EntityTagKind.System>;
};
export type NewEntityTag = EntityTag & {
  isNew: true;
};
export const isTagNew = (tag: EntityTag | NewEntityTag): tag is NewEntityTag =>
  'isNew' in tag;
