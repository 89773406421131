import * as React from 'react';
import { useMemo, useState } from 'react';
import {
  Button,
  IconButton,
  Input,
  Modal,
  ModalActions,
  ModalHeaderWithSubtitle,
} from '@/stories';
import { DialogProps } from '@/shared/lib/hooks/useModal';

interface Props extends DialogProps<string[]> {
  createMode: 'below' | 'sub';
  current: string;
}

function NewSubFinancialCategoriesModal({
  createMode,
  current,
  onClose,
  onSubmit,
}: Props) {
  const [categories, setCategories] = useState<string[]>([
    createMode === 'sub' ? 'new_subcategory' : 'new_category',
  ]);

  const onChangeFor =
    (id: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setCategories(categories.map((c, i) => (id === i ? e.target.value : c)));
    };

  const onRemoveFor = (id: number) => () => {
    setCategories(categories.filter((_, i) => i !== id));
  };

  const canSubmit = useMemo(
    () => categories.length > 0 && categories.every((c) => c.length > 0),
    [categories],
  );

  return (
    <Modal
      header={
        <ModalHeaderWithSubtitle
          title={`New ${createMode === 'sub' && 'Sub'} Categories`}
          subtitle={current}
          order="subtitle-title"
        />
      }
      toggle={() => onClose()}
      actions={
        <ModalActions>
          <Button
            onClick={() => onSubmit?.(categories)}
            variant="success"
            fluid
            disabled={!canSubmit}
          >
            {createMode === 'sub'
              ? 'Add Sub Categories'
              : 'Add Categories Below'}
          </Button>
        </ModalActions>
      }
      classes={{
        body: 'bg-white',
      }}
      size="sm"
    >
      <div className="flex flex-col gap-s">
        {categories.map((c, i) => (
          <div key={c} className="flex items-center gap-s">
            <Input
              value={categories[i]}
              onChange={onChangeFor(i)}
              placeholder="Enter Category Name"
              leftIcon="category"
              autoFocus
            />
            <IconButton
              className="bg-white"
              iconName="closeSmall"
              onClick={onRemoveFor(i)}
            />
          </div>
        ))}
        <Button
          variant="primary"
          iconPosition="left"
          iconName="categoryPlus"
          className="blue border-0 bg-blue-light-5"
          onClick={() => setCategories([...categories, ''])}
        >
          {createMode === 'sub'
            ? 'Add Sub Category'
            : 'Add Another Category Below'}
        </Button>
      </div>
    </Modal>
  );
}

export default NewSubFinancialCategoriesModal;
