import FlyBottomPanelContainer from 'bundles/Settings/components/REport/CategoriesList/PathBar/FlyBottomPanelContainer';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import {
  transformTree,
  treeDFS,
  useTreeStateUpdateDropHandler,
} from 'lib/treeHelpers';
import * as React from 'react';
import { useState } from 'react';
import Button from 'stories/Button/Button';
import { Modal, TreeView } from '@/stories';
import SetFinancialCategoryModal from '@/widgets/financialCategory/set/ui/SetFinancialCategoryModal';
import {
  SreUnitType,
  useSreUnitTypeSettingsModal,
} from '@/bundles/Shared/entities/sreUnitType';
import { SearchableTree } from '@/bundles/Settings/components/REport/CategoriesList/SearchableTree/SearchableTree';

interface Props
  extends DialogProps<SreUnitTypeNode[]>,
    Pick<React.ComponentProps<typeof Modal>, 'header'> {
  unitTypes: SreUnitTypeNode[];
  readonly?: boolean;
}

interface SreUnitTypeNode extends SreUnitType {
  key: string;
  name: string;
  children: SreUnitTypeNode[];
  parentId: number | null;
  title: string;
  isNew?: boolean;
  expanded?: boolean;
}

// copy paste from src/features/financialCategory/edit/ui/EditFinancialCategoryModal.tsx
export function UnitTypeManageTreeModal({
  onClose,
  onSubmit,
  unitTypes: initialUnitTypes,
  ...props
}: Props) {
  const [_, setIsSubmitting] = useState(false);
  const { openModal } = useModal();
  const openSreUnitTypeSettingsModal = useSreUnitTypeSettingsModal();
  const [unitTypes, setUnitTypes] = useState<SreUnitTypeNode[]>(
    initialUnitTypes.map((c) =>
      transformTree(
        c,
        (cx) =>
          ({
            ...cx,
            name: cx.name,
            title: cx.name,
            expanded: true,
            key: cx.id.toString(),
          }) as SreUnitTypeNode,
      ),
    ),
  );
  const handleDrop = useTreeStateUpdateDropHandler({
    tree: unitTypes,
    setTree: setUnitTypes,
  });
  const handleEdit = async (unitType: SreUnitTypeNode) => {
    const res = await openSreUnitTypeSettingsModal(unitType);
    if (!res) {
      return;
    }
    const categoryToUpdatePath = treeDFS(
      unitTypes,
      (node) => node.id === unitType?.id,
    );
    const categoryToUpdate = categoryToUpdatePath?.at(-1);

    if (categoryToUpdate) {
      categoryToUpdate.name = res.name;
      categoryToUpdate.label = res.label;
    }

    setUnitTypes([...unitTypes]);
  };

  const handleChangeCategoryParent = async (unitType: SreUnitTypeNode) => {
    const categoryToUpdatePath = treeDFS(
      unitTypes,
      (node) => node.id === unitType.id,
    );
    if (categoryToUpdatePath == null) {
      return;
    }
    const categoryToUpdate = categoryToUpdatePath.at(-1)!;
    const oldCategoryParent = categoryToUpdatePath.at(-2);

    const newCategoryParent = await openModal(SetFinancialCategoryModal, {
      categories: unitTypes,
      selectedCategory: unitType,
      everyNodeWithCheckbox: true,
      header: 'Change Parent Unit Type',
    });
    if (!newCategoryParent) {
      return;
    }

    const newCategoryParentPath = treeDFS(
      unitTypes,
      (node) => node.id === newCategoryParent.id,
    );
    const newCategoryParentToUpdate = newCategoryParentPath!.at(-1)!;
    newCategoryParentToUpdate.children?.push(categoryToUpdate);

    if (oldCategoryParent) {
      oldCategoryParent.children = oldCategoryParent.children?.filter(
        (c) => c.id !== categoryToUpdate.id,
      );
      setUnitTypes([...unitTypes]);
    } else {
      // if no parent, then it's a root category, remove it from categories
      setUnitTypes([...unitTypes].filter((c) => c.id !== categoryToUpdate.id));
    }
  };

  const handleCreateCategory = async (
    unitType: SreUnitTypeNode,
    createMode: 'below' | 'sub',
  ) => {
    const newUnitType = await openSreUnitTypeSettingsModal({
      name: 'new_unit_type',
      label: 'New Unit Type',
      id: null,
    });
    if (newUnitType == null) {
      return;
    }
    const newUnitTypeNode = {
      ...newUnitType,
      title: newUnitType.label,
    } as SreUnitTypeNode;
    const categoryToUpdatePath = treeDFS(unitTypes, (node) =>
      createMode === 'sub'
        ? node.id === unitType.id
        : node.children?.some((cx) => cx.id === unitType.id),
    );
    const categoryToUpdate = categoryToUpdatePath?.at(-1);

    if (categoryToUpdate == null) {
      setUnitTypes([...unitTypes, newUnitTypeNode]);
    } else {
      categoryToUpdate.children = [
        ...(categoryToUpdate.children ?? []),
        newUnitTypeNode,
      ];

      setUnitTypes([...unitTypes]);
    }
  };

  const handleCreateNearby = (unitType: SreUnitTypeNode) => {
    handleCreateCategory(unitType, 'below');
  };

  const handleCreateSub = (unitType: SreUnitTypeNode) => {
    handleCreateCategory(unitType, 'sub');
  };

  const handleRemove = (unitType: SreUnitTypeNode) => {
    const categoryToUpdatePath = treeDFS(
      unitTypes,
      (node) => node.id === unitType.id,
    );
    const categoryToUpdate = categoryToUpdatePath?.at(-2);

    if (categoryToUpdate == null) {
      setUnitTypes(unitTypes.filter((c) => c.id !== unitType.id));
    } else {
      categoryToUpdate.children = categoryToUpdate.children?.filter(
        (c) => c.id !== unitType.id,
      );
      setUnitTypes([...unitTypes]);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await onSubmit?.(unitTypes);
    setIsSubmitting(false);
  };

  const actions = [
    {
      text: 'Rename',
      handler: handleEdit,
    },
    {
      text: 'New Unit Type Below',
      handler: handleCreateNearby,
    },
    {
      text: 'Create Sub Unit Type',
      handler: handleCreateSub,
    },
    {
      text: 'Change Parent Unit Type',
      handler: handleChangeCategoryParent,
    },
    {
      text: 'Remove',
      handler: handleRemove,
    },
  ];

  return (
    <Modal
      size="huge"
      header="Manage Unit Types"
      bodyPadding="0"
      toggle={onClose}
      {...props}
    >
      <div className="grid h-full w-full grid-cols-[450px_1fr]">
        <SearchableTree
          data={unitTypes}
          treeProps={{ draggable: true, onDrop: handleDrop }}
          readonly
        />
        <TreeView
          data={unitTypes}
          actions={actions}
          showMultipleRootsParent
          multipleRootsParentName="Unit Types"
        />
      </div>

      <FlyBottomPanelContainer>
        <Button variant="success" size="s" onClick={handleSubmit}>
          Save
        </Button>
      </FlyBottomPanelContainer>
    </Modal>
  );
}
