import { settingsReportOperationalUnitTypesApi } from '@/entities/report/unitTypes/api/settingsReportOperationalUnitTypesGeneratedApi';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';

const unitTypesToastr = new EntityToastrCreator('Unit Type');
const unitTypesTreeToastr = new EntityToastrCreator('Unit Type Tree');

export const settingsReportOperationalUnitTypesEnhancedApi =
  settingsReportOperationalUnitTypesApi.enhanceEndpoints({
    addTagTypes: ['SourceUnitTypes', 'SreUnitTypes', 'SreUnitTypesTree'],
    endpoints: {
      getApiSettingsReportRentRollSourceUnitTypes: {
        providesTags: ['SourceUnitTypes'],
      },
      putApiSettingsReportRentRollSourceUnitTypesSetUnitType: {
        transformResponse: (res) => {
          toastr.success(unitTypesToastr.update().toString());
          return res;
        },
        invalidatesTags: ['SourceUnitTypes'],
      },

      getApiSettingsReportRentRollUnitTypes: {
        providesTags: ['SreUnitTypes'],
      },
      deleteApiSettingsReportRentRollUnitTypesById: {
        invalidatesTags: ['SreUnitTypes', 'SourceUnitTypes'],
        transformResponse: (res) => {
          toastr.success(unitTypesToastr.remove().toString());
          return res;
        },
      },
      putApiSettingsReportRentRollUnitTypesById: {
        invalidatesTags: ['SreUnitTypes', 'SourceUnitTypes'],
        transformResponse: (res) => {
          toastr.success(unitTypesToastr.update().toString());
          return res;
        },
      },
      postApiSettingsReportRentRollUnitTypes: {
        invalidatesTags: ['SreUnitTypes', 'SourceUnitTypes'],
        transformResponse: (res) => {
          toastr.success(unitTypesToastr.create().toString());
          return res;
        },
      },
      getApiSettingsReportRentRollUnitTypesTree: {
        providesTags: ['SreUnitTypesTree'],
      },
      putApiSettingsReportRentRollUnitTypesTreeUpdate: {
        invalidatesTags: [
          'SreUnitTypes',
          'SourceUnitTypes',
          'SreUnitTypesTree',
        ],
        transformResponse: (res) => {
          toastr.success(unitTypesTreeToastr.update().toString());
          return res;
        },
      },
    },
  });

export const {
  // Source Unit Types
  useGetApiSettingsReportRentRollSourceUnitTypesQuery,
  usePutApiSettingsReportRentRollSourceUnitTypesSetUnitTypeMutation,

  // SRE Unit Types
  useGetApiSettingsReportRentRollUnitTypesQuery,
  usePutApiSettingsReportRentRollUnitTypesByIdMutation,
  useDeleteApiSettingsReportRentRollUnitTypesByIdMutation,
  usePostApiSettingsReportRentRollUnitTypesMutation,

  // import/export doesn't work properly with rtk-query approach
  // useGetApiSettingsReportRentRollSourceUnitTypesExportCsvQuery,
  // usePostApiSettingsReportRentRollSourceUnitTypesImportMutation,

  // Unit Types Tree
  useGetApiSettingsReportRentRollUnitTypesTreeQuery,
  useLazyGetApiSettingsReportRentRollUnitTypesTreeQuery,
  usePutApiSettingsReportRentRollUnitTypesTreeUpdateMutation,
} = settingsReportOperationalUnitTypesEnhancedApi;
