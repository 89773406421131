import { CSSProperties, FC } from 'react';
import Fractions from 'stories/ValueFormatters/components/Fractions';
import {
  GetClassesObject,
  GetStylesObject,
  NumberFormatterProps,
} from 'stories/ValueFormatters/types';
import {
  getFormattedValueParts,
  getMergedClasses,
  getMergedStyles,
} from 'stories/ValueFormatters/utils';
import {
  OUTPUT_DEFAULT_CLASSES,
  OutputFormatter,
  OutputFormatterClassKeys,
} from './OutputFormatter';
import SignWrapper from 'stories/ValueFormatters/components/SignWrapper';
import { DEFAULT_NUMBER_FALLBACK } from '@/shared/lib/formatting/number';

export type NumberFormatterClasses = GetClassesObject<
  OutputFormatterClassKeys | 'fractions'
>;
export type NumberFormatterStyles = GetStylesObject<
  OutputFormatterClassKeys | 'fractions' | 'brackets'
>;

export type RawNumberFormatterProps = NumberFormatterProps<
  NumberFormatterClasses,
  NumberFormatterStyles
>;

const DEFAULT_CLASSES = {
  ...OUTPUT_DEFAULT_CLASSES,
  fractions: 'text-neutral-850',
} as const satisfies NumberFormatterClasses;

export const NumberFormatter: FC<RawNumberFormatterProps> = ({
  value,
  hideFractions,
  toLocalStringOptions,
  fallbackValue = DEFAULT_NUMBER_FALLBACK,
  ...props
}) => {
  if (value === null || value === undefined || isNaN(value))
    return <span>{fallbackValue}</span>;

  const formattedValue = Math.abs(value).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...toLocalStringOptions,
  });

  const { displayValue } = getFormattedValueParts(formattedValue);

  const classes = getMergedClasses({
    classes: props.classes,
    allParts: props.classes?.allParts,
    defaultClasses: DEFAULT_CLASSES,
    value,
  });

  const styles = getMergedStyles({
    ...(props.styles ?? {}),
    // @ts-expect-error
    partNames: [
      'brackets',
      'endSymbol',
      'fractions',
      'partNames',
      'startSymbol',
      'value',
      'wrapper',
    ] as const,
  });

  return (
    <SignWrapper
      negative={value < 0}
      className={classes?.brackets}
      styles={styles as { brackets: CSSProperties }}
      variant="brackets"
    >
      <OutputFormatter
        classes={{
          ...classes,
          value: value === 0 ? 'text-neutral-500' : classes?.value,
        }}
        styles={styles}
      >
        {displayValue}
        {!hideFractions && value !== 0 && (
          <Fractions
            formattedValue={formattedValue}
            className={classes.fractions}
            style={styles.fractions}
          />
        )}
      </OutputFormatter>
    </SignWrapper>
  );
};
