import { useLoadedInvestmentObject } from '@/bundles/REturn/hooks/useLoadedInvestmentObject';
import {
  usePutApiCapitalInvestmentObjectsByIdMutation,
  usePutApiCapitalInvestmentObjectsByInvestmentObjectIdAccruedStatusesAndIdMutation,
} from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import { cn } from '@/shared/lib/css/cn';
import { RadioGroup } from '@/stories/RadioButton/RadioGroup';
import {
  CAPITAL_ACCRUED_SETTINGS_DESCRIPTION_JSX,
  CAPITAL_ACCRUED_SETTINGS_OPTIONS,
  CAPITAL_SETTINGS_DESCRIPTION_JSX,
} from 'bundles/REturn/components/Ownership/ownershipsHeader/dumbJSX';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';
import { IconButton, Popover, Tooltip, Tumbler } from 'stories/index';

function CapitalSettingsDropdown() {
  const { data: capitalInvestmentObject } = useLoadedInvestmentObject();
  const [updateInvestmentObject, { isLoading: isUpdatingInvestmentObject }] =
    usePutApiCapitalInvestmentObjectsByIdMutation();
  const [updateAccruedStatus] =
    usePutApiCapitalInvestmentObjectsByInvestmentObjectIdAccruedStatusesAndIdMutation();

  const { fromSourceEnabled, canDisableFromSource, accruedStatus } =
    capitalInvestmentObject;

  const handleChange = () => {
    updateInvestmentObject({
      id: capitalInvestmentObject.id,
      body: {
        from_source_enabled: !fromSourceEnabled,
      },
    });
  };

  const template = (
    <div>
      <div className="grid grid-cols-[208px_148px] bg-light-5">
        <div className="flex-1 bg-white p-tw-4">
          {CAPITAL_SETTINGS_DESCRIPTION_JSX}
        </div>
        <div className="flex flex-1 flex-col gap-tw-4 bg-light p-tw-4">
          <div className="flex flex-col gap-m">
            <span className="light-60 secondary-regular ">Settings</span>
            <div className="flex flex-col gap-tw-2">
              <Tooltip
                mainText="Cannot be disabled as linked transactions exist."
                disabled={canDisableFromSource}
              >
                <Tumbler
                  disabled={isUpdatingInvestmentObject || !canDisableFromSource}
                  checked={fromSourceEnabled}
                  classes={{ text: 'label-semibold' }}
                  onChange={handleChange}
                >
                  From Source
                </Tumbler>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <hr className="m-0" />
      <div className="bg-light-5">
        <div className="flex-1 bg-white p-tw-4">
          {CAPITAL_ACCRUED_SETTINGS_DESCRIPTION_JSX}

          <RadioGroup
            className="mt-tw-2"
            options={CAPITAL_ACCRUED_SETTINGS_OPTIONS}
            value={accruedStatus.status}
            onChange={({ value }) => {
              updateAccruedStatus({
                investmentObjectId: capitalInvestmentObject.id,
                id: accruedStatus.id,
                body: {
                  status: value,
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <Popover
      trigger="click"
      className="overflow-hidden p-0"
      placement="bottom-start"
      hiddenArrow
      offset={DEFAULT_DROPDOWN_OFFSET}
      template={template}
      maxWidth={480}
    >
      {({ triggered }) => (
        <IconButton
          size="l"
          variant="secondary"
          iconName="settings"
          className={cn(triggered && 'border-neutral-200 bg-neutral-200')}
        />
      )}
    </Popover>
  );
}

export default CapitalSettingsDropdown;
