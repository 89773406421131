import React from 'react';
import {
  NavLink,
  NavLinkList,
  NavSection,
  NavSectionHeader,
  ProjectedContingencyCard,
  TimingImpactCard,
} from 'bundles/REconcile/components/development/home/dashboard/Wrappers';
import {
  generatePath,
  ROUTES_ROOT,
  TRouteParams,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { useGetProjectQuery } from 'bundles/REconcile/components/development/api/projects';
import { TitledCard } from 'stories/TitledCard/TitledCard';
import { CurrencyFormatter } from 'stories/ValueFormatters/CurrencyFormatter';

const LE_ROUTES = ROUTES_ROOT.reconcile.development.legalEntity;

function ReconcileDevelopmentDashboardChangeManagementNav() {
  const { projectId } = useQueryParams<TRouteParams['RECONCILE_DEVELOPMENT']>();
  const { data } = useGetProjectQuery(projectId!);

  const legalEntityCode = data!.legalEntity.code;
  const { changeManagement } = data!;

  const links = [
    {
      to: generatePath(LE_ROUTES.changeEventLogs.fullPath, {
        legalEntityCode,
      }),
      title: 'Change Events',
    },
    {
      to: generatePath(LE_ROUTES.changeOrderLogs.fullPath, {
        legalEntityCode,
      }),
      title: 'Change Orders',
    },
    {
      to: generatePath(LE_ROUTES.reallocations.fullPath, {
        legalEntityCode,
      }),
      title: 'Reallocations',
    },
  ];

  return (
    <NavSection>
      <NavSectionHeader title="Change Management">
        <NavLinkList>
          {links.map((link) => (
            <NavLink key={link.to} to={link.to}>
              {link.title}
            </NavLink>
          ))}
        </NavLinkList>
      </NavSectionHeader>
      <div className="grid grid-cols-3 grid-rows-[minmax(140px,auto)] gap-tw-4">
        <TitledCard title="Current Contract Sum">
          <CurrencyFormatter
            classes={{ wrapper: 'header3-bold', value: 'text-dark-60' }}
            abbreviate
            value={changeManagement.currentContractSum}
          />
        </TitledCard>
        <TimingImpactCard
          timingImpact={changeManagement.timingImpact}
          legalEntityCode={legalEntityCode}
        />
        <ProjectedContingencyCard changeManagement={changeManagement} />
      </div>
    </NavSection>
  );
}

export default ReconcileDevelopmentDashboardChangeManagementNav;
