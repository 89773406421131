import { TextTableVizConfigRow } from './model';
import { useCallback } from 'react';
import { Draft, produce } from 'immer';
import { KpiTableWidgetConfig } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { WidgetConfigProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import {
  TableVizConfig,
  TableVizConfigColumn,
  TableVizConfigTextDisplayStrategy,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';

import { ColumnSettingsForm } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import { ValueDisplayOptions } from '@/shared/lib/formatting/displayOptions';
import { convertPercentageToDecimal } from '@/shared/lib/converters';
import { UNGROUPED_GROUP_KEY } from '@/bundles/Shared/entities/dashboard';

export const pinColumn =
  (columnId: string) =>
  (draft: Draft<Pick<KpiTableWidgetConfig, 'viz_config'>>) => {
    const { columns } = draft.viz_config;
    const columnSettings = columns.find((c) => c.col_id === columnId)!;
    columnSettings.column_group_show = columnSettings.column_group_show
      ? undefined
      : 'open';
  };

export const usePinColumn = <
  T extends {
    viz_config: TableVizConfig;
  },
>({
  onSettingsChange,
  settings,
}: WidgetConfigProps<T>) => {
  return useCallback(
    (colId: string) => {
      onSettingsChange?.(produce(settings!, pinColumn(colId)));
    },
    [settings, onSettingsChange],
  );
};

export const transformHighlightingRulesBackgroundToDto = (
  highlighting_rules: ColumnSettingsForm['highlighting_rules'],
): TableVizConfigColumn['background'] => {
  if (!highlighting_rules || highlighting_rules.type === 'font') {
    return undefined;
  }
  return {
    type: 'gradient',
    ignore_zeros: highlighting_rules.gradient_ignore_zeros ?? undefined,
    threshold: highlighting_rules.gradient_thresholds!.map((t) => ({
      ...t,
      value:
        t.type === 'percentage' ? convertPercentageToDecimal(t.value) : t.value,
    })),
  };
};

const transformTextDisplayStrategyToDto = (
  textDisplayStrategy: TableVizConfigTextDisplayStrategy | undefined,
): TextTableVizConfigRow['cell_renderer'] | undefined => {
  return (
    textDisplayStrategy && {
      type: 'text',
      params: {
        variant: textDisplayStrategy,
      },
    }
  );
};

export const upsertColumnSettings = <
  T extends {
    viz_config: TableVizConfig;
  },
>(
  column: ColumnSettingsForm,
  draft: Draft<T>,
  {
    groupId,
    key,
  }: {
    key: string;
    groupId?: string;
  },
) => {
  let columnIndex = draft.viz_config.columns.findIndex((c) => c.key === key);
  if (columnIndex === -1) {
    columnIndex = draft.viz_config.columns.length;
  }
  draft.viz_config.columns[columnIndex] = produce(
    draft.viz_config.columns[columnIndex] ?? {
      col_id: key,
      key,
      order: columnIndex,
      column_group_show: 'open',
      group_id: null,
    },
    (draftCol) => {
      draftCol.initial_sort = column.sort ?? undefined;
      draftCol.value_display_options = column.value_display_options;
      draftCol.header = column.header;
      draftCol.font_weight = column.font_weight;
      draftCol.border_color = column.border_color;
      draftCol.background = transformHighlightingRulesBackgroundToDto(
        column.highlighting_rules,
      );
      draftCol.comparison =
        column.hide_negative_values ||
        column.comparison ||
        column.highlighting_rules?.color_rule
          ? {
              rule:
                column.comparison?.rule ??
                column.highlighting_rules?.color_rule,
              to_col_id: column.comparison?.compareTo,
              hide_negative_values: column.hide_negative_values,
            }
          : undefined;
    },
  );
  if (groupId && groupId !== UNGROUPED_GROUP_KEY) {
    draft.viz_config.columns[columnIndex].group_id = groupId;
  }
};

export const upsertRowSettings = <
  T extends {
    viz_config: TableVizConfig;
  },
>(
  row: {
    value_display_options: ValueDisplayOptions;
    font_weight: string;
    highlighting_rules: ColumnSettingsForm['highlighting_rules'];
    text_display_strategy: TableVizConfigTextDisplayStrategy;
  },
  draft: Draft<T>,
  {
    key,
  }: {
    key: string;
  },
) => {
  let rowIndex = draft.viz_config.rows.findIndex((r) => r.key === key);
  if (rowIndex === -1) {
    rowIndex = draft.viz_config.rows.length;
  }
  draft.viz_config.rows[rowIndex] = produce(
    draft.viz_config.rows[rowIndex] ?? {
      key,
      order: rowIndex,
    },
    (draftRow) => {
      draftRow.value_display_options = row.value_display_options;
      draftRow.font_weight = row.font_weight;
      draftRow.comparison = row.highlighting_rules?.color_rule
        ? {
            rule: row.highlighting_rules?.color_rule,
          }
        : undefined;
      draftRow.background = transformHighlightingRulesBackgroundToDto(
        row.highlighting_rules,
      );
      draftRow.cell_renderer = transformTextDisplayStrategyToDto(
        row.text_display_strategy,
      );
    },
  );
};
