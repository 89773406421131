import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { IconsId } from 'types/sre-icons';
import { Checkbox, Icon, Tooltip } from 'stories';
import { DashboardIcon } from 'bundles/Shared/entities/dashboard';

interface Props
  extends Pick<React.HTMLAttributes<HTMLDivElement>, 'onClick' | 'className'> {
  label: React.ReactNode;
  iconName: IconsId;
  selected?: boolean;
  withCheckbox?: boolean;
  disabled?: boolean;
  tooltipProps?: Pick<React.ComponentProps<typeof Tooltip>, 'mainText'>;
  classes?: {
    label?: string;
  };
}

export function DashboardSelectionItem({
  label,
  iconName,
  selected,
  disabled,
  withCheckbox,
  tooltipProps,
  className,
  classes,
  onClick,
}: Props) {
  return (
    <div
      onClick={disabled ? undefined : onClick}
      className={cn(
        'group relative flex h-[144px] cursor-pointer items-center justify-center !rounded-lg bg-neutral-050 px-tw-4',
        selected && 'bg-info-055',
        disabled && 'cursor-default bg-neutral-100',
        !selected && !disabled && 'hover:bg-neutral-100',
        className,
      )}
    >
      {tooltipProps && (
        <Tooltip
          classes={{
            spanContainer: 'absolute top-tw-4 right-tw-4',
          }}
          {...tooltipProps}
        >
          <Icon className={cn(selected && 'text-info-020')} iconName="info" />
        </Tooltip>
      )}
      {withCheckbox && (
        <Checkbox
          inverted={selected}
          labelClassName="absolute top-tw-4 left-tw-4"
          disabled={disabled}
          checked={selected}
        />
      )}
      <div className="flex flex-col items-center gap-tw-2">
        {[
          'eagleEyeDashboardGreyscale',
          'objectLevelDashboardGreyscale',
          'compare',
        ].includes(iconName) ? (
          <DashboardIcon
            className={cn(
              'flex-2 h-[48px] w-[48px]',
              selected && 'fill-info-000',
              disabled && 'fill-neutral-400',
            )}
            iconName={iconName}
          />
        ) : (
          <Icon
            className={cn(
              'flex-2 text-[48px]',
              selected && 'text-neutral-000',
              disabled && 'text-neutral-400',
            )}
            iconName={iconName}
          />
        )}
        <span
          className={cn(
            'header6-bold text-center text-neutral-900',
            selected && 'text-neutral-000',
            disabled && 'text-neutral-550',
            classes?.label,
          )}
        >
          {label}
        </span>
      </div>
    </div>
  );
}
