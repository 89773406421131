import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import {
  DashboardContext,
  ReportDashboardType,
} from '@/bundles/Shared/entities/dashboard';
import { EntityTagField } from '@/bundles/Shared/entities/tag';
import { ReportDashboardAssetFilter } from '@/bundles/Shared/features/dashboard/object/filter/byAsset/ui/ReportDashboardAssetFilter';
import { ReportDashboardDateFilterBlock } from '@/bundles/Shared/features/dashboard/object/filter/byDate';
import { ReportBuilderTemplateContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import { BUILDER_UI_WIDGET_CARD_WIDGET_TYPE_ICON_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { AvailableWidgetsModal } from '@/bundles/Shared/widgets/dashboard/widgetsBar/ui/AvailableWidgetsModal';
import type { AssetsReportBuilderAssetDto } from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import {
  useDeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation,
  useDeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdMutation,
  useGetApiSettingsReportBuilderGotenbergTemplatesByIdQuery,
  useGetApiSettingsReportBuilderTemplatesMetaQuery,
  usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsMutation,
  usePutApiSettingsReportBuilderTemplatesByIdMutation,
  usePutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation,
  usePutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdMutation,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type {
  PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdApiArg,
  RecentRentrollWidgetConfigDto,
  ReportBuilderTemplateDto,
  ReportBuilderTemplateGroupWidgetSectionDto,
  ReportBuilderTemplateTagDto,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import {
  isReportBuilderTempalteWidgetEditable,
  isReportBuilderTempalteWidgetIncludeSourceUnitTypesEditable,
} from '@/entities/report/reportBuilder/lib';
import {
  REPORT_BUILDER_TEMPLATE_SETTINGS_BASIC_SCHEMA,
  type ReportBuilderTemplateSettingsBasicSchema,
} from '@/entities/report/reportBuilder/lib/reportBuilderTemplateSettingsBasic';
import { useGetApiReportBuilderTemplatePreviewPdfQuery } from '@/entities/report/reportBuilder/lib/useGetApiReportBuilderTemplatePreviewPdfQuery';
import { ExpandCollapseRowsIconButton } from '@/entities/report/reportBuilder/ui/ExpandCollapseRowsIconButton';
import { ReportBuilderTemplateInUseBanner } from '@/entities/report/reportBuilder/ui/ReportBuilderTemplateInUseBanner';
import { ReportBuilderTemplateWidgetWidthDropdown } from '@/entities/report/reportBuilder/ui/ReportBuilderTemplateWidgetWidthDropdown';
import { TemplateGroupHeaderDropdown } from '@/entities/report/reportBuilder/ui/TemplateGroupHeaderDropdown';
import { TemplateGroupPageOrientationDropdown } from '@/entities/report/reportBuilder/ui/TemplateGroupPageOrientationDropdown';
import { useDeleteReportBuilderTemplate } from '@/features/report/reportBuilderTemplate/deleteTemplate/lib';
import { currentUserAllowedToSeeNavigateToReportBuilderTemplateViewPage } from '@/pages/reportBuilderTemplate/[reportBuilderTemplateId]/permissions';
import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import {
  useReportBuilderTemplateRedirectToFirstAssetEffect,
  useReportBuilderTemplateScreenParams,
} from '@/shared/lib/hooks/navigation/dashboardsNavitation';
import { useAssetIdSearchParam } from '@/shared/lib/hooks/navigation/useAssetIdSearchParam';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { sortByPosition } from '@/shared/lib/listHelpers/toSorted';
import { AssetsInUsePopover } from '@/shared/ui/AssetsInUsePopover';
import { BuilderUI } from '@/shared/ui/BuilderUI';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { SaveStatus } from '@/shared/ui/SaveStatus';
import { Button, Icon, IconButton, Input } from '@/stories';
import { TumblerController } from '@/stories/Tumbler/Tumbler';
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DropResult,
} from '@hello-pangea/dnd';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, type RouteComponentProps } from '@reach/router';
import { ReactComponent as LandscapeSvg } from 'fonts/sre-icons/Icons/landscape.svg';
import { ReactComponent as PortraitSvg } from 'fonts/sre-icons/Icons/portrait.svg';
import { startCase } from 'lodash-es';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

const Builder = ({
  template,
  meta,
}: {
  template: ReportBuilderTemplateDto;
  meta: {
    tags: ReportBuilderTemplateTagDto[];
    assets: Pick<AssetsReportBuilderAssetDto, 'id' | 'name'>[];
  };
}) => {
  const { navigateBack } = useNavigateBack({
    fallbackUrl: ROUTES_ROOT.settings.report.reportTemplates.fullPath,
  });
  const { confirm, openModal, modalList } = useModal();
  const navigate = useNavigate();
  const savingUpdates = useAppSelector(
    (s) => s.reportBuilderTemplate.pendingRequestIds.length > 0,
  );
  const [updateTemplate, updateOptions] =
    usePutApiSettingsReportBuilderTemplatesByIdMutation();
  const assetId = useAssetIdSearchParam();

  useReportBuilderTemplateRedirectToFirstAssetEffect();

  const methods = useForm<ReportBuilderTemplateSettingsBasicSchema>({
    resolver: yupResolver(REPORT_BUILDER_TEMPLATE_SETTINGS_BASIC_SCHEMA),
    defaultValues: {
      name: template.name,
      tags: template.templateTags,
      displayDate: template.displayDate,
      displayHeader: template.displayHeader,
      displayPageNumber: template.displayPageNumber,
    },
  });
  const { register, control, watch, formState } = methods;
  const { isDirty } = formState;

  const [deleteTemplate, deleteOptions] = useDeleteReportBuilderTemplate();

  const [createGroup, createGroupOptions] =
    usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsMutation();

  const [deleteGroup, deleteGroupOptions] =
    useDeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdMutation();

  const [updateWidget, updateWidgetOptions] =
    usePutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation();

  const [deleteWidget, deleteWidgetOptions] =
    useDeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation();

  const [updateGroup, updateGroupOptions] =
    usePutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdMutation();

  const templateStateDate = useAppSelector(
    (state) => state.reportBuilderTemplate.entities[template.id]?.date,
  );

  const handleDeleteWidget = async (
    widgetId: ReportBuilderTemplateDto['groups'][number]['widgetSections'][number]['id'],
    groupId: ReportBuilderTemplateDto['groups'][number]['id'],
  ) => {
    const bool = await confirm({
      title: 'Are you sure you want to delete a widget from this template',
    });
    if (!bool) return;

    deleteWidget({
      id: widgetId,
      groupId,
      templateId: template.id,
    });
  };

  const handleDeleteGroup = async (
    groupId: ReportBuilderTemplateDto['groups'][number]['id'],
  ) => {
    const bool = await confirm({
      title: 'Are you sure you want to delete a group from this template',
    });
    if (!bool) return;

    deleteGroup({
      id: groupId,
      templateId: template.id,
    });
  };

  const handleAddWidgetToGroup = (groupId: string) => {
    openModal(
      AvailableWidgetsModal,
      {
        type: ReportDashboardType.REPORT_BUILDER_TEMPLATE,
        reportBuilderTemplateWidgetGroupId: groupId,
        layout: {
          lg: [],
        },
      },
      {
        topLevelRender: false,
      },
    );
  };

  const handleCreateGroup = () => {
    createGroup({
      templateId: template.id,
      body: {
        page_orientation: 'portrait',
      },
    });
  };

  const handleDeleteReportAndNavigateBack = async () => {
    const response = await deleteTemplate(template.id);

    if ((response != null && 'error' in response) || response == null) {
      return;
    }

    navigateBack();
  };

  const handleUpdateGroup = (
    args: Pick<
      PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdApiArg,
      'id'
    > &
      Pick<
        PutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdApiArg['body'],
        'page_orientation' | 'position' | 'display_header'
      >,
  ) => {
    updateGroup({
      ...args,
      id: args.id,
      templateId: template.id,
      body: {
        position: args.position,
        page_orientation: args.page_orientation,
        display_header: args.display_header,
      },
    });
  };

  const handleSaveUpdates = () => {
    updateTemplate({
      body: {
        name: watch('name').trim(),
        tags: watch('tags')?.map((tag) => tag.name) ?? [],
        display_date: watch('displayDate'),
        display_header: watch('displayHeader'),
        display_page_number: watch('displayPageNumber'),
      },
      id: template.id,
    });
  };

  const handleNaviageToViewPage = () => {
    navigate(
      generateUrl(
        ROUTES_ROOT.reportBuilderTemplates.reportBuilderTemplate.view.fullPath,
        {
          pathParams: {
            templateId: template.id,
          },
          queryParams: {
            assetId,
          },
        },
      ),
    );
  };

  const navigateToWidget = (widgetId: string) => {
    navigate(
      generateUrl(
        ROUTES_ROOT.reportBuilderTemplates.reportBuilderTemplate.widgets.widget
          .fullPath,
        {
          pathParams: {
            templateId: template.id,
            widgetId,
          },
          queryParams: {
            assetId,
          },
        },
      ),
    );
  };

  const widgetsMap = useMemo(() => {
    return new Map(
      template.groups.flatMap((g) =>
        g.widgetSections.map((w) => [w.id, { ...w, groupId: g.id }]),
      ),
    );
  }, [template.groups]);

  const handleOnDragEnd = (result: DropResult) => {
    if (result.destination == null) return;

    if (result.type !== 'groups') {
      const widgetId = result.draggableId;
      const widgetData = widgetsMap.get(widgetId);
      const newPosition = result.destination.index;

      const newGroupId =
        result.source.droppableId !== result.destination.droppableId
          ? result.destination.droppableId
          : undefined;

      if (widgetData == null) return;

      updateWidget({
        id: widgetData.id,
        groupId: widgetData.groupId,
        templateId: template.id,
        body: {
          position: newPosition,
          destination_group_id: newGroupId,
        },
      });
      return;
    }
    const currentGroup = template.groups.find(
      (g) => g.id === result.draggableId,
    );
    if (currentGroup == null) return;

    handleUpdateGroup({
      id: currentGroup.id,
      position: result.destination.index,
    });
  };

  const handleWidgetWidthChange = (
    widget: ReportBuilderTemplateGroupWidgetSectionDto & { groupId: string },
    newWidth: number,
  ) => {
    updateWidget({
      id: widget.id,
      groupId: widget.groupId,
      templateId: template.id,
      body: {
        width_percent: newWidth,
      },
    });
  };
  const handleWidgetIncludeSourceUnitTypesChange = (
    widget: ReportBuilderTemplateGroupWidgetSectionDto & { groupId: string },
    include_source_unit_types: boolean,
  ) => {
    updateWidget({
      id: widget.id,
      groupId: widget.groupId,
      templateId: template.id,
      body: {
        config: {
          include_source_unit_types,
        },
      },
    });
  };

  const { dataObjectURL: pdfSrc, isLoading: isPDFLoading } =
    useGetApiReportBuilderTemplatePreviewPdfQuery();

  const isLoading =
    deleteOptions.isLoading ||
    updateOptions.isLoading ||
    createGroupOptions.isLoading ||
    deleteWidgetOptions.isLoading ||
    updateGroupOptions.isLoading ||
    updateWidgetOptions.isLoading ||
    deleteGroupOptions.isLoading;

  const showInUseBanner = (template.assets?.length ?? 0) > 0;

  return (
    <BuilderUI>
      {modalList}
      <BuilderUI.Settings>
        <BuilderUI.Settings.Header>
          <BuilderUI.Settings.Header.BackIconButton
            disabled={isLoading}
            onClick={navigateBack}
          />
          <BuilderUI.Settings.Header.TitleInfo
            subtitle="Builders"
            title={template.name}
          >
            {template.assets.length > 0 && (
              <AssetsInUsePopover assets={template.assets} />
            )}
          </BuilderUI.Settings.Header.TitleInfo>
          <BuilderUI.Settings.Header.Actions>
            <BuilderUI.Settings.Header.DeleteIconButton
              onClick={handleDeleteReportAndNavigateBack}
              disabled={isLoading}
              isLoading={deleteOptions.isLoading}
            />
            {currentUserAllowedToSeeNavigateToReportBuilderTemplateViewPage() && (
              <BuilderUI.Settings.Header.ViewIconButton
                onClick={handleNaviageToViewPage}
              />
            )}
            <SaveStatus saving={savingUpdates} />
          </BuilderUI.Settings.Header.Actions>
        </BuilderUI.Settings.Header>
        {showInUseBanner && <ReportBuilderTemplateInUseBanner />}
        <BuilderUI.Settings.Main>
          <BuilderUI.Settings.Main.Basic>
            <BuilderUI.Settings.Main.Basic.Field required label="Template Name">
              <Input
                classes={{ backLayer: 'bg-transparent' }}
                {...register('name')}
              />
            </BuilderUI.Settings.Main.Basic.Field>
            <EntityTagField
              options={meta.tags}
              className="px-tw-1"
              control={control}
              name="tags"
            />
            <BuilderUI.Settings.Main.Basic.Hr />
            <BuilderUI.Settings.Main.Basic.Field
              className="flex-row justify-between"
              label="Header (Default)"
            >
              <TumblerController control={control} name="displayHeader" />
            </BuilderUI.Settings.Main.Basic.Field>
            <BuilderUI.Settings.Main.Basic.Hr className="-mt-tw-2" />
            <BuilderUI.Settings.Main.Basic.Field
              className="flex-row justify-between"
              label="Page numbers"
            >
              <TumblerController control={control} name="displayPageNumber" />
            </BuilderUI.Settings.Main.Basic.Field>
            <BuilderUI.Settings.Main.Basic.Hr className="-mt-tw-2" />
            <BuilderUI.Settings.Main.Basic.Field
              className="flex-row justify-between"
              label="Date"
            >
              <TumblerController control={control} name="displayDate" />
            </BuilderUI.Settings.Main.Basic.Field>
          </BuilderUI.Settings.Main.Basic>

          <BuilderUI.Settings.WidgetLayout>
            <BuilderUI.Settings.WidgetLayout.Header title="Widgets">
              <Button
                size="xs"
                loading={createGroupOptions.isLoading}
                disabled={isLoading}
                onClick={handleCreateGroup}
              >
                Add Group
              </Button>
            </BuilderUI.Settings.WidgetLayout.Header>

            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable type="groups" droppableId="droppableId">
                {(rootProvided) => (
                  <div
                    ref={rootProvided.innerRef}
                    {...rootProvided.droppableProps}
                  >
                    <BuilderUI.Settings.WidgetLayout className="gap-0">
                      {template.groups
                        .toSorted(sortByPosition)
                        .map((group, index) => (
                          <Draggable
                            key={group.id}
                            draggableId={group.id}
                            index={index}
                          >
                            {(providedDraggableGroup) => (
                              <div
                                className="group/templateGroup"
                                ref={providedDraggableGroup.innerRef}
                                {...providedDraggableGroup.draggableProps}
                              >
                                <Droppable type="group" droppableId={group.id}>
                                  {(providedDroppableGroup) => (
                                    <div
                                      ref={providedDroppableGroup.innerRef}
                                      {...providedDroppableGroup.droppableProps}
                                      className="mb-tw-4"
                                    >
                                      <BuilderUI.Settings.WidgetLayout.WidgetGroup
                                        key={group.id}
                                      >
                                        <div className="flex items-center gap-tw-2 p-tw-4 text-neutral-550">
                                          <Icon
                                            className="hidden group-hover/templateGroup:block"
                                            iconName="move"
                                            {...providedDraggableGroup.dragHandleProps}
                                          />
                                          {group.pageOrientation ===
                                            'portrait' && (
                                            <PortraitSvg className="group-hover/templateGroup:hidden" />
                                          )}
                                          {group.pageOrientation ===
                                            'landscape' && (
                                            <LandscapeSvg className="group-hover/templateGroup:hidden" />
                                          )}
                                          <BuilderUI.Settings.WidgetLayout.WidgetGroup.Title>
                                            {`Group ${index + 1}`}
                                          </BuilderUI.Settings.WidgetLayout.WidgetGroup.Title>
                                          <GrowDiv />
                                          <IconButton
                                            onClick={() =>
                                              handleDeleteGroup(group.id)
                                            }
                                            iconName="trash"
                                            isLoading={
                                              deleteGroupOptions.isLoading
                                            }
                                            disabled={isLoading}
                                          />
                                          <TemplateGroupHeaderDropdown
                                            displayHeader={group.displayHeader}
                                            isLoading={isLoading}
                                            onChange={(displayHeader) => {
                                              handleUpdateGroup({
                                                id: group.id,
                                                display_header: displayHeader,
                                              });
                                            }}
                                          />
                                          <TemplateGroupPageOrientationDropdown
                                            isLoading={isLoading}
                                            pageOrientation={
                                              group.pageOrientation
                                            }
                                            onChange={(pageOrientation) => {
                                              handleUpdateGroup({
                                                id: group.id,
                                                page_orientation:
                                                  pageOrientation,
                                              });
                                            }}
                                          />
                                          <IconButton
                                            onClick={() =>
                                              handleAddWidgetToGroup(group.id)
                                            }
                                            tooltipProps={{
                                              mainText: 'Add Widget',
                                            }}
                                            iconName="addSmall"
                                            disabled={isLoading}
                                          />
                                        </div>

                                        <BuilderUI.Settings.WidgetLayout.WidgetGroup.Children>
                                          {group.widgetSections.map(
                                            (w, wIdx) => (
                                              <Draggable
                                                draggableId={w.id}
                                                index={wIdx}
                                                key={w.id}
                                              >
                                                {(providedWidget) => (
                                                  <div
                                                    ref={
                                                      providedWidget.innerRef
                                                    }
                                                    {...providedWidget.draggableProps}
                                                  >
                                                    <BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCard className="group/widget">
                                                      <Icon
                                                        className=" hidden group-hover/widget:block"
                                                        iconName="move"
                                                        {...providedWidget.dragHandleProps}
                                                      />
                                                      <Icon
                                                        className="group-hover/widget:hidden"
                                                        iconName={
                                                          BUILDER_UI_WIDGET_CARD_WIDGET_TYPE_ICON_MAP[
                                                            w.widgetType
                                                          ] ?? 'widget'
                                                        }
                                                      />
                                                      <BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCard.Title>
                                                        {w.title ??
                                                          startCase(
                                                            w.widgetType,
                                                          )}
                                                      </BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCard.Title>

                                                      <GrowDiv />
                                                      {isReportBuilderTempalteWidgetIncludeSourceUnitTypesEditable(
                                                        w.widgetType,
                                                      ) && (
                                                        <ExpandCollapseRowsIconButton
                                                          disabled={isLoading}
                                                          includeSourceUnitTypes={
                                                            (
                                                              w.widgetConfig as RecentRentrollWidgetConfigDto
                                                            )
                                                              .include_source_unit_types
                                                          }
                                                          onClick={(
                                                            newIncludeSourceUnit_types,
                                                          ) => {
                                                            handleWidgetIncludeSourceUnitTypesChange(
                                                              {
                                                                ...w,
                                                                groupId:
                                                                  group.id,
                                                              },

                                                              newIncludeSourceUnit_types,
                                                            );
                                                          }}
                                                        />
                                                      )}
                                                      <ReportBuilderTemplateWidgetWidthDropdown
                                                        widgetWidth={
                                                          w.position.w
                                                        }
                                                        disabled={isLoading}
                                                        onClick={(width) => {
                                                          handleWidgetWidthChange(
                                                            {
                                                              ...w,
                                                              groupId: group.id,
                                                            },
                                                            width,
                                                          );
                                                        }}
                                                      />
                                                      {isReportBuilderTempalteWidgetEditable(
                                                        w.widgetType,
                                                      ) && (
                                                        <IconButton
                                                          iconName="edit"
                                                          onClick={() => {
                                                            navigateToWidget(
                                                              w.id,
                                                            );
                                                          }}
                                                        />
                                                      )}
                                                      <IconButton
                                                        onClick={() =>
                                                          handleDeleteWidget(
                                                            w.id,
                                                            group.id,
                                                          )
                                                        }
                                                        iconName="trash"
                                                        isLoading={
                                                          deleteWidgetOptions.isLoading
                                                        }
                                                        disabled={isLoading}
                                                      />
                                                    </BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCard>
                                                  </div>
                                                )}
                                              </Draggable>
                                            ),
                                          )}
                                          {providedDroppableGroup.placeholder}
                                        </BuilderUI.Settings.WidgetLayout.WidgetGroup.Children>
                                      </BuilderUI.Settings.WidgetLayout.WidgetGroup>
                                    </div>
                                  )}
                                </Droppable>
                              </div>
                            )}
                          </Draggable>
                        ))}
                    </BuilderUI.Settings.WidgetLayout>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </BuilderUI.Settings.WidgetLayout>
        </BuilderUI.Settings.Main>

        <BuilderUI.Settings.Footer>
          <Button onClick={navigateBack}>Cancel</Button>
          <GrowDiv />
          <Button
            onClick={handleSaveUpdates}
            loading={updateOptions.isLoading}
            disabled={isLoading || !isDirty}
            variant="success"
            className="w-max"
          >
            Save
          </Button>
        </BuilderUI.Settings.Footer>
      </BuilderUI.Settings>

      {isPDFLoading && pdfSrc == null && <BuilderUI.PdfPreview.Loading />}
      {pdfSrc && (
        <BuilderUI.PdfPreview.Iframe
          header={
            // move to BuilderUI.PdfPreview.Header
            <div className="flex h-[80px] w-full items-center justify-center gap-tw-4 bg-neutral-850">
              <ReportDashboardAssetFilter
                assetId={assetId}
                assets={meta.assets}
                path={
                  ROUTES_ROOT.reportBuilderTemplates.reportBuilderTemplate
                    .fullPath
                }
                pathParams={{
                  templateId: template.id,
                }}
              />
              <ReportDashboardDateFilterBlock
                date={templateStateDate}
                dashboardId=""
              />
            </div>
          }
          src={pdfSrc}
        >
          {isPDFLoading && <BuilderUI.PdfPreview.IframeFetching />}
        </BuilderUI.PdfPreview.Iframe>
      )}
      {!isPDFLoading && pdfSrc == null && <BuilderUI.PdfPreview.NoData />}
    </BuilderUI>
  );
};

export const ReportBuilderTemplatePage: RouteComponentProps = () => {
  const params = useReportBuilderTemplateScreenParams();
  const { data, isLoading } =
    useGetApiSettingsReportBuilderGotenbergTemplatesByIdQuery({
      id: params.templateId,
    });
  const { data: metaData, isLoading: isMetaDataLoading } =
    useGetApiSettingsReportBuilderTemplatesMetaQuery();

  if (isLoading || isMetaDataLoading) {
    return <BuilderUI.Loading />;
  }

  if (data == null || metaData == null) {
    return <NoDataOverlay title="No data" />;
  }

  return (
    <DashboardContext.Provider
      value={{
        boardId: '',
        dashboardId: '',
        dashboardType: ReportDashboardType.REPORT_BUILDER_TEMPLATE,
      }}
    >
      <ReportBuilderTemplateContext.Provider
        value={{
          groupId: '',
          widgetId: '',
          templateId: data.id,
        }}
      >
        <Builder template={data} meta={metaData ?? { tags: [], assets: [] }} />
      </ReportBuilderTemplateContext.Provider>
    </DashboardContext.Provider>
  );
};
