import { LocationProvider, navigate } from '@reach/router';
import { initHotjar } from 'lib/hotjar';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { IUser } from 'types/User';
import UsersPage from '../../pages/users';
import LoggedInApp from './LoggedInApp';
// eslint-disable-next-line import/extensions
import { fetchCustomerData, fetchGenericData } from '@/app/reducers/actions';
import {
  resetGenericData,
  selectCustomer,
  selectGenericData,
} from '@/app/reducers/genericSlice';
import ErrorBoundary from '@/app/ui/ErrorBoundary';
import ErrorBoundaryPage from '@/app/ui/ErrorBoundaryPage';
import ModalProvider from '@/bundles/Shared/ModalProvider';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { AnimationLoader } from '@/stories';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface IMainNavItem {
  label: string;
  slug: string;
  icon?: string;
  children?: IMainNavItem[];
}

type DashboardNavigationItem = {
  id: string;
  label: string;
  slug: string;
  status: 'draft' | 'published';
};

export interface MainNavigation {
  impersonatableUsers: IUser[];
  advisees: IUser[];
  financialSubItems: IMainNavItem[];
  scoreboardsSubItems: IMainNavItem[];
  comparisonDashboards: DashboardNavigationItem[];
  objectDashboards: DashboardNavigationItem[];
}

export function App() {
  const dispatch = useAppDispatch();
  const genericData = useAppSelector(selectGenericData);
  const customer = useAppSelector(selectCustomer)!;

  const [loading, setLoading] = useState<boolean>(true);
  // TODO: not sure how to reset state outside of any react component if session has been expired
  window.adHocResetData = () => dispatch(resetGenericData());

  useEffect(() => {
    const fetchData = async () => {
      initHotjar();

      await Promise.all([
        dispatch(fetchCustomerData()),
        dispatch(fetchGenericData()),
      ]);

      setLoading(false);
    };
    fetchData();
  }, []);

  const redirectUrl = new URLSearchParams(window.location.search).get(
    'redirect_url',
  );

  useEffect(() => {
    if (genericData?.impersonating && redirectUrl) navigate(redirectUrl);
  }, [genericData]);

  if (loading) return <AnimationLoader />;
  if (!customer && !loading) return <ErrorBoundaryPage />;

  return (
    <ErrorBoundary fallback={<ErrorBoundaryPage />}>
      <Helmet>
        <title>{customer.name}</title>
        <meta property="og:image" content={customer.emailLogoUrl} />
      </Helmet>
      <LocationProvider>
        <ModalProvider>
          {genericData ? (
            <DndProvider backend={HTML5Backend}>
              <LoggedInApp />
            </DndProvider>
          ) : (
            <UsersPage />
          )}
        </ModalProvider>
      </LocationProvider>
    </ErrorBoundary>
  );
}
