import {
  DEFAULT_CONFIG_MAP,
  WIDGETS_CONFIG_MAP,
} from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsMutation } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import {
  addSectionsToLayout,
  AllWidgetTypes,
  mapLayoutToLayoutDto,
  ReportBuilderTemplateWidgetSectionTypes,
  ReportDashboardSectionPositionWithId,
  ReportDashboardType,
  useDashboardContext,
  usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
  usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
  usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
} from 'bundles/Shared/entities/dashboard';

const WIDGET_ADD_MUTATIONS = {
  [ReportDashboardType.OBJECT]:
    usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
  [ReportDashboardType.EAGLE_EYE]:
    usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
  [ReportDashboardType.COMPARISON_MODE]:
    usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
  [ReportDashboardType.REPORT_BUILDER_TEMPLATE]:
    usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsMutation,
};

const useAddReportBuilderTemplateWidgetSection = () => {
  const [createWidgetMutation, options] =
    usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsMutation();

  const handleCreateWidget = async <
    T extends ReportBuilderTemplateWidgetSectionTypes,
  >(params: {
    type: T[];
    groupId: string;
    templateId: string;
  }) => {
    const { templateId, groupId, type } = params;
    const [oneType] = type;
    const widgetConfigMap =
      DEFAULT_CONFIG_MAP[ReportDashboardType.REPORT_BUILDER_TEMPLATE];
    return await createWidgetMutation({
      groupId,
      templateId,
      body: {
        widget_type: oneType,
        width_percent: 100,
        config: widgetConfigMap[oneType],
        title: `${WIDGETS_CONFIG_MAP[oneType].title}`,
      },
    });
  };

  return [handleCreateWidget, options] as const;
};

export const useAddDashboardWidgetSection = () => {
  const { dashboardId, dashboardType } = useDashboardContext();
  const useAddWidgetMutation = WIDGET_ADD_MUTATIONS[dashboardType];
  const [createWidgetMutation, options] = useAddWidgetMutation();
  const addReportBuilderTemplateWidgetSection =
    useAddReportBuilderTemplateWidgetSection();

  if (dashboardType === ReportDashboardType.REPORT_BUILDER_TEMPLATE) {
    return addReportBuilderTemplateWidgetSection;
  }

  const handleCreateWidget = async <T extends AllWidgetTypes>(params: {
    type: T | T[];
    layout: ReportDashboardSectionPositionWithId[];
    layoutItem?: ReportDashboardSectionPositionWithId;
    boardId?: string;
  }) => {
    const { layout, layoutItem, boardId } = params;

    if (boardId == null) return;

    const types = Array.isArray(params.type) ? params.type : [params.type];
    const newLayout = addSectionsToLayout(
      layout,
      types.map((type) => ({
        i: type,
        type,
        ...WIDGETS_CONFIG_MAP[type].layout,
        ...layoutItem,
      })),
      { toTheEnd: layoutItem == null },
    );

    const dashboard_layout = mapLayoutToLayoutDto(
      layout.filter((l) => !types.includes(l.i as unknown as T)),
    );

    return await createWidgetMutation({
      objectDashboardId: dashboardId,
      eagleEyeDashboardId: dashboardId,
      comparisonDashboardId: dashboardId,
      boardId,
      body: {
        // @ts-expect-error
        widget_sections: types.map((type) => ({
          title: `${WIDGETS_CONFIG_MAP[type].title}`,
          widget_type: type,
          position: newLayout.find((l) => l.i === type)!,
          // @ts-expect-error
          config: DEFAULT_CONFIG_MAP[dashboardType][type],
        })),
        dashboard_layout,
      },
    });
  };

  return [handleCreateWidget, options] as const;
};
