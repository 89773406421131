import { stringifyColDefId } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/lib';
import {
  createMapByKey,
  getReckonerPeriodByPeriodTypeAndDate,
  groupColumnsAndColumnsSettingsByGroupId,
  mergeColumnsAndColumnSettings,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import {
  FinancialTableSingeDateWidgetState,
  isFinancialTableSingleDateWidgetSection,
} from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate';
import { isFinancialTableSinglePeriodWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/model';
import { XYChartSingleKpiWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { createAction, EntityState } from '@reduxjs/toolkit';
import { ReportDashboardState } from 'bundles/Shared/entities/dashboard';
import {
  isKpiTableWidgetSection,
  KpiTableWidgetState,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { isKpiTableSingleDateWidgetSection } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate';
import { isXYChartSingleKpiWidgetSection } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';
import { XYChartSingleKpiWidgetState } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/widget';
import { capitalize, groupBy, pick } from 'lodash-es';
import { UnknownRecord } from 'type-fest/source/internal';
import {
  EagleEyeBoard,
  ReportComparisonDashboardSection,
  ReportEagleEyeDashboard,
  ReportEagleEyeDashboardSection,
  ReportObjectDashboardSection,
} from '../types';

import dayjs from 'dayjs';

import { isChartCategorical } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib/common';
import { getInitialStateForGlobalLeaseTableWidget } from '@/bundles/Shared/widgets/dashboard/widgets/globalLeaseTable/lib';

export const UNGROUPED_GROUP_KEY = 'ungrouped';
export const UNGROUPED_GROUP_HEADER_NAME = 'Ungrouped Columns';

export const updateDashboardWidgetState = createAction<{
  id: string;
  dashboardId: string;
  widgetState: UnknownRecord;
  boardId?: string;
}>('updateEagleEyeWidgetState');

export const loadInitialWidgetState = createAction<{
  id: string;
  dashboardId: string;
  boardId?: string;
}>('loadInitialWidgetState');

export const getInitialStateForEagleEyeDashboardWidget = (
  section: ReportEagleEyeDashboardSection,
  dashboard?: ReportEagleEyeDashboard,
) => {
  if (isXYChartSingleKpiWidgetSection(section)) {
    const xyChartSingleKpiWidgetSection =
      section as unknown as XYChartSingleKpiWidgetSection;
    const { dateFrom, dateTo, granularity, groupingType, objects } =
      xyChartSingleKpiWidgetSection.defaultOptions;
    const assets =
      objects?.filter((obj) => obj.type === 'asset') ?? dashboard?.assets ?? [];
    const segments =
      objects?.filter((obj) => obj.type === 'segment') ??
      dashboard?.segments ??
      [];
    const isCategorical = isChartCategorical(
      xyChartSingleKpiWidgetSection.widgetConfig.am_chart_config,
    );

    return {
      groupingType: groupingType ?? 'assets',
      granularity: granularity ?? 'day',
      assets: mapListToIds(assets),
      segments: mapListToIds(segments),
      kpi: isCategorical
        ? null
        : xyChartSingleKpiWidgetSection.widgetConfig.kpis?.[0]?.key,
      dateFrom: isCategorical
        ? formatToDateStringForRequest(dayjs(dateFrom).startOf(granularity))
        : dateFrom,
      dateTo: isCategorical
        ? formatToDateStringForRequest(dayjs(dateFrom).endOf(granularity))
        : dateTo ?? formatToDateStringForRequest(new Date()),
    } satisfies XYChartSingleKpiWidgetState;
  }
  if (isKpiTableWidgetSection(section)) {
    return {
      groupingType: section.defaultOptions.groupingType,
      date: section.defaultOptions.date,
      period: getReckonerPeriodByPeriodTypeAndDate(
        section.defaultOptions.periodType ?? 't3',
        section.defaultOptions.date,
      ),
    } satisfies KpiTableWidgetState;
  }
  if (section.widgetType === 'global_lease_table') {
    return getInitialStateForGlobalLeaseTableWidget(section);
  }
  if (isKpiTableSingleDateWidgetSection(section)) {
    return {
      groupingType: section.defaultOptions.groupingType,
      date: section.defaultOptions.date,
    };
  }
  return {};
};

const LAYOUT_ID_DELIMITER = '_';

export const buildLayoutsId = ({
  boardId,
  dashboardId,
}: {
  dashboardId: ReportEagleEyeDashboard['id'];
  boardId?: EagleEyeBoard['id'];
}) => [dashboardId, boardId].filter(Boolean).join(LAYOUT_ID_DELIMITER);

export const extractIdsFromLayoutId = (id: string) => {
  const [dashboardId, boardId] = id.split(LAYOUT_ID_DELIMITER);
  return {
    dashboardId,
    boardId,
  };
};

export const COLUM_GROUP_FOR_UNGROUPED_COLUMNS = {
  group_id: UNGROUPED_GROUP_KEY as string,
  header_name: capitalize(UNGROUPED_GROUP_KEY),
  hidden: false,
  icon: null,
  background: 'transparent',
  border_color: 'transparent',
  order: 0,
};

export const getOverridableState = (
  section:
    | ReportEagleEyeDashboardSection
    | ReportComparisonDashboardSection
    | ReportObjectDashboardSection,
  dashboard: ReportEagleEyeDashboard,
) => {
  switch (true) {
    case isXYChartSingleKpiWidgetSection(section):
      return pick(
        getInitialStateForEagleEyeDashboardWidget(section, dashboard),
        ['groupingType', 'granularity', 'dateFrom', 'dateTo'],
      );
    case isFinancialTableSinglePeriodWidgetSection(section):
    case isFinancialTableSingleDateWidgetSection(section): {
      const columnsSettingsMap = createMapByKey(
        section.widgetConfig.viz_config?.columns ?? [],
      );
      const mergedColumnAndColumnSettings = mergeColumnsAndColumnSettings(
        section.widgetConfig?.columns ?? [],
        columnsSettingsMap,
      );

      const groupedByGroupId = groupColumnsAndColumnsSettingsByGroupId(
        mergedColumnAndColumnSettings,
      );

      const possibleDisplayedGroups = [
        ...(section.widgetConfig.viz_config?.column_groups ?? []),
      ] satisfies typeof section.widgetConfig.viz_config.column_groups;

      if (groupedByGroupId[UNGROUPED_GROUP_KEY] != null) {
        possibleDisplayedGroups.unshift(COLUM_GROUP_FOR_UNGROUPED_COLUMNS);
      }

      const displayedGroups =
        possibleDisplayedGroups.map((g) => ({
          id: g.group_id,
          name: g.header_name,
          hide: Boolean(g.hidden),
          allColDefIds:
            groupedByGroupId[g.group_id]?.map((col) =>
              stringifyColDefId({
                groupId: g.group_id,
                columnId: String(col.key),
              }),
            ) ?? [],
          viz_config: g,
          children:
            groupedByGroupId[g.group_id]?.map((col) => ({
              id: String(col.key),
              name: col.label,
              hide: Boolean(col.viz_config?.hidden),
              allColDefIds: [
                stringifyColDefId({
                  groupId: g.group_id,
                  columnId: String(col.key),
                }),
              ],
            })) ?? [],
        })) ?? [];

      return {
        displayedGroups,
      } satisfies Partial<FinancialTableSingeDateWidgetState>;
    }
    case isKpiTableSingleDateWidgetSection(section):
    case isKpiTableWidgetSection(section):
    case section.widgetType === 'master_unit_table':
    case section.widgetType === 'unit_type_table':
    case section.widgetType === 'global_lease_table': {
      const { viz_config } = section.widgetConfig;

      const groupedByGroupId = groupBy(
        viz_config?.columns ?? [],
        (c) => c?.group_id ?? UNGROUPED_GROUP_KEY,
      );

      const possibleDisplayedGroups = [
        ...(viz_config?.column_groups ?? []),
      ] satisfies typeof viz_config.column_groups;

      if (groupedByGroupId[UNGROUPED_GROUP_KEY] != null) {
        possibleDisplayedGroups.unshift(COLUM_GROUP_FOR_UNGROUPED_COLUMNS);
      }

      const displayedGroups =
        possibleDisplayedGroups.map((g) => ({
          id: g.group_id,
          hide: Boolean(g.hidden),
          allColDefIds:
            groupedByGroupId[g.group_id]?.map((col) => String(col.col_id)) ??
            [],
          children:
            groupedByGroupId[g.group_id]?.map((col) => ({
              id: col.col_id,
              hide: Boolean(col?.hidden),
              allColDefIds: [String(col.col_id)],
            })) ?? [],
        })) ?? [];

      return {
        displayedGroups,
      } satisfies Partial<KpiTableWidgetState>;
    }

    default: {
      return {};
    }
  }
};

export const layoutsIdHasBoard = (id: string) =>
  id.includes(LAYOUT_ID_DELIMITER);

export const loadInitialWidgetStateReducer = (
  state: EntityState<ReportDashboardState>,
  action: ReturnType<typeof loadInitialWidgetState>,
) => {
  const { dashboardId, boardId, id: widgetId } = action.payload;
  const id = buildLayoutsId({
    dashboardId,
    boardId,
  });
  const board = state.entities[id];
  if (board === undefined) {
    return;
  }
  const widgetState = board.widgetsStateInitial?.[widgetId];
  if (widgetState === undefined || board.widgetsState === undefined) {
    return;
  }
  board.widgetsState[widgetId] = widgetState;
};

export const updateWidgetStateReducer = (
  state: EntityState<ReportDashboardState>,
  action: ReturnType<typeof updateDashboardWidgetState>,
) => {
  const { dashboardId, id: sectionId, boardId } = action.payload;
  const id = buildLayoutsId({
    dashboardId,
    boardId,
  });
  const board = state.entities[id];
  if (board === undefined) {
    return;
  }
  if (board.widgetsState?.[sectionId] === undefined) {
    return;
  }
  const widgetState = board.widgetsState[sectionId];

  board.widgetsState[action.payload.id] = {
    ...widgetState,
    ...action.payload.widgetState,
  };
};
