import {
  LEAVE_DASHBOARD_CONFIRM_FUNC_PROPS,
  WIDGET_SETTINGS_BUTTON_TOOLTIP,
} from '@/bundles/Settings/components/REport/Dashboards/Dashboard/config';
import { SettingsReportComparisonDashboardDateFilter } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportDashboardDateFilter';
import {
  selectReportComparisonDashboardMetadataById,
  selectReportComparisonDashboardSelectedObjectLegalEntityIds,
} from '@/bundles/Shared/entities/dashboard/model/slices/comparisonSlice';
import { useAddDashboardWidgetSection } from '@/bundles/Shared/features/dashboard/addWidget/lib';
import { ComparisonDashboardAssetFilter } from '@/bundles/Shared/features/dashboard/object/filter/byAsset/ui/ComparisonDashboardAssetFilter';
import { DashboardSectionRemoveButton } from '@/bundles/Shared/features/dashboard/sectionActions/ui/DashboardSectionRemoveButton';
import { COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { ComparisonDashboardWidget } from '@/bundles/Shared/widgets/dashboard/widgetsHelpers/ui/ComparisonDashboardWidget';
import { cn } from '@/shared/lib/css/cn';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  generateUrl,
  ROUTES_ROOT,
  useQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { Link, useNavigate } from '@reach/router';
import { orderDashboardSections } from 'bundles/Settings/components/REport/Dashboards/Dashboard/lib';
import {
  DashboardLayout,
  ObjectLevelDashboardLayout,
} from 'bundles/Shared/components/layouts/dashboard/DashboardLayout';
import {
  buildLayoutsId,
  DashboardContext,
  DashboardContextValue,
  DashboardHasChangesButton,
  DashboardIcon,
  DashboardSection,
  DashboardSettingsToggle,
  DashboardStatusDropdown,
  findBoardByIdOrSlug,
  isComparisonDashboardSectionEditable,
  ObjectDashboardSectionTypes,
  ReportComparisonDashboardSection,
  ReportDashboardType,
  useDashboardContext,
  useGetApiSettingsReportComparisonDashboardsByIdQuery,
} from 'bundles/Shared/entities/dashboard';
import { selectReportDashboardLayoutDirty } from 'bundles/Shared/entities/dashboard/model/slices/layouts';
import { useDeleteDashboard } from 'bundles/Shared/features/dashboard/delete';
import { MoveToBoardButton } from 'bundles/Shared/features/dashboard/moveToBoard';
import { useDashboardUpdateLayout } from 'bundles/Shared/features/dashboard/sectionActions';
import DashboardSectionDragButton from 'bundles/Shared/features/dashboard/sectionActions/ui/DashboardSectionDragButton';
import { ComparisonDashboardUpdate } from 'bundles/Shared/features/dashboard/update/object';
import { useUpdateDashboardStatus } from 'bundles/Shared/features/dashboard/updateStatus';
import { DashboardBoards } from 'bundles/Shared/widgets/dashboard/board/ui/DashboardBoards';
import { DashboardGridLayout } from 'bundles/Shared/widgets/dashboard/layout';
import {
  DashboardWidgetsBar,
  useDashboardWidgetsBarDroppableItem,
} from 'bundles/Shared/widgets/dashboard/widgetsBar';
import React, { useMemo } from 'react';
import { Icon, IconButton } from 'stories';
import {
  useNavigateDashboardSettingsToBoard,
  useNavigateToFirstAssetAndBoardEffect,
  useNavigateToWidget,
  useReportSettingsDashboardScreenParams,
  useReportSettingsDashboardScreenQueryParams,
} from '@/shared/lib/hooks/navigation/dashboardsNavitation';
import { DashboardBoardNavigationEditable } from '@/bundles/Shared/widgets/dashboard/board/ui/navigation/DashboardBoardNavigationEditable';
import { GrowDiv } from '@/shared/ui/GrowDiv';

const DashboardWidget = ({
  widgetSection,
}: {
  widgetSection: ReportComparisonDashboardSection;
}) => {
  const { dashboardId, boardId } = useDashboardContext();

  const { assetId } = useQueryParams<{
    assetId: string;
    boardId: string;
  }>();
  const objectLegalEntityIds = useAppSelector((state) =>
    selectReportComparisonDashboardSelectedObjectLegalEntityIds(state, {
      dashboardId,
      selectedObjectId: Number(assetId),
    }),
  );
  return (
    <ComparisonDashboardWidget
      dashboardId={dashboardId}
      widgetSection={widgetSection}
      mode="edit"
      boardId={boardId}
      selectedAssetId={Number(assetId)}
      legalEntityIds={objectLegalEntityIds}
      context={{
        objectLegalEntityIds,
        columnVisibilityEnabled: true,
      }}
    />
  );
};

export function SettingsReportComparisonDashboard() {
  const navigate = useNavigate();
  const { confirm } = useModal();
  const { dashboardId, dashboardSlug, boardId, boardSlug } =
    useDashboardContext();
  const { assetId } = useQueryParams<{
    assetId: string;
    boardId: string;
  }>();
  const [deleteDashboard] = useDeleteDashboard(
    ReportDashboardType.COMPARISON_MODE,
  );
  const viewDashboardUrl = generateUrl(
    ROUTES_ROOT.report.comparisonDashboards.fullPath,
    {
      pathParams: {
        dashboardId: dashboardSlug ?? '',
      },
      queryParams: {
        assetId,
        boardId: boardSlug ?? '',
      },
    },
  );
  const { data, isLoading } =
    useGetApiSettingsReportComparisonDashboardsByIdQuery({
      id: dashboardSlug ?? '',
    });
  const board = findBoardByIdOrSlug(data?.boards ?? [], boardSlug ?? '');

  const navigateToWidget = useNavigateToWidget();
  const navigateToBoard = useNavigateDashboardSettingsToBoard();
  const isLayoutDirty = useAppSelector(
    selectReportDashboardLayoutDirty(
      {
        dashboardId,
        boardId,
      },
      selectReportComparisonDashboardMetadataById,
      {
        withoutColor: true,
      },
    ),
  );
  const moveLayout = useDashboardUpdateLayout();
  const updateDashboardStatus = useUpdateDashboardStatus();

  const boardState = useAppSelector((state) =>
    selectReportComparisonDashboardMetadataById(
      state,
      buildLayoutsId({
        dashboardId,
        boardId,
      }),
    ),
  );
  const [addDashboardWidget] = useAddDashboardWidgetSection();
  const {
    handleDragStart,
    droppableItem,
    handleDrop: handleWidgetsBarDrop,
    droppableItemPlaceholderProps,
  } = useDashboardWidgetsBarDroppableItem();

  useNavigateToFirstAssetAndBoardEffect({
    assets: data?.assets ?? [],
    boards: data?.boards ?? [],
  });

  const handleDrop: React.ComponentProps<
    typeof DashboardGridLayout
  >['onDrop'] = async (layout, layoutItem, _event) => {
    const type = layoutItem.i as ObjectDashboardSectionTypes;
    const res = await addDashboardWidget({
      layout,
      layoutItem,
      type,
      boardId,
    });

    handleWidgetsBarDrop();

    if (typeof res === 'object' && res !== null && 'error' in res) {
      return;
    }
  };

  const handlePreviewClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!isLayoutDirty) {
      const res = await confirm(LEAVE_DASHBOARD_CONFIRM_FUNC_PROPS);

      if (res) {
        await moveLayout({
          layout: boardState!.layouts!.lg,
          boardId,
        });
      }
    }
    navigate(viewDashboardUrl);
  };

  const generateLayout = () => {
    if (data === undefined || board === undefined) {
      return null;
    }

    if (board.sections?.length === 0 && droppableItem != null) {
      return <div {...droppableItemPlaceholderProps} />;
    }

    return orderDashboardSections(board.sections).map((s) => {
      const section = s as unknown as ReportComparisonDashboardSection;
      if (COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP[section.widgetType] == null) {
        return null;
      }
      const { title, icon } =
        COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP[section.widgetType];
      const layoutItem = boardState?.layouts?.lg.find(
        (item) => item.i === section.id,
      );
      const isEditable = isComparisonDashboardSectionEditable(section);
      const handleEdit = isEditable
        ? () => {
            navigateToWidget({
              widgetId: section.id,
            });
          }
        : undefined;

      return (
        <div
          className={cn(section.position.w === 1 && 'col-span-2')}
          data-grid={layoutItem}
          key={section.id}
        >
          <DashboardSection
            id={section.id}
            className="h-full"
            panel={
              <div className="flex items-center gap-tw-2">
                <DashboardSectionDragButton />
                <div className="flex gap-tw-1">
                  <Icon iconName={icon} />
                  <span className="secondary-semibold uppercase text-neutral-500">
                    {title}
                  </span>
                </div>
                <GrowDiv />
                <div className="flex items-center gap-tw-2">
                  {isEditable && (
                    <IconButton
                      size="s"
                      iconName="edit"
                      onClick={handleEdit}
                      tooltipProps={{
                        mainText: WIDGET_SETTINGS_BUTTON_TOOLTIP,
                      }}
                    />
                  )}
                  <MoveToBoardButton
                    boardId={boardId}
                    sectionId={section.id}
                    boards={data.boards}
                  />
                  <DashboardSectionRemoveButton
                    sectionId={section.id}
                    boardId={boardId}
                    layout={boardState!.layouts!.lg}
                  />
                </div>
              </div>
            }
          >
            <DashboardWidget widgetSection={section} />
          </DashboardSection>
        </div>
      );
    });
  };
  if (isLoading) {
    return <DashboardLayout.LoadingPlaceholder />;
  }

  if (data === undefined) {
    return null;
  }

  return (
    <ObjectLevelDashboardLayout>
      <ObjectLevelDashboardLayout.Sidebar>
        <DashboardLayout.StickyHeader className="bg-neutral-100">
          <DashboardLayout.Header className="h-auto">
            <DashboardLayout.Header.Title
              title={data.name}
              subtitle={
                <>
                  <DashboardIcon iconName="objectLevelDashboard" />
                  Comparison
                </>
              }
            />
            <div className="flex items-center gap-tw-2">
              <IconButton
                iconName="trash"
                onClick={async () => {
                  const res = await deleteDashboard(dashboardId);
                  if (!res) return;
                  navigate(
                    generateUrl(
                      ROUTES_ROOT.settings.report.dashboards.fullPath,
                    ),
                  );
                }}
              />
              <DashboardStatusDropdown
                onChange={(status) => {
                  updateDashboardStatus({
                    dashboardId,
                    status,
                  });
                }}
                value={data.status}
              />
            </div>
            <GrowDiv />
            <Link onClick={handlePreviewClick} to={viewDashboardUrl}>
              <DashboardSettingsToggle isActive />
            </Link>
          </DashboardLayout.Header>
          <div className="px-tw-6 pt-tw-2">
            <ComparisonDashboardUpdate model={data} />
          </div>
        </DashboardLayout.StickyHeader>
      </ObjectLevelDashboardLayout.Sidebar>
      <ObjectLevelDashboardLayout.Body>
        <DashboardLayout.StickyHeader>
          <DashboardLayout.Header className="grid-column-2">
            <ComparisonDashboardAssetFilter
              assetId={Number(assetId)}
              pathParams={{
                dashboardId: dashboardSlug,
              }}
              path={ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath}
            />
            <SettingsReportComparisonDashboardDateFilter />
            <GrowDiv />
            <DashboardHasChangesButton
              isDirty={isLayoutDirty}
              onClick={() => {
                moveLayout({
                  layout: boardState!.layouts!.lg,
                  boardId,
                });
              }}
            />
          </DashboardLayout.Header>
          {data && (
            <div className="px-tw-6 pb-tw-2">
              <DashboardBoardNavigationEditable
                boards={data.boards}
                selectedBoardId={boardId}
                onBoardChange={navigateToBoard}
              />
            </div>
          )}
        </DashboardLayout.StickyHeader>
        <DashboardLayout.Body className="p-0">
          <DashboardBoards>
            <DashboardLayout.Grid>
              <DashboardGridLayout
                originalLayout={boardState?.layouts?.lg}
                editable
                droppingItem={droppableItem ?? undefined}
                isDroppable
                onDrop={handleDrop}
              >
                {generateLayout()}
              </DashboardGridLayout>
            </DashboardLayout.Grid>
          </DashboardBoards>
        </DashboardLayout.Body>
      </ObjectLevelDashboardLayout.Body>
      <DashboardWidgetsBar
        layout={boardState?.layouts}
        onDragStart={handleDragStart}
      />
    </ObjectLevelDashboardLayout>
  );
}

export const SettingsReportComparisonDashboardPage = () => {
  const { dashboardId: dashboardSlug } =
    useReportSettingsDashboardScreenParams();
  const { boardId: boardSlug } = useReportSettingsDashboardScreenQueryParams();
  const { data } = useGetApiSettingsReportComparisonDashboardsByIdQuery({
    id: dashboardSlug ?? '',
  });
  const dashboardId = data?.id ?? '';
  const board = findBoardByIdOrSlug(data?.boards ?? [], boardSlug ?? '');
  const boardId = board?.id ?? '';

  const dashboardContextValue = useMemo<DashboardContextValue>(
    () => ({
      dashboardId,
      dashboardSlug: dashboardSlug ?? '',
      dashboardType: ReportDashboardType.COMPARISON_MODE,
      boardId,
      boardSlug: boardSlug ?? '',
    }),
    [data, boardId],
  );

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      <SettingsReportComparisonDashboard />
    </DashboardContext.Provider>
  );
};
