import { TRootState } from '@/app/stores';
import { listenerMiddleware } from '@/app/stores/listener';
import { coreAssetsReportBuilderReportsEnhancedApi } from '@/entities/report/reportBuilder/api';
import {
  AssetsReportBuilderReportGroupDto,
  AssetsReportBuilderReportWidgetDto,
  PostApiCoreAssetsReportBuilderReportsByReportIdWidgetsAndIdSnapshotApiArg,
} from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import {
  shiftDateSixMonthsBackward,
  shiftDateYearBackward,
  yesterdayDate,
} from '@/shared/lib/date';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CamelCasedProperties } from 'type-fest';
import { REPORT_BUILDER_WIDGET_TYPES } from '../types';
import { getInitialStateForGlobalLeaseTableWidget } from 'bundles/Shared/widgets/dashboard/widgets/globalLeaseTable/lib';
import { ReportDashboardType } from '@/bundles/Shared/entities/dashboard';

type GroupedWidgetState = OverrideField<
  AssetsReportBuilderReportGroupDto,
  'widgets',
  (AssetsReportBuilderReportWidgetDto & { context: Context })[]
>;

type State = {
  reportId: string | null;
  date: string | null;
  groupedWidgets: GroupedWidgetState[];
};

type Context = CamelCasedProperties<
  PostApiCoreAssetsReportBuilderReportsByReportIdWidgetsAndIdSnapshotApiArg['body']['context']
>;

type UpdateWidgetPayload = {
  context: Context;
  widgetGroupId: string;
  widgetId: string;
};

const initialState: State = {
  reportId: null,
  date: null,
  groupedWidgets: [],
};

const getInitialWidgetSnapshotContext = (
  widget: AssetsReportBuilderReportWidgetDto,
  reportDate: string,
): Context => {
  switch (widget.widgetType) {
    case REPORT_BUILDER_WIDGET_TYPES.XY_CHART: {
      return {
        dateFrom:
          widget.defaultOptions?.dateFrom ??
          formatToDateStringForRequest(
            shiftDateYearBackward(new Date(reportDate)),
          ),
        dateTo:
          widget.defaultOptions?.dateTo ??
          formatToDateStringForRequest(reportDate),
        granularity: 'month',
      };
    }
    case REPORT_BUILDER_WIDGET_TYPES.GLOBAL_LEASE_TABLE: {
      return getInitialStateForGlobalLeaseTableWidget(widget, {
        dashboardType: ReportDashboardType.REPORT_BUILDER_TEMPLATE,
      });
    }
    case 'media':
    case 'text_area': {
      return {};
    }
    default: {
      return {
        date: reportDate,
      };
    }
  }
};

const filterNullableFields = <T extends Record<string, unknown>>(
  obj: T,
): Partial<T> => {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value !== null),
  ) as Partial<T>;
};

export const reportWidgetsSlice = createSlice({
  name: 'reportWidgetsContext',
  initialState,
  reducers: {
    updateWidgetContext: (
      state,
      action: PayloadAction<UpdateWidgetPayload>,
    ) => {
      state.groupedWidgets = state.groupedWidgets.map((g) => {
        if (g.id !== action.payload.widgetGroupId) return g;

        return {
          ...g,
          widgets: g.widgets.map((w) => {
            if (w.id !== action.payload.widgetId) return w;

            return {
              ...w,
              context: action.payload.context,
            };
          }),
        };
      });
    },
    updateReportDate: (
      state,
      { payload: { date: newReportDate } }: PayloadAction<{ date: string }>,
    ) => {
      state.date = newReportDate;

      state.groupedWidgets = state.groupedWidgets.map((g) => {
        return {
          ...g,
          widgets: g.widgets.map((w) => {
            if (w.widgetType === REPORT_BUILDER_WIDGET_TYPES.XY_CHART) {
              return {
                ...w,
                context: {
                  ...w.context,
                  dateTo: newReportDate,
                  // by default PDF chart should show 6 months data
                  // https://linear.app/symmetre/issue/FE-3178/[bug]-report-builder-switching-date-in-pdf-changes-charts-default#comment-f3939b26
                  dateFrom: formatToDateStringForRequest(
                    shiftDateSixMonthsBackward(new Date(newReportDate)),
                  ),
                },
              };
            }
            if (
              w.widgetType === REPORT_BUILDER_WIDGET_TYPES.GLOBAL_LEASE_TABLE
            ) {
              return {
                ...w,
                context: {
                  ...w.context,
                  ...getInitialStateForGlobalLeaseTableWidget(),
                },
              };
            }
            if (
              w.widgetType === REPORT_BUILDER_WIDGET_TYPES.TEXT_AREA ||
              w.widgetType === REPORT_BUILDER_WIDGET_TYPES.MEDIA
            ) {
              return {
                ...w,
                context: {},
              };
            }
            return {
              ...w,
              context: {
                ...w.context,
                date: newReportDate,
              },
            };
          }),
        };
      });
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      coreAssetsReportBuilderReportsEnhancedApi.endpoints
        .getApiCoreAssetsByAssetIdReportBuilderReportsAndId.matchFulfilled,
      (state, action) => {
        const newReportDate =
          action.payload.report?.date ??
          formatToDateStringForRequest(yesterdayDate());

        if (
          state.date === newReportDate &&
          state.reportId === action.payload.report.id
        ) {
          return;
        }

        state.date = newReportDate;
        state.reportId = action.payload.report.id;
        state.groupedWidgets =
          action.payload.report?.groups.map((g) => {
            return {
              ...g,
              widgets: g.widgets.map((w) => ({
                ...w,
                context: {
                  ...getInitialWidgetSnapshotContext(w, newReportDate),
                  ...filterNullableFields(w.snapshotContext ?? {}),
                },
              })),
            };
          }) ?? [];
      },
    );
  },
});

export const reportWidgetsSliceActions = reportWidgetsSlice.actions;

listenerMiddleware.startListening({
  actionCreator: reportWidgetsSliceActions.updateWidgetContext,
  effect: (_, listenerApi) => {
    listenerApi.dispatch(
      coreAssetsReportBuilderReportsEnhancedApi.util.invalidateTags(['Report']),
    );
  },
});

export const selectWidgetById = (widgetId: string) => (state: TRootState) => {
  const widget = state.reportWidgets.groupedWidgets
    .flatMap((g) => g.widgets.map((w) => ({ ...w, group: g })))
    .find((w) => w.id === widgetId);
  return widget;
};
