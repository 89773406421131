import { getRRuleOptionsFormErrorState } from '@/bundles/Settings/components/EmailSettings/utils/utils';
import {
  RESET_RRULE_OPTIONS,
  RRULE_WEEKDAY_MAP,
} from '@/entities/emails/customTemplates/config';
import { MonthDropdown } from '@/entities/emails/customTemplates/form/rrule/MonthDropdown';
import { TimezoneDropdown } from '@/entities/emails/customTemplates/form/rrule/TimezoneDropdown';
import { WeekdayDropdown } from '@/entities/emails/customTemplates/form/rrule/WeekdayDropdown';
import { RRuleOptionsContext } from '@/entities/emails/customTemplates/lib/context';
import { rruleToText } from '@/entities/emails/customTemplates/lib/rruleToText';
import {
  Button,
  Checkbox,
  Dropdown,
  DropdownItem,
  Input,
  LinkButton,
  Modal,
  ModalActions,
  RadioButton,
} from '@/stories';
import { capitalize, findKey } from 'lodash-es';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Frequency, RRule, type Weekday } from 'rrule';

interface Props {
  onClose: () => void;
  onSubmit: (newRrule: RRule) => void;
  rrule: RRule;
}

const mapFrequncyToLabel = {
  day: Frequency.DAILY,
  week: Frequency.WEEKLY,
  month: Frequency.MONTHLY,
  year: Frequency.YEARLY,
};

const COMMON_WHITE_LIST_ITEMS = [
  'dtstart',
  'interval',
  'byhour',
  'byminute',
  'until',
  'freq',
  'tzid',
];
const WHITE_LIST_OBJECT = {
  [RRule.DAILY]: [...COMMON_WHITE_LIST_ITEMS],
  [RRule.WEEKLY]: [...COMMON_WHITE_LIST_ITEMS, 'byweekday'],
  [RRule.MONTHLY]: [
    ...COMMON_WHITE_LIST_ITEMS,
    'bymonthday',
    'bysetpos',
    'byweekday',
  ],
  [RRule.YEARLY]: [
    ...COMMON_WHITE_LIST_ITEMS,
    'bymonth',
    'bymonthday',
    'bysetpos',
    'byweekday',
  ],
};

const ON_MONTH_DAY_LABEL = 'On (month day)';
const ON_WEEK_DAY_LABEL = 'On (week day)';

const ReccurenceModal = ({ onClose, onSubmit, rrule }: Props) => {
  const [newRruleOptions, setNewRruleOptions] = useState({ ...rrule.options });
  const [monthYearRadioOnDay, setMonthYearRadioOnDay] = useState(
    rrule.options.byweekday == null,
  );

  const formErrorState = getRRuleOptionsFormErrorState(newRruleOptions);

  const isSubmitDisabled = () => {
    const res: boolean[] = [formErrorState.repeatEvery];

    if (
      monthYearRadioOnDay &&
      [Frequency.MONTHLY, Frequency.YEARLY].includes(newRruleOptions.freq)
    ) {
      res.push(formErrorState.monthDay);
    }

    return res.some(Boolean);
  };

  const defaultDailyRules = {
    ...newRruleOptions,
    ...RESET_RRULE_OPTIONS,
    freq: Frequency.DAILY,
  } satisfies RRule['options'];

  const defaultWeeklyRules = {
    ...newRruleOptions,
    ...RESET_RRULE_OPTIONS,
    freq: Frequency.WEEKLY,
  } satisfies RRule['options'];

  const defaultMonthlyRules = {
    ...newRruleOptions,
    ...RESET_RRULE_OPTIONS,
    freq: Frequency.MONTHLY,
  } satisfies RRule['options'];

  const defaultYearlyRules = {
    ...newRruleOptions,
    ...RESET_RRULE_OPTIONS,
    freq: Frequency.YEARLY,
  } satisfies RRule['options'];

  const currentRule = (currentFreq: RRule['options']['freq']) => {
    switch (currentFreq) {
      case Frequency.DAILY:
        return defaultDailyRules;
      case Frequency.WEEKLY:
        return defaultWeeklyRules;
      case Frequency.MONTHLY:
        return defaultMonthlyRules;
      case Frequency.YEARLY:
        return defaultYearlyRules;
      default:
        return defaultDailyRules;
    }
  };
  const filterRruleObjByWhitelist = (rruleObj: RRule['options']) => {
    const whitelist = WHITE_LIST_OBJECT[rruleObj.freq];

    const filteredRruleObj = {};
    Object.keys(rruleObj).forEach((key) => {
      if (whitelist.includes(key)) {
        filteredRruleObj[key] = rruleObj[key];
      }
    });

    return filteredRruleObj;
  };

  const handleWeekDayChange = (value: Weekday) => {
    setNewRruleOptions({
      ...newRruleOptions,
      byweekday: [value.weekday],
    });
  };

  // BYSETPOS is not support on BE yet
  // const handleSetPosChange = (value: Values<typeof RRULE_SET_POS_OPTIONS>) => {
  //   setNewRruleOptions({
  //     ...newRruleOptions,
  //     bysetpos: [value],
  //   });
  // };

  const onMonthDayRadioButton = (
    <RadioButton
      classes={{
        label: '!m-0',
      }}
      checked={monthYearRadioOnDay}
      onChange={(e) => {
        setNewRruleOptions(currentRule(newRruleOptions.freq));
        setMonthYearRadioOnDay(e.target.checked);
      }}
    >
      {ON_MONTH_DAY_LABEL}
    </RadioButton>
  );

  const onWeekDayRadioButton = (
    <RadioButton
      checked={!monthYearRadioOnDay}
      onChange={(e) => {
        setNewRruleOptions(currentRule(newRruleOptions.freq));
        setMonthYearRadioOnDay(!e.target.checked);
      }}
    >
      {ON_WEEK_DAY_LABEL}
    </RadioButton>
  );

  const onMonthDayInput = (
    <Input
      className="w-[4rem]"
      min={1}
      maxLength={2}
      error={monthYearRadioOnDay && formErrorState.monthDay}
      value={newRruleOptions.bymonthday?.[0] || ''}
      onChange={(e) =>
        setNewRruleOptions({
          ...newRruleOptions,
          bymonthday: [Number(e.target.value)],
        })
      }
      disabled={!monthYearRadioOnDay}
    />
  );

  const onWeekDayWeekDropdown = (
    <WeekdayDropdown
      disabled={monthYearRadioOnDay}
      onClick={handleWeekDayChange}
    />
  );

  const selectedTime = new Date(
    null,
    null,
    null,
    newRruleOptions.byhour || 0,
    newRruleOptions.byminute || 0,
    0,
    0,
  );

  return (
    <Modal
      toggle={onClose}
      header="Configuring Recurrence Rules"
      size="700"
      actions={
        <ModalActions>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            disabled={isSubmitDisabled()}
            onClick={() =>
              onSubmit(new RRule(filterRruleObjByWhitelist(newRruleOptions)))
            }
            variant="success"
          >
            Set Reccurence Rules
          </Button>
        </ModalActions>
      }
    >
      <RRuleOptionsContext.Provider value={newRruleOptions}>
        <div className="reccurence-grid">
          <div className="font-weight-500 light-60 secondary-regular text-right">
            Start
          </div>
          <div>
            <ReactDatePicker
              portalId="datepicker-portal"
              placeholderText="Start date"
              selected={newRruleOptions.dtstart}
              onChange={(date) =>
                setNewRruleOptions({ ...newRruleOptions, dtstart: date })
              }
              customInput={<Input rightIcon="calendar" />}
            />
          </div>
          <div className="font-weight-500 light-60 secondary-regular text-right">
            Repeat every
          </div>
          <div className="flex items-center gap-tw-2">
            <Input
              min={1}
              maxLength={2}
              error={formErrorState.repeatEvery}
              value={newRruleOptions.interval || ''}
              onChange={(e) =>
                setNewRruleOptions({
                  ...newRruleOptions,
                  interval: Number(e.target.value),
                })
              }
            />
            <Dropdown
              items={
                <>
                  {Object.entries(mapFrequncyToLabel).map(([key, value]) => (
                    <DropdownItem
                      key={key}
                      onClick={() => setNewRruleOptions(currentRule(value))}
                    >
                      {capitalize(key)}
                    </DropdownItem>
                  ))}
                </>
              }
            >
              <Button
                iconName="bottom"
                size="m"
                variant="secondary"
                iconPosition="right"
              >
                {capitalize(
                  findKey(
                    mapFrequncyToLabel,
                    (value) => value === newRruleOptions.freq,
                  ),
                )}
              </Button>
            </Dropdown>
          </div>
          <div />
          <div className="flex flex-col gap-tw-2">
            {newRruleOptions.freq === RRule.WEEKLY &&
              Object.entries(RRULE_WEEKDAY_MAP).map(([key, weekDayRRule]) => {
                const weekdayNumbers = newRruleOptions.byweekday || [];

                return (
                  <Checkbox
                    key={key}
                    labelClassName="mt-tw-2"
                    checked={weekdayNumbers.includes(weekDayRRule.weekday)}
                    onChange={() => {
                      if (weekdayNumbers.includes(weekDayRRule.weekday)) {
                        setNewRruleOptions({
                          ...newRruleOptions,
                          byweekday: newRruleOptions.byweekday.filter(
                            (day) => day !== weekDayRRule.weekday,
                          ),
                        });
                      } else {
                        setNewRruleOptions({
                          ...newRruleOptions,
                          byweekday: [
                            ...weekdayNumbers,
                            weekDayRRule.weekday,
                          ].toSorted(),
                        });
                      }
                    }}
                  >
                    <span className="dark-60 font-weight-400 inline-regular">
                      {capitalize(key)}
                    </span>
                  </Checkbox>
                );
              })}
            {newRruleOptions.freq === RRule.MONTHLY && (
              <>
                <div className="flex items-center gap-tw-2">
                  {onMonthDayRadioButton}
                  {onMonthDayInput}
                </div>
                <div className="flex items-center gap-tw-2">
                  {onWeekDayRadioButton}
                  {/* BYSETPOS is not support on BE yet */}
                  {/* <SetPosDropdown
                    disabled={monthYearRadioOnDay}
                    onClick={handleSetPosChange}
                  /> */}

                  {onWeekDayWeekDropdown}
                </div>
              </>
            )}
            {newRruleOptions.freq === RRule.YEARLY && (
              <>
                <div className="flex items-center gap-tw-2">
                  {onMonthDayRadioButton}
                  {onMonthDayInput}
                  <MonthDropdown
                    disabled={!monthYearRadioOnDay}
                    onClick={(index) => {
                      setNewRruleOptions({
                        ...newRruleOptions,
                        bymonth: [index],
                      });
                    }}
                  />
                </div>
                <div className="flex items-center gap-tw-2">
                  {onWeekDayRadioButton}
                  {/* BYSETPOS is not support on BE yet */}
                  {/* <SetPosDropdown
                    disabled={monthYearRadioOnDay}
                    onClick={handleSetPosChange}
                  /> */}
                  {onWeekDayWeekDropdown}
                  <MonthDropdown
                    disabled={monthYearRadioOnDay}
                    onClick={(index) => {
                      setNewRruleOptions({
                        ...newRruleOptions,
                        bymonth: [index],
                      });
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <div className="font-weight-500 light-60 secondary-regular text-right">
            Timezone
          </div>
          <div className="flex">
            <TimezoneDropdown
              onClick={(tzid) => {
                setNewRruleOptions({
                  ...newRruleOptions,
                  tzid,
                });
              }}
            />
          </div>
          <div className="font-weight-500 light-60 secondary-regular text-right">
            Time
          </div>
          <div>
            <ReactDatePicker
              portalId="datepicker-portal"
              popperClassName="timepicker"
              placeholderText="Time"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              selected={selectedTime}
              onChange={(time) => {
                setNewRruleOptions({
                  ...newRruleOptions,
                  byhour: [time?.getHours() ?? 0],
                  byminute: [time?.getMinutes() ?? 0],
                });
              }}
              customInput={<Input rightIcon="clock" />}
            />
          </div>
          <div className="font-weight-500 light-60 secondary-regular text-right">
            End
          </div>
          <div className="flex-inline items-center">
            <ReactDatePicker
              portalId="datepicker-portal"
              placeholderText="Never"
              selected={newRruleOptions.until}
              onChange={(date) =>
                setNewRruleOptions({
                  ...newRruleOptions,
                  until: date,
                })
              }
              customInput={<Input rightIcon="calendar" />}
            />
            {newRruleOptions.until && (
              <LinkButton
                className="blue ml-m"
                onClick={() =>
                  setNewRruleOptions({
                    ...newRruleOptions,
                    until: undefined,
                  })
                }
              >
                Remove
              </LinkButton>
            )}
          </div>
          <div />
          <p className="dark-60 font-weight-400 inline-regular mt-s">
            {rruleToText(newRruleOptions)}
          </p>
        </div>
      </RRuleOptionsContext.Provider>
    </Modal>
  );
};

export default ReccurenceModal;
