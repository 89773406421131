import { CssVar } from '@/shared/config/cssVar';
import { cn } from '@/shared/lib/css/cn';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import useMediaQuery from '@/shared/lib/hooks/useMediaQuery';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ExtractParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { navigate, useParams } from '@reach/router';
import { fetchChangeOrder } from 'bundles/Construction/actions';
import {
  approveChangeOrder,
  viewDocumentReconcileChangeEvent,
} from 'bundles/Construction/actions/changeOrders';
import MessageWithApprovers from 'bundles/Construction/components/ChangeOrder/AvailableActions/MessageWithApprovers';
import ChangeOrderDetails from 'bundles/Construction/components/ChangeOrder/ChangeOrderDetails/ChangeOrderDetails';
import { CONFIRM_APPROVE_CHANGE_ORDER_PROPS } from 'bundles/Construction/components/ChangeOrder/const';
import History from 'bundles/Construction/components/ChangeOrder/History';
import IncludesChangeEvents from 'bundles/Construction/components/ChangeOrder/IncludesChangeEvents/IncludesChangeEvents';
import {
  DownloadFileIconButton,
  FileStatusBadge,
  PrintFileIconButton,
} from 'bundles/Construction/components/DocumentsUpload';
import { IChangeOrder } from 'bundles/Construction/types';
import { fetchDevelopmentLegalEntity } from 'bundles/REconcile/reducers/developmentBudgetSlice';
import { useGenericData } from 'bundles/Shared/GenericContext';
import { upperCase } from 'lodash-es';
import pluralize from 'pluralize';
import { useEffect, useState } from 'react';
import {
  AnimationLoader,
  Button,
  Collapse,
  CurrencyFormatter,
  DocumentCard,
  IconButton,
  Label,
  MultilineAlert,
} from 'stories';

const AlertMessage = ({ days }) => (
  <MultilineAlert
    message={
      <p className="secondary-regular text-dark-60">
        This change order awaits <br />
        your approval for <strong>{days}</strong> {pluralize('day', days)}
      </p>
    }
  />
);
const { fullPath: ROUTE } =
  ROUTES_ROOT.reconcile.development.legalEntity.changeOrder.approve;
const ChangeOrderExternal = () => {
  const {
    genericData: { currentUser, customer },
  } = useGenericData();
  const { changeOrderId, legalEntityCode } =
    useParams<ExtractParams<typeof ROUTE>>();
  const isMobile = useMediaQuery('(pointer:coarse)');
  const { confirm } = useModal();
  const dispatch = useAppDispatch();
  const changeOrder: IChangeOrder = useAppSelector(
    (state) => state.changeOrderManagement.changeOrder,
  );
  const legalEntity = useAppSelector(
    (state) => state.developmentBudget.legalEntity,
  );
  const [historyCollapsed, setHistoryCollapsed] = useState(!isMobile);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      const changeOrderResult = await dispatch(
        fetchChangeOrder(legalEntityCode, changeOrderId),
      );
      const { approvers } = changeOrderResult;
      if (
        approvers.find((user) => user.userId === currentUser.id) === undefined
      ) {
        navigate('/');
        return;
      }
      dispatch(fetchDevelopmentLegalEntity(legalEntityCode));
    };
    loadData();
  }, [legalEntityCode, changeOrderId]);

  const handleApprove = async () => {
    const result = await confirm(CONFIRM_APPROVE_CHANGE_ORDER_PROPS);
    if (result) {
      setIsLoading(true);
      await dispatch(approveChangeOrder(legalEntityCode, changeOrder.id));
      setIsLoading(false);
    }
  };

  if (!changeOrder || !legalEntity) return <AnimationLoader />;

  const daysSinceRequested = changeOrder.daysAwaitingApproval ?? 0;

  return (
    <div className="flex h-screen flex-col">
      <div className="bg-white p-tw-4">
        <img src={customer.logoUrl} alt="" className="h-32" />
      </div>
      <div className="grid flex-1 gap-x-tw-4 gap-y-tw-6 p-tw-2 md:grid-cols-[1fr_2fr_1fr] md:grid-rows-[min-content_1fr] md:p-tw-6">
        <div className="flex flex-col gap-tw-4">
          <div className="flex flex-col gap-tw-4 rounded-xl bg-light p-tw-2 pb-tw-4">
            <div className="flex flex-col gap-tw-4 rounded-lg bg-white p-tw-4">
              <div className="flex justify-between">
                <span className="header5-bold text-dark-60">Change Order</span>
                {changeOrder.status === 'APPROVED' && (
                  <Label color={CssVar.green} outline>
                    {upperCase(changeOrder.status)}
                  </Label>
                )}
              </div>
              <div className="flex flex-col gap-tw-1">
                <span className="secondary-regular text-light-60">
                  Asset Name
                </span>
                <span className="inline-regular text-dark-60">
                  {legalEntity.assetName}
                </span>
              </div>
              <div className="flex flex-col gap-tw-1">
                <span className="secondary-regular text-light-60">
                  Legal Entity
                </span>
                <span className="inline-regular text-dark-60">
                  {legalEntity.name}
                </span>
              </div>
            </div>
            <div className="flex items-baseline justify-between gap-tw-1 px-tw-4">
              <span className="secondary-regular text-light-60">
                Total Amount
              </span>
              <CurrencyFormatter
                classes={{ wrapper: 'header5-regular text-dark-60' }}
                value={changeOrder.amount}
              />
            </div>
          </div>
          {((changeOrder?.approvers?.length > 0 &&
            changeOrder.approvers.some((a) => a.requestedAt != null)) ||
            changeOrder.status === 'APPROVED') && (
            <MessageWithApprovers
              status={changeOrder.status}
              approvers={changeOrder.approvers}
            />
          )}

          {changeOrder.canApprove && (
            <div className="flex h-max flex-col gap-tw-4 rounded-2xl bg-light p-tw-4">
              {daysSinceRequested > 0 && (
                <AlertMessage days={daysSinceRequested} />
              )}
              <Button
                variant="success"
                iconName="acceptAlt"
                onClick={handleApprove}
                fluid
                disabled={isLoading}
              >
                Approve Change Order
              </Button>
            </div>
          )}
        </div>
        <div>
          <ChangeOrderDetails changeOrder={changeOrder} />
          <IncludesChangeEvents
            changeOrder={changeOrder}
            legalEntityCode={legalEntityCode}
          />
          <div className="change-card ">
            <div className="change-card__header">
              <div className="change-card__title header6-bold pb-m">
                Documents
              </div>
            </div>
            <div className="flex flex-col gap-tw-2 px-l pb-l">
              {changeOrder.documents.map((file, idx) => (
                <DocumentCard
                  {...file}
                  key={`doc${idx}`}
                  filename={file.title}
                  fileExtension={file.extension}
                  date={file.createdAt}
                  actions={
                    <div className="flex gap-tw-2">
                      <FileStatusBadge
                        className="max-xl:hidden"
                        status={
                          changeOrder.status === 'APPROVED'
                            ? 'Approved'
                            : 'Verified'
                        }
                      />
                      <IconButton
                        iconName="eye"
                        onClick={async () => {
                          const changeOrderFile =
                            await viewDocumentReconcileChangeEvent({
                              legalEntityCode,
                              documentId: file.id,
                              id: changeOrder.id,
                            });
                          window.open(changeOrderFile.url, '_blank');
                        }}
                      />
                      <DownloadFileIconButton
                        className="max-xl:hidden"
                        url={file.url}
                        title={file.title}
                      />
                      <PrintFileIconButton
                        className="max-xl:hidden"
                        file={file}
                      />
                    </div>
                  }
                  disabled
                />
              ))}
            </div>
          </div>
        </div>

        <div className="row-span-2 m-tw-2 md:m-0">
          <h6 className="light-60 header6-bold mb-tw-2 hidden md:block">
            History
          </h6>
          <Collapse expanded={historyCollapsed}>
            <History
              changeOrder={changeOrder}
              legalEntityCode={legalEntityCode}
              className="h-auto"
            />
          </Collapse>
          <Button
            className={cn({ hidden: !isMobile })}
            variant="secondary"
            onClick={() => setHistoryCollapsed(!historyCollapsed)}
            fluid
          >
            {historyCollapsed ? 'Hide' : 'Show'} CO History
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChangeOrderExternal;
