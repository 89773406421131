import {
  SourceUnitType,
  SreUnitType,
  useSreUnitTypeSettingsModal,
} from 'bundles/Shared/entities/sreUnitType';
import { Button, IconButton, Popover, SearchInput } from '@/stories';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useEffect, useState } from 'react';
import {
  currentUserAllowedToConfigureUnitTypes,
  useDeleteApiSettingsReportRentRollUnitTypesByIdMutation,
  useGetApiSettingsReportRentRollUnitTypesQuery,
  usePostApiSettingsReportRentRollUnitTypesMutation,
  usePutApiSettingsReportRentRollSourceUnitTypesSetUnitTypeMutation,
  usePutApiSettingsReportRentRollUnitTypesByIdMutation,
} from '@/entities/report/unitTypes';
import { useItemsFilterByText } from '@/shared/lib/hooks/useItemsFilterByText';
import fuzzysort from 'fuzzysort';
import { BOTTOM_LEVEL_POPOVER_Z_INDEX } from 'bundles/Shared/constants';
import { SETTINGS_REPORT_OPERATIONAL_DICTIONARY } from 'bundles/Settings/components/REport/Operational/config';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';
import { mapItemsToListOption } from '@/shared/lib/listHelpers';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';

export const SreUnitTypePopover = ({
  initialValue,
  sourceUnitType,
  onClose,
  ...props
}: {
  initialValue: SreUnitType | null;
  sourceUnitType: SourceUnitType;
  onClose: () => void;
} & Omit<React.ComponentProps<typeof Popover>, 'template'>) => {
  const { confirm } = useModal();
  const [checkedUnitType, setCheckedUnitType] = useState<SreUnitType | null>(
    initialValue,
  );

  useEffect(() => {
    setCheckedUnitType(initialValue);
  }, [initialValue]);

  const { data: unitTypesData } =
    useGetApiSettingsReportRentRollUnitTypesQuery();
  const unitTypes = unitTypesData?.items ?? [];
  const openSreUnitTypeSettingsModal = useSreUnitTypeSettingsModal();

  const [updateSourceUnitType] =
    usePutApiSettingsReportRentRollSourceUnitTypesSetUnitTypeMutation();
  const [editUnitType] = usePutApiSettingsReportRentRollUnitTypesByIdMutation();
  const [deleteUnitType] =
    useDeleteApiSettingsReportRentRollUnitTypesByIdMutation();

  const [createUnitType] = usePostApiSettingsReportRentRollUnitTypesMutation();
  const handleRemoveUnitType = async (unitType: SreUnitType) => {
    const res = await confirm();

    if (!res) return;

    deleteUnitType({
      id: unitType.id,
    });
  };
  const handleResetUnitType = async (id: SourceUnitType['id']) => {
    const res = await confirm();

    if (!res) return;

    updateSourceUnitType({
      body: {
        source_unit_type_ids: [id],
        unit_type_id: null,
      },
    });
  };

  const handleEditUnitType = async (unitType: SreUnitType) => {
    const res = await openSreUnitTypeSettingsModal(unitType);

    if (res == null) return;

    editUnitType({
      id: unitType.id,
      body: {
        label: res.label,
        name: res.name,
      },
    });
  };

  const handleCreateUnitType = async () => {
    const res = await openSreUnitTypeSettingsModal({
      label: '',
      name: '',
    });

    if (res == null) return;

    createUnitType({
      body: {
        label: res.label,
        name: res.name,
      },
    });
  };
  const searchRowActions = currentUserAllowedToConfigureUnitTypes() && (
    <IconButton
      onClick={handleCreateUnitType}
      variant="secondary"
      size="l"
      iconName="add"
    />
  );
  const onUnitTypeEdit = currentUserAllowedToConfigureUnitTypes()
    ? handleEditUnitType
    : undefined;
  const onUnitTypeRemove = currentUserAllowedToConfigureUnitTypes()
    ? handleRemoveUnitType
    : undefined;
  const onUnitTypeReset = () => handleResetUnitType(sourceUnitType.id);

  const { filteredItems: filteredUnitTypes, inputProps } = useItemsFilterByText(
    unitTypes,
    (item, searchText) =>
      fuzzysort.go(searchText, [item.label, item.name]).length > 0,
  );
  const onSubmit = (unitType: SreUnitType) => {
    updateSourceUnitType({
      body: {
        unit_type_id: unitType.id,
        source_unit_type_ids: [sourceUnitType.id],
      },
    });
  };
  return (
    <Popover
      onHidden={() => {
        if (
          checkedUnitType == null ||
          initialValue?.id === checkedUnitType.id
        ) {
          onClose();
          return;
        }

        onSubmit(checkedUnitType);
        onClose();
      }}
      appendToBody
      placement="bottom-end"
      trigger="click"
      zIndex={BOTTOM_LEVEL_POPOVER_Z_INDEX}
      maxWidth={280}
      hiddenArrow
      template={
        <div className="flex flex-col gap-tw-2">
          <div className="flex items-center justify-between">
            <p className="secondary-semibold text-neutral-500">
              sRE {SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.pluralForm}
            </p>

            {initialValue && (
              <Button
                variant="secondary"
                size="xs"
                onClick={() => {
                  onUnitTypeReset();
                }}
              >
                Reset
              </Button>
            )}
          </div>
          <div className="flex items-center gap-tw-2">
            <SearchInput
              size="s"
              placeholder={
                SETTINGS_REPORT_OPERATIONAL_DICTIONARY.UnitTypes.singularForm
              }
              {...inputProps}
            />
            {searchRowActions}
          </div>
          <OverlayScrollbarsComponent
            style={{
              maxHeight: 200,
            }}
          >
            <RadioGroup
              value={checkedUnitType?.id}
              onChange={(o) =>
                setCheckedUnitType(unitTypes.find((ut) => ut.id === o.value))
              }
              getItemProps={(o) => ({
                actionsSlot: (onUnitTypeEdit || onUnitTypeRemove) && (
                  <div className="ml-auto flex gap-tw-1">
                    {onUnitTypeEdit && (
                      <IconButton
                        onClick={() =>
                          onUnitTypeEdit(
                            unitTypes.find((ut) => ut.id === o.value),
                          )
                        }
                        iconName="edit"
                      />
                    )}
                    {onUnitTypeRemove && (
                      <IconButton
                        onClick={() =>
                          onUnitTypeRemove(
                            unitTypes.find((ut) => ut.id === o.value),
                          )
                        }
                        iconName="trash"
                      />
                    )}
                  </div>
                ),
              })}
              options={mapItemsToListOption(filteredUnitTypes, 'label')}
            />
            {filteredUnitTypes.length === 0 && (
              <NoDataOverlay title="Not Found" />
            )}
          </OverlayScrollbarsComponent>
        </div>
      }
      {...props}
    />
  );
};
