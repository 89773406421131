import React, { useMemo } from 'react';
import { WidgetStateProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { DateRangeWidgetStateWithGranularity } from 'bundles/Shared/widgets/dashboard/widgets/common';
import dayjs from 'dayjs';
import { CalendarRangeSelector } from 'stories/Calendar/RangeSelector/CalendarRangeSelector';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { useAppSelector } from '@/shared/lib/hooks/redux';

export function WidgetStateCalendarRangeSelector({
  state,
  onStateChange,
  ...props
}: WidgetStateProps<DateRangeWidgetStateWithGranularity> &
  Omit<
    React.ComponentProps<typeof CalendarRangeSelector>,
    'value' | 'onChange'
  >) {
  const fullscreen = useAppSelector((s) => s.widgetFullscreen.value);

  const step = useMemo(() => {
    return {
      value: 1,
      unit: state.granularity,
    };
  }, [state.granularity]);

  const defaultValue = useMemo(
    () => [dayjs(state.dateFrom).toDate(), dayjs(state.dateTo).toDate()],
    [],
  );

  return (
    <CalendarRangeSelector
      popoverProps={{
        appendTo: fullscreen ? 'parent' : () => document.body,
      }}
      defaultValue={defaultValue}
      stepValue={step.value}
      stepUnit={step.unit}
      disableFuture
      value={[dayjs(state.dateFrom).toDate(), dayjs(state.dateTo).toDate()]}
      onChange={(value) => {
        onStateChange({
          ...state,
          dateFrom: formatToDateStringForRequest(dayjs(value[0])),
          dateTo: formatToDateStringForRequest(dayjs(value[1])),
        });
      }}
      {...props}
    />
  );
}
