import { EditPermissionsModal } from '@/bundles/Shared/components/Permissions/EditPermissionsModal';
import type { Permitted } from '@/bundles/Shared/entities/permissions';
import { useLazyGetPermissionModalSubjectablesQuery } from '@/bundles/Shared/shared/api/pemissionModalApi';
import { useModal } from '@/shared/lib/hooks/useModal';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { uniqBy } from 'lodash-es';

export const useEditTemplatePermitted = ({
  permitted,
}: {
  permitted?: Pick<
    Permitted,
    'directUsers' | 'directRoles' | 'directTags' | 'directInvestmentEntities'
  >;
}) => {
  const [fetchPermissionModalSubjectables] =
    useLazyGetPermissionModalSubjectablesQuery();

  const { openModal } = useModal();

  const handleEditPermitted = async () => {
    if (permitted == null) return;

    const permittedResult = await openModal(EditPermissionsModal, {
      onlyRestrictedTab: true,
      ignoreUndeselectable: true,
      modalProps: {
        header: 'Configure Custom Template Recipients',
      },
      fetchSubjectables: async () => {
        const res = await fetchPermissionModalSubjectables({});

        if (!('data' in res) && res.data == null) return;

        const { data } = res;

        const permittedRoleIds = mapListToIds(permitted?.directRoles ?? []);
        const permittedTagIds = mapListToIds(permitted?.directTags ?? []);
        const permittedInvestmentEntitiesIds = mapListToIds(
          permitted?.directInvestmentEntities ?? [],
        );

        const indirectUsersFromTags = (data?.users ?? []).filter(
          (u) => u.tags?.some((tag) => permittedTagIds.includes(tag.id)),
        );
        const indirectUsersFromRoles = (data?.users ?? []).filter((u) =>
          u.role ? permittedRoleIds.includes(u.role.id) : false,
        );
        const indirectUsersFromInvestmentEntities = (data?.users ?? []).filter(
          (u) =>
            u.investmentEntities?.some((ie) =>
              permittedInvestmentEntitiesIds.includes(ie.id),
            ),
        );
        const indirectUsers = uniqBy(
          indirectUsersFromRoles
            .concat(indirectUsersFromTags)
            .concat(indirectUsersFromInvestmentEntities),
          'id',
        );

        return {
          allUsers: data?.users ?? [],
          allInvestmentEntities: data?.investmentEntities ?? [],
          allRoles: data?.userRoles ?? [],
          allTags: data?.userTags ?? [],

          directInvestmentEntities: permitted?.directInvestmentEntities ?? [],
          directRoles: permitted?.directRoles ?? [],
          directTags: permitted?.directTags ?? [],
          directUsers: permitted?.directUsers ?? [],
          indirectUsers,
        };
      },
    });

    if (permittedResult == null) return;

    return permittedResult;
  };

  return handleEditPermitted;
};
