import { WIDGETS_CONFIG_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import {
  useGetApiReportEagleEyeDashboardsByEagleEyeDashboardIdWidgetSectionsAndIdQuery,
  useGetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdQuery,
} from 'bundles/Shared/shared/api/dashboardSettingsEnhancedApi';
import { WidgetProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { useCallback, useMemo } from 'react';

import { DisplayedGroupsWidgetContext } from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { useWidgetInView } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/useWidgetInView';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import {
  GetApiReportEagleEyeDashboardsByEagleEyeDashboardIdWidgetSectionsAndIdApiArg,
  ReportDashboardAsset,
  ReportDashboardSegment,
  ReportDashboardType,
  selectWidgetStateFromDashboardSettingsById,
  updateDashboardWidgetState,
} from 'bundles/Shared/entities/dashboard';
import { WidgetErrorBoundary } from 'bundles/Shared/widgets/dashboard/widgetsHelpers/ui/WidgetErrorBoundary';
import { UnknownRecord } from 'type-fest/source/internal';
import {
  ErrorResponse,
  RtkQueryErrorLoggerMiddlewareArgs,
} from '@/app/stores/errorHandling';

type Props = Omit<
  WidgetProps,
  'onStateChange' | 'data' | 'state' | 'dashboardType' | 'context'
> &
  EagleEyeDashboardWidgetContext;

export type EagleEyeDashboardWidgetContext = DisplayedGroupsWidgetContext & {
  segments: ReportDashboardSegment[];
  assets: ReportDashboardAsset[];
};

export function EagleEyeDashboardWidget(props: Props) {
  const { ref, inView } = useWidgetInView();
  const dispatch = useAppDispatch();
  const { mode, dashboardId, widgetSection, boardId, assets, segments } = props;
  const widgetId = widgetSection.id;
  const state = useAppSelector(
    selectWidgetStateFromDashboardSettingsById({
      widgetId,
      dashboardId,
      boardId,
    }),
  ) as UnknownRecord;

  const context = useMemo<EagleEyeDashboardWidgetContext>(
    () => ({
      columnVisibilityEnabled: true,
      segments,
      assets,
    }),
    [assets, segments],
  );

  const handleStateChange = useCallback(
    (widgetState: UnknownRecord) => {
      dispatch(
        updateDashboardWidgetState({
          widgetState,
          dashboardId,
          id: widgetId,
          boardId,
        }),
      );
    },
    [dashboardId, widgetId],
  );

  const getRequestArgs = () => {
    if (!state) {
      return {};
    }
    return {
      id: widgetId,
      boardId,
      eagleEyeDashboardId: dashboardId,
      date: (state.date as string) ?? formatToDateStringForRequest(new Date()),
      dateFrom: state?.dateFrom,
      dateTo: state?.dateTo,
      granularity: state?.granularity,
      groupingType: state?.groupingType,
      period: state.period,
      assetIds: state?.assets,
      segmentIds: state?.segments,
      legalEntityIds: state?.legalEntities,
      filters: state.filters ? JSON.stringify(state.filters) : undefined,
      query: state?.query,
      page: state?.page,
      perPage: state.perPage,
      periodType: state.periodType,
      disableToast: true,
      useLtoThresholds: state.useLtoThresholds,
    } as GetApiReportEagleEyeDashboardsByEagleEyeDashboardIdWidgetSectionsAndIdApiArg &
      RtkQueryErrorLoggerMiddlewareArgs;
  };
  const viewData =
    useGetApiReportEagleEyeDashboardsByEagleEyeDashboardIdWidgetSectionsAndIdQuery(
      getRequestArgs() as GetApiReportEagleEyeDashboardsByEagleEyeDashboardIdWidgetSectionsAndIdApiArg,
      {
        skip: mode === 'edit' || !inView,
      },
    );

  const settingsData =
    useGetApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdQuery(
      getRequestArgs(),
      {
        skip: mode === 'view' || !inView,
      },
    );

  const { Component } = WIDGETS_CONFIG_MAP[widgetSection.widgetType] ?? {};

  if (Component == null) {
    return null;
  }
  const currentData = mode === 'edit' ? settingsData : viewData;

  return (
    <WidgetErrorBoundary
      widgetSection={widgetSection}
      dashboardType={ReportDashboardType.EAGLE_EYE}
      mode={props.mode}
    >
      <div className="h-full" ref={ref}>
        <Component
          data={currentData.data}
          isLoading={currentData.isLoading}
          isError={currentData.isError}
          isFetching={currentData.isFetching}
          isUninitialized={currentData.isUninitialized}
          errorMessage={(currentData.error?.data as ErrorResponse)?.errors}
          onStateChange={handleStateChange}
          state={state ?? {}}
          dashboardType={ReportDashboardType.EAGLE_EYE}
          context={context}
          {...props}
        />
      </div>
    </WidgetErrorBoundary>
  );
}
