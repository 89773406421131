import {
  DateRangeWidgetStateWithGranularity,
  DateRangeWidgetState,
  WidgetStateGranularity,
} from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { useContextChangeHandler } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilder';
import { WidgetStateCalendarRangeSelector } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateCalendarRangeSelector';
import { widgetHasData } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib';
import { WidgetConfigCardProps } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { selectWidgetById } from '@/entities/report/reportBuilder';
import { ExpandableWidgetConfigCard } from '@/entities/report/reportBuilder/ui/BaseWidgetConfigCard';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import React from 'react';

export const WidgetContextCalendarRangeCard = (
  props: WidgetConfigCardProps &
    React.ComponentProps<typeof ExpandableWidgetConfigCard>,
) => {
  const widget = useAppSelector(selectWidgetById(props.widgetSection.id));
  const handleContextChange = useContextChangeHandler<DateRangeWidgetState>({
    widgetId: widget?.id ?? '',
    widgetGroupId: widget?.group.id ?? '',
  });
  return (
    <ExpandableWidgetConfigCard {...props} hasData={widgetHasData(props.data)}>
      {widget && (
        <WidgetStateCalendarRangeSelector
          state={widget.context}
          onStateChange={handleContextChange}
        />
      )}
    </ExpandableWidgetConfigCard>
  );
};

export const WidgetContextCalendarRangeWithGranularityCard = (
  props: WidgetConfigCardProps &
    React.ComponentProps<typeof ExpandableWidgetConfigCard>,
) => {
  const widget = useAppSelector(selectWidgetById(props.widgetSection.id));
  const handleContextChange =
    useContextChangeHandler<DateRangeWidgetStateWithGranularity>({
      widgetId: widget?.id ?? '',
      widgetGroupId: widget?.group.id ?? '',
    });
  return (
    <ExpandableWidgetConfigCard {...props} hasData={widgetHasData(props.data)}>
      {widget && (
        <div className="flex flex-col gap-tw-2">
          <WidgetStateGranularity
            state={widget.context}
            onStateChange={handleContextChange}
          />
          <WidgetStateCalendarRangeSelector
            state={widget.context}
            onStateChange={handleContextChange}
          />
        </div>
      )}
    </ExpandableWidgetConfigCard>
  );
};
