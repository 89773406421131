import { IInvestmentEntity } from '@/types/IInvestmentEntity';
import { IUserTag } from '@/types/IUserTag';
import { IUser, IUserRole } from '@/types/User';
import { emptySplitApi as api } from '../../../../app/stores/api';

interface GetPermissionModalSubjectablesApiArg {
  only_product?: string;
  asset_id?: number;
  fund_id?: number;
  objectable_id?: number;
  objectable_type?: string;
}

export interface GetPermissionModalSubjectablesApiResponse {
  investmentEntities: Pick<IInvestmentEntity, 'id' | 'name'>[];
  userRoles: Pick<
    IUserRole,
    'id' | 'name' | 'group' | 'category' | 'undeselectable'
  >[];
  users: Pick<
    IUser,
    | 'id'
    | 'fullName'
    | 'initials'
    | 'role'
    | 'email'
    | 'tags'
    | 'investmentEntities'
  >[];
  userTags: Pick<IUserTag, 'id' | 'name'>[];
}

const permissionModalApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPermissionModalSubjectables: build.query<
      GetPermissionModalSubjectablesApiResponse,
      GetPermissionModalSubjectablesApiArg
    >({
      query: (queryArg) => ({
        url: '/api/permission_modal/subjectables',
        params: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetPermissionModalSubjectablesQuery } =
  permissionModalApi;
