import type { RRule } from 'rrule';

export const getFailedEmailMsgs = (emailMessages) => {
  const filteredMsgs = emailMessages.filter(
    ({ events }) => events.length > 0 && events.at(-1).kind === 'failed',
  );
  return filteredMsgs;
};

export const filterUnsubscribedMessages = (deliveredEmails) =>
  deliveredEmails.filter((em) =>
    em.events.some((e) => e.kind === 'unsubscribed'),
  );

export const filterComplainedMessages = (deliveredEmails) =>
  deliveredEmails.filter((em) =>
    em.events.some((e) => e.kind === 'complained'),
  );

export const getRRuleOptionsFormErrorState = (
  rruleOptions: RRule['options'],
) => {
  return {
    monthDay:
      rruleOptions.bymonthday?.[0] > 31 ||
      (rruleOptions.bymonthday?.[0] ?? 0) === 0,
    repeatEvery: rruleOptions.interval === 0,
  };
};
