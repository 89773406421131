import React, { useMemo } from 'react';
import { SectionField } from 'stories/Field/FieldsWrappers';
import {
  findFormulaOrVariableByReference,
  FormulaCard,
  isFormula,
  TEXT_VARIABLE_REFERENCE_PREFIX,
  useReportFormulasQuery,
} from 'bundles/Shared/entities/formula';
import { Button, IconButton, InlineAlert } from '@/stories';
import { Control, useController } from 'react-hook-form';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useGetApiSettingsReportVariablesQuery } from 'bundles/Shared/shared/api/settingsReportFormulasEnhanced';
import { InspectFormulaButton } from 'bundles/Shared/features/formula/editFormula';
import { FormulaAndVariablesPanel } from 'bundles/Shared/widgets/formula/panel';
import { isEmpty } from 'lodash-es';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import { VariablesPanel } from '@/bundles/Shared/widgets/formula/panel/ui/VariablesPanel';
import { GrowDiv } from '@/shared/ui/GrowDiv';

const useSelectedFormulaOrVariable = (reference: string) => {
  const { data: variablesData = [], isLoading: isLoadingVariables } =
    useGetApiSettingsReportVariablesQuery();

  const { formulas, isLoading: isFormulasLoading } = useReportFormulasQuery();
  const formulaOrVariable = useMemo(() => {
    return findFormulaOrVariableByReference(
      [...formulas, ...variablesData] ?? [],
      reference,
    );
  }, [formulas, variablesData, reference]);

  return [
    formulaOrVariable,
    {
      isLoading: isFormulasLoading || isLoadingVariables,
    },
  ] as const;
};

const EmptyAlert = ({
  value,
  required,
}: {
  value: string;
  required?: boolean;
}) => {
  const { isLoading } = useReportFormulasQuery();

  return !isLoading && isEmpty(value) && !required ? (
    <InlineAlert
      className="w-full"
      status="info"
      message="Don't worry! You can choose your formula anytime later!"
    />
  ) : null;
};

const NotFoundAlert = ({ value }: { value: string }) => {
  const formulaReference = value;
  const [selectedFormulaOrVariable, { isLoading }] =
    useSelectedFormulaOrVariable(formulaReference);
  if (isLoading || isEmpty(formulaReference) || selectedFormulaOrVariable) {
    return null;
  }
  return (
    <InlineAlert
      className="h-auto w-full"
      classes={{
        body: 'py-tw-1',
      }}
      status="danger"
      message={
        <>
          Can't find formula or variable with reference{' '}
          <span className="secondary-semibold">{formulaReference}</span>
        </>
      }
    />
  );
};

const Card = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const [selectedFormulaOrVariable] = useSelectedFormulaOrVariable(value);
  const renderCloseButton = () => {
    return (
      <IconButton
        iconName="closeSmall"
        size="m"
        variant="white"
        onClick={() => {
          onChange('');
        }}
      />
    );
  };
  const renderVariableCard = () => {
    if (!selectedFormulaOrVariable) {
      return null;
    }
    return (
      <FormulaCard
        reference={selectedFormulaOrVariable.reference}
        label={selectedFormulaOrVariable.label}
        labelInfo={renderCloseButton()}
        description={undefined}
        className="!rounded-lg bg-neutral-100"
      />
    );
  };
  const renderFormulaCard = () => {
    if (!selectedFormulaOrVariable || !isFormula(selectedFormulaOrVariable)) {
      return null;
    }
    return (
      <FormulaCard
        className="!rounded-lg bg-neutral-100"
        reference={selectedFormulaOrVariable.reference}
        label={selectedFormulaOrVariable.label}
        description={selectedFormulaOrVariable.description}
        tags={selectedFormulaOrVariable.tags}
        labelPanel={
          <>
            {isFormula(selectedFormulaOrVariable) && (
              <InspectFormulaButton
                reference={selectedFormulaOrVariable.reference}
              />
            )}
            <GrowDiv />
            {renderCloseButton()}
          </>
        }
      />
    );
  };
  return (
    value &&
    !isEmpty(selectedFormulaOrVariable) &&
    (isFormula(selectedFormulaOrVariable)
      ? renderFormulaCard()
      : renderVariableCard())
  );
};

const SelectButton = ({ onChange }: { onChange: (value: string) => void }) => {
  const { openModal } = useModal();
  const handleClick = async () => {
    const res = await openModal(FormulaAndVariablesPanel, {});
    if (!res) {
      return;
    }

    onChange(res);
  };

  return (
    <Button variant="primary" onClick={handleClick}>
      Select
    </Button>
  );
};

export const TextVariableField = ({
  control,
  name,
  required,
  children,
}: React.PropsWithChildren<{
  name: string;
  control: Control<FieldValues>;
  required?: boolean;
  label?: string;
}>) => {
  const { openModal } = useModal();
  const { field } = useController({
    control,
    name,
  });
  const variableReference = field.value;
  const [selectedVariable, { isLoading }] =
    useSelectedFormulaOrVariable(variableReference);

  const showSelectButton =
    (!isLoading && isEmpty(variableReference)) || !selectedVariable;

  const handleSelect = async () => {
    const res = await openModal(VariablesPanel, {
      hideNamespaceFilter: true,
      selectedNamespace: TEXT_VARIABLE_REFERENCE_PREFIX,
    });
    if (!res) {
      return;
    }

    field.onChange(res);
  };
  return (
    <SectionField
      className="gap-tw-4"
      button={
        showSelectButton && (
          <Button variant="primary" onClick={handleSelect}>
            Select
          </Button>
        )
      }
      labelText="Variable"
    >
      <EmptyAlert value={field.value} required={required} />
      <NotFoundAlert value={field.value} />
      <Card value={field.value} onChange={field.onChange} />
      {children}
    </SectionField>
  );
};

export function FormulaField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  required,
  children,
  label,
}: React.PropsWithChildren<{
  name: TName;
  control: Control<TFieldValues>;
  required?: boolean;
  label?: string;
}>) {
  const { field } = useController({
    control,
    name,
  });
  const formulaReference = field.value;
  const [selectedFormulaOrVariable, { isLoading }] =
    useSelectedFormulaOrVariable(formulaReference);

  const showSelectButton =
    (!isLoading && isEmpty(formulaReference)) || !selectedFormulaOrVariable;

  return (
    <SectionField
      className="gap-tw-4"
      button={showSelectButton && <SelectButton onChange={field.onChange} />}
      labelText={
        <div className="flex flex-col gap-tw-1">
          {label ?? 'Value'}
          <span className="secondary-regular text-neutral-550">
            Formula or Variable
          </span>
        </div>
      }
    >
      <EmptyAlert value={field.value} required={required} />
      <NotFoundAlert value={field.value} />
      <Card value={field.value} onChange={field.onChange} />
      {children}
    </SectionField>
  );
}

FormulaField.EmptyAlert = EmptyAlert;
FormulaField.NotFoundAlert = NotFoundAlert;
FormulaField.Card = Card;
FormulaField.SelectButton = SelectButton;
