import { usePutApiCapitalLegalEntitiesByLegalEntityIdPublishToggleMutation } from '@/entities/return/api/capitalLegalEntitiesEnhancedApi';
import CapitalInvestmentImage from '@/entities/return/ui/CapitalInvestmentImage';
import CapitalInvestmentStatus from '@/entities/return/ui/CapitalInvestmentStatus';
import CapitalSettingsDropdown from '@/entities/return/ui/CapitalSettingsDropdown';
import { Link, useLocation } from '@reach/router';
import { LEList } from 'bundles/Shared/entities/legalEntity/ui/LegalEntitiesIconWithTooltip';
import { useLoadedInvestmentObject } from 'bundles/REturn/hooks/useLoadedInvestmentObject';
import {
  ROUTES_ROOT,
  generatePath,
} from '@/shared/lib/hooks/useNavigation';
import { currentUserIsExternal } from 'lib/permissions';
import { Button } from 'stories/index';
import { exportCapitalInvestmentObject } from '@/bundles/REturn/actions/capitalInvestments';
import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';

const OwnershipsHeader = () => {
  const { pathname } = useLocation();
  const { data: capitalInvestmentObject } = useLoadedInvestmentObject();
  const [toggleLegalEntityPublished] =
    usePutApiCapitalLegalEntitiesByLegalEntityIdPublishToggleMutation();
  const { object, legalEntities } = capitalInvestmentObject;

  const [searchParams] = useSearchParams();
  const filterLeIds = JSON.parse(searchParams?.get('legalEntityIds') ?? '[]');

  const objectLink =
    object.objectType === 'asset'
      ? ROUTES_ROOT.assets.asset.fullPath
      : ROUTES_ROOT.funds.fund.fullPath;
  const objectLinkSlug:
    | Record<'assetSlug', string>
    | Record<'fundSlug', string> =
    object.objectType === 'asset'
      ? { assetSlug: object.slug }
      : { fundSlug: object.slug };

  return (
    <div className="w-100 align-center items=center flex justify-between">
      <div className="flex items-center gap-tw-4">
        <Link
          to={generatePath(objectLink, objectLinkSlug)}
          state={{ from: pathname }}
          className="relative"
        >
          <CapitalInvestmentImage pictureUrl={object?.smallPictureUrl} />
        </Link>
        <div className="flex flex-col gap-tw-2">
          <p className="secondary-semibold uppercase text-neutral-500">
            return
          </p>
          <h5 className="header5-bold text-neutral-900">{object?.name}</h5>
        </div>
        <div className="flex items-center gap-tw-2">
          <Button
            tooltipProps={{
              mainText: <LEList legalEntities={legalEntities} />,
            }}
            className="secondary-regular gap-tw-1"
            iconName="entities"
            variant="secondary"
            size="s"
          >
            {legalEntities.length}
          </Button>
          {!currentUserIsExternal() && legalEntities.length > 0 && (
            <CapitalInvestmentStatus
              legalEntities={legalEntities}
              onChange={({ id: legalEntityId }) =>
                toggleLegalEntityPublished({
                  legalEntityId,
                  capitalInvestmentObjectId: capitalInvestmentObject.id,
                })
              }
            />
          )}
          <CapitalSettingsDropdown />
        </div>
      </div>
      <div>
        <Button
          onClick={() =>
            exportCapitalInvestmentObject(
              pathname.split('/').pop(),
              object.slug,
              filterLeIds?.length > 0 ? filterLeIds : undefined,
            )
          }
          variant="secondary"
          iconName="docExcel"
          size="s"
          iconClasses="green-dark-1"
          tooltipProps={{
            mainText: 'Export as xlsx',
          }}
        >
          Export
        </Button>
      </div>
    </div>
  );
};

export default OwnershipsHeader;
