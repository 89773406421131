import React, { useState } from 'react';
import { navigate, useParams } from '@reach/router';
import {
  useApproveInvoiceMutation,
  useGetInvoiceQuery,
} from 'bundles/Construction/api/invoices';
import FilePreview from 'bundles/Shared/components/FilePreviews/FilePreview';
import { useModal } from '@/shared/lib/hooks/useModal';
import { generatePath, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import {
  AnimationLoader,
  Button,
  Checkbox,
  CurrencyFormatter,
  Icon,
  IconButton,
  Tooltip,
} from 'stories';
import { formatDate } from '@/shared/lib/formatting/dates';
import StatusesInvoice from './components/StatusesInvoice';
import InvoiceRequisitionModal from '../requisition/InvoiceRequisitionModal';
import LineItemsList from './components/LineItemsList';
import ApproveActions from './components/ApproveActions';
import KeyDetailsItem from 'bundles/DrawPackage/InvoicesFlow/Invoices/layouts/KeyDetailsItem';
import { GapBlock } from 'bundles/DrawPackage/InvoicesFlow/Invoices/layouts/GapBlock';

const ApproveInvoice = () => {
  const [confirmDetails, setConfirmDetails] = useState<boolean>(false);
  const params = useParams();
  const { openModal } = useModal();

  const { data: invoice, isLoading } = useGetInvoiceQuery({
    legalEntityCode: params?.legalEntityCode,
    invoiceId: params.invoiceId,
  });

  const inRequisition = invoice?.requisition;
  const inDraw = invoice?.funding === 'in_draw';
  const isApprove = invoice?.status === 'approved';

  const [
    approveInvoice,
    { isLoading: isApproving, isSuccess: isSuccessApproving },
  ] = useApproveInvoiceMutation();

  const handleClose = () => {
    navigate(
      generatePath(
        ROUTES_ROOT.reconcile.development.legalEntity.invoices.fullPath,
        {
          legalEntityCode: params.legalEntityCode,
        },
      ),
    );
  };

  const approveInvoiceHandle = () => {
    approveInvoice({
      legalEntityCode: params.legalEntityCode,
      invoiceId: params.invoiceId,
    });
  };

  if (isSuccessApproving) {
    navigate(
      generatePath(
        ROUTES_ROOT.reconcile.development.legalEntity.invoices.pay.fullPath,
        {
          legalEntityCode: params.legalEntityCode,
          invoiceId: params.invoiceId,
        },
      ),
    );
  }

  const requisitionHandle = () => {
    openModal(InvoiceRequisitionModal, {
      params: {
        ...params,
        id: params?.invoiceId ?? params['*'],
      },
      type: inRequisition ? 'remove' : 'add',
    });
  };

  const routeRequistions = () => {
    navigate(
      generatePath(
        ROUTES_ROOT.reconcile.development.legalEntity.requisitions.fullPath,
        {
          legalEntityCode: params.legalEntityCode,
        },
      ),
    );
  };

  if (isLoading && !invoice) return <AnimationLoader />;

  return (
    <div>
      <FilePreview
        file={{
          url: invoice?.document.url,
        }}
        handleClose={handleClose}
        withoutHeader
      >
        <div className="relative flex h-screen flex-col bg-white">
          <div className="absolute right-tw-5 top-tw-5 flex gap-tw-2">
            {!isApprove && !inDraw && <ApproveActions />}
            <IconButton
              iconName="close"
              size="l"
              onClick={() => handleClose()}
              variant="secondary"
            />
          </div>
          <div className="flex-1">
            <div className="flex w-full justify-center py-tw-4">
              <CurrencyFormatter
                value={invoice?.amount}
                classes={{
                  value: 'header2-regular text-dark-60',
                  fractions: 'header2-regular text-dark-60',
                  startSymbol: 'header2-regular text-dark-60',
                }}
              />
            </div>
            <div className="body-regular flex justify-center gap-tw-1">
              <div>{formatDate(invoice?.date, 'MMM DD, YYYY')}</div>
              <div>•</div>
              <div>{invoice?.vendor.name}</div>
            </div>
            <div className="flex flex-col gap-tw-6">
              <div>
                <div className="px-tw-6 pb-tw-3 pt-tw-4">
                  <StatusesInvoice />
                </div>
                <div className="px-tw-6">
                  <div className="rounded-lg bg-light">
                    <KeyDetailsItem title="Invoice">
                      {invoice?.document.title}
                    </KeyDetailsItem>
                    <KeyDetailsItem title="Number">
                      {invoice?.number}
                    </KeyDetailsItem>
                    {inRequisition && (
                      <KeyDetailsItem title="Requisition">
                        <div className="flex items-center gap-tw-4">
                          <div className="inline-regular text-light-60">
                            {`(${formatDate(
                              invoice?.requisition.beginningDate,
                              'MMM DD, YYYY',
                            )} - ${formatDate(
                              invoice?.requisition.endingDate,
                              'MMM DD, YYYY',
                            )})`}
                          </div>
                          {!inDraw && (
                            <Tooltip
                              placement="top-end"
                              arrowPosition="end"
                              titleText="Remove from Requisition"
                            >
                              <Icon
                                iconName="closeSmall"
                                onClick={requisitionHandle}
                                className="text-light-60"
                              />
                            </Tooltip>
                          )}
                          <Icon
                            iconName="externalLink"
                            onClick={routeRequistions}
                            className="hidden text-light-60 group-hover:block"
                          />
                        </div>
                      </KeyDetailsItem>
                    )}
                  </div>
                </div>
                {!inRequisition && (
                  <div className="px-tw-6 pt-tw-4">
                    <Button
                      variant="secondary"
                      className="w-full"
                      onClick={requisitionHandle}
                    >
                      <div className="inline-semibold text-light-90">
                        Add to Requisition
                      </div>
                    </Button>
                  </div>
                )}
              </div>
              <GapBlock>
                <div className="body-semibold text-dark-60">
                  Development Line Items
                </div>
                <div>
                  <LineItemsList />
                </div>
              </GapBlock>
              {/* Temporary hide this functionality */}
              {/* {!isApprove && (
                <div className="px-tw-6">
                  <InvoiceApprover />
                </div>
              )} */}
            </div>
          </div>
          {invoice?.status === 'verified' && (
            <div className="flex flex-col gap-tw-4 bg-white px-tw-6 pb-tw-6 pt-tw-4">
              <div className="flex items-center gap-tw-2">
                <Checkbox
                  checked={confirmDetails}
                  onChange={() => setConfirmDetails(!confirmDetails)}
                />
                <div className="label-semibold text-light-90">
                  I confirm, that Invoice details have been Reviewed
                </div>
              </div>
              <Button
                variant="success"
                className="w-full"
                onClick={approveInvoiceHandle}
                disabled={!confirmDetails || isApproving}
              >
                <div className="flex gap-tw-2">
                  {!isApproving && (
                    <Icon
                      className={
                        !confirmDetails || isApproving
                          ? 'text-light-60'
                          : 'text-white'
                      }
                      iconName="acceptAlt"
                    />
                  )}
                  {isApproving && (
                    <Icon
                      className="form-button-loading text-light-60"
                      iconName="sync"
                    />
                  )}
                  <div
                    className={
                      !confirmDetails || isApproving
                        ? 'text-light-60'
                        : 'text-white'
                    }
                  >
                    Approve
                  </div>
                </div>
              </Button>
            </div>
          )}
        </div>
      </FilePreview>
    </div>
  );
};

export default ApproveInvoice;
