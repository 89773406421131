import { JSONEditor } from '@/shared/ui/CodeEditor/component';
import { Button } from '@/stories';
import {
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { useState } from 'react';

export function SettingsReportWidgetConfigEditor() {
  const { widget } = useWidgetConfig();
  const [updateConfig] = useUpdateWidgetConfig(widget.widgetType);
  const stringifiedWidgetConfig = JSON.stringify(
    { title: widget.title, widgetConfig: widget.widgetConfig },
    null,
    2,
  );
  const [widgetConfig, setWidgetConfig] = useState(stringifiedWidgetConfig);
  const isDirty = widgetConfig !== stringifiedWidgetConfig;

  const handleSave = () => {
    const config = JSON.parse(widgetConfig) as {
      title: string;
      widgetConfig: Record<string, unknown>;
    };
    updateConfig({
      config: config.widgetConfig,
      title: config.title,
    });
  };

  return (
    <div className="flex h-full w-[500px] min-w-[500px] flex-col gap-tw-4">
      <Button disabled={!isDirty} onClick={handleSave} variant="success">
        Save Updates
      </Button>
      <JSONEditor
        onChange={(value) => {
          setWidgetConfig(value!);
        }}
        value={widgetConfig}
      />
    </div>
  );
}
