import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

export const REPORT_BUILDER_VIEW_PAGES = [
  ROUTES_ROOT.reports.fullPath,
  ROUTES_ROOT.reportBuilderTemplates.fullPath,
];

export const REPORT_BUILDER_VIEW_END_PAGE_PATH =
  ROUTES_ROOT.reports.report.view.path;

export const isPathReportBuilderView = (path: string) =>
  REPORT_BUILDER_VIEW_PAGES.some((p) => path.startsWith(p)) &&
  path.endsWith(REPORT_BUILDER_VIEW_END_PAGE_PATH);
