import {
  AssetsReportBuilderReportDto,
  AssetsReportBuilderReportWidgetDto,
  PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg,
} from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { REPORT_BUILDER_WIDGET_TYPES_LIST } from '@/entities/report/reportBuilder/model';
import { isEqual, pick } from 'lodash-es';
import { UseFormWatch } from 'react-hook-form';

export const filterKnownWidgetTypes = (
  widget: AssetsReportBuilderReportWidgetDto,
): boolean => REPORT_BUILDER_WIDGET_TYPES_LIST.includes(widget.widgetType);

type PayloadBody =
  PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg['body'];

export const transformFormWatchToPayloadBody = (
  watch: UseFormWatch<{
    name: string;
    subtitle: string;
    date: string | undefined;
    dateFormat: string | undefined;
    legalEntityIds: string[];
  }>,
): PayloadBody => {
  return {
    name: watch('name').trim(),
    subtitle: watch('subtitle').trim(),
    date: watch('date'),
    legal_entity_ids: watch('legalEntityIds'),
    date_format: watch('dateFormat'),
  };
};
export const isReportPayloadBodyAndReportDataEqual = (
  report: AssetsReportBuilderReportDto,
  payloadBody: PayloadBody,
) => {
  const isFormDateAndDataDateEqual = isEqual(report.date, payloadBody.date);
  const isFormDateFormatEqual = isEqual(
    report.dateFormat,
    payloadBody.date_format,
  );
  const isFormAndDataEqual =
    isFormDateAndDataDateEqual &&
    isEqual(pick(report, ['name', 'subtitle']), {
      name: payloadBody.name,
      subtitle: payloadBody.subtitle,
    }) &&
    isFormDateFormatEqual;
  return {
    isFormDateAndDataDateEqual,
    isFormAndDataEqual,
    isFormDateFormatEqual,
  };
};

export const filterRedundantFieldsFromPayloadBody = ({
  isFormDateAndDataDateEqual,
  payloadBody,
}: {
  payloadBody: PayloadBody;
  isFormDateAndDataDateEqual: boolean;
}): PayloadBody => ({
  date: !isFormDateAndDataDateEqual ? payloadBody.date : undefined,
  legal_entity_ids: payloadBody.legal_entity_ids,
  name: payloadBody.name,
  subtitle: payloadBody.subtitle,
  date_format: payloadBody.date_format,
});
