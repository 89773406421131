import { REFERENCE_FIELD_NOTE } from 'bundles/Shared/entities/formula/config';
import {
  isSreUnitTypeNameValid,
  SRE_UNIT_TYPE_LABEL_FIELD_NOTE,
} from 'bundles/Shared/entities/sreUnitType';
import { LabelFieldController } from '@/shared/ui/LabelFieldController';
import { Field, Input } from '@/stories';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { FieldsContainer } from 'stories/Field/Field';
import { SettingsFormModal } from '@/bundles/Shared/components/SettingsModal/SettingsModal';

export const UNIT_TYPE_SCHEMA = yup.object().shape({
  name: yup.string().required().test(isSreUnitTypeNameValid),
  label: yup.string().required(),
});

export type UnitTypeForm = yup.InferType<typeof UNIT_TYPE_SCHEMA>;

export const UnitTypeFields = () => {
  const { control, register } = useFormContext<UnitTypeForm>();
  return (
    <FieldsContainer>
      <Field labelText="Label" note={SRE_UNIT_TYPE_LABEL_FIELD_NOTE}>
        <LabelFieldController
          name="label"
          control={control}
          referenceFieldName="name"
        />
      </Field>
      <Field labelText="Reference" note={REFERENCE_FIELD_NOTE}>
        <Input type="text" placeholder="" {...register('name')} />
      </Field>
    </FieldsContainer>
  );
};

export const UnitTypeSettingsModal = (
  props: Omit<
    React.ComponentProps<typeof SettingsFormModal<UnitTypeForm>>,
    'fields'
  >,
) => {
  return (
    <SettingsFormModal<UnitTypeForm> fields={<UnitTypeFields />} {...props} />
  );
};
