import React from 'react';
import { ValueDisplayOptionsFields } from 'bundles/Shared/widgets/dashboard/widgets/common';
import { SortingField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/SortingField';
import { Control, useFormContext } from 'react-hook-form';
import { ColumnSettingsForm } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import { FontField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/FontField';
import { ColorField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColorField';
import { HideNegativeValuesField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HideNegativeValuesField';
import { TableVizConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { HighlithingWithComparisonField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HighlithingWithComparisonField';

export function ColumnSettingsFields<
  TFieldValues extends ColumnSettingsForm = ColumnSettingsForm,
>({
  columnsConfig,
  ...props
}: {
  control: Control<TFieldValues>;
  vizConfig: TableVizConfig;
  columnsConfig: {
    key: number;
    label: string;
  }[];
}) {
  const control = props.control as Control<ColumnSettingsForm>;
  const { watch } = useFormContext<ColumnSettingsForm>();
  const comparison = watch('comparison');
  const highlightingRules = watch('highlighting_rules');

  return (
    <>
      <ValueDisplayOptionsFields
        control={control}
        name="value_display_options"
      />
      <SortingField control={control} name="sort" />
      <FontField control={control} name="font_weight" />
      <HideNegativeValuesField
        disabled={comparison != null || highlightingRules != null}
        disabledNote="Settings are not available because Comparison or Highlighting Rules are active"
        name="hide_negative_values"
        control={control}
      />
      <HighlithingWithComparisonField
        columnsConfig={columnsConfig}
        control={control}
        vizConfig={props.vizConfig}
      />
      <ColorField
        labelText="Border Color"
        control={control}
        name="border_color"
      />
    </>
  );
}
