import React, { useState } from 'react';
import { CategoryObject, IconButton } from 'stories';
import SetFinancialCategoryModal from '@/widgets/financialCategory/set/ui/SetFinancialCategoryModal';
import { useCategoryPathById } from '@/entities/finanicalCategory/lib';
import { FinancialCategory } from '@/entities/finanicalCategory/model';

interface Props {
  categoryId: FinancialCategory['id'] | null;
  categoriesTree: FinancialCategory[];
  onChange?: (id: FinancialCategory['id'] | null) => void;
}

export default function CategoriesList({
  categoriesTree,
  categoryId,
  onChange,
}: Props) {
  const [modalOpen, setModalOpen] = useState(false);

  const path = useCategoryPathById(categoryId, categoriesTree);

  const handleSubmit = (category: FinancialCategory | null) => {
    onChange?.(category?.id);
    setModalOpen(false);
  };

  return (
    <>
      <CategoryObject
        path={path.map(({ code }) => code)}
        onSetCategory={() => setModalOpen(true)}
      />
      {categoryId !== null && onChange && (
        <IconButton
          onClick={() => setModalOpen(true)}
          data-tip="Edit"
          iconName="edit"
          className="ml-s bg-white"
        />
      )}
      {modalOpen && (
        <SetFinancialCategoryModal
          categories={categoriesTree}
          onSubmit={handleSubmit}
          onClose={() => setModalOpen(false)}
          selectedCategory={path.find((c) => c.id === categoryId)}
          noSelectedPlaceholder="Select Category"
        />
      )}
    </>
  );
}
