import { useResetAllModalsOnUnmount } from '@/shared/lib/hooks/useModal';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useGetApiReconcileUnderwritingBudgetsByBudgetIdQuery } from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetEnhancedApi';
import { UpdateFinancialRow } from 'bundles/REconcile/underwritting/features/UpdateFinancialRow';
import { UpdateMetrics } from 'bundles/REconcile/underwritting/features/UpdateMetrics';
import {
  getUWBCategoryRows,
  getUWBMetricRows,
  useProcessDataFromClipboard,
  useUWBColumnDefs,
} from 'bundles/REconcile/underwritting/lib';
import { BudgetRow } from 'bundles/REconcile/underwritting/model';
import { SymmetreAgGridTable } from '@/bundles/Shared/components/AgGrid/Table/SymmetreAgGridTable';
import { AutoGroupCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupCellRenderer';
import { AutoGroupHeaderComponent } from 'bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupHeaderComponent';
import { CurrencyCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CurrencyCellRenderer';
import { HeaderComponent } from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderComponent';
import {
  DIVIDER_ROW,
  FINANCIAL_ROOT_ROW,
  METRICS_ROOT_ROW,
} from 'bundles/Shared/entities/undewriting/config';
import { useGridDefaultParams } from 'lib/ag-grid/useGridDefaultParams';
import { assertsType } from 'lib/typeHelpers/assertsType';
import { useMemo, useRef } from 'react';
import { AnimationLoader } from 'stories/index';

import { getDefaultAgGridNumberColDef } from '@/shared/lib/formatting/table';

export const UWBTableComponent = ({ budgetId }: { budgetId: string }) => {
  const gridRef = useRef<AgGridReact>(null);

  const { onGridReady } = useGridDefaultParams({
    enableExpandOnClick: true,
  });

  useResetAllModalsOnUnmount();
  const {
    data: budgetData,
    isFetching,
    isLoading,
  } = useGetApiReconcileUnderwritingBudgetsByBudgetIdQuery(
    { budgetId },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const columnDefs = useUWBColumnDefs({
    budgetId,
    budgetPeriods: budgetData?.periods ?? [],
    deps: [budgetData],
  });

  const rowData = useMemo(() => {
    const categories = getUWBCategoryRows({
      budgetFinancialCategoryRows:
        budgetData?.budgetFinancialCategoryRows ?? [],
    });
    const metrics = getUWBMetricRows({
      budgetMetricRows: budgetData?.budgetMetricRows ?? [],
    });

    return [
      {
        ...FINANCIAL_ROOT_ROW,
        label: <UpdateFinancialRow id={budgetId} />,
      },
      ...categories,
      DIVIDER_ROW,
      {
        ...METRICS_ROOT_ROW,
        label: <UpdateMetrics budgetId={budgetId} />,
      },
      ...metrics,
    ];
  }, [budgetData]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => {
        const { data } = params;
        if (data == null) return;
        assertsType<BudgetRow>(data, true);

        if (data.kind !== 'metric') {
          return <CurrencyCellRenderer {...params} />;
        }

        const colDef = getDefaultAgGridNumberColDef({
          type: data.valueType,
        });

        return colDef.cellRenderer(params);
      },
      headerComponent: HeaderComponent,
      suppressMovable: true,
      minWidth: 124,
    }),
    [],
  );

  const processDataFromClipboard = useProcessDataFromClipboard();

  if (isLoading) {
    return <AnimationLoader className="static h-full w-full" />;
  }
  return (
    <div className="relative h-full">
      {isFetching && (
        <AnimationLoader className="z-10 h-full w-full bg-neutral-000/50" />
      )}
      <SymmetreAgGridTable
        ref={gridRef}
        defaultColDef={defaultColDef}
        rowData={rowData}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        groupIncludeTotalFooter={false}
        groupIncludeFooter={false}
        processDataFromClipboard={processDataFromClipboard}
        autoGroupColumnDef={{
          flex: 1,
          pinned: true,
          resizable: true,
          headerName: '',
          suppressMenu: true,
          minWidth: 232,
          initialWidth: 232,
          headerComponent: AutoGroupHeaderComponent,
          cellRenderer: AutoGroupCellRenderer,
          valueGetter: (p) => {
            return p.data?.label as string;
          },
        }}
      />
    </div>
  );
};
