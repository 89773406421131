import React, { useMemo } from 'react';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { IReallocation } from 'bundles/Construction/types';
import { reallocationNumberFormatter } from 'bundles/Construction/components/helpers';
import styles from 'bundles/Construction/styles.module.scss';
import { Badge, CurrencyFormatter, IconButton } from 'stories';
import { xor } from 'lodash-es';
import statusColor from 'bundles/Construction/constants/statusColor';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { Link } from '@reach/router';
import DocumentsPreview from 'bundles/Construction/components/DocumentsPreview';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import pluralize from 'pluralize';
import { JobCostCodeView } from 'bundles/Construction/components/Reallocation/Modals/createManualReallocationModal/Wrappers';
import { CssVar } from '@/shared/config/cssVar';

export const useColumns = (
  deps: any[],
  {
    setOpenedRows,
  }: {
    setOpenedRows: React.Dispatch<React.SetStateAction<number[]>>;
  },
) => {
  return useMemo<IColumn<IReallocation>[]>(
    () => [
      {
        sortable: true,
        text: '#',
        dataField: 'number',
        formatter: ({ row: object }) => (
          <div className="flex items-center">
            <span>{reallocationNumberFormatter(object)}</span>
          </div>
        ),
        classes: styles.orderNumber,
        headerStyle: {
          width: 60,
        },
      },
      {
        sortable: true,
        text: 'REallocations details',
        dataField: 'description',
        formatter: ({ row: object, openedRows }) =>
          object.children.length > 0 ? (
            <>
              <div className={styles.reDetails}>
                <span className="font-semibold">{object.description}</span>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenedRows((prev) => xor(prev, [object.id]));
                  }}
                  iconName={
                    openedRows!.includes(object.id) ? 'arrowTop' : 'arrowBottom'
                  }
                  variant="white"
                />
              </div>
              <span className="mt-tw-1 text-light-60">
                {pluralize('SubREallocation', object.children.length)}
              </span>
            </>
          ) : (
            <span className="font-semibold">{object.description}</span>
          ),
        headerStyle: {
          width: 244,
        },
      },
      {
        text: 'MOVED FROM',
        dataField: 'movedFrom',
        formatter: ({ row: object }) => (
          <JobCostCodeView jobCostCode={object.movedFrom} />
        ),
        headerStyle: {
          width: 224,
        },
      },
      {
        sortable: true,
        text: 'Value',
        dataField: 'value',
        formatter: ({ row: object }) => (
          <CurrencyFormatter value={object.value} />
        ),
        headerStyle: {
          width: 100,
        },
      },
      {
        text: 'MOVED TO',
        dataField: 'movedTo',
        formatter: ({ row: object }) => (
          <JobCostCodeView jobCostCode={object.movedTo} />
        ),
        headerStyle: {
          width: 224,
        },
      },
      {
        sortable: true,
        text: 'Status',
        dataField: 'status',
        formatter: ({ row: object }) => (
          <Badge
            backgroundColor={statusColor[object.status]}
            textColor={CssVar.light}
          >
            {object.status}
          </Badge>
        ),
        headerStyle: {
          width: 120,
        },
      },
      {
        sortable: true,
        text: 'Received date',
        dataField: 'createdAt',
        formatter: ({ row: object }) =>
          formatUnixDate(object.createdAt, 'MMM DD, YYYY'),
        headerStyle: {
          width: 150,
        },
      },
      {
        sortable: true,
        text: 'CO#',
        dataField: 'changeOrderNumber',
        formatter: ({ row: object }) =>
          object.changeOrderNumber ? (
            <Link
              to={generateUrl(
                ROUTES_ROOT.reconcile.development.legalEntity.changeOrder
                  .fullPath,
                {
                  pathParams: {
                    legalEntityCode: object.changeOrder.legalEntityCode,
                    changeOrderId: object.changeOrder.id.toString(),
                  },
                },
              )}
              className={styles.link}
            >
              CO {object.changeOrderNumber}
            </Link>
          ) : (
            '-'
          ),
        headerStyle: {
          width: 100,
        },
      },
      {
        text: 'Files',
        dataField: 'status',
        formatter: ({ row: object }) => {
          const ApprovedFileTag = 'APPROVED';
          const coSharedFiles =
            object.changeOrder?.sharedFiles?.filter((co) =>
              co.name.includes(ApprovedFileTag),
            ) ?? [];
          const allSharedFiles = [...object.sharedFiles, ...coSharedFiles];
          return allSharedFiles?.length ? (
            <DocumentsPreview sharedFiles={allSharedFiles} />
          ) : (
            ''
          );
        },
        headerStyle: {
          width: 80,
        },
      },
    ],
    [...deps],
  );
};
