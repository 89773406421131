import { usePermittedState } from '@/bundles/Shared/components/Permissions/EditPermissionsModal/lib/PermittedStateContext';
import {
  InvestmentEntitiesList,
  RolesList,
  TagsList,
  UsersList,
} from 'bundles/Shared/components/ModalWithSideTabs/Lists';
import {
  Permitted,
  UserForPermitted,
} from 'bundles/Shared/entities/permissions';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { ComponentProps } from 'react';
import { IUser } from 'types/User';
import { ProceedWithCautionJSX } from '../../helpers/dumbJSX';
import { SideTabKey } from '../types';

const OVERLAY_SCROLLBARS_PROPS = {
  className:
    'permissions-modal-body-part p-tw-4 w-full flex flex-col gap-m h-full',
} satisfies ComponentProps<typeof OverlayScrollbarsComponent>;

export function PermissionsModalBody({
  handleUpdate,
  allUsers,
}: {
  handleUpdate: (
    key: SideTabKey,
    val: Permitted[SideTabKey] | Permitted[SideTabKey][number],
  ) => void;
  allUsers: IUser[];
}) {
  const permittedState = usePermittedState();
  const { isPublic } = permittedState;

  if (isPublic) {
    return (
      <OverlayScrollbarsComponent {...OVERLAY_SCROLLBARS_PROPS}>
        <ProceedWithCautionJSX />
        <UsersList
          members={allUsers}
          onClose={(item) =>
            handleUpdate('directUsers', item as UserForPermitted)
          }
        />
      </OverlayScrollbarsComponent>
    );
  }

  const isEmpty = ![
    permittedState.directRoles.length,
    permittedState.directTags.length,
    permittedState.directInvestmentEntities.length,
    permittedState.directUsers.length,
    permittedState.indirectUsers?.length,
  ].some(Boolean);

  return (
    <OverlayScrollbarsComponent {...OVERLAY_SCROLLBARS_PROPS}>
      <h5 className="header5-regular mb-m">Share with</h5>
      {isEmpty ? (
        <p className="white-space-nw">You haven&apos;t chosen anyone yet</p>
      ) : (
        <div className="flex flex-col gap-m">
          {permittedState.directRoles.length > 0 && (
            <RolesList
              closable
              items={permittedState.directRoles}
              onClose={(item) => handleUpdate('directRoles', item)}
            />
          )}
          {permittedState.directTags.length > 0 && (
            <TagsList
              closable
              items={permittedState.directTags}
              onClose={(item) => handleUpdate('directTags', item)}
            />
          )}
          {permittedState.directInvestmentEntities.length > 0 && (
            <InvestmentEntitiesList
              closable
              items={permittedState.directInvestmentEntities}
              onClose={(item) => handleUpdate('directInvestmentEntities', item)}
            />
          )}
          {Boolean(
            permittedState.directUsers.length ||
              permittedState.indirectUsers?.length,
          ) && (
            <UsersList
              // @ts-ignore
              members={permittedState.directUsers}
              closable
              displayTagsColumn
              // @ts-ignore
              autoSelectedMembers={permittedState.indirectUsers}
              onClose={(user) =>
                handleUpdate('directUsers', user as unknown as UserForPermitted)
              }
            />
          )}
        </div>
      )}
    </OverlayScrollbarsComponent>
  );
}
