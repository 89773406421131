import ManageFinancialCategoriesModal, {
  FinancialCategoryNode,
} from '@/widgets/financialCategory/manage/ui/ManageFinancialCategoriesModal';
import {
  useGetApiReportFinancialCategoriesTreeQuery,
  usePutApiReportFinancialCategoriesTreeMutation,
} from 'bundles/Shared/shared/api/reportFinancialCategoriesApiEnhanced';
import {
  SyncWithClientsButton,
  useSyncWithUniformMeta,
} from 'bundles/Shared/features/syncWithUniform';
import { transformTree } from 'lib/treeHelpers';
import { IconButton } from '@/stories';
import { currentUserIsSreAdmin } from 'lib/permissions';
import { useModal } from '@/shared/lib/hooks/useModal';

export const ManageFinancialCategoriesSettingsButton = () => {
  const { openModal } = useModal();
  const { data } = useSyncWithUniformMeta();
  const [updateCategoriesTree] =
    usePutApiReportFinancialCategoriesTreeMutation();
  const { data: categoriesData, isFetching } =
    useGetApiReportFinancialCategoriesTreeQuery();
  const categoriesTree = categoriesData?.tree ?? [];

  const handleClick = async () => {
    const res = await openModal(ManageFinancialCategoriesModal, {
      categories: categoriesTree,
      classes: {
        headerContent: 'flex grow pr-tw-4',
        header: 'relative',
      },
      header: (
        <div className="header6-bold flex grow items-center justify-between text-neutral-900">
          Manage Categories
          <SyncWithClientsButton entity="category" meta={data} />
        </div>
      ),
    });
    if (res != null) {
      const treeToUpdate = transformTree<
        FinancialCategoryNode,
        FinancialCategoryNode
      >({ children: res } as unknown as FinancialCategoryNode, (n) => ({
        id: n.isNew ? undefined : n.id,
        code: n.code,
        children: n.children,
        classifications: n.classifications,
      }));
      updateCategoriesTree({
        body: {
          tree: treeToUpdate.children,
        },
      });
    }
  };

  return (
    currentUserIsSreAdmin() && (
      <IconButton
        disabled={isFetching}
        isLoading={isFetching}
        className="ml-s"
        onClick={handleClick}
        iconName="settings"
      />
    )
  );
};
