import {
  generateUrl,
  type ExtractParams,
  type TRouteQueryParams,
} from '@/shared/lib/hooks/useNavigation';
import { useNavigate } from '@reach/router';

export const useAppNavigate = () => {
  const navigate = useNavigate();

  const handleNavigate = <
    T extends string,
    QueryParams = T extends keyof TRouteQueryParams
      ? TRouteQueryParams[T]
      : Record<string, unknown>,
  >(
    path: T,
    params?: {
      pathParams?: ExtractParams<T>;
      queryParams?: QueryParams;
    },
  ) => {
    navigate(generateUrl(path, params));
  };

  return handleNavigate;
};
