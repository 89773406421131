import { useEffect, useMemo, useRef, useState } from 'react';

import useElementSize from '@/shared/lib/hooks/useElementSize';
import { useOverlayScrollbars } from 'overlayscrollbars-react';
import { VirtuosoProps } from 'react-virtuoso';

export const useVirtuosoOverlayScrollbarsScroller = () => {
  const [scroller, setScroller] = useState<HTMLElement | Window | null>(null);
  const [initializeOs, osInstance] = useOverlayScrollbars({ defer: true });
  const rootRef = useRef<HTMLDivElement>(null);
  const { height } = useElementSize(rootRef);

  useEffect(() => {
    const { current: root } = rootRef;

    if (scroller && root) {
      initializeOs({
        target: root,
        elements: {
          viewport: scroller as HTMLElement,
        },
      });
    }

    return () => osInstance()?.destroy();
  }, [scroller, initializeOs, osInstance]);

  const virtuosoProps = useMemo(() => {
    return {
      style: { height },
      scrollerRef: setScroller,
    } satisfies VirtuosoProps<unknown, unknown>;
  }, [height, setScroller]);

  return {
    virtuosoProps,
    rootRef,
  };
};
