import RecapPageButton from '@/bundles/Shared/components/RecapPageButton';
import {
  UnitTypeTableWidgetConfigDto,
  UnitTypeTableWidgetDto,
} from '@/bundles/Shared/entities/dashboard';
import { isWidgetSectionPositionWidthFullSize } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib';
import { useWidgetFlags } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/useWidgetFlags';
import {
  ColumSizeWidgetState,
  DateWidgetState,
  DisplayedGroupsWidgetContext,
  DisplayedGroupsWidgetState,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/model';
import {
  DashboardWidgetCard,
  DashboardWidgetTableCard,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/DashboardWidgetCard';
import { WidgetStateColumns } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateColumns';
import { WidgetStateDate } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateDate';
import { useTableWidgetExportFeature } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import { WidgetTablePlaceholder } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetTable';
import { useWidgetFullScreen } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { useKpiWidgetColumnVisibilityState } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib/useColumnsVisibilityState';
import { useWidgetTableColumnSize } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib/useWidgetTableColumnSize';
import { useAutoGroupColDef } from '@/bundles/Shared/widgets/dashboard/widgets/kpiTable/lib/table';
import { KpiTableWidgetTable } from '@/bundles/Shared/widgets/dashboard/widgets/kpiTable/ui/KpiTableWidgetTable';
import {
  WidgetConfigProps,
  WidgetContextProps,
  WidgetProps,
  WidgetStateProps,
} from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { UnitTypeTableWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/model';
import { cn } from '@/shared/lib/css/cn';
import { AgGridReact } from 'ag-grid-react';
import { useRef } from 'react';

type UnitTypeWidgetState = DateWidgetState &
  ColumSizeWidgetState &
  DisplayedGroupsWidgetState;

export const UnitTypeWidget = (
  props: WidgetProps<UnitTypeTableWidgetDto, UnitTypeTableWidgetSection> &
    WidgetStateProps<UnitTypeWidgetState> &
    WidgetConfigProps<UnitTypeTableWidgetConfigDto> &
    WidgetContextProps<DisplayedGroupsWidgetContext>,
) => {
  const {
    mode,
    widgetSection,
    context,
    state,
    onStateChange,
    data,
    settings,
    onSettingsChange,
  } = props;
  const { shouldDisplayPlaceholder, shouldDisplayData } = useWidgetFlags(props);
  const { viz_config } = widgetSection.widgetConfig;
  const wrapperDivRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<AgGridReact | null>(null);
  const widgetStateFullScreenFeature = useWidgetFullScreen(wrapperDivRef);
  const columnSizeFeature = useWidgetTableColumnSize({
    gridRef,
    state,
    onStateChange,
  });
  const columnVisibilityFeature = useKpiWidgetColumnVisibilityState({
    grid: gridRef.current,
    state,
    onStateChange,
  });
  const exportFeature = useTableWidgetExportFeature({
    gridRef,
    mode,
    widgetTitle: widgetSection.title,
    widgetId: widgetSection.id,
    state,
  });

  const autoGroupColDef = useAutoGroupColDef({
    groupingType: 'segments',
    mode,
    ref: gridRef,
    colDef: {
      headerName: 'Unit Type',
      headerComponentParams: {
        enableExpand: mode !== 'pdf',
      },
    },
  });

  return (
    <DashboardWidgetTableCard
      {...props}
      ref={wrapperDivRef}
      className={cn(props.className, mode === 'edit' ? 'h-[500px]' : 'h-full')}
    >
      {mode === 'pdf' &&
        isWidgetSectionPositionWidthFullSize(widgetSection.position) && (
          <DashboardWidgetCard.PDFHeader>
            {widgetSection.title}
          </DashboardWidgetCard.PDFHeader>
        )}
      {mode !== 'pdf' && (
        <DashboardWidgetCard.Header>
          <div className="flex">
            <DashboardWidgetCard.Header.Title>
              {widgetSection.title}
            </DashboardWidgetCard.Header.Title>
            <RecapPageButton
              name={widgetSection.recapPage?.name}
              slug={widgetSection.recapPage?.slug}
            />
          </div>
          <div className="grow" />
          <WidgetStateDate state={state} onStateChange={onStateChange} />
          {!widgetStateFullScreenFeature.isFullscreen &&
            context.columnVisibilityEnabled && (
              <WidgetStateColumns
                onColumnSizeChange={columnSizeFeature.handleColumnSizeChange}
                state={state}
                groupsState={{
                  groups: state.displayedGroups ?? [],
                }}
                onColumnStateChange={columnVisibilityFeature.onChange}
                vizConfig={viz_config}
                columns={data?.columns ?? []}
              />
            )}
          <exportFeature.ExportButtonComponent />
          <widgetStateFullScreenFeature.IconButton />
        </DashboardWidgetCard.Header>
      )}
      {shouldDisplayPlaceholder && <WidgetTablePlaceholder />}
      {shouldDisplayData && (
        <KpiTableWidgetTable
          groupDefaultExpanded={mode === 'pdf' ? -1 : 0}
          ref={gridRef}
          vizConfig={viz_config}
          data={data}
          state={state}
          domLayout={mode === 'edit' ? 'normal' : 'autoHeight'}
          onStateChange={onStateChange}
          settings={settings}
          onSettingsChange={onSettingsChange}
          mode={mode}
          columnsConfig={widgetSection.widgetConfig.columns}
          onRowDataUpdated={columnSizeFeature.handleRowDataUpdated}
          excelStyles={exportFeature.excelStyles}
          autoGroupColumnDef={autoGroupColDef}
        />
      )}
    </DashboardWidgetTableCard>
  );
};
