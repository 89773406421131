import {
  BasicCellRenderer,
  BasicCellRendererProps,
} from '@/bundles/Shared/components/AgGrid/Table/cellComponents/BasicCellRenderer';
import {
  formatWidgetDataRowHistoryDates,
  orderHistoryByDateFromDesc,
  columnHistoryHasMoreThanOneEntry,
  defaultHistoryValueGetter,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/history';
import { HistoryDataTooltip } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/HistoryDataTooltip';
import { TableVizConfigTextDisplayStrategy } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { WidgetDataRowHistoryEntry } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { DEFAULT_NUMBER_FALLBACK } from '@/shared/lib/formatting/number';
import { defaultValueFallbackCondition } from '@/shared/lib/formatting/table';
import { Icon } from '@/stories';
import { ICellRendererParams } from 'ag-grid-community';

export type TextCellRenderer = {
  type: 'text_default';
  params: {
    variant: TableVizConfigTextDisplayStrategy;
  };
};

const formatHistoryValue = (history: WidgetDataRowHistoryEntry[]) => {
  return orderHistoryByDateFromDesc(history)
    .map((h) => `${h.value} (${formatWidgetDataRowHistoryDates(h)})`)
    .join(', ');
};

export const WidgetTableTextCellRenderer = (
  params: BasicCellRendererProps &
    ICellRendererParams & {
      config: TextCellRenderer;
      historyValueGetter: (
        params: ICellRendererParams,
      ) => WidgetDataRowHistoryEntry[];
      fallbackValue?: string;
    } & {
      value: string;
    },
) => {
  const {
    config,
    historyValueGetter = defaultHistoryValueGetter,
    fallbackValue = DEFAULT_NUMBER_FALLBACK,
  } = params;
  const { variant } = config.params ?? {};
  const history = historyValueGetter(params);
  const hasHistory = columnHistoryHasMoreThanOneEntry(history);

  const renderContent = () => {
    const resolveValue = () => {
      if (defaultValueFallbackCondition(params.value)) {
        return fallbackValue;
      }
      if (variant === 'text_default' && hasHistory) {
        return formatHistoryValue(history);
      }

      return params.value;
    };
    const value = resolveValue();

    if (hasHistory) {
      return (
        <HistoryDataTooltip
          classes={{
            spanContainer: 'text-ellipsis inline-block',
          }}
          history={history}
        >
          {value}
          {params.context.mode !== 'pdf' && (
            <Icon
              className="relative top-1 ml-tw-2 align-text-top text-neutral-500"
              iconName="info"
            />
          )}
        </HistoryDataTooltip>
      );
    }
    return value;
  };

  return <BasicCellRenderer {...params}>{renderContent()}</BasicCellRenderer>;
};
