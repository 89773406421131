import { ComponentProps } from 'react';
import TextEditor from '../../../../Shared/components/TextEditor';
import * as Type from './types';

const TemplateEditor: React.FC<{
  text: RawHTML | undefined;
  setText: (text: RawHTML) => void;
  variables: Type.Variable[];
  placeholder: string;
  toolbarItems: ComponentProps<typeof TextEditor>['toolbarItems'];
  onReady: (editor: unknown) => void;
  onChangeStart?: (editor: unknown) => void;
  usedVariables?: Type.Variable[];
  setUsedVariables?: (vars: Type.Variable[]) => void;
}> = ({
  text,
  setText,
  variables,
  usedVariables,
  setUsedVariables,
  toolbarItems,
  onReady,
  onChangeStart,
  placeholder,
}) => {
  function buildEditorConfiguration() {
    return {
      placeholder,
      toolbar: {
        items: toolbarItems,
        shouldNotGroupWhenFull: true,
      },
      variableConfig: {
        types: variables.map((v) => v.label),
      },
      language: 'en',
      link: {
        addTargetToExternalLinks: true,
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          'tableCellProperties',
          'tableProperties',
        ],
      },
    };
  }

  const updateVariables = (data: string) => {
    if (!usedVariables || !setUsedVariables) {
      return;
    }
    const matches = data.matchAll(/<span class="variable">(.*?)<\/span>/g);
    const labelsInText = new Set([...matches].map((m) => m[1]));
    const persistedUsedVariables = usedVariables
      .filter((uv) => uv.id)
      .map((uv) => {
        if (labelsInText.has(uv.name_in_text)) {
          return { ...uv, _destroy: 0 };
        }
        return { ...uv, _destroy: 1 };
      });
    const persistedUsedVariableLabels = persistedUsedVariables.map(
      (uv) => uv.name_in_text,
    );
    const newUsedVariables = variables
      .filter(
        (v) =>
          labelsInText.has(v.label) &&
          !persistedUsedVariableLabels.includes(v.label),
      )
      .map((v) => ({ variable_id: v.id, name_in_text: v.label }));
    setUsedVariables([...persistedUsedVariables, ...newUsedVariables]);
  };

  return (
    <div>
      {variables.length > 0 && (
        <TextEditor
          placeholder={placeholder}
          onReady={onReady}
          config={buildEditorConfiguration()}
          text={text}
          setText={setText}
          onChangeStart={onChangeStart}
          onChangeEnd={updateVariables}
        />
      )}
    </div>
  );
};

export default TemplateEditor;
