import {
  ValueDisplayOptions,
  ValueDisplayType,
} from '@/shared/lib/formatting/displayOptions';

export const AMCHART_NUMBER_SYMBOLS_DISPLAY_VALUE_TYPE_MAP = {
  number: '',
  currency: '$',
  metric: 'x',
  sqft: 'ft²',
  percentage: '%',
} as const satisfies Record<ValueDisplayType, string>;

const wrapWithBrackets = <T extends string>(format: T): `(${T}s)` =>
  `(${format}s)`;

export const joinFormat = <
  Positive extends string,
  Negative extends string = `(${Positive}s)`,
  Zero extends string = '0',
>(args: {
  positiveFormat: Positive;
  negativeFormat?: Negative;
  zeroFormat?: Zero;
}) => {
  const {
    positiveFormat,
    negativeFormat = wrapWithBrackets(positiveFormat),
    zeroFormat = '0',
  } = args;

  return [positiveFormat, negativeFormat, zeroFormat].join(
    '|',
  ) as `${Positive}|${Negative}|${Zero}`;
};
export const formatVariableString = (value: string, format: string) =>
  `{${value}.formatNumber("${format}")}`;

export const DEFAULT_AMCHART_NUMBER_FORMAT = '#,###';
export const DEFAULT_AMCHART_MONTH_FORMAT = 'MMM yy';

export const getAmchartNumberFormatByDisplayOptions = (
  options: ValueDisplayOptions | null,
  { abbreviate = true } = {},
) => {
  const numberType = options?.type ?? 'currency';
  const symbol = AMCHART_NUMBER_SYMBOLS_DISPLAY_VALUE_TYPE_MAP[numberType];
  const precision =
    options?.precision === 0 || (numberType === 'currency' && abbreviate)
      ? '.##'
      : `.${'0'.repeat(options?.precision ?? 2)}`;

  switch (numberType) {
    case 'currency':
      if (abbreviate) {
        return joinFormat({
          positiveFormat: `${symbol}#.##a!`,
          zeroFormat: `${symbol}#.##a!`,
        });
      }
      return joinFormat({
        positiveFormat: `${symbol}${DEFAULT_AMCHART_NUMBER_FORMAT}${precision}`,
        zeroFormat: `${symbol}${DEFAULT_AMCHART_NUMBER_FORMAT}${precision}`,
      });
    case 'percentage':
      return joinFormat({
        positiveFormat: ` ${DEFAULT_AMCHART_NUMBER_FORMAT}${precision}${symbol}`,
        negativeFormat: `'('${DEFAULT_AMCHART_NUMBER_FORMAT}${precision}${symbol}s)'`,
      });
    case 'number':
      return joinFormat({
        positiveFormat: `${DEFAULT_AMCHART_NUMBER_FORMAT}${precision}`,
      });
    case 'sqft':
    case 'metric':
      return joinFormat({
        positiveFormat: `${DEFAULT_AMCHART_NUMBER_FORMAT}${precision}${symbol}`,
      });
    default: {
      return joinFormat({
        positiveFormat: `${DEFAULT_AMCHART_NUMBER_FORMAT}${precision}`,
      });
    }
  }
};

export const getCurrencyTooltipFormat = (value = 'valueY') =>
  formatVariableString(
    value,
    getAmchartNumberFormatByDisplayOptions(
      {
        type: 'currency',
        precision: 2,
      },
      {
        abbreviate: false,
      },
    ),
  );
export const getPercentageTooltipFormat = (value = 'valueY') =>
  formatVariableString(
    value,
    getAmchartNumberFormatByDisplayOptions(
      {
        type: 'percentage',
        precision: 0,
      },
      {
        abbreviate: false,
      },
    ),
  );

export const DEFAULT_AMCHART_DATE_FORMATS = {
  day: 'MMM dd',
  week: 'MMM dd',
  month: DEFAULT_AMCHART_MONTH_FORMAT,
  quarter: 'q yy',
  year: 'YYYY',
} as const;
