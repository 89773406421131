import { ErrorResponse } from '@/app/stores/errorHandling';
import { REPORT_BUILDER_WIDGETS_CONFIG_MAP } from 'bundles/Shared/widgets/dashboard/widgets/config';
import { usePostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotQuery } from '@/entities/report/reportBuilder/api';
import { AssetsReportBuilderReportWidgetDto } from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { selectWidgetById } from '@/entities/report/reportBuilder/model';
import { asserts } from 'lib/typeHelpers/assertsType';
import { useAssetIdSearchParam } from '@/shared/lib/hooks/navigation/useAssetIdSearchParam';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useParams } from '@reach/router';

export const ReportWidgetConfigCard = ({
  widgetSection,
  isLoading,
  disabled,
}: {
  widgetSection: AssetsReportBuilderReportWidgetDto;
  index: number;
  isLoading: boolean;
  disabled?: boolean;
}) => {
  const assetId = useAssetIdSearchParam();
  const params = useParams();
  const { title, CardConfigComponent } =
    REPORT_BUILDER_WIDGETS_CONFIG_MAP[widgetSection.widgetType] ?? {};

  const widget = useAppSelector(selectWidgetById(widgetSection.id));
  const context = widget?.context ?? {};
  const { data, isError, error } =
    usePostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotQuery(
      {
        assetId: assetId!,
        reportId: params.id,
        id: widgetSection.id,
        body: {
          context: {
            date: context.date,
            granularity: context.granularity,
            date_from: context.dateFrom,
            date_to: context.dateTo,
            per_page: context.perPage,
            page: context.page,
          },
        },
      },
      {
        skip: assetId == null,
        refetchOnMountOrArgChange: true,
      },
    );

  asserts(
    CardConfigComponent,
    `Every Config inside REPORT_BUILDER_WIDGETS_CONFIG_MAP needs to have CardConfigComponent ${widgetSection.widgetType}`,
  );

  if (!CardConfigComponent) {
    return null;
  }

  return (
    <CardConfigComponent
      data={data!}
      disabled={disabled}
      assetId={Number(assetId)}
      isLoading={isLoading}
      isError={isError}
      errorMessage={(error?.data as ErrorResponse)?.errors}
      title={widgetSection.title ?? title}
      widgetSection={widgetSection}
      reportId={params.id}
    />
  );
};
