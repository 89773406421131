import type { IThinTabItem } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';

export const EMAIL_BUILDER_ITEMS_TABS = [
  {
    id: 'sections',
    label: 'Sections',
    value: 'sections',
  },
  {
    id: 'attachments',
    label: 'Attachments',
    value: 'attachments',
  },
] satisfies IThinTabItem[];
