import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import { settingsEmailsCustomTemplateEnhancedApi } from '@/entities/emails/customTemplates/api/settingsEmailsCustomTemplateEnhancedApi';
import { BuilderUI } from '@/shared/ui/BuilderUI';
import { EmailCustomTemplateBuilder } from '@/widgets/email/custom/template/builder';
import { useParams, type RouteComponentProps } from '@reach/router';
import { type FC } from 'react';

export const EmailCustomTemplateEditorPage: FC<RouteComponentProps> = () => {
  const params = useParams();
  const { id } = params;
  const { data: customTemplate, isLoading: isCustomTemplateLoading } =
    settingsEmailsCustomTemplateEnhancedApi.useGetEmailCustomTemplateQuery(
      {
        templateId: id!,
      },
      {
        skip: id == null,
      },
    );
  const {
    data: customTemplateVariables,
    isLoading: isCustomTemplateVariablesLoading,
  } =
    settingsEmailsCustomTemplateEnhancedApi.useGetEmailCustomTemplateVariablesQuery();

  settingsEmailsCustomTemplateEnhancedApi.useGetApiEmailBuilderCustomTemplatesMetaQuery();

  const isLoading = isCustomTemplateLoading || isCustomTemplateVariablesLoading;

  if (isLoading) {
    return <BuilderUI.Loading />;
  }

  if (customTemplate == null || customTemplateVariables == null) {
    return <NoDataOverlay title="No data" />;
  }

  return <EmailCustomTemplateBuilder template={customTemplate} />;
};
