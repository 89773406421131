import { ValueTypeCellEditor } from '@/entities/valueType/ui/CellEditor/ValueTypeCellEditor';
import {
  convertCentsToDollars,
  convertDollarsToCents,
} from '@/shared/lib/converters';
import { exportFile } from '@/shared/lib/exportFile';
import { formatDate } from '@/shared/lib/formatting/dates';
import { parseNumberFromCurrencyInput } from '@/shared/lib/formatting/number';
import {
  allSubPathMatches,
  generateUrl,
  ROUTES_ROOT,
} from '@/shared/lib/hooks/useNavigation';
import { importFile } from '@/shared/lib/importFile';
import { useMatch, useNavigate } from '@reach/router';
import {
  ColGroupDef,
  EditableCallbackParams,
  ICellRendererParams,
  ProcessDataFromClipboardParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { transformAmount } from 'bundles/REconcile/components/operational/editor/helpers/transformAmounts';
import { useGetApiReconcileUnderwritingBudgetsByBudgetIdQuery } from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetEnhancedApi';
import {
  GetApiReconcileUnderwritingBudgetsLegalEntitiesApiResponse,
  LegalEntityUnderwritingBudget,
  PutApiReconcileUnderwritingBudgetsByBudgetIdValuesBulkApiArg,
  UnderwritingBudget,
  UnderwritingBudgetFinancialCategoryRow,
} from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetGeneratedApi';
import {
  BudgetRow,
  CategoryRow,
  MetricRow,
} from 'bundles/REconcile/underwritting/model';
import { CurrencyCellRendererProps } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CurrencyCellRenderer';
import { HeaderGroupComponentWithShortYear } from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderGroupComponent';
import {
  DISABLED_CELL_RENDERER_PARAMS,
  UWBRowType,
} from 'bundles/Shared/entities/undewriting/config';
import dayjs from 'dayjs';
import http from 'lib/http';
import { TreeData } from 'lib/treeHelpers';
import { mustFind } from 'lib/typeHelpers';
import { assertsQueryDataLoadedBefore } from 'lib/typeHelpers/redux/rtkApiHelpers';
import { get } from 'lodash-es';
import { ComponentProps, useCallback, useEffect, useMemo } from 'react';

export const useLoadedUnderwritingBudget = ({
  budgetId,
}: {
  budgetId: NonNullish<LegalEntityUnderwritingBudget['budget']>['id'];
}) => {
  const { data, ...rest } =
    useGetApiReconcileUnderwritingBudgetsByBudgetIdQuery({ budgetId });

  assertsQueryDataLoadedBefore(data);

  return { ...rest, data };
};

export const isCellEditor = (
  rowType: UWBRowType,
): rowType is UWBRowType.CellEditor => {
  return rowType === 'cell-editor';
};

export function useNavigateToFirstItem({
  underwritingBudgetData: data,
}: {
  underwritingBudgetData:
    | GetApiReconcileUnderwritingBudgetsLegalEntitiesApiResponse
    | undefined;
}) {
  const navigate = useNavigate();
  const match = useMatch(
    allSubPathMatches(ROUTES_ROOT.reconcile.underwriting.legalEntity.fullPath),
  );
  const legalEntityIdFromURL = match?.legalEntityId;

  useEffect(() => {
    if (data == null || data.items.length <= 0) return;

    const legalEntityIds = data.items.map((a) => a.id);

    const navigateToLE = (le: LegalEntityUnderwritingBudget) => {
      navigate(
        generateUrl(ROUTES_ROOT.reconcile.underwriting.legalEntity.fullPath, {
          pathParams: {
            legalEntityId: le.id,
          },
          queryParams: {
            budgetId: le.budget?.id,
          },
        }),
      );
    };

    if (legalEntityIds.includes(legalEntityIdFromURL ?? '')) {
      const thisLE = mustFind(
        data.items,
        (le) => le.id === legalEntityIdFromURL,
      );
      navigateToLE(thisLE);
      return;
    }

    const [firstLE] = data.items;

    navigateToLE(firstLE);
  }, [data]);

  return {
    legalEntityIdFromURL,
  };
}

export async function putApiReconcileUnderwritingBudgetsByIdValuesBulk(
  args: PutApiReconcileUnderwritingBudgetsByBudgetIdValuesBulkApiArg,
) {
  await http.put(
    `/reconcile/underwriting/budgets/${args.budgetId}/values/bulk`,
    args.body,
  );
}

export const isFinancialRow = (b: BudgetRow): b is CategoryRow =>
  'categoryId' in b;

export const isMetricRow = (b: BudgetRow): b is MetricRow =>
  !('categoryId' in b);

export function useProcessDataFromClipboard() {
  return useCallback(
    (params: ProcessDataFromClipboardParams): string[][] | null => {
      const rawData = [...params.data];
      const data = rawData.map((amounts) => amounts.map(transformAmount));

      return data.slice(0, 1);
    },
    [],
  );
}

export const valueGetter: (params: ValueGetterParams) => number | null = (
  params,
) => {
  const data = params.data as BudgetRow | null;
  const key = params.colDef.colId!;

  if (data == null) return null;

  const res = get(data, ['values', key]);

  if (res == null) return null;

  if (isFinancialRow(data)) {
    return convertCentsToDollars(res);
  }

  if (isMetricRow(data)) {
    return res;
  }

  return null;
};

type Node = {
  children: Node[];
};

export const getAllDescendants = <N extends Node>(node: N): N[] => {
  const descendants: N[] = [];

  TreeData.deepFirst([node], (n) => {
    descendants.push(...(n.children as N[]));
    return false;
  });

  return descendants;
};

export const getValueSetter: (
  budgetId: string,
) => (params: ValueSetterParams) => boolean = (budgetId) => {
  return (params) => {
    const data = params.data as BudgetRow | null;
    const key = params.colDef.colId!;

    const getValue = () => {
      if (data.valueType === 'date') return params.newValue;

      return params.newValue
        ? parseNumberFromCurrencyInput(params.newValue)
        : null;
    };
    const newValue = getValue();

    if (data == null) return false;

    let args:
      | PutApiReconcileUnderwritingBudgetsByBudgetIdValuesBulkApiArg
      | undefined;

    if (isFinancialRow(data)) {
      if (data.id == null) return false;

      const converted = convertDollarsToCents(Number(newValue));
      const value = newValue == null ? null : converted;

      args = {
        budgetId,
        body: [
          {
            cents: value,
            budget_financial_category_id: data.financialCategoryId,
            period_key: key,
          },
        ],
      };

      data.values = {
        ...data.values,
        [key]: value,
      };

      const updatedRelatives = [...data.ancestors, ...data.descendants].map(
        (row) => {
          const _rowData = params.api.getRowNode(row.categoryId)
            .data as CategoryRow;

          const newDisabledValueKeys = _rowData.disabledValueKeys ?? new Set();

          if (value != null) {
            newDisabledValueKeys.add(key);
          } else {
            newDisabledValueKeys.delete(key);
          }

          return {
            ..._rowData,
            values: {
              ..._rowData.values,
              [key]: value == null ? undefined : 'empty',
            },
            disabledValueKeys: newDisabledValueKeys,
          } as CategoryRow;
        },
      );

      params.api.applyTransaction({
        update: updatedRelatives,
      });
      putApiReconcileUnderwritingBudgetsByIdValuesBulk(args);

      return true;
    }

    if (isMetricRow(data)) {
      const value = newValue == null ? null : Number(newValue);
      args = {
        budgetId,
        body: [
          {
            value,
            budget_metric_id: data.id,
            period_key: key,
          },
        ],
      };

      putApiReconcileUnderwritingBudgetsByIdValuesBulk(args);

      data.values = {
        ...data.values,
        [key]: value,
      };
      return true;
    }

    return false;
  };
};

export function getUWBCategoryRows({
  budgetFinancialCategoryRows,
}: {
  budgetFinancialCategoryRows: UnderwritingBudgetFinancialCategoryRow[];
}): CategoryRow[] {
  const res: CategoryRow[] = [];

  TreeData.deepFirst(budgetFinancialCategoryRows ?? [], (node, passedNodes) => {
    const ancestors = passedNodes.filter(
      (n) => n.categoryId !== node.categoryId,
    );
    const descendants = getAllDescendants(node);
    const disabledValueKeys = new Set<string>();
    const relatives = [...ancestors, ...descendants];

    relatives.forEach((n) => {
      const entriesOfDataValues = Object.entries(n.values);

      entriesOfDataValues.forEach(([key]) => {
        disabledValueKeys.add(key);
      });
    });

    res.push({
      ...node,
      id: node.categoryId,
      financialCategoryId: node.id!,
      key: node.categoryId,
      path: passedNodes.map((n) => n.categoryId),
      level: passedNodes.length,
      type: UWBRowType.CellEditor,
      kind: 'category',
      ancestors,
      descendants,
      disabledValueKeys,
    });
    return false;
  });

  return res;
}

export function getUWBMetricRows({
  budgetMetricRows,
}: {
  budgetMetricRows: UnderwritingBudget['budgetMetricRows'];
}): MetricRow[] {
  const res: MetricRow[] = [];

  TreeData.deepFirst(budgetMetricRows, (i, nodes) => {
    res.push({
      ...i,
      // @ts-ignore
      key: i.id,
      // @ts-ignore
      path: nodes.map((n) => n.id),
      level: nodes.length,
      type: UWBRowType.CellEditor,
      kind: 'metric',
    });
    return false;
  });

  return res;
}

export function isCellDisabled({
  colKey,
  rowData,
}: {
  colKey: string;
  rowData: BudgetRow | null | undefined;
}): boolean {
  if (rowData?.kind === 'metric') return false;

  if (rowData?.financialCategoryId == null) return true;

  if (rowData != null && 'disabledValueKeys' in rowData) {
    return (
      (rowData.disabledValueKeys?.has(colKey) ?? false) || rowData.id == null
    );
  }

  return false;
}

export const isUWBCellEditable = (params: EditableCallbackParams): boolean => {
  if (
    isCellDisabled({
      colKey: params.colDef.colId!,
      rowData: params.data,
    })
  ) {
    return false;
  }

  return isCellEditor(params.data?.type);
};

export const UWBCellRendererParams = (
  params: ICellRendererParams,
): ICellRendererParams & CurrencyCellRendererProps => {
  if (
    isCellDisabled({
      colKey: params.colDef!.colId!,
      rowData: params.data,
    })
  ) {
    return {
      ...params,
      ...DISABLED_CELL_RENDERER_PARAMS,
    };
  }
  if (isCellEditor(params.data?.type)) {
    return {
      ...params,
      formatterParams: {
        fallbackValue: '-',
      },
    };
  }
  return {
    ...params,
    ...DISABLED_CELL_RENDERER_PARAMS,
  };
};

export const useUWBColumnDefs = ({
  budgetId,
  budgetPeriods,
  deps = [],
}: {
  budgetId: UnderwritingBudget['id'];
  budgetPeriods: UnderwritingBudget['periods'];
  deps?: React.DependencyList;
}) => {
  const res = useMemo(
    () =>
      budgetPeriods.map((period) => ({
        colId: period.key,
        headerName: formatDate(period.date as DateString, 'MMMM'),
        headerGroupComponent: HeaderGroupComponentWithShortYear,
        headerGroupComponentParams: {
          year: dayjs(period.date).year(),
        } satisfies Partial<
          ComponentProps<typeof HeaderGroupComponentWithShortYear>
        >,
        children: [
          {
            editable: isUWBCellEditable,
            cellRendererParams: UWBCellRendererParams,
            colId: period.key,
            cellEditor: ValueTypeCellEditor,
            headerName: 'Budget',
            label: 'Budget',
            period: period.date,
            valueSetter: getValueSetter(budgetId),
            valueGetter,
          },
        ],
      })) satisfies ColGroupDef[],
    [budgetId, budgetPeriods, ...deps],
  );

  return res;
};

export function importUnderwritingBudget(params: {
  file: File;
  budgetId: UnderwritingBudget['id'];
}) {
  return importFile({
    file: params.file,
    url: `api/reconcile/underwriting/budgets/${params.budgetId}/import`,
  });
}

export function exportUnderwritingBudget({
  budgetId,
}: {
  budgetId: UnderwritingBudget['id'];
}) {
  exportFile({
    url: `/reconcile/underwriting/budgets/${budgetId}/export`,
    filePostfix: 'underwriting',
  });
}

export const showUnderwritingBudgetMismatchFireIcon = (
  isMismatched: boolean,
  state: LegalEntityUnderwritingBudget['state'],
) => isMismatched && state !== 'draft';
