import { COLUMN_CONFIGURATION_LABEL_INFO_TEXT } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { ColumnHeaderField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColumnHeaderField';
import {
  UNIT_TYPE_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA,
  UnitTypeTableWidgetConfigColumnForm,
  transformColumnConfigToForm,
} from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/config/column.form';
import { unitTypeTableUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/config/updaters';
import { UnitTypeTableWidgetConfigColumn } from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/model';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { FieldsContainer } from '@/stories/Field/Field';
import {
  SectionField,
  SectionFieldsContainer,
} from '@/stories/Field/FieldsWrappers';
import { yupResolver } from '@hookform/resolvers/yup';
import { OBJECT_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import {
  CreateNewForm,
  EditPanelActions,
  FormulaField,
  PeriodShiftField,
  PeriodTypeField,
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { ColumnSettingsFields } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColumnSettingsFields';
import { TotalCalculationStrategyField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/TotalCalculationStrategyField';
import { TableVizConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { DEFAULT_KPI_TABLE_WIDGET_CONFIG_COLUMN } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { FormProvider, useForm } from 'react-hook-form';
import { Field, InlineAlert, Input } from 'stories';
import { SidePanel } from 'stories/Modals/Modal/Modal';

interface Props extends DialogProps<CreateNewForm> {
  type: 'create' | 'edit';
  columnConfig?: UnitTypeTableWidgetConfigColumn;
  columnSettings?: TableVizConfigColumn;
  groupId?: string;
}

export function UnitTypeTableWidgetConfigColumnPanel({
  type,
  columnConfig,
  columnSettings,
  groupId,
  onClose,
  onSubmit,
}: Props) {
  const resolver = yupResolver(UNIT_TYPE_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA);
  const methods = useForm<UnitTypeTableWidgetConfigColumnForm & CreateNewForm>({
    resolver,
    mode: 'all',
    defaultValues: {
      ...DEFAULT_KPI_TABLE_WIDGET_CONFIG_COLUMN,
      ...(columnConfig &&
        columnSettings &&
        transformColumnConfigToForm(columnConfig, columnSettings)),
    },
  });

  const { control, register, handleSubmit } = methods;
  const { widget } =
    useWidgetConfig<typeof OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE>();
  const [updateConfig] = useUpdateWidgetConfig(
    OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE,
  );

  const group = widget.widgetConfig.viz_config.column_groups?.find(
    (cg) => cg.group_id === groupId,
  );

  const cantOverridePeriod = group?.child_can_override_period === false;

  const handleRemove = () => {
    updateConfig({
      config: unitTypeTableUpdaters.removeColumn(
        {
          columnKey: columnConfig!.key.toString(),
        },
        widget.widgetConfig,
      ),
    });
    onClose();
  };

  const handleSubmitClick = handleSubmit(async (values) => {
    const config = unitTypeTableUpdaters.upsertColumn(
      values,
      widget.widgetConfig,
      { groupId },
    );
    await updateConfig({ config });

    onSubmit?.({ createNew: values.createNew });
  });

  return (
    <FormProvider {...methods}>
      <SidePanel
        header={type === 'create' ? 'Create' : 'Edit'}
        toggle={onClose}
        actions={
          <EditPanelActions
            type={type}
            onClose={onClose}
            onSubmit={handleSubmitClick}
            onRemove={handleRemove}
          />
        }
      >
        <FieldsContainer>
          <Field note={COLUMN_CONFIGURATION_LABEL_INFO_TEXT} labelText="Title">
            <Input
              placeholder="Enter Primary Title"
              className="w-full"
              {...register('label')}
            />
          </Field>
          <SectionFieldsContainer>
            <FormulaField
              control={control}
              name="expression"
              required
              label="Floorplan Value"
            />
            <TotalCalculationStrategyField
              control={control}
              name="unit_type_calculation_strategy"
              labelText="Override for sRE Unit Type row"
            />
          </SectionFieldsContainer>
          <SectionFieldsContainer>
            <SectionField labelText="Specify Period">
              {cantOverridePeriod && (
                <InlineAlert
                  status="attention"
                  message="The period has been set for all columns in the group configuration"
                />
              )}
              <PeriodTypeField
                disabled={cantOverridePeriod}
                control={control}
                name="period_type"
              />
              <Field labelText="Period shift">
                <PeriodShiftField
                  disabled={cantOverridePeriod}
                  control={control}
                  name="period_shift"
                />
              </Field>
            </SectionField>
            <TotalCalculationStrategyField
              control={control}
              name="total_calculation_strategy"
            />
            <ColumnHeaderField name="header" control={control} />
          </SectionFieldsContainer>
          <ColumnSettingsFields
            columnsConfig={widget.widgetConfig.columns}
            vizConfig={widget.widgetConfig.viz_config}
            control={control}
          />
        </FieldsContainer>
      </SidePanel>
    </FormProvider>
  );
}
