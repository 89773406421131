import { emptySplitApi as api } from '@/app/stores/api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiCoreAssetsByAssetIdReportBuilderReports: build.query<
      GetApiCoreAssetsByAssetIdReportBuilderReportsApiResponse,
      GetApiCoreAssetsByAssetIdReportBuilderReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/assets/${queryArg.assetId}/report_builder/reports`,
      }),
    }),
    postApiCoreAssetsByAssetIdReportBuilderReports: build.mutation<
      PostApiCoreAssetsByAssetIdReportBuilderReportsApiResponse,
      PostApiCoreAssetsByAssetIdReportBuilderReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/assets/${queryArg.assetId}/report_builder/reports`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getApiCoreAssetsByAssetIdReportBuilderReportsAndId: build.query<
      GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
      GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/assets/${queryArg.assetId}/report_builder/reports/${queryArg.id}`,
      }),
    }),
    putApiCoreAssetsByAssetIdReportBuilderReportsAndId: build.mutation<
      PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
      PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/assets/${queryArg.assetId}/report_builder/reports/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteApiCoreAssetsByAssetIdReportBuilderReportsAndId: build.mutation<
      DeleteApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse,
      DeleteApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/assets/${queryArg.assetId}/report_builder/reports/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    getApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdf: build.query<
      GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfApiResponse,
      GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/assets/${queryArg.assetId}/report_builder/reports/${queryArg.id}/preview_pdf`,
      }),
    }),
    postApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublish: build.mutation<
      PostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishApiResponse,
      PostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishApiArg
    >({
      query: (queryArg) => ({
        url: `/api/core/assets/${queryArg.assetId}/report_builder/reports/${queryArg.id}/publish`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshot:
      build.query<
        PostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiResponse,
        PostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiArg
      >({
        query: (queryArg) => ({
          url: `/api/core/assets/${queryArg.assetId}/report_builder/reports/${queryArg.reportId}/widget_sections/${queryArg.id}/snapshot`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
    putApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshot:
      build.mutation<
        PutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiResponse,
        PutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiArg
      >({
        query: (queryArg) => ({
          url: `/api/core/assets/${queryArg.assetId}/report_builder/reports/${queryArg.reportId}/widget_sections/${queryArg.id}/snapshot`,
          method: 'PUT',
          body: queryArg.body,
        }),
      }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as coreAssetsReportBuilderReportsGeneratedApi };
export type GetApiCoreAssetsByAssetIdReportBuilderReportsApiResponse =
  /** status 200 success */ AssetsReportBuilderPreviewReportDto[];
export type GetApiCoreAssetsByAssetIdReportBuilderReportsApiArg = {
  assetId: string;
};
export type PostApiCoreAssetsByAssetIdReportBuilderReportsApiResponse =
  /** status 200 success */ AssetsReportBuilderPreviewReportDto;
export type PostApiCoreAssetsByAssetIdReportBuilderReportsApiArg = {
  assetId: string;
  body: {
    name: string;
    date: string;
    date_format?: ('day' | 'quarter') | null;
    template_id: string;
  };
};
export type GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse =
  /** status 200 success */ {
    report: AssetsReportBuilderReportDto;
    meta: {
      asset: AssetsReportBuilderAssetDto;
    };
  };
export type GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg = {
  id: string;
  assetId: string;
};
export type PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse =
  /** status 200 success */ AssetsReportBuilderPreviewReportDto;
export type PutApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg = {
  id: string;
  assetId: string;
  body: {
    name?: string;
    subtitle?: string;
    date?: string;
    date_format?: ('day' | 'quarter') | null;
    legal_entity_ids?: string[];
  };
};
export type DeleteApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse =
  unknown;
export type DeleteApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiArg = {
  id: string;
  assetId: string;
};
export type GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfApiResponse =
  /** status 200 success */ string;
export type GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfApiArg =
  {
    id: string;
    assetId: string;
  };
export type PostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishApiResponse =
  /** status 200 success */ AssetsReportBuilderPreviewReportDto;
export type PostApiCoreAssetsByAssetIdReportBuilderReportsAndIdPublishApiArg = {
  id: string;
  assetId: string;
  body: {
    shared_file: {
      public_permission_attributes?: {
        _destroy?: boolean;
      };
      directly_permitted_user_role_ids: string[];
      directly_permitted_user_ids: string[];
      directly_permitted_investment_entity_ids: string[];
      directly_permitted_user_tag_ids: string[];
    };
  };
};
export type PostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiResponse =
  /** status 200 success */ {
    id?: string;
    widget?:
      | AssetsReportBuilderKpiCardWidgetDto
      | AssetsReportBuilderTextAreaWidgetDto
      | AssetsReportBuilderMediaWidgetDto
      | AssetsReportBuilderUnitMixTableWidgetDto
      | AssetsReportBuilderUnitMixSummaryTableWidgetDto
      | AssetsReportBuilderUnitTypeTableWidgetDto
      | AssetsReportBuilderFinancialTableSingleDateWidgetDto
      | AssetsReportBuilderFinancialTableSinglePeriodWidgetDto
      | AssetsReportBuilderXyChartWidgetDto
      | AssetsReportBuilderRecentRentRollTableWidgetDto
      | AssetsReportBuilderDelinquencyTrackerTableWidgetDto
      | AssetsReportBuilderHistoricalReviewTableWidgetDto
      | AssetsReportBuilderGlobalLeaseTableWidgetDto;
  };
export type PostApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiArg =
  {
    reportId: string;
    assetId: string;
    id: string;
    body: {
      context?: {
        date?: string;
        date_from?: string;
        date_to?: string;
        granularity?: string;
      };
    };
  };
export type PutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiResponse =
  /** status 200 success */ {
    id?: string;
    widget?:
      | AssetsReportBuilderTextAreaWidgetDto
      | AssetsReportBuilderMediaWidgetDto;
  };
export type PutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetsIdSnapshotApiArg =
  {
    reportId: string;
    assetId: string;
    id: string;
    body: {
      snapshot_data:
        | {
            text: string;
          }
        | {
            items: {
              id: string;
              type: 'shared_file';
            }[];
          };
    };
  };
export type AssetsReportBuilderSharedFileDto = {
  id: string;
  permitted?: {
    isPublic: boolean;
    users: {
      id: string;
      fullName: string;
      email: string;
    }[];
    roles: {
      id: string;
      name: string;
    }[];
    tags: {
      id: string;
      name: string;
    }[];
    investmentEntities: {
      id: string;
      name: string;
    }[];
  };
};
export type AssetsReportBuilderPreviewReportDto = {
  id: string;
  name: string;
  subtitle: string | null;
  status: 'draft' | 'published';
  date: string;
  dateFormat?: ('day' | 'quarter') | null;
  confidential?: boolean;
  sharedFile: AssetsReportBuilderSharedFileDto | null;
};
export type UnprocessableEntityDto = {
  errors?: string[];
};
export type AssetsReportBuilderLegalEntityDto = {
  id: string;
  name: string;
  code: string;
};
export type TrailingPeriodTypeDto = {
  type: 'day' | 'month' | 'quarter';
  amount: number;
};
export type AssetsReportBuilderReportKpiCardWidgetConfigDto = {
  kpis: {
    key: number;
    label: string;
    expression: string;
    value_display_options: {
      type?:
        | 'number'
        | 'percentage'
        | 'currency'
        | 'metric'
        | 'sqft'
        | 'bps'
        | 'date';
      precision?: 0 | 1 | 2;
      kilo_formatting?: boolean;
      hide_comma_separator?: boolean;
    };
    period_type:
      | (
          | 'day'
          | 'week'
          | 'month'
          | 'quarter'
          | 'qtd'
          | 'year'
          | 'ytd'
          | 't3'
          | 't6'
          | 't12'
        )
      | TrailingPeriodTypeDto;
    period_shift?: {
      days?: number;
      weeks?: number;
      months?: number;
      quarters?: number;
      years?: number;
    };
  }[];
};
export type AssetsReportBuilderReportUnitMixSummaryTableWidgetConfigDto = {
  header_background?: string;
};
export type WidgetColumnComparisonDto = {
  to_col_id?: string;
  rule?:
    | 'negative_red_positive_green'
    | 'negative_green_positive_red'
    | 'negative_red';
  hide_negative_values?: boolean;
};
export type AssetsReportBuilderReportUnitTypeTableWidgetConfigDto = {
  viz_type: string;
  viz_config: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
      child_can_override_period?: boolean;
    }[];
    columns?: {
      col_id: string;
      key: string;
      group_id: string | null;
      column_group_show: string;
      hide_dates?: boolean;
      hidden?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      min_width?: number;
      max_width?: number;
      align?: 'left' | 'center' | 'right';
      value_display_options: {
        type?:
          | 'number'
          | 'percentage'
          | 'currency'
          | 'metric'
          | 'sqft'
          | 'bps'
          | 'date';
        precision?: 0 | 1 | 2;
        kilo_formatting?: boolean;
        hide_comma_separator?: boolean;
        date_format?:
          | 'YYYY'
          | 'MM'
          | 'MMM'
          | 'MM/DD/YYYY'
          | 'MMMM D, YYYY'
          | 'MMM D, YYYY'
          | 'Relative';
      };
      order: number;
      comparison?: WidgetColumnComparisonDto;
      border_color?: string | null;
      font_weight?: string | null;
    }[];
  };
  hide_floorplan_rows?: boolean;
  hide_total_row?: boolean;
  columns: {
    key: number;
    label: string;
    expression: string;
    period_type:
      | ('day' | 'week' | 'month' | 'mtd' | 'quarter' | 'qtd' | 'year' | 'ytd')
      | TrailingPeriodTypeDto;
    period_shift?: {
      days?: number;
      weeks?: number;
      months?: number;
      quarters?: number;
      years?: number;
    };
    unit_type_calculation_strategy: 'evaluate' | 'sum' | 'avg' | 'none';
    total_calculation_strategy: 'evaluate' | 'sum' | 'avg' | 'none';
  }[];
  default_options: {
    date?: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
  };
};
export type ValueDisplayOptionsConfigDto = {
  type:
    | 'number'
    | 'percentage'
    | 'currency'
    | 'metric'
    | 'sqft'
    | 'bps'
    | 'date';
  precision: 0 | 1 | 2;
  kilo_formatting: boolean;
  hide_comma_separator?: boolean;
  date_format?:
    | 'YYYY'
    | 'MM'
    | 'MMM'
    | 'MM/DD/YYYY'
    | 'MMMM D, YYYY'
    | 'MMM D, YYYY'
    | 'Relative';
};
export type VizConfigBackgroundDto = {
  background?:
    | {
        type: 'gradient';
        ignore_zeros?: boolean;
        thresholds: {
          type: 'min' | 'max' | 'number' | 'percentage' | 'percentile';
          value?: number;
        }[];
      }
    | {
        type: 'color';
        color: string;
      };
};
export type VizConfigAutoColumnDto = {
  header?: {
    title?: string;
  };
};
export type BaseFinancialTableWidgetConfigBaseColumnDto = {
  key: number;
  label: string;
  sub_label?: string;
  hide_sub_label?: boolean;
  adjustment_expression_name?:
    | 'financial_beginning_balance'
    | 'financial_ending_balance'
    | 'financial_debit'
    | 'financial_credit'
    | 'op_variance'
    | 'op_variance_percent'
    | 'op_budget'
    | 'uw_variance'
    | 'uw_variance_percent'
    | 'uw_budget'
    | 'financial_t1a'
    | 'financial_t3a';
  adjustment_average_per?:
    | 'unit'
    | 'unit_occupied'
    | 'unit_vacant'
    | 'unit_available'
    | 'unit_preleased'
    | 'sqft';
  value_display_options_override?: ValueDisplayOptionsConfigDto;
  period_shift?: {
    months?: number;
    quarters?: number;
    years?: number;
  };
};
export type BaseFinancialTableWidgetConfigRowBaseRowDto = {
  path: string;
  label?: string;
  children?: BaseFinancialTableWidgetConfigRowDto[];
  display_options?: {
    font_weight?: string;
    color?: string;
    background?: string;
  };
  value_display_options?: ValueDisplayOptionsConfigDto;
};
export type BaseFinancialTableWidgetConfigRowDto =
  | (BaseFinancialTableWidgetConfigRowBaseRowDto & {
      type: 'blank';
    })
  | (BaseFinancialTableWidgetConfigRowBaseRowDto & {
      type: 'formula';
      expression: string;
      negate_variance?: boolean;
      adjustment_expression_source_overrides?: {
        source: 'budget' | 'underwriting_budget';
        override_to: string;
      }[];
    })
  | (BaseFinancialTableWidgetConfigRowBaseRowDto & {
      type: 'category';
      category_path: string;
      hide_general_ledgers?: boolean;
    });
export type AssetsReportBuilderReportFinancialTableSingleDateWidgetConfigDto = {
  hide_header?: boolean;
  header_background?: string;
  viz_type?: string;
  viz_config?: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
    }[];
    columns?: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      hidden?: boolean;
      initial_sort?: string;
      hide_dates?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      min_width?: number;
      max_width?: number;
      align?: 'left' | 'center' | 'right';
      value_display_options?: ValueDisplayOptionsConfigDto;
      font_weight?: string | null;
      border_color?: string | null;
    }[];
    rows?: {
      key: string;
      value_display_options: ValueDisplayOptionsConfigDto;
      font_weight?: string;
      font_color?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    auto_column?: VizConfigAutoColumnDto;
    header_background?: string;
  };
  columns: (BaseFinancialTableWidgetConfigBaseColumnDto & {
    period_type:
      | ('month' | 'quarter' | 'year' | 'mtd' | 'qtd' | 'ytd' | 'itd')
      | TrailingPeriodTypeDto;
  })[];
  rows: BaseFinancialTableWidgetConfigRowDto[];
  default_options: {
    date: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
  };
};
export type AssetsReportBuilderReportFinancialTableSinglePeriodWidgetConfigDto =
  {
    period_types: (
      | 't3'
      | 't6'
      | 't12'
      | 'month'
      | 'quarter'
      | 'year'
      | 'itd'
    )[];
    hide_header?: boolean;
    header_background?: string;
    viz_type?: string;
    viz_config?: {
      column_groups?: {
        group_id: string;
        header_name: string;
        icon: string | null;
        background: string;
        border_color: string;
        order: number;
      }[];
      columns?: {
        col_id: string;
        key: string;
        order: number;
        group_id?: string | null;
        column_group_show?: string;
        hidden?: boolean;
        initial_sort?: string;
        hide_dates?: boolean;
        header?: {
          hide_title?: boolean;
          hide_subtitle?: boolean;
        };
        min_width?: number;
        max_width?: number;
        align?: 'left' | 'center' | 'right';
        value_display_options?: ValueDisplayOptionsConfigDto;
        font_weight?: string | null;
        border_color?: string | null;
      }[];
      rows?: {
        key: string;
        value_display_options: ValueDisplayOptionsConfigDto;
        font_weight?: string;
        font_color?: string;
        background?: VizConfigBackgroundDto;
        comparison?: {
          rule?:
            | 'negative_red_positive_green'
            | 'negative_red'
            | 'negative_green_positive_red';
          hide_negative_values?: boolean;
        };
      }[];
      auto_column?: VizConfigAutoColumnDto;
      header_background?: string;
    };
    columns: BaseFinancialTableWidgetConfigBaseColumnDto[];
    rows: BaseFinancialTableWidgetConfigRowDto[];
    default_options: {
      date: {
        period_shift?: {
          days?: number;
          weeks?: number;
          months?: number;
          quarters?: number;
          years?: number;
        };
      };
      period_type: 't3' | 't6' | 't12' | 'month' | 'quarter' | 'year' | 'itd';
    };
  };
export type AssetsReportBuilderReportXyChartWidgetConfigDto = {
  kpis: {
    key: number;
    expression: string;
  }[];
  granularities: ('day' | 'week' | 'month')[];
  default_options: {
    granularity: 'day' | 'week' | 'month';
    date_from: {
      period_type: 'day' | 'week' | 'month';
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
      };
    };
    date_to?: {
      period_type: 'day' | 'week' | 'month';
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
      };
    };
  };
  am_chart_config: object;
};
export type AdjustmentExpressionDto = {
  name:
    | 'financial_t1a'
    | 'financial_t3a'
    | 'financial_beginning_balance'
    | 'financial_ending_balance'
    | 'financial_debit'
    | 'financial_credit'
    | 'op_variance'
    | 'op_variance_percent'
    | 'op_variance_flipped'
    | 'op_variance_flipped_percent'
    | 'op_budget'
    | 'uw_variance'
    | 'uw_variance_percent'
    | 'uw_variance_flipped'
    | 'uw_variance_flipped_percent'
    | 'uw_budget';
  source_overrides?: {
    source: 'budget' | 'underwriting_budget';
    override_to: string;
  }[];
};
export type AssetsReportBuilderReportHistoricalReviewTableWidgetConfigDto = {
  viz_type?: string;
  viz_config?: {
    rows: {
      key: string;
      font_weight?: string;
      font_color?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    columns: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      initial_sort?: string;
      hidden?: boolean;
      hide_dates?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      min_width?: number;
      max_width?: number;
      align?: 'left' | 'center' | 'right';
      value_display_options?: ValueDisplayOptionsConfigDto;
      font_weight?: string;
    }[];
    column_groups: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
    }[];
  };
  columns: {
    key: number;
    label: string;
    period_type:
      | ('day' | 'week' | 'month' | 'quarter' | 'qtd' | 'year' | 'ytd')
      | TrailingPeriodTypeDto;
    period_shift?: {
      days?: number;
      weeks?: number;
      months?: number;
      quarters?: number;
      years?: number;
    };
  }[];
  rows: {
    key: number;
    label: string;
    type?: 'numeric' | 'text';
    expression: string;
    value_display_options?: ValueDisplayOptionsConfigDto;
    adjustment_expression?: AdjustmentExpressionDto;
  }[];
  default_options?: {
    date?: {
      period_shift?: {
        days?: number;
        weeks?: number;
        months?: number;
        quarters?: number;
        years?: number;
      };
    };
  };
};
export type AssetsReportBuilderReportTextAreaWidgetConfigDto = {
  text: string;
};
export type AssetsReportBuilderGlobalLeaseTableWidgetConfigDto = {
  viz_type: 'table';
  viz_config: {
    rows?: object[];
    columns: object[];
    column_groups?: object[];
    grid_options?: object;
  };
};
export type AssetsReportBuilderReportWidgetDto = {
  id: string;
  title: string | null;
  widgetType:
    | 'kpi_card'
    | 'unit_mix_table'
    | 'unit_mix_summary_table'
    | 'unit_type_table'
    | 'financial_table_single_date'
    | 'financial_table_single_period'
    | 'xy_chart'
    | 'recent_rent_roll_table'
    | 'delinquency_tracker_table'
    | 'historical_review_table'
    | 'text_area'
    | 'media'
    | 'global_lease_table';
  widgetConfig:
    | (
        | AssetsReportBuilderReportKpiCardWidgetConfigDto
        | AssetsReportBuilderReportUnitMixSummaryTableWidgetConfigDto
        | AssetsReportBuilderReportUnitTypeTableWidgetConfigDto
        | AssetsReportBuilderReportFinancialTableSingleDateWidgetConfigDto
        | AssetsReportBuilderReportFinancialTableSinglePeriodWidgetConfigDto
        | AssetsReportBuilderReportXyChartWidgetConfigDto
        | AssetsReportBuilderReportHistoricalReviewTableWidgetConfigDto
        | AssetsReportBuilderReportTextAreaWidgetConfigDto
        | AssetsReportBuilderGlobalLeaseTableWidgetConfigDto
      )
    | null;
  defaultOptions: {
    [key: string]: any;
  } | null;
  position: {
    y: number;
    w: number;
  };
  fontSize: number | null;
  snapshotContext: {
    date: string | null;
    dateFrom: string | null;
    dateTo: string | null;
    granularity: string | null;
  } | null;
};
export type AssetsReportBuilderReportGroupDto = {
  id: string;
  position: number;
  displayHeader: boolean | null;
  pageOrientation: 'portrait' | 'landscape';
  widgets: AssetsReportBuilderReportWidgetDto[];
};
export type AssetsReportBuilderReportDto = {
  id: string;
  name: string;
  subtitle: string | null;
  status: 'draft' | 'published';
  date: string;
  dateFormat?: ('day' | 'quarter') | null;
  displayHeader: boolean;
  legalEntities: AssetsReportBuilderLegalEntityDto[];
  groups: AssetsReportBuilderReportGroupDto[];
};
export type AssetsReportBuilderAssetDto = {
  id: number;
  name: string;
  slug?: string;
  address?: string;
  legalEntities: AssetsReportBuilderLegalEntityDto[];
};
export type ValueDisplayOptionsDto = {
  type:
    | 'number'
    | 'percentage'
    | 'currency'
    | 'metric'
    | 'sqft'
    | 'bps'
    | 'date';
  precision: 0 | 1 | 2;
  kiloFormatting: boolean;
  hideCommaSeparator: boolean;
  dateFormat?:
    | 'YYYY'
    | 'MM'
    | 'MMM'
    | 'MM/DD/YYYY'
    | 'MMMM D, YYYY'
    | 'MMM D, YYYY'
    | 'Relative';
};
export type AssetsReportBuilderKpiCardWidgetDto = {
  widgetType: 'kpi_card';
  kpis: {
    key: number;
    label: string;
    valueDisplayOptions: ValueDisplayOptionsDto;
    value: number | null;
  }[];
};
export type AssetsReportBuilderTextAreaWidgetDto = {
  widgetType: 'text_area';
  text: string;
};
export type AssetsReportBuilderMediaWidgetDto = {
  widgetType: 'media';
  items: {
    id: string;
    type: 'shared_file';
    dataUri: string;
  }[];
};
export type AssetsReportBuilderUnitMixTableWidgetDto = {
  widgetType: 'unit_mix_table';
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    path: string;
    label: string;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
};
export type AssetsReportBuilderUnitMixSummaryTableWidgetDto = {
  widgetType: 'unit_mix_summary_table';
  headerBackground: string | null;
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    path: string;
    label: string;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
  total: {
    path: string;
    label: string;
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  };
};
export type AssetsReportBuilderUnitTypeTableWidgetDto = {
  widgetType?: 'unit_type_table';
  columns: {
    key: number;
    label: string;
    dateFrom: string;
    dateTo: string;
  }[];
  data: {
    type: 'unit_type' | 'source_unit_type';
    label: string;
    path: string;
    additionalProperties?: number;
  }[];
};
export type AssetsReportBuilderFinancialTableSingleDateWidgetBaseGroupDto = {
  key: number;
  legalEntities: {
    id: string;
    name: string;
    code: string;
  }[];
};
export type AssetsReportBuilderFinancialTableSingleDateWidgetBaseRowDto = {
  path: string;
  label: string;
  displayOptions: {
    fontWeight: string | null;
    color: string | null;
    background: string | null;
  };
  valueDisplayOptions: ValueDisplayOptionsDto;
  values: {
    columnKey: number;
    groupKey: number;
    expression: string;
    value: number | null;
  }[];
};
export type AssetsReportBuilderFinancialTableSingleDateWidgetDto = {
  widgetType: 'financial_table_single_date';
  hideHeader: boolean;
  headerBackground: string | null;
  vizType?: string;
  vizConfig?: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
    }[];
    columns?: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      hidden?: boolean;
      initial_sort?: string;
      hide_dates?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      value_display_options?: ValueDisplayOptionsConfigDto;
      font_weight?: string | null;
      border_color?: string | null;
    }[];
    rows?: {
      key: string;
      value_display_options: ValueDisplayOptionsConfigDto;
      font_weight?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    auto_column?: VizConfigAutoColumnDto;
    header_background?: string;
  };
  groups: (
    | (AssetsReportBuilderFinancialTableSingleDateWidgetBaseGroupDto & {
        type: 'total';
      })
    | (AssetsReportBuilderFinancialTableSingleDateWidgetBaseGroupDto & {
        type: 'asset';
        asset: {
          id: number;
          name: string;
        };
      })
    | (AssetsReportBuilderFinancialTableSingleDateWidgetBaseGroupDto & {
        type: 'segment';
        segment: {
          id: number;
          name: string;
        };
      })
  )[];
  columns: {
    key: number;
    label: string;
    subLabel: string | null;
    dateFrom: string | null;
    dateTo: string | null;
    valueDisplayOptionsOverride?: ValueDisplayOptionsDto;
  }[];
  rows: (
    | (AssetsReportBuilderFinancialTableSingleDateWidgetBaseRowDto & {
        type: 'blank';
      })
    | (AssetsReportBuilderFinancialTableSingleDateWidgetBaseRowDto & {
        type: 'formula';
      })
    | (AssetsReportBuilderFinancialTableSingleDateWidgetBaseRowDto & {
        type: 'category';
      })
    | (AssetsReportBuilderFinancialTableSingleDateWidgetBaseRowDto & {
        type: 'general_ledger';
        legalEntityCode: string;
        erpSystem: string;
      })
  )[];
};
export type AssetsReportBuilderFinancialTableSinglePeriodWidgetBaseGroupDto = {
  key: number;
  legalEntities: {
    id: string;
    name: string;
    code: string;
  }[];
};
export type AssetsReportBuilderFinancialTableSinglePeriodWidgetBaseRowDto = {
  path: string;
  label: string;
  displayOptions: {
    fontWeight: string | null;
    color: string | null;
    background: string | null;
  };
  valueDisplayOptions: ValueDisplayOptionsDto;
  values: {
    columnKey: number;
    groupKey: number;
    expression: string;
    value: number | null;
  }[];
};
export type AssetsReportBuilderFinancialTableSinglePeriodWidgetDto = {
  widgetType: 'financial_table_single_period';
  hideHeader: boolean;
  headerBackground: string | null;
  vizType?: string;
  vizConfig?: {
    column_groups?: {
      group_id: string;
      header_name: string;
      icon: string | null;
      background: string;
      border_color: string;
      order: number;
    }[];
    columns?: {
      col_id: string;
      key: string;
      order: number;
      group_id?: string | null;
      column_group_show?: string;
      hidden?: boolean;
      initial_sort?: string;
      hide_dates?: boolean;
      header?: {
        hide_title?: boolean;
        hide_subtitle?: boolean;
      };
      value_display_options?: ValueDisplayOptionsConfigDto;
      font_weight?: string | null;
      border_color?: string | null;
    }[];
    rows?: {
      key: string;
      value_display_options: ValueDisplayOptionsConfigDto;
      font_weight?: string;
      background?: VizConfigBackgroundDto;
      comparison?: {
        rule?:
          | 'negative_red_positive_green'
          | 'negative_red'
          | 'negative_green_positive_red';
        hide_negative_values?: boolean;
      };
    }[];
    auto_column?: VizConfigAutoColumnDto;
    header_background?: string;
  };
  groups: (
    | (AssetsReportBuilderFinancialTableSingleDateWidgetBaseGroupDto & {
        type: 'total';
      })
    | (AssetsReportBuilderFinancialTableSinglePeriodWidgetBaseGroupDto & {
        type: 'asset';
        asset: {
          id: number;
          name: string;
        };
      })
    | (AssetsReportBuilderFinancialTableSinglePeriodWidgetBaseGroupDto & {
        type: 'segment';
        segment: {
          id: number;
          name: string;
        };
      })
  )[];
  columns: {
    key: number;
    label: string;
    subLabel: string | null;
    dateFrom: string | null;
    dateTo: string | null;
    valueDisplayOptionsOverride?: ValueDisplayOptionsDto;
  }[];
  rows: (
    | (AssetsReportBuilderFinancialTableSinglePeriodWidgetBaseRowDto & {
        type: 'blank';
      })
    | (AssetsReportBuilderFinancialTableSinglePeriodWidgetBaseRowDto & {
        type: 'formula';
      })
    | (AssetsReportBuilderFinancialTableSinglePeriodWidgetBaseRowDto & {
        type: 'category';
      })
    | (AssetsReportBuilderFinancialTableSinglePeriodWidgetBaseRowDto & {
        type: 'general_ledger';
        legalEntityCode: string;
        erpSystem: string;
      })
  )[];
};
export type AssetsReportBuilderXyChartWidgetDto = {
  widgetType: 'xy_chart';
  items: {
    values: {
      [key: string]: number;
    }[];
    dateFrom: string;
    dateTo: string;
  }[];
};
export type AssetsReportBuilderRecentRentRollTableWidgetDto = {
  widgetType: 'recent_rent_roll_table';
  columns: {
    key: number;
    label: string;
    shortLabel?: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    values: {
      key: number;
      value: number | null;
      withFallback: boolean;
    }[];
    label: string;
    path: string;
  }[];
  total: {
    values: {
      key: number;
      value: number | null;
    }[];
    path: string;
  };
  psf: {
    values: {
      key: number;
      value: number | null;
    }[];
    path: string;
  };
  latestParsings?: {
    legalEntityId: string;
    date: string;
  }[];
};
export type AssetsReportBuilderDelinquencyTrackerTableWidgetDto = {
  widgetType: 'delinquency_tracker_table';
  columns: {
    key: number;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
  total: {
    values: {
      key: number;
      value: (string | number) | null;
    }[];
  }[];
};
export type AssetsReportBuilderHistoricalReviewTableWidgetDto = {
  widgetType: 'historical_review_table';
  columns: {
    key: number;
    label: string;
    dateFrom: string;
    dateTo: string;
  }[];
  data: object[];
};
export type AssetsReportBuilderGlobalLeaseTableWidgetDto = {
  widgetType: 'global_lease_table';
  columns: {
    key: number;
    viz_config_key?: string;
    label: string;
    valueDisplayOptions?: ValueDisplayOptionsDto;
  }[];
  rows: {
    key: number;
    unit_id?: string;
    filtered_reasons?: string[];
    values: {
      key: number;
      value: (string | number | boolean) | null;
    }[];
  }[];
  filterOptions: {
    column?: string;
    options?: string[];
  }[];
  config: {
    viz_type?: string;
    viz_config?: {
      columns: {
        col_id: string;
        key:
          | 'tradeout'
          | 'prior_rent'
          | 'current_rent'
          | 'lease_type'
          | 'lease_status'
          | 'unit_type'
          | 'asset_name'
          | 'unit_label'
          | 'unit_sqft'
          | 'lease_signed_date'
          | 'lease_start'
          | 'lease_end'
          | 'resident_name'
          | 'term_months'
          | 'previous_lease_resident_name'
          | 'previous_lease_type'
          | 'previous_lease_start_date'
          | 'previous_lease_end_date'
          | 'previous_lease_term_months'
          | 'filtered_tradeout';
        order: number;
        group_id?: string | null;
        column_group_show?: string;
        initial_sort?: string;
        hidden?: boolean;
        hide_dates?: boolean;
        value_display_options?: ValueDisplayOptionsDto;
        comparison?: {
          rule?:
            | 'negative_red_positive_green'
            | 'negative_red'
            | 'negative_green_positive_red';
          hide_negative_values?: boolean;
        };
        font_weight?: string;
        header?: {
          hide_title?: boolean;
          hide_subtitle?: boolean;
        };
        min_width?: number;
        max_width?: number;
        align?: 'left' | 'center' | 'right';
      }[];
      column_groups: {
        group_id: string;
        header_name: string;
        icon: string | null;
        background: string;
        border_color: string;
        order: number;
      }[];
      auto_column?: VizConfigAutoColumnDto;
      header_background?: string;
    };
  };
  totalSize: number;
};
