import {
  LegalEntityManagmentPeriodsInline,
  useLegalEntityManagementPeriodsColorsMap,
} from '@/bundles/Settings/components/Portal/LegalEntities/components/LegalEntityManagmentPeriodsInline';
import { LegalEntityManagmentPeriodsMappingValues } from '@/bundles/Settings/components/Portal/LegalEntities/components/LegalEntityManagmentPeriodsMappingModal';
import { ManagementPeriodCard } from '@/bundles/Settings/components/Portal/LegalEntities/components/ManagementPeriodCard';
import { CoreLegalEntitiesManagementPeriodDto } from '@/entities/core/legalEntity/api/settingsCoreLegalEntitiesGeneratedApi';
import { CssVar } from '@/shared/config/cssVar';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { isBeforeDate } from '@/shared/lib/date';
import { formatDate } from '@/shared/lib/formatting/dates';
import {
  fillPeriodsDateTo,
  orderPeriodsByDateFrom,
  updatePeriodByDateFrom,
} from '@/shared/lib/period';
import { Button } from '@/stories';
import { useEffect, useMemo, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

export const FromTo = ({
  from,
  to,
}: {
  from: string | undefined | null;
  to: string | undefined | null;
}) => {
  return (
    <span className="flex gap-tw-2">
      {from && (
        <span className="text-neutral-850">
          <span className="text-neutral-550">From:</span>{' '}
          {formatDate(from, 'MMM DD, YYYY')}
        </span>
      )}
      {to && (
        <span className="text-neutral-850">
          <span className="text-neutral-550">To:</span>{' '}
          {formatDate(to, 'MMM DD, YYYY')}
        </span>
      )}
    </span>
  );
};

export const LegalEntityManagmentPeriodsMappingPeriods = () => {
  const initial = useRef(false);
  const { control, getValues } =
    useFormContext<LegalEntityManagmentPeriodsMappingValues>();
  const {
    fields: periods,
    update,
    replace,
  } = useFieldArray({
    name: 'periods',
    control,
  });

  const source = getValues('source');
  const legalEntity = getValues('legalEntity');
  const { name, managementPeriods } = legalEntity;

  const isMapped = Boolean(periods.length);

  const isSourceMapped = useMemo(() => {
    return periods.some(
      (period) => period.pmcManagedObjectId === source.pmcManagedObjectId,
    );
  }, [source, periods]);

  const handleSourceUpdate = (period: CoreLegalEntitiesManagementPeriodDto) => {
    const updatedPeriods = [...periods, { ...period, dateTo: null }];
    const sortedPeriods = orderPeriodsByDateFrom(updatedPeriods);
    const filledPeriods = fillPeriodsDateTo(sortedPeriods);

    replace(filledPeriods);
  };

  const handlePeriodUpdate = (period: CoreLegalEntitiesManagementPeriodDto) => {
    replace(
      updatePeriodByDateFrom(periods, {
        id: period.id,
        dateFrom: period.dateFrom
          ? formatDate(period.dateFrom, 'YYYY-MM-DD')
          : null,
      }),
    );
  };

  const colorsMap = useLegalEntityManagementPeriodsColorsMap(periods);
  const isLastPeriod = (index: number) => index === periods.length - 1;

  useEffect(() => {
    if (!initial.current) {
      replace(managementPeriods);
    }

    initial.current = true;
  }, [managementPeriods, source]);

  // prevent backend values from being used
  const formattedSource = {
    ...source,
    dateFrom: null,
    dateTo: null,
    rawDataPeriod: {
      dateFrom: source.dateFrom,
      dateTo: source.dateTo,
    },
  };

  if (!source) {
    return null;
  }

  return (
    <>
      <div className="inline-regular text-base font-medium text-neutral-850">
        Fill in the data for the data source
      </div>

      <LegalEntityManagmentPeriodsInline
        periods={periods}
        colorsMap={colorsMap}
      />

      {!isSourceMapped && (
        <ManagementPeriodCard
          period={formattedSource}
          className="shadow-[0px_4px_24px_8px_rgba(62,74,99,0.05)]"
        >
          <div className="flex min-h-[1.5rem] items-center justify-between gap-tw-4">
            <ManagementPeriodCard.Header indicatorColor={CssVar.neutral250} />
          </div>

          <ManagementPeriodCard.Body className="flex gap-tw-4">
            <div className="flex-1">
              <ManagementPeriodCard.DateForm
                label="Date From"
                onChange={(date) => {
                  handleSourceUpdate({
                    ...formattedSource,
                    dateFrom: date ? formatToDateStringForRequest(date) : null,
                  });
                }}
              />
            </div>
          </ManagementPeriodCard.Body>

          <ManagementPeriodCard.Body>
            <div className="flex flex-col gap-tw-4">
              <ManagementPeriodCard.Dates key={`${source.id}-dates`} />
            </div>
          </ManagementPeriodCard.Body>
        </ManagementPeriodCard>
      )}

      {isMapped && (
        <div className="space-y-tw-2">
          <p className="secondary-regular text-xs font-semibold uppercase text-neutral-550">
            {name} Data Sources
          </p>
          <div className="flex flex-col-reverse gap-tw-4">
            {periods.map((period, index) => (
              <ManagementPeriodCard
                key={period.id}
                period={period}
                className="shadow-[0px_4px_24px_8px_rgba(62,74,99,0.05)]"
              >
                <div className="flex min-h-[1.5rem] items-center justify-between gap-tw-4">
                  <ManagementPeriodCard.Header
                    indicatorColor={colorsMap.get(period.id)}
                  />
                  <div className="flex shrink-0 items-center gap-tw-2">
                    {isLastPeriod(index) &&
                      (!period.dateTo ? (
                        <Button
                          variant="secondary"
                          size="xs"
                          onClick={() => {
                            update(index, {
                              ...period,
                              dateTo:
                                period.dateFrom &&
                                isBeforeDate(new Date(), period.dateFrom)
                                  ? period.dateFrom
                                  : formatToDateStringForRequest(new Date()),
                            });
                          }}
                        >
                          Add Date To
                        </Button>
                      ) : (
                        <Button
                          variant="secondary"
                          size="xs"
                          onClick={() => {
                            update(index, {
                              ...period,
                              dateTo: null,
                            });
                          }}
                        >
                          Remove Date To
                        </Button>
                      ))}
                  </div>
                </div>

                <ManagementPeriodCard.Body className="flex gap-tw-4">
                  <div className="flex-1">
                    <ManagementPeriodCard.DateForm
                      value={period.dateFrom ? new Date(period.dateFrom) : null}
                      label="Date From"
                      maxDate={period.dateTo ? new Date(period.dateTo) : null}
                      onChange={(date) => {
                        handlePeriodUpdate({
                          ...period,
                          dateFrom: date
                            ? formatToDateStringForRequest(date)
                            : null,
                        });
                      }}
                    />
                  </div>
                  {isLastPeriod(index) && period.dateTo && (
                    <div className="flex-1">
                      <ManagementPeriodCard.DateForm
                        value={period.dateTo ? new Date(period.dateTo) : null}
                        label="Date To"
                        minDate={
                          period.dateFrom ? new Date(period.dateFrom) : null
                        }
                        onChange={(date) => {
                          update(index, {
                            ...period,
                            dateTo: date
                              ? formatToDateStringForRequest(date)
                              : null,
                          });
                        }}
                      />
                    </div>
                  )}
                </ManagementPeriodCard.Body>

                <ManagementPeriodCard.Body>
                  <div className="flex flex-col gap-tw-4">
                    <ManagementPeriodCard.Dates key={`${period.id}-dates`} />
                  </div>
                </ManagementPeriodCard.Body>
              </ManagementPeriodCard>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
