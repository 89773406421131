import CurrencyInputNumber from '@/bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';
import { CapitalInvestment } from '@/entities/return';
import { formatDate } from '@/shared/lib/formatting/dates';
import { CurrencyFormatter } from '@/stories';
import Table from 'bundles/Shared/components/Table/Table';
import { useMemo } from 'react';
import InvestmentEntityColumn from '../../ownershipTable/formatters/InvestmentEntityColumn';
import styles from '../capitalInvestmentsEditor/CapitaInvestmentEditor.module.scss';
import { firstContributionDateColumn } from '@/bundles/REturn/components/Ownership/modals/addAccruedModal/AccruedValuesEditor';

export const combineRowsWithSameAmount = (items: CapitalInvestment[]) => {
  const combinedItems = [];
  let currentCombinedItem = null as CapitalInvestment | null;

  items.forEach((item) => {
    if (
      currentCombinedItem &&
      currentCombinedItem.initialAmount === item.initialAmount
    ) {
      currentCombinedItem.endDate = item.endDate;
    } else {
      if (currentCombinedItem) {
        combinedItems.push(currentCombinedItem);
      }
      currentCombinedItem = { ...item };
    }
  });

  if (currentCombinedItem) {
    combinedItems.push(currentCombinedItem);
  }

  return combinedItems;
};

export const createAccruedTableItems = (items: CapitalInvestment[]) => {
  const flatItems = items.flatMap((item) =>
    item.entries.map((entry, index) => {
      return {
        ...item,
        ...entry,
        accruedStartDate: item.startDate,
        accruedEndDate: item.endDate,
        periodType: item.periodType,
        hidden: index !== 0,
      };
    }),
  );

  return combineRowsWithSameAmount(flatItems);
};

export const AccruedAmountValuesEditor = ({
  selectedCapitalInvestments,
  setSelectedCapitalInvestments,
}: {
  selectedCapitalInvestments: CapitalInvestment[];
  setSelectedCapitalInvestments: (value: CapitalInvestment[]) => void;
}) => {
  const tableItems = useMemo(() => {
    return createAccruedTableItems(selectedCapitalInvestments);
  }, [selectedCapitalInvestments]);

  const columns = useMemo(
    () => [
      {
        text: 'Investment Entity',
        dataField: 'investmentEntity',
        formatter: ({ row }) => {
          if (row.hidden) return;

          return (
            <InvestmentEntityColumn
              capitalInvestment={row}
              withIcon={false}
              withUsers={false}
              withLegalEntity
            />
          );
        },
      },
      firstContributionDateColumn(),
      {
        text: 'Capital Balance',
        dataField: 'capitalBalance',
        classes: 'text-right',
        headerStyle: {
          width: 140,
        },
        formatter: ({ row }) => {
          return (
            <CurrencyFormatter
              classes={{ value: 'text-neutral-900' }}
              value={row.capitalBalance}
            />
          );
        },
      },
      {
        text: (
          <div>
            <div className="mt-s flex items-center gap-s">Accrued Pref.</div>
          </div>
        ),
        dataField: 'amount',
        headerStyle: {
          width: 450,
        },
        formatter: ({ row }) => {
          const currentAmount = row.entries.find(
            (entry) =>
              entry.startDate >= row.startDate &&
              (entry.endDate ? entry.endDate <= row.endDate : true),
          )?.amount;

          return (
            <div className="flex gap-s">
              <div className="w-[280px] rounded-lg border border-neutral-150 bg-neutral-150 px-[12px] py-[7px]">
                <span className="text-neutral-550">From: </span>
                <span className="text-neutral-900">
                  {formatDate(row.startDate, 'MMM DD, YYYY')}
                </span>
                {row.endDate && (
                  <>
                    {' '}
                    <span className="text-neutral-550">To: </span>
                    <span className="text-neutral-900">
                      {formatDate(row.endDate, 'MMM DD, YYYY')}
                    </span>
                  </>
                )}
              </div>
              <div>
                <CurrencyInputNumber
                  value={currentAmount}
                  onValueChange={(value) => {
                    setSelectedCapitalInvestments((prev) => {
                      return prev.map((selectedCapitalInvestment) => {
                        if (selectedCapitalInvestment.id !== row.id)
                          return selectedCapitalInvestment;

                        return {
                          ...selectedCapitalInvestment,
                          entries: selectedCapitalInvestment.entries.map(
                            (entry) => {
                              const isDatesInRange =
                                entry.startDate >= row.startDate &&
                                entry.endDate <= row.endDate;

                              const shouldUpdate =
                                isDatesInRange ||
                                (!row.endDate &&
                                  entry.startDate >= row.startDate);

                              if (shouldUpdate) {
                                return { ...entry, amount: value };
                              }
                              return entry;
                            },
                          ),
                        } as CapitalInvestment;
                      });
                    });
                  }}
                  size="m"
                  prefix="("
                  suffix=")"
                  allowNegative={false}
                  placeholder="(0)"
                  classes={{
                    outer: 'w-[130px]',
                  }}
                />
              </div>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Table
        borderLessOutside
        classes={{
          table: styles.table,
          container: styles.container,
          th: 'align-bottom',
        }}
        defaultColumn={{
          headerClasses: 'bg-white pt-tw-2 light-60',
        }}
        items={tableItems}
        columns={columns}
      />
    </>
  );
};
