import { cn } from '@/shared/lib/css/cn';
import { ListOption } from '@/stories/Checkbox/CheckList';

export const InfoList = ({
  items,
  getItemProps,
}: {
  items: ListOption[];
  getItemProps?: (i: ListOption) => {
    label?: React.ReactNode;
    value?: React.ReactNode;
    classes?: { label?: string; value?: string };
  };
}) => (
  <div className="flex flex-col gap-tw-2">
    {items.map((item, idx) => {
      const itemProps = getItemProps?.(item);

      return (
        <div key={idx} className={cn('flex items-center gap-tw-1')}>
          <span
            className={cn(
              'secondary-regular text-neutral-550',
              itemProps?.classes?.label,
            )}
          >
            {itemProps?.label ?? item.label}
          </span>
          <span className="flex-1 self-end pb-tw-1">
            <span className="border-b-2 border-dotted border-neutral-250" />
          </span>
          <span
            className={cn(
              'secondary-regular text-neutral-550',
              itemProps?.classes?.value,
            )}
          >
            {itemProps?.value ?? item.value}
          </span>
        </div>
      );
    })}
  </div>
);
