import {
  getTableCellBackgroundStyle,
  getTableCellFontSizeStyle,
} from '@/bundles/Shared/components/AgGrid/Table/utils/getTableCellStyle';

export const buildTableCellStyles = (displayOptions: React.CSSProperties) => {
  return {
    ...displayOptions,
    ...(displayOptions.background != null
      ? getTableCellBackgroundStyle(
          displayOptions.background as React.CSSProperties['color'],
        )
      : {}),
    ...(displayOptions.fontSize != null
      ? getTableCellFontSizeStyle(displayOptions.fontSize as number)
      : {}),
  } as React.CSSProperties;
};
