import { NotFound } from '@/bundles/Errors';
import {
  useGetApiSettingsReportBuilderGotenbergTemplatesByIdQuery,
  useGetApiSettingsReportBuilderGotenbergTemplatesMetaQuery,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type {
  GetApiSettingsReportBuilderGotenbergTemplatesByIdApiArg,
  GetApiSettingsReportBuilderGotenbergTemplatesMetaApiArg,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { resolveReportBuilderGroupHeader } from '@/entities/report/reportBuilder/lib/resolveReportBuilderGroupHeader';
import { ReportPDFHeader } from '@/entities/report/reportBuilder/ui/ReportPDFHeader';
import { useReportBuilderTemplateScreenParams } from '@/shared/lib/hooks/navigation/dashboardsNavitation';
import { useAssetIdSearchParam } from '@/shared/lib/hooks/navigation/useAssetIdSearchParam';
import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import { PdfUI } from '@/shared/ui/PdfUI';
import { AnimationLoader } from '@/stories';
import { type RouteComponentProps } from '@reach/router';
import { ReportBuilderTemplateWidget } from 'bundles/Shared/widgets/dashboard/widgetsHelpers/ui/ReportBuilderWidget';
import { useMemo, type FC } from 'react';

export const ReportBuilderTemplateViewPage: FC<RouteComponentProps> = () => {
  const params = useReportBuilderTemplateScreenParams();
  const [searchParams] = useSearchParams();
  const assetId = useAssetIdSearchParam();
  const hidePlaceholders = Boolean(searchParams.get('hidePlaceholders'));
  const { data, isLoading } =
    useGetApiSettingsReportBuilderGotenbergTemplatesByIdQuery({
      id: params.templateId,
    } as GetApiSettingsReportBuilderGotenbergTemplatesByIdApiArg);
  const { data: metaData, isLoading: isMetaDataLoading } =
    useGetApiSettingsReportBuilderGotenbergTemplatesMetaQuery(
      {} as GetApiSettingsReportBuilderGotenbergTemplatesMetaApiArg,
    );

  const asset = useMemo(() => {
    return metaData?.assets.find((a) => a.id === Number(assetId));
  }, [metaData, assetId]);

  if (isLoading || isMetaDataLoading) return <AnimationLoader />;

  if (data == null || metaData == null) {
    return <NotFound />;
  }
  return (
    <PdfUI>
      {data.groups.map((g, gIdx) => (
        <PdfUI.WidgetGroup
          hidePlaceholders={hidePlaceholders}
          key={g.id}
          {...g}
        >
          {resolveReportBuilderGroupHeader({
            displayHeaderGlobal: data.displayHeader,
            displayHeaderGroup: g.displayHeader,
          }) && (
            <ReportPDFHeader
              objectName={asset?.name ?? ''}
              reportTitle={data.name}
            />
          )}
          {g.widgetSections.map((w, wIdx) => (
            <ReportBuilderTemplateWidget
              key={w.id}
              widgetSection={{ ...w, groupId: g.id }}
              pageOrientation={g.pageOrientation}
              isLastInGroup={
                gIdx === data.groups.length - 1 &&
                wIdx === g.widgetSections.length - 1
              }
            />
          ))}
        </PdfUI.WidgetGroup>
      ))}
    </PdfUI>
  );
};
