import React, { useEffect, useMemo, useState } from 'react';
import { Button, LoadMoreButton, Modal, MultilineAlert } from 'stories';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import Table from 'bundles/Shared/components/Table/Table';
import {
  useGetApiSettingsReportGeneralLedgersQuickMapSuggestionsQuery,
  usePutApiSettingsReportGeneralLedgersQuickMappingMutation,
} from 'bundles/Settings/actions/report/generalLedgersApiEnhanced';
import { IColumn } from 'bundles/Shared/components/Table/types';
import { GetApiSettingsReportGeneralLedgersQuickMapSuggestionsApiResponse } from 'bundles/Settings/actions/report/generalLedgersApiGeneratedApi';
import { nameOf } from 'types/utils';
import SuggestedCategoryCell, {
  SuggestedCategoryContextMenu,
  SuggestedCategoryContextMenuParams,
} from 'bundles/Settings/components/REport/GeneralLedgers/quickMapping/SuggestedCategoryCell';

import { FinancialCategory } from '@/entities/finanicalCategory/model';
import LineItemsCountCell from 'bundles/Settings/components/REport/GeneralLedgers/Table/columns/LineItemsCountCell';

type Props = DialogProps;

export type GeneralLedger =
  GetApiSettingsReportGeneralLedgersQuickMapSuggestionsApiResponse['items'][number] & {
    selectedCategory: FinancialCategory['id'] | null | 'ignored';
  };

function QuickMappingModal({ onClose }: Props) {
  const [page, setPage] = useState(1);
  const [quickMap] =
    usePutApiSettingsReportGeneralLedgersQuickMappingMutation();
  const { data, isFetching } =
    useGetApiSettingsReportGeneralLedgersQuickMapSuggestionsQuery({
      page,
      perPage: 10,
    });
  const [generalLedgers, setGeneralLedgers] = useState<GeneralLedger[]>([]);

  useEffect(() => {
    if (data == null) {
      return;
    }
    const { items } = data;
    const itemsWithSelectedCategory = items.map((item) => ({
      ...item,
      selectedCategory:
        item.suggestedReportFinancialCategories.length === 1
          ? item.suggestedReportFinancialCategories[0].id
          : null,
    }));
    const includePrevItems = page > 1;
    setGeneralLedgers((prev) =>
      includePrevItems
        ? [...prev, ...itemsWithSelectedCategory]
        : itemsWithSelectedCategory,
    );
  }, [data]);

  const onSelectedChange = (
    row: GeneralLedger,
    id: GeneralLedger['selectedCategory'],
  ) => {
    setGeneralLedgers((prev) =>
      prev.map((item) => {
        if (item.id === row.id) {
          return {
            ...item,
            selectedCategory: id,
          };
        }

        return item;
      }),
    );
  };

  const handleSubmit = async () => {
    await quickMap({
      body: {
        mappings: generalLedgers.map((item) => ({
          id: item.id,
          report_financial_category_id:
            item.selectedCategory !== 'ignored'
              ? item.selectedCategory
              : undefined,
          ignored: item.selectedCategory === 'ignored' ? true : undefined,
        })),
      },
    });
    setPage(1);
  };

  const columns = useMemo<IColumn<GeneralLedger>[]>(
    () => [
      {
        dataField: nameOf<GeneralLedger>('accNumber'),
        text: 'Account',
        headerClasses: 'w-1/4',
      },
      {
        dataField: nameOf<GeneralLedger>('accTitle'),
        text: 'Account Title',
        headerClasses: 'w-1/4',
      },
      {
        text: 'Items count',
        dataField: nameOf<GeneralLedger>('lineItemsCount'),
        headerClasses: 'w-1/4',
        formatter: ({ row }) => (
          <LineItemsCountCell value={row.lineItemsCount} data={row} />
        ),
      },
      {
        dataField: nameOf<GeneralLedger>('suggestedReportFinancialCategories'),
        text: 'Suggested Category',
        headerClasses: 'w-1/4',
        formatter: SuggestedCategoryCell,
        classes: 'cursor-pointer',
        contextMenu: SuggestedCategoryContextMenu,
        contextMenuParams: {
          onSelectedChange,
        } satisfies SuggestedCategoryContextMenuParams,
      },
    ],
    [],
  );

  const canSubmit = generalLedgers.every(
    (item) => item.selectedCategory != null,
  );

  return (
    <Modal
      toggle={onClose}
      header={'Quick Mapping'}
      size="900"
      classes={{
        body: 'bg-neutral-100',
      }}
      zIndex={1}
      actions={
        <>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!canSubmit}
            variant="success"
          >
            Apply Changes
          </Button>
        </>
      }
    >
      <div className="flex flex-col gap-tw-2">
        <MultilineAlert
          classes={{
            body: 'py-tw-2',
          }}
          icon="acceptAlt"
          message="Please check the following suggested categories for these accounts, be sure to mark as ignore if we're wrong."
          status="info"
        />
        <Table
          borderLessOutside
          classes={{
            table: 'table-fixed',
          }}
          columns={columns}
          items={generalLedgers}
        />
        {data && data.meta.perPage < data.meta.totalSize && (
          <LoadMoreButton
            isLoading={isFetching}
            className="!bg-neutral-000"
            onClick={() => setPage((prev) => prev + 1)}
            fluid
          >
            Load More
          </LoadMoreButton>
        )}
      </div>
    </Modal>
  );
}

export default QuickMappingModal;
