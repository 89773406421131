import { LegalEntities } from '@/widgets/core/legalEntity/settings';
import { Router, useMatch } from '@reach/router';
import Assets from 'bundles/Assets/components/Assets';
import ActivityLogListModal from 'bundles/Settings/components/Portal/LegalEntities/ActivityLogListModal';
import ContainerWithAppBar from 'bundles/Shared/components/Containers/ContainerWithAppBar';
import AppBar from 'bundles/Shared/components/Containers/appBar/AppBar';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { Button } from 'stories';
import FundCardList from '../../Funds/components/FundCardList';
import EntitiesTracker from '../components/Portal/InvestmentEntities/EntitiesTracker';
import PortalCoreSettings from '../components/Portal/PortalCoreSettings';
import PortalSettingsNavigation from '../components/Portal/PortalSettingsNavigation';
import { LegalEntitiyManagementPeriods } from '@/widgets/core/legalEntity/settings/ui/periods';

const CoreSettings = () => {
  const { openModal } = useModal();

  const rightActionAvailable = useMatch(
    ROUTES_ROOT.settings.portal.legalEntities.fullPath,
    ROUTES_ROOT.settings.portal.legalEntities.periods.fullPath,
  );

  const rightActionOnClick = () => {
    switch (rightActionAvailable?.path) {
      case ROUTES_ROOT.settings.portal.legalEntities.fullPath:
        return () => openModal(ActivityLogListModal);
      default:
        return undefined;
    }
  };

  return (
    <ContainerWithAppBar
      appBar={
        <AppBar
          title="coRE Settings"
          className="justify-between gap-m"
          classes={{
            title: 'header3-bold',
          }}
        >
          <Button
            iconName="reload"
            variant="secondary"
            className={rightActionAvailable ? '' : 'd-none'}
            onClick={rightActionOnClick()}
          >
            Activity Log
          </Button>
        </AppBar>
      }
    >
      <PortalSettingsNavigation className="mgmt-tabs-underline pb-l" />
      <Router primary={false}>
        <PortalCoreSettings path="core" />
        <FundCardList
          isCreate
          editable
          path="funds"
          containerClassName="my-m"
        />
        <Assets view="table" isCreate isEdit path="assets" />
        <EntitiesTracker path="investment-entities" />
        <LegalEntities path="legal-entities">
          <LegalEntitiyManagementPeriods path=":legalEntityId/periods" />
        </LegalEntities>
      </Router>
    </ContainerWithAppBar>
  );
};

export default CoreSettings;
