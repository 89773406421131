import FlyBottomPanelContainer from 'bundles/Settings/components/REport/CategoriesList/PathBar/FlyBottomPanelContainer';
import TreeViewWithNavigation from 'bundles/Settings/components/REport/CategoriesList/TreeViewWithNavigation/TreeViewWithNavigation';
import {
  LeClassification,
  LeClassificationModal,
  resolveAvailableAssetClassesForCategory,
} from 'bundles/Shared/entities/leClasssification';
import { FinancialCategory } from '@/entities/finanicalCategory/model';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { transformTree, treeDFS } from 'lib/treeHelpers';
import { isEmpty } from 'lodash-es';
import * as React from 'react';
import { useState } from 'react';
import Button from 'stories/Button/Button';
import { Modal } from '@/stories';
import EditFinancialCategoryModal from '@/features/financialCategory/edit/ui/EditFinancialCategoryModal';
import NewSubFinancialCategoriesModal from '@/widgets/financialCategory/manage/ui/NewSubFinancialCategoriesModal';
import SetFinancialCategoryModal from '@/widgets/financialCategory/set/ui/SetFinancialCategoryModal';

interface Props
  extends DialogProps<FinancialCategoryNode[]>,
    Pick<React.ComponentProps<typeof Modal>, 'header'> {
  categories: FinancialCategory[];
  readonly?: boolean;
}

export interface FinancialCategoryNode extends FinancialCategory {
  name: string;
  children: FinancialCategoryNode[];
  parentId: number | null;
  title: string;
  isNew?: boolean;
  expanded?: boolean;
}

// all state mutation works for now, because d3 manageTags dom,
// can breaks if react will manageTags dom
function ManageFinancialCategoriesModal({
  onClose,
  onSubmit,
  categories: initialCategories,
  ...props
}: Props) {
  const [_, setIsSubmitting] = useState(false);
  const { openModal } = useModal();
  const [categories, setCategories] = useState<FinancialCategoryNode[]>(
    initialCategories.map((c) =>
      transformTree(
        c,
        (cx) =>
          ({
            ...cx,
            // TODO: used for unit types and financial categories pass from outside
            name: cx.code ?? cx.name,
            title: cx.code ?? cx.name,
            expanded: true,
          }) as FinancialCategoryNode,
      ),
    ),
  );
  const handleRename = async (category: FinancialCategoryNode) => {
    const res = await openModal(EditFinancialCategoryModal, {
      category,
    });
    if (!res) {
      return;
    }
    const categoryToUpdatePath = treeDFS(
      categories,
      (node) => node.id === category?.id,
    );
    const categoryToUpdate = categoryToUpdatePath?.at(-1);

    if (categoryToUpdate) {
      categoryToUpdate.code = res.name;
      categoryToUpdate.name = res.name;
    }

    setCategories([...categories]);
  };

  const handleChangeCategoryParent = async (data: FinancialCategoryNode) => {
    const categoryToUpdatePath = treeDFS(
      categories,
      (node) => node.id === data.id,
    );
    if (categoryToUpdatePath == null) {
      return;
    }
    const categoryToUpdate = categoryToUpdatePath.at(-1)!;
    const oldCategoryParent = categoryToUpdatePath.at(-2);

    const newCategoryParent = await openModal(SetFinancialCategoryModal, {
      categories,
      selectedCategory: data,
      everyNodeWithCheckbox: true,
    });
    if (!newCategoryParent) {
      return;
    }

    const newCategoryParentPath = treeDFS(
      categories,
      (node) => node.id === newCategoryParent.id,
    );
    const newCategoryParentToUpdate = newCategoryParentPath!.at(-1)!;
    newCategoryParentToUpdate.children?.push(categoryToUpdate);

    if (oldCategoryParent) {
      oldCategoryParent.children = oldCategoryParent.children?.filter(
        (c) => c.id !== categoryToUpdate.id,
      );
      setCategories([...categories]);
    } else {
      // if no parent, then it's a root category, remove it from categories
      setCategories(
        [...categories].filter((c) => c.id !== categoryToUpdate.id),
      );
    }
  };

  const handleCreateCategory = async (
    data: FinancialCategoryNode,
    createMode: 'below' | 'sub',
  ) => {
    const newCategories = await openModal(NewSubFinancialCategoriesModal, {
      createMode,
      current: data.name,
    });
    if (newCategories == null) {
      return;
    }
    const newCategoriesNodes = newCategories.map((c, i) => ({
      id: new Date().getTime() + i,
      isNew: true,
      code: c,
      name: c,
      title: c,
    }));
    const categoryToUpdatePath = treeDFS(categories, (node) =>
      createMode === 'sub'
        ? node.id === data.id
        : node.children?.some((cx) => cx.id === data.id),
    );
    const categoryToUpdate = categoryToUpdatePath?.at(-1);

    if (categoryToUpdate == null) {
      setCategories([...categories, ...newCategoriesNodes]);
    } else {
      categoryToUpdate.children = [
        ...(categoryToUpdate.children ?? []),
        ...newCategoriesNodes,
      ];

      setCategories([...categories]);
    }
  };

  const handleCreateNearby = (data: FinancialCategoryNode) => {
    handleCreateCategory(data, 'below');
  };

  const handleCreateSub = (data: FinancialCategoryNode) => {
    handleCreateCategory(data, 'sub');
  };

  const handleRemove = (data: FinancialCategoryNode) => {
    const categoryToUpdatePath = treeDFS(
      categories,
      (node) => node.id === data.id,
    );
    const categoryToUpdate = categoryToUpdatePath?.at(-2);

    if (categoryToUpdate == null) {
      setCategories(categories.filter((c) => c.id !== data.id));
    } else {
      categoryToUpdate.children = categoryToUpdate.children?.filter(
        (c) => c.id !== data.id,
      );
      setCategories([...categories]);
    }
  };

  const handleSpecifyAssetClasses = async (data: FinancialCategoryNode) => {
    const categoryToUpdatePath = treeDFS(
      categories,
      (node) => node.id === data.id,
    )!;
    const categoryToUpdate = categoryToUpdatePath.at(-1)!;

    const { availableAssetClasses, requiredAssetClasses } =
      resolveAvailableAssetClassesForCategory({
        categoryPath: categoryToUpdatePath,
      });

    const res = await openModal(LeClassificationModal, {
      isMulti: true,
      defaultClass: isEmpty(data.classifications)
        ? requiredAssetClasses
        : data.classifications,
      availableClasses: isEmpty(availableAssetClasses)
        ? undefined
        : availableAssetClasses,
      isDefaultClassesClearable: isEmpty(requiredAssetClasses),
    });

    if (res == null) {
      return;
    }

    categoryToUpdate.classifications = res as LeClassification[];
    setCategories([...categories]);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await onSubmit?.(categories);
    setIsSubmitting(false);
  };

  const actions = [
    {
      text: 'Rename',
      handler: handleRename,
    },
    {
      text: 'New Category Below',
      handler: handleCreateNearby,
    },
    {
      text: 'Create Sub Category',
      handler: handleCreateSub,
    },
    {
      text: 'Change Parent Category',
      handler: handleChangeCategoryParent,
    },
    {
      text: 'Specify Legal Entity Classes',
      handler: handleSpecifyAssetClasses,
      disabled: (data) => isEmpty(data.children),
    },
    {
      text: 'Remove',
      handler: handleRemove,
    },
  ];

  return (
    <Modal
      size="huge"
      header="Categories"
      bodyPadding="0"
      toggle={onClose}
      {...props}
    >
      <div className="h-full w-full">
        <TreeViewWithNavigation
          data={categories}
          onChange={setCategories}
          actions={actions}
          showMultipleRootsParent
          multipleRootsParentName="Categories"
          readonlyNavigation
          handleManageAssetClasses={handleSpecifyAssetClasses}
        />
      </div>
      <FlyBottomPanelContainer>
        <Button variant="success" size="s" onClick={handleSubmit}>
          Save
        </Button>
      </FlyBottomPanelContainer>
    </Modal>
  );
}

export default ManageFinancialCategoriesModal;
